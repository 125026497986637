import React from 'react'
import {LinkArrow} from '~/images/LinkArrow'
import type {Course} from './useSelfGuidanceToolStore'

export const CourseCard = ({course, handleOnClick}: {course: Course; handleOnClick: (courseUrl: string) => void}) => {
	return (
		<button
			className="flex flex-shrink-0 flex-col items-start overflow-hidden text-left text-gray-600 hover:no-underline"
			onClick={() => handleOnClick(course.url)}
		>
			<div className="flex-shrink-0">
				<img
					className="h-[215] w-full object-cover"
					src={course.thumbnailFileIdUrl}
					alt={course.title}
				/>
			</div>
			<div className="flex flex-1 flex-col bg-white pt-[12px]">
				<h4 className="pb-0">{course.title}</h4>
				<div className="mt-auto text-blue-700">
					View in LinkedIn Learning{' '}
					<LinkArrow
						className="ml-1.5 text-blue-700"
						url={course.url}
					/>
				</div>
			</div>
		</button>
	)
}
