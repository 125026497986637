import React from 'react'
import {Icon} from '~/blocks/Icon'
import {FrontpageNewsArticle, FrontpageNewsCard} from '~/cards/FrontpageNewsCard'
import {queryDataSet, queryHref, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {useSideScrollButtons} from '~/utils/useSideScrollButtons'
import {cx} from '~/utils/utils'

type SectionPadding = 'none' | 'half' | 'full'
type Background = 'white' | 'gray'

interface SectionProps {
	background?: Background
	paddingTop?: SectionPadding
	paddingBottom?: SectionPadding
}

interface FrontpageNewsProps extends SectionProps {
	title?: string
	articles: FrontpageNewsArticle[]
	viewAllUrl?: string
	viewAllLabel?: string
	viewAllMobileLabel?: string
}

function getSectionProps(element: HTMLElement, props: SectionProps) {
	props.background = element.dataset.background?.toLowerCase() as Background
	props.paddingTop = element.dataset.paddingTop?.toLowerCase() as SectionPadding
	props.paddingBottom = element.dataset.paddingBottom?.toLowerCase() as SectionPadding
}

export function initFrontpageNews() {
	document.querySelectorAll<HTMLElement>('.sh-staff-news-highlights, .block-staff-news-highlights').forEach((element) => {
		const props: FrontpageNewsProps = {
			title: queryTextContent('h2', element),
			articles: Array.from(element.querySelectorAll<HTMLDivElement>('[data-sh-2021="home-news"]')).map((article) => ({
				postType: queryTextContent('.post-type', article),
				title: queryTextContent('.title', article),
				url: queryHref('a', article),
				imageUrl: queryDataSet('.image-url', article)?.bgImage ?? '',
				description: queryTextContent('.body', article),
				publishedDate: queryTextContent('.published-date', article),
			})),
			viewAllUrl: queryHref('a#view-all-news', element),
			viewAllLabel: queryTextContent('a#view-all-news', element),
			viewAllMobileLabel: queryDataSet('a#view-all-news', element)?.shortTitle,
		}
		getSectionProps(element, props)
		element.classList.add('no-container')
		render(<FrontpageNews {...props} />, element)
	})
}

export function FrontpageNews(props: FrontpageNewsProps) {
	const {sideScrollRef, handleSideScrollLeftPointerDown, handleSideScrollLeftPointerUp, handleSideScrollRightPointerDown, handleSideScrollRightPointerUp} = useSideScrollButtons<HTMLDivElement>()

	return (
		<div className={cx(props.background === 'gray' && 'full-width-background before:bg-gray-50', 'mb-[-48px]')}>
			<div className="relative py-[48px]">
				<h2 className="block-uplift pb-[24px] text-2xl font-semibold leading-8 text-gray-900 md:pt-0">{props.title}</h2>
				<div className="hidden md:block">
					<div className="grid space-y-[24px] md:grid-cols-2 md:space-y-[0] md:space-x-[24px]">
						{props.articles.slice(0, 2).map((article) => (
							<FrontpageNewsCard
								key={article.title}
								article={article}
								imageClass="h-[330px]"
							/>
						))}{' '}
						{/* Posts 1 and 2 */}
					</div>
					<div className="grid space-y-[24px] !pt-[21px] md:grid-cols-3 md:space-y-[0] md:space-x-[24px]">
						{props.articles.slice(2, 5).map((article) => (
							<FrontpageNewsCard
								key={article.title}
								article={article}
								imageClass="h-[256px]"
							/>
						))}{' '}
						{/* Posts 3, 4 and 5 */}
					</div>
				</div>
				<div
					ref={sideScrollRef}
					className="hide-horizontal-scrollbar -mx-4 flex gap-x-[18px] overflow-y-hidden px-4 pt-[16px] pb-[20px] sm:-mx-6 sm:px-6 md:hidden"
				>
					{props.articles.map((article) => (
						<FrontpageNewsCard
							key={article.title}
							article={article}
							imageClass="h-[200px]"
						/>
					))}
				</div>
				<div className="flex justify-between md:pt-[24px]">
					<div className="flex space-x-2 md:invisible">
						<button
							className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
							onPointerDown={handleSideScrollLeftPointerDown}
							onPointerUp={handleSideScrollLeftPointerUp}
							aria-label="scroll left"
						>
							<Icon
								icon="chevron-left"
								className="mr-[2px] h-[22px] w-[22px]"
							/>
						</button>
						<button
							className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
							onPointerDown={handleSideScrollRightPointerDown}
							onPointerUp={handleSideScrollRightPointerUp}
							aria-label="scroll right"
						>
							<Icon
								icon="chevron-right"
								className="ml-[2px] h-[22px] w-[22px]"
							/>
						</button>
					</div>
					<div className="flex items-center">
						<a
							className="items-center text-sm font-medium leading-5 text-blue-700 hover:underline"
							href={props.viewAllUrl}
						>
							<span className="hidden md:inline">{props.viewAllLabel}</span>
							<span className="md:hidden">{props.viewAllMobileLabel}</span>
							<Icon
								icon="arrow-forward"
								className="mb-[5px] ml-[4px] inline h-[16px]"
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
