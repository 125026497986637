import {Popover, Transition} from '@headlessui/react'
import React, {Fragment} from 'react'
import {Icon} from '~/blocks/Icon'
import {headerNavConfig, Link, NavSection} from '~/global/header/HeaderNavDesktop'
import {IconInBox} from '~/images/IconInBox'
import {cx} from '~/utils/utils'

export function HeaderNavMobile() {
	return (
		<Popover className="ml-4 flex-shrink-0 md:hidden">
			{({open, close}) => (
				<>
					<div>
						<Popover.Button className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-gray-50 p-2 text-sm font-medium leading-5 text-gray-900 hover:text-gray-500 focus:bg-gray-200">
							<span className="sr-only">Open main navigation menu</span>
							<Icon
								icon="menu"
								className="block h-6 w-6"
								aria-hidden="true"
							/>
						</Popover.Button>
					</div>
					<Transition
						show={open}
						as={Fragment}
						enter="transition ease-in-out duration-500"
						enterFrom="translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-500"
						leaveFrom="translate-x-0"
						leaveTo="translate-x-full"
						afterEnter={() => document.body.classList.add('header-menu-open')}
						beforeLeave={() => document.body.classList.remove('header-menu-open')}
					>
						<Popover.Panel
							static
							className="absolute left-0 top-0 z-50 h-screen w-full bg-white px-4 shadow-lg"
						>
							<div className="flex h-[80px] flex-row-reverse items-center text-base font-medium leading-6">
								<button
									onClick={close}
									className="mr-2 flex h-10 w-10 items-center justify-center rounded-full bg-gray-50 focus:bg-gray-400 focus:outline-none md:bg-white md:hover:bg-gray-100"
								>
									<Icon
										icon="close"
										className="float-right h-6 w-6 p-0 font-light"
									/>
								</button>
							</div>
							{headerNavConfig.map((section) => (
								<Section
									key={section.title}
									section={section}
									closeParent={() => close()}
								/>
							))}
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	)
}

function Section({section, closeParent}: {section: NavSection; closeParent: () => void}) {
	return (
		<Popover>
			{({open, close}) => (
				<>
					<Popover.Button className="flex h-[72px] w-full items-center rounded-md px-3 focus:bg-gray-100">
						<span className="font-medium">{section.title}</span>
						<Icon
							icon="arrow-forward"
							className="mb-[3px] ml-1.5 h-[18px] w-[18px] focus:bg-gray-400"
							aria-hidden="true"
						/>
					</Popover.Button>
					<Transition
						show={open}
						as={Fragment}
						enter="transition ease-in-out duration-500"
						enterFrom="translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-500"
						leaveFrom="translate-x-0"
						leaveTo="translate-x-full"
					>
						<Popover.Panel className="absolute left-0 top-0 z-[51] h-screen w-full bg-white">
							<div className="flex h-[80px] items-center justify-between px-3 text-base font-medium leading-6">
								<button
									onClick={close}
									className="mr-2 flex h-10 w-10 items-center justify-center rounded-full bg-gray-50 focus:bg-gray-400 focus:outline-none md:bg-white md:hover:bg-gray-100"
								>
									<Icon
										icon="arrow-back"
										className="float-right h-6 w-6 p-0 font-light"
									/>
								</button>
								<button
									onClick={closeParent}
									className="float-right mr-2 flex h-10 w-10 items-center justify-center rounded-full bg-gray-50 focus:bg-gray-400 focus:outline-none md:bg-white md:hover:bg-gray-100"
								>
									<Icon
										icon="close"
										className="float-right h-6 w-6 p-0 font-light"
									/>
								</button>
							</div>
							<div className="h-[calc(100svh-80px)]">
								<h2 className="block-uplift pb-[31px] pt-[25px] text-center font-medium">{section.title}</h2>
								<div className="skinny-scrollbar h-[calc(100svh-160px)] overflow-y-auto">
									{section.linkSections != null && (
										<div className="container">
											{section.linkSectionHeading != null &&
												(section.linkSectionHeadingUrl != null ? (
													<a href={section.linkSectionHeadingUrl}>
														<h3 className="mb-[24px] text-xl font-semibold leading-7 text-gray-900 underline">
															{section.linkSectionHeading}
															<Icon
																icon="chevron-right"
																className="ml-[8px] inline h-[20px]"
															/>
														</h3>
													</a>
												) : (
													<h3 className="text-xl font-semibold leading-7 text-gray-900">{section.linkSectionHeading}</h3>
												))}
											<div className="mt-[12px] mb-[54px] flex flex-col gap-y-[42px]">
												{section.linkSections.map((linkSection, index) => (
													<div key={index}>
														{linkSection.title != null && linkSection.url != null ? (
															<a href={linkSection.url}>
																<h4 className="!mt-0 !pb-0 text-lg font-semibold leading-6 text-gray-900 underline">
																	{linkSection.title}
																	<Icon
																		icon="chevron-right"
																		className="ml-[8px] inline h-[20px]"
																	/>
																</h4>
															</a>
														) : (
															<h4 className="!mt-0 !pb-0 text-lg font-semibold leading-6 text-gray-900">{linkSection.title}</h4>
														)}
														<div className={cx('ml-[8px] flex flex-col gap-y-[8px]', section.linkSectionHeading == null ? 'mt-[16px]' : 'mt-[4px]')}>
															{linkSection.links.map((link, index) => (
																<Link
																	key={index}
																	link={link}
																/>
															))}
														</div>
													</div>
												))}
											</div>
										</div>
									)}
									{section.menuItemSectionHeading != null &&
										(section.menuItemSectionHeadingUrl != null ? (
											<div className="container">
												<a href={section.menuItemSectionHeadingUrl}>
													<h3 className="!mt-[38px] mb-[24px] !p-0 pt-[24px] text-xl font-semibold leading-7 text-gray-900 underline">
														{section.menuItemSectionHeading}
														<Icon
															icon="chevron-right"
															className="ml-[8px] inline h-[20px]"
														/>
													</h3>
												</a>
											</div>
										) : (
											<h3 className="!mt-[38px] mb-[24px] !p-0 pt-[24px] text-xl font-semibold leading-7 text-gray-900">{section.menuItemSectionHeading}</h3>
										))}
									{section.menuItems != null &&
										section.menuItems?.map((menuItem) => (
											<div
												key={menuItem.title}
												className="px-4"
											>
												<a
													href={menuItem.url}
													target={menuItem.openInNewTab ? '_blank' : '_self'}
													className="flex flex-col justify-between rounded-lg px-3 py-4 !no-underline hover:bg-gray-50"
												>
													<div className="flex">
														{menuItem.icon != null && (
															<div className="pr-4">
																<IconInBox
																	colour={menuItem.icon === 'kb' ? 'navy' : menuItem.icon === 'form' ? 'yellow' : 'gray'}
																	icon={menuItem.icon === 'kb' ? 'support-article' : menuItem.icon}
																/>
															</div>
														)}
														<div className="">
															<div className="-mt-1 font-medium text-gray-900">{menuItem.title}</div>
															<p className="mt-1 pb-0 text-sm text-gray-500">{menuItem.description}</p>
															{menuItem.linkText && (
																<div className="mt-2 flex w-full items-center rounded-md pb-0 text-sm font-medium text-blue-700">
																	{menuItem.linkText}{' '}
																	<Icon
																		icon="arrow-forward"
																		className="ml-2 !h-4 !w-4"
																	/>
																</div>
															)}
														</div>
													</div>
												</a>
											</div>
										))}
									{section.additionalMenuItemSectionHeading != null &&
										(section.additionalMenuItemSectionHeadingUrl != null ? (
											<div className="container">
												<a href={section.additionalMenuItemSectionHeadingUrl}>
													<h3 className="!mt-[38px] mb-[24px] !p-0 pt-[24px] text-xl font-semibold leading-7 text-gray-900 underline">
														{section.additionalMenuItemSectionHeading}
														<Icon
															icon="chevron-right"
															className="ml-[8px] inline h-[20px]"
														/>
													</h3>
												</a>
											</div>
										) : (
											<h3 className="!mt-[38px] mb-[24px] !p-0 pt-[24px] text-xl font-semibold leading-7 text-gray-900">{section.additionalMenuItemSectionHeading}</h3>
										))}
									{section.additionalMenuItems != null &&
										section.additionalMenuItems?.map((menuItem) => (
											<div
												key={menuItem.title}
												className="px-4"
											>
												<a
													href={menuItem.url}
													target={menuItem.openInNewTab ? '_blank' : '_self'}
													className="flex flex-col justify-between rounded-lg px-3 py-4 !no-underline hover:bg-gray-50"
												>
													<div className="flex">
														{menuItem.icon != null && (
															<div className="pr-4">
																<IconInBox
																	colour={menuItem.icon === 'kb' ? 'navy' : menuItem.icon === 'form' ? 'yellow' : 'gray'}
																	icon={menuItem.icon === 'kb' ? 'support-article' : menuItem.icon}
																/>
															</div>
														)}
														<div className="">
															<div className="-mt-1 font-medium text-gray-900">{menuItem.title}</div>
															<p className="mt-1 pb-0 text-sm text-gray-500">{menuItem.description}</p>
															{menuItem.linkText && (
																<div className="mt-2 flex w-full items-center rounded-md pb-0 text-sm font-medium text-blue-700">
																	{menuItem.linkText}{' '}
																	<Icon
																		icon="arrow-forward"
																		className="ml-2 !h-4 !w-4"
																	/>
																</div>
															)}
														</div>
													</div>
												</a>
											</div>
										))}

									{section.footerMenuItems != null && (
										<div className="mt-3 bg-gray-50">
											<div className="space-y-6 px-4 py-5">
												{section.footerMenuItems?.map((menuItem) => (
													<a
														key={menuItem.title}
														href={menuItem.url}
														target={menuItem.openInNewTab ? '_blank' : '_self'}
														className="flex w-full  rounded-md p-3  text-base  hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
													>
														{menuItem.icon != null && (
															<IconInBox
																colour={menuItem.icon === 'form' ? 'yellow' : 'navy'}
																icon={menuItem.icon === 'kb' ? 'support-article' : menuItem.icon}
															/>
														)}
														<div className="ml-4">
															<div className="font-medium text-gray-900">{menuItem.title}</div>
															<div className="pt-[4px] text-sm font-normal">{menuItem.description}</div>
														</div>
													</a>
												))}
											</div>
										</div>
									)}
								</div>
							</div>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	)
}
