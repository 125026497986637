import React, {useRef} from 'react'
import {useLineCount} from '~/pages/search/useLineCount'
import {autoLink} from '~/utils/utils'
import {Icon} from '~/blocks/Icon'

export interface StaffNewsArticle {
	title: string
	url: string
	summary: string
	datePublished: string
	issueTitle: string
	issueUrl: string
}

interface StaffNewsArticleCardProps {
	article: StaffNewsArticle
}

export function StaffNewsArticleCard({article}: StaffNewsArticleCardProps) {
	const titleRef = useRef<HTMLDivElement>(null)
	const headerLineCount = useLineCount(titleRef, 25)

	return (
		<article className="focus-within:border-blue flex cursor-pointer flex-col rounded-lg border-2 border-transparent p-6 shadow hover:shadow-lg focus:outline-none">
			<a
				href={article.url}
				className="flex-grow text-gray-600 hover:no-underline"
			>
				<time className="">{article.datePublished}</time>
				<h3 className="block-uplift !pt-[16px] text-lg font-semibold leading-6 text-gray-900">{article.title}</h3>
				<div
					className={`word-break-word mt-3 break-words text-base line-clamp-${8 - headerLineCount}`}
					dangerouslySetInnerHTML={{__html: autoLink(article.summary)}}
				/>
			</a>
			<div className="flex justify-between pt-[24px]">
				{article.issueTitle ? (
					<a
						href={article.issueUrl}
						tabIndex={-1}
						className="mr-2 max-w-[83px] overflow-x-hidden whitespace-nowrap rounded-full border border-gray-300 bg-gray-50 px-[13px] py-1 text-xs font-medium leading-4 text-blue-700 ring-inset hover:bg-gray-100 hover:underline focus:border-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-700"
					>
						Issue {article.issueTitle}
					</a>
				) : (
					<div />
				)}
				<a
					href={article.url}
					className="text-sm font-medium leading-5 text-blue-700 hover:underline focus:outline-none"
				>
					Read full story{' '}
					<Icon
						icon="arrow-forward"
						className="mb-[4px] inline h-4 w-4"
					/>
				</a>
			</div>
		</article>
	)
}
