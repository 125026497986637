import React from 'react'
import {IconInBox} from '~/images/IconInBox'

export function DownloadIconInBox() {
	return (
		<IconInBox
			colour="red"
			icon="download"
		/>
	)
}
