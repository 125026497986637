import React from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'

interface ListGridViewButtonsParams {
	gridView: boolean
	setGridView: (gridView: boolean) => void
}

export function ListGridViewButtons(props: ListGridViewButtonsParams) {
	return (
		<div className="flex gap-[8px]">
			<button
				className={cx(
					props.gridView ? 'border-gray-600' : 'border-blue-700 bg-blue-700 text-white',
					'inline-flex cursor-pointer items-center items-center gap-[12px] rounded-[4px] border-2 px-[11px] py-[7px] text-xs font-medium leading-4',
				)}
				onClick={() => props.setGridView(false)}
			>
				<Icon
					icon="menu"
					className="h-[18px]"
				/>
				List
			</button>
			<button
				className={cx(
					!props.gridView ? 'border-gray-600' : 'border-blue-700 bg-blue-700 text-white',
					'inline-flex cursor-pointer items-center items-center gap-[12px] rounded-[4px] border-2 px-[11px] py-[7px] text-xs font-medium leading-4',
				)}
				onClick={() => props.setGridView(true)}
			>
				<Icon
					icon="grid"
					className="h-[16px]"
				/>
				Grid
			</button>
		</div>
	)
}
