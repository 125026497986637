import React from 'react'
import {IconAttachedToLastWord} from '~/components/IconAttachedToLastWord'
import {getSectionProps, type SectionProps, SectionWrapper} from '~/components/SectionWrapper'
import {render} from '~/utils/render'
import {cx, isUrlSameHost} from '~/utils/utils'
import './LinkListing.css'

interface LinkListingProps extends SectionProps {
	headingUrl?: string
	numberOfColumns: number
	links: {
		label: string
		url: string
	}[]
}

export function initLinkListing() {
	document.querySelectorAll<HTMLElement>('.staff-ui-link-listing').forEach((element) => {
		const sectionProps = getSectionProps(element)
		const props: LinkListingProps = {
			...sectionProps,
			headingUrl: element.querySelector<HTMLAnchorElement>('a.heading-link[href]:not([href=""]):not(:empty)')?.href,
			numberOfColumns: element.dataset.numberOfColumns ? parseInt(element.dataset.numberOfColumns) : 2,
			links: Array.from(element.querySelectorAll<HTMLAnchorElement>('a.child-link[href]:not([href=""]):not(:empty)')).map((link) => {
				return {
					label: link.textContent ?? '',
					url: link.href,
				}
			}),
		}
		element.classList.add('doesnt-need-gap-without-padding')
		render(<LinkListing {...props} />, element)
	})
}

export function LinkListing(props: LinkListingProps) {
	const rows = Math.ceil(props.links.length / props.numberOfColumns)

	const numberOfColumnsIncludingHeading = props.heading != null && props.heading.length > 0 ? props.numberOfColumns + 1 : props.numberOfColumns
	return (
		<SectionWrapper
			{...props}
			dontShowHeading={true}
		>
			<div
				className={cx(
					'flex flex-col gap-x-[24px] gap-y-[12px] md:grid md:gap-y-[16px]',
					numberOfColumnsIncludingHeading === 4 && 'grid-cols-3 lg:grid-cols-4',
					numberOfColumnsIncludingHeading === 3 && 'grid-cols-3',
					numberOfColumnsIncludingHeading === 2 && 'grid-cols-2',
				)}
			>
				{/* row-span-full was not working */}
				{props.heading && (
					<>
						<div
							className="row-start-1 pb-[12px] md:pb-0 lg:hidden"
							style={{gridRowEnd: rows + 2}}
						>
							{props.headingUrl != null && props.headingUrl.length > 0 ? (
								<a
									href={props.headingUrl}
									className="block-uplift block text-2xl font-semibold leading-8 text-gray-900 underline hover:decoration-2"
								>
									<IconAttachedToLastWord
										text={props.heading ?? ''}
										icon="chevron-right"
										className="ml-[8px] inline h-[28px]"
									/>
								</a>
							) : (
								<h2 className="block-uplift block text-2xl font-semibold leading-8 text-gray-900">{props.heading}</h2>
							)}
							{props.subheading != null && props.subheading.length > 0 && (
								<div
									className="description mt-[16px] text-base font-normal leading-6"
									dangerouslySetInnerHTML={{__html: props.subheading}}
								/>
							)}
						</div>
						<div
							className="row-start-1 hidden pb-[12px] md:pb-0 lg:block"
							style={{gridRowEnd: rows + 1}}
						>
							{props.headingUrl != null && props.headingUrl.length > 0 ? (
								<a
									href={props.headingUrl}
									className="block-uplift block text-2xl font-semibold leading-8 text-gray-900 underline hover:decoration-2"
								>
									<IconAttachedToLastWord
										text={props.heading ?? ''}
										icon="chevron-right"
										className="ml-[8px] inline h-[28px]"
									/>
								</a>
							) : (
								<h2 className="block-uplift block text-2xl font-semibold leading-8 text-gray-900">{props.heading}</h2>
							)}
							{props.subheading != null && props.subheading.length > 0 && (
								<div
									className="description mt-[16px] text-base font-normal leading-6"
									dangerouslySetInnerHTML={{__html: props.subheading}}
								/>
							)}
						</div>
					</>
				)}

				{props.links.map((link) => (
					<a
						key={link.label}
						href={link.url}
						className="block text-sm font-medium underline"
					>
						<IconAttachedToLastWord
							text={link.label}
							icon={isUrlSameHost(link.url) ? '' : 'external-link'}
							className="ml-[16px] inline-block h-[14px]"
						/>
					</a>
				))}
			</div>
		</SectionWrapper>
	)
}
