import React from 'react'
import {ModalButton, ModalButtonProps} from '~/components/ModalButton'
import {render} from '~/utils/render'
import './ModalUplift.css'

export function initModalUplift() {
	document.querySelectorAll<HTMLElement>('[data-modal-target]').forEach((element) => {
		const props: ModalButtonProps = {
			className: element.className.includes('button') ? element.className : element.className + ' sh-link',
			buttonContent: element.innerHTML,
			modalContent: document.querySelector('#' + element.dataset.modalTarget)?.innerHTML,
			title: element.textContent?.substring(0, 19),
		}

		const span = document.createElement('span')
		element.parentElement?.insertBefore(span, element)

		render(<ModalButton {...props} />, span)
	})
}
