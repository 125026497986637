// Matrix JS API update metadata function call this pushes the metadata string up to the user account

let js_api: any
export function updateMetadata(metadata: string) {
	if (js_api == null) {
		const options: any = []
		options['key'] = '3499802749'
		const matrixJsApi = (window as any).Squiz_Matrix_API
		js_api = new matrixJsApi(options)
	}

	js_api.setMetadata({
		asset_id: (window as any).user_assetid,
		field_id: 4116903,
		field_val: metadata,
		dataCallback: (apiResponseOnj: any) => {
			if (apiResponseOnj.error) {
				console.error('Error: ' + apiResponseOnj.error, apiResponseOnj)
			}
		},
	})
}

//Needed as CMS sanitises stored metadata as part of SAML login process - looks like it replaces reserved HTML characters with their Entity Name. So this does basic cleaning to replace &quot; with " so we can rebuild the JSON Data Object, and then returns that JSON obj.
export function cleanFetchData(dataString: string) {
	return dataString.replace(/&quot;/g, '"')
}
