import React from 'react'
import {render} from '~/utils/render'
import {cx} from '~/utils/utils'
import './Grid.css'

export interface GridProps {
	cols: string
	items: {
		span: string
		body: string
	}[]
}

export function initGrid() {
	document.querySelectorAll<HTMLElement>('.sh-grid,.staff-ui-grid').forEach((element) => {
		const props: GridProps = {
			cols: element.classList.contains('cols-3') || element.classList.contains('three-cols') ? '3' : '2',
			items: Array.from(element.children).map((item) => ({
				span: item.classList.contains('span-2') ? '2' : '1',
				body: item.outerHTML,
			})),
		}

		element.classList.add('section-spacing')

		render(<Grid {...props} />, element)
	})

	document.querySelectorAll<HTMLElement>('.half').forEach((element) => {
		element.classList.add('section-spacing')

		const props: GridProps = {
			cols: '2',
			items: Array.from(element.children).map((item) => ({
				span: '1',
				body: item.outerHTML,
			})),
		}

		render(<Grid {...props} />, element)
	})
}

export function Grid({cols, items}: GridProps) {
	return (
		<div className={cx(cols === '3' ? 'lg:grid-cols-3' : '', 'grid gap-[24px] pb-6 pt-4 md:grid-cols-2')}>
			{items.map((item, index) => (
				<div
					key={index}
					className={cx(item.span === '2' ? 'lg:col-span-2' : '')}
					dangerouslySetInnerHTML={{__html: item.body}}
				/>
			))}
		</div>
	)
}
