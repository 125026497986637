import React, {useRef, useState} from 'react'
import {useOnClickOutside} from 'usehooks-ts'
import {render} from '~/utils/render'
import {replaceWithNewDiv} from '~/utils/utils'
import {Icon} from '~/blocks/Icon'

interface EmbedSurveyProps {
	url?: string
	icon?: string
	title?: string
	timeLabel?: string
	linkLabel?: string
	height?: string
}

export function initEmbedSurvey() {
	document.querySelectorAll<HTMLAnchorElement>('.sh-embed-qualtrics-survey').forEach((element) => {
		const props: EmbedSurveyProps = {
			url: element.href,
			icon: element.dataset.icon,
			title: element.dataset.title,
			timeLabel: element.dataset.timeLabel,
			linkLabel: element.dataset.linkLabel,
			height: element.dataset.height,
		}

		const div = replaceWithNewDiv(element)

		render(<EmbedSurvey {...props} />, div)
	})
}

export function EmbedSurvey({url, icon, title, timeLabel, linkLabel, height}: EmbedSurveyProps) {
	const [isOpen, setIsOpen] = useState(false)
	const iframeRef = useRef<HTMLIFrameElement>(null)
	const clickOutsideRef = useRef<HTMLDivElement>(null)
	useOnClickOutside(clickOutsideRef, () => setIsOpen(false))

	return (
		<div
			ref={clickOutsideRef}
			className="mb-[24px] w-full rounded-md px-[20px] py-[10px] shadow"
		>
			<button
				className="hidden w-full justify-between md:flex"
				onClick={() => setIsOpen(!isOpen)}
			>
				<div>
					<Icon
						icon={icon ?? 'chat'}
						className="mr-[24px] inline-block h-[24px] w-[24px] text-uom-navy-900"
					/>
					<span className="mr-[.5rem] text-sm font-normal leading-5 text-gray-900">{title}</span>
					<span className="hidden text-xs font-medium leading-4 text-gray-600 lg:inline">{timeLabel}</span>
				</div>
				<div className="whitespace-nowrap text-blue-700">
					<span className="mr-[10px] text-sm font-medium leading-5">{linkLabel}</span>
					<Icon
						icon="chevron-up"
						className={`${isOpen ? 'rotate-180 transform' : ''} inline-block h-6 w-6`}
					/>
				</div>
			</button>
			<button
				className="flex w-full gap-x-[16px] md:hidden"
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-uom-navy-900 text-white">
					<Icon
						icon={icon ?? 'chat'}
						className="h-6 w-6 flex-shrink-0"
					/>
				</div>
				{/*<div className="flex flex-1 h-[40px] w-[40px] items-center justify-center rounded bg-uom-navy-900">{icon === 'chat-alt' && <ChatAltIcon className="h-[24px] w-[24px] text-white"/>}</div>*/}
				<div className="text-left">
					<div className="text-base font-medium leading-6 text-gray-900">Feedback</div>
					<div className="pt-[4px] text-sm font-normal leading-5 text-gray-600">{title}</div>
					<div className="whitespace-nowrap pt-[24px] text-blue-700">
						<span className="mr-[10px] text-sm font-medium leading-5">{linkLabel}</span>
						<Icon
							icon="chevron-up"
							className={`${isOpen ? 'rotate-180 transform' : ''} inline-block h-6 w-6`}
						/>
					</div>
				</div>
			</button>
			{isOpen && (
				<div className="py-4">
					<iframe
						ref={iframeRef}
						className="h-[] w-full border-0"
						height={height ?? '500px'}
						src={url}
					/>
				</div>
			)}
		</div>
	)
}
