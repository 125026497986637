import React from 'react'
import {StaffProfileCard} from '~/cards/StaffProfileCard'
import {SocialIcon} from '~/images/SocialIcon'
import {queryHref, querySrc, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import type {HeadingTags} from '~/utils/utils'
import {getBackgroundImage} from '~/utils/utils'
import './Article.css'

interface ArticleHeaderProps {
	heading?: string
	headingTag?: string
	imageUrl?: string
	author?: string
	authorOccupation?: string
}

interface ArticleFooterProps {
	authorFullName?: string
	authorUrl?: string
	authorPosition?: string
	authorProfileImageUrl?: string

	categoriesHtml?: string
	creditHtml?: string
	socialLinks: {
		url?: string
		icon?: string
	}[]
	republishUrl?: string
	republishLabel?: string
}

export function initArticle() {
	Array.from(document.querySelectorAll<HTMLFormElement>('article header')).forEach((element) => {
		const authorNameAndOccupation = queryTextContent('.author', element)
		const authorName = queryTextContent('.author em', element)

		const props: ArticleHeaderProps = {
			heading: queryTextContent('h1,h2,h3,h4,h5', element),
			headingTag: element.querySelector('h1,h2,h3,h4,h5')?.tagName?.toLowerCase(),
			imageUrl: getBackgroundImage(element),
			author: authorName,
			authorOccupation: authorNameAndOccupation?.replace(authorName ?? '', '').trim(),
		}

		const newHeader = document.createElement('header')
		element.parentNode?.replaceChild(newHeader, element)

		render(<ArticleHeader {...props} />, newHeader)
	})

	Array.from(document.querySelectorAll<HTMLFormElement>('article footer')).forEach((element) => {
		const props: ArticleFooterProps = {
			authorFullName: queryTextContent('.staff-listing-detailed strong', element),
			authorUrl: queryHref('.staff-listing-detailed a', element),
			authorPosition: queryTextContent('.staff-listing-detailed p', element),
			authorProfileImageUrl: querySrc('img', element),

			categoriesHtml: element.querySelector('.categories')?.innerHTML,
			creditHtml: element.querySelector('.credit')?.innerHTML,

			socialLinks: Array.from(element.querySelectorAll<HTMLAnchorElement>('.share li')).map((share) => ({
				url: queryHref('a', share),
				icon: share.className,
			})),

			republishUrl: queryHref('.republish a:not([data-modal-target])', element),
			republishLabel: queryTextContent('.republish a:not([data-modal-target])', element),
		}

		const newFooter = document.createElement('footer')
		element.parentNode?.replaceChild(newFooter, element)

		render(<ArticleFooter {...props} />, newFooter)
	})
}

const ArticleHeader = (props: ArticleHeaderProps) => {
	const HeadingTag = (props.headingTag ?? 'h2') as HeadingTags

	return (
		<>
			{props.imageUrl != null && props.imageUrl.length > 0 && (
				<img
					className="h-[500px] w-full object-cover"
					src={props.imageUrl}
					alt=""
				/>
			)}
			<div className="mb-[48px] max-w-content border-b-2 border-gray-200 pb-[24px]">
				<HeadingTag className="!mt-[48px] !pb-[24px] !pt-0 text-3xl font-extrabold leading-8 tracking-tight text-gray-900">{props.heading}</HeadingTag>
				<div className="pb-[8px] text-sm font-semibold leading-5 text-gray-900">{props.author}</div>
				<div className="text-sm font-normal leading-5 text-gray-600">{props.authorOccupation}</div>
			</div>
		</>
	)
}

const ArticleFooter = (props: ArticleFooterProps) => {
	return (
		<>
			<StaffProfileCard
				fullName={props.authorFullName}
				position={props.authorPosition}
				photoUrl={props.authorProfileImageUrl}
				borderLessSmall={true}
				url={props.authorUrl}
			/>
			{props.categoriesHtml != null && props.categoriesHtml.length > 0 && (
				<div
					className="categories mt-[20px] text-gray-600"
					dangerouslySetInnerHTML={{__html: props.categoriesHtml}}
				/>
			)}
			{props.creditHtml != null && props.creditHtml.length > 0 && (
				<div
					className="credit mt-[24px] text-gray-600"
					dangerouslySetInnerHTML={{__html: props.creditHtml}}
				/>
			)}
			<div className="mt-[51px] flex max-w-content items-center justify-between">
				<div className="flex gap-x-[8px]">
					{props.socialLinks.map((socialLink, index) => (
						<a
							key={index}
							className="flex h-[24px] w-[24px] items-center justify-center rounded-sm bg-uom-navy-900 text-white"
							href={socialLink.url}
						>
							<SocialIcon
								name={socialLink.icon}
								className="w-[18px]"
							/>
						</a>
					))}
				</div>
				{props.republishUrl != null && props.republishUrl.length > 0 && (
					<a
						href={props.republishUrl}
						className="button-xs soft !mt-0"
					>
						{props.republishLabel}
					</a>
				)}
			</div>
		</>
	)
}
