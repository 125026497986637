import React from 'react'
import {StaffNewsArticle, StaffNewsArticleCard} from '~/cards/StaffNewsArticleCard'
import {getNewsArticles} from '~/pages/news/getNewsArticles'
import {queryDataSet, queryHref, queryTextContent} from '~/utils/query'
import {renderToMain} from '~/utils/render'
import {pageType} from '~/utils/utils'
import {Issue, RecentIssues} from './RecentIssues'

export interface StaffNewsIssuePageProps {
	title?: string
	articles: StaffNewsArticle[]
	adminTextViewUrl?: string
	adminEditDetailsUrl?: string
	adminEditLinkingUrl?: string
	adminStatus?: string
	adminStatusUrl?: string

	recentIssues: Issue[]
}

export const initStaffNewsIssuePage = () => {
	if (pageType === 'staff-news-issue') {
		const props: StaffNewsIssuePageProps = {
			title: queryTextContent('[data-sh-2021="page-title"]'),
			articles: getNewsArticles('[data-sh-2021="articles"] > article'),
			adminTextViewUrl: queryHref('[data-sh-2021="admin-text-view"]'),
			adminEditDetailsUrl: queryHref('[data-sh-2021="admin-edit-details"]'),
			adminEditLinkingUrl: queryHref('[data-sh-2021="admin-edit-linking"]'),
			adminStatus: queryTextContent('[data-sh-2021="status"]'),
			adminStatusUrl: queryHref('[data-sh-2021="status"]'),

			recentIssues: Array.from(document.querySelectorAll<HTMLAnchorElement>('[data-sh-2021="recent-issues"] > li > a ')).map((issue) => ({
				url: issue.href,
				title: issue?.textContent ?? '',
				active: issue.dataset.issueNumber === queryDataSet('[data-sh-2021="page-title"]')?.issueNumber,
			})),
		}

		renderToMain(<StaffNewsIssuePage {...props} />)
	}
}

export default function StaffNewsIssuePage(props: StaffNewsIssuePageProps) {
	return (
		<div className="pb-20">
			<div className="border-b-2 border-gray-200 pb-[23px] pt-[10px] md:pt-[30px]">
				<h1 className="block-uplift text-3xl font-extrabold leading-8 tracking-tight text-gray-900">{props.title}</h1>
			</div>
			{props.adminTextViewUrl && (
				<div className="pt-6 text-gray-600">
					<h3 className="block-uplift text-xs font-medium uppercase leading-4 tracking-wider">Admin</h3>
					<div className="mt-3 flex gap-x-2 text-sm">
						<a
							href={props.adminTextViewUrl}
							className="text-blue-700 underline"
						>
							View as text
						</a>
						<a
							href={props.adminEditDetailsUrl}
							className="text-blue-700 underline"
						>
							Edit Details
						</a>
						<a
							href={props.adminEditLinkingUrl}
							className="text-blue-700 underline"
						>
							Edit Linking
						</a>
						<span className="text-gray-900 !no-underline">
							Status:{' '}
							<a
								href={props.adminStatusUrl}
								className="text-blue-700 underline"
							>
								{props.adminStatus}
							</a>
						</span>
					</div>
				</div>
			)}
			<RecentIssues issues={props.recentIssues} />
			<div className="grid gap-5 pb-[132px] pt-[24px] md:grid-cols-2 lg:grid-cols-3">
				{props.articles.map((article) => (
					<StaffNewsArticleCard
						key={article.url}
						article={article}
					/>
				))}
			</div>
		</div>
	)
}
