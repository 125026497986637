import React, {useState} from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'

export const CopyButton = ({text, label, classNames}: {text: string; label?: string; classNames?: string}) => {
	const [copied, setCopied] = useState(false)

	const copyCode = () => {
		navigator.clipboard.writeText(text).then(() => {
			setCopied(true)
			setTimeout(() => {
				setCopied(false)
			}, 2000)
		})
	}

	return (
		<button
			className={cx(classNames, 'button-xs soft !focus:outline-none !hover:no-underline !group-hover:no-underline !mt-0 !no-underline')}
			onClick={() => copyCode()}
		>
			<span className={cx(copied ? 'hidden' : '', 'copy-code')}>{label ?? 'Copy'}</span>
			<span className={cx(copied ? '' : 'hidden', 'copied flex items-center')}>
				Copied{' '}
				<Icon
					icon="check"
					className="!mr-0 ml-[8px] h-[16px] w-[16px]"
				/>
			</span>
		</button>
	)
}
