import React from 'react'
import {Icon} from '~/blocks/Icon'
import {CopyToClipboardButton} from '~/components/CopyToClipboardButton'
import {cx, formatPhoneNumber} from '~/utils/utils'
import type {CardProps} from './Card'
import {CommonCardProps} from './Card'
import {Listbox, Popover} from '@headlessui/react'

export interface StaffProfileCardProps extends CommonCardProps {
	fullName?: string
	position?: string
	departmentName?: string
	email?: string
	phone?: string
	personId?: string
	photoUrl?: string
	showPersonModal?: (personId?: string) => void
	url?: string
	borderLessLarge?: boolean
	borderLessSmall?: boolean
	faeExists?: string
	faeProfileUrl?: string
}

export interface StaffProfile {
	fullName?: string
	position?: string
	departmentName?: string
	email?: string
	phone?: string
	personId?: string
	photoUrl?: string
	faeExists?: string
	faeProfileUrl?: string
}

const profilePictureUrl = 'https://pictures.staff.unimelb.edu.au/squiz/index_eis.php?id='

export function StaffProfileCard(props: CardProps) {
	const handleCardClick = () => {
		if (props.showPersonModal != null) {
			props.showPersonModal(props.personId)
		} else if (props.url != null && props.url.length > 0) {
			window.location.href = props.url
		}
	}

	if (props.borderLessLarge) {
		return (
			<div className={cx('flex flex-col items-start gap-[24px] rounded md:flex-row')}>
				<div className="flex shrink-0 flex-col items-start">
					<img
						className="mx-auto h-[128px] w-[128px] rounded-full object-cover object-top"
						src={props.photoUrl ? props.photoUrl : profilePictureUrl + props.personId}
						alt={props.fullName + ' profile picture'}
					/>
				</div>
				<div className="flex w-full flex-col items-start gap-y-[24px] md:pt-[16px]">
					<div>
						<h3 className="block-uplift pb-[4px] text-lg font-semibold leading-6 text-gray-900">{props.fullName != null && props.fullName.length > 0 ? props.fullName : props.title}</h3>
						<div className="min-h-[36px] pb-[12px] text-sm font-normal uppercase text-gray-600">{props.position}</div>
						{props.departmentName != null && props.departmentName.length > 0 && (
							<div className="w-max items-center  rounded-full bg-red-100 px-2.5 text-xs font-medium leading-4 text-red-900">{props.departmentName} </div>
						)}
					</div>
					<div>
						{props.phone && props.phone.length > 0 && (
							<a
								href={'tel:' + props.phone}
								className="mb-[8px] flex items-center gap-x-[24px] hover:no-underline"
							>
								<div className="rounded bg-gray-50 p-[12px]">
									<Icon
										icon="phone"
										className="h-[18px] text-gray-600"
									/>
								</div>
								<div className="text-sm text-blue-700 underline">{formatPhoneNumber(props.phone)}</div>
							</a>
						)}
						{props.email && props.email.length > 0 && (
							<div className="flex items-center gap-x-[24px] hover:no-underline">
								<div className="rounded bg-gray-50 p-[12px]">
									<Icon
										icon="mail"
										className="h-[18px] text-gray-600"
									/>
								</div>
								<a
									href={'mailto:' + props.email}
									className="text-sm text-blue-700 underline "
								>
									{props.email}
								</a>
								<CopyToClipboardButton
									className="!hidden md:!flex"
									value={props.email ?? ''}
								/>
							</div>
						)}
						{props.faeExists && props.faeExists === 'Y' && (
							<div className="mt-[16px] flex items-center gap-x-[24px] hover:no-underline">
									<div className="rounded bg-gray-50 p-[12px]">
										<Icon
											icon="face"
											className="h-[18px] text-gray-600"
										/>
									</div>
								<a href={props.faeProfileUrl} className="text-sm text-blue-700 underline " target="_blank">Find an Expert profile</a>
							</div>
						)}
					</div>
					<div className="flex w-full flex-col gap-y-[8px] md:hidden">
						<CopyToClipboardButton
							className="!w-full"
							value={props.email ?? ''}
						/>
						{props.phone && props.phone.length > 0 && (
							<a
								href={'tel:' + props.phone}
								className="button-xs soft !focus:outline-none !mt-0 !block !w-full !max-w-full text-center"
							>
								Call
							</a>
						)}
					</div>
				</div>
			</div>
		)
	}

	if (props.borderLessSmall) {
		return (
			<div className={cx('flex flex-col items-start gap-[24px] rounded md:flex-row')}>
				<button
					onClick={handleCardClick}
					className="flex shrink-0 flex-col items-start"
				>
					<img
						className="mx-auto h-[64px] w-[64px] rounded-full object-cover object-top"
						src={props.photoUrl ? props.photoUrl : profilePictureUrl + props.personId}
						alt={props.fullName + ' profile picture'}
					/>
				</button>
				<div className="flex w-full flex-col flex-col items-start gap-y-[24px] pt-[6px]">
					<button
						onClick={handleCardClick}
						className="text-left hover:no-underline"
					>
						<div className="mb-[6px] flex items-center gap-x-[16px]">
							<h3 className="block-uplift text-md font-semibold leading-6 text-gray-900 hover:underline">{props.fullName != null && props.fullName.length > 0 ? props.fullName : props.title}</h3>
							{props.departmentName != null && props.departmentName.length > 0 && (
								<div className="hidden w-max items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium leading-4 text-red-900 md:block">{props.departmentName} </div>
							)}
						</div>
						{props.position != null && props.position.trim().length > 0 && <div className="mt-[6px] text-sm text-sm font-normal uppercase leading-5 text-gray-600">{props.position}</div>}
						{props.departmentName != null && props.departmentName.length > 0 && (
							<div className="mt-[12px] w-max items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium leading-4 text-red-900 md:hidden">{props.departmentName} </div>
						)}
					</button>
					<div className="flex flex-col gap-y-[16px] text-sm font-medium md:flex-row">
						{props.phone && props.phone.length > 0 && (
							<a
								className="pr-[24px] underline"
								href={'tel:' + props.phone}
							>
								{formatPhoneNumber(props.phone)}
							</a>
						)}
						<a
							className="underline"
							href={'mailto:' + props.email}
						>
							{props.email}
						</a>
					</div>
					{props.phone && props.phone.length > 0 && (
						<div className="w-full md:hidden">
							<a
								href={'tel:' + props.phone}
								className="button-xs soft !focus:outline-none !mt-0 !block !w-full !max-w-full text-center"
							>
								Call
							</a>
						</div>
					)}
				</div>
			</div>
		)
	}

	if (props.horizontalLayout) {
		return (
			<div className={cx('flex flex-row gap-x-[24px] rounded-lg border-2 border-transparent p-[24px] shadow hover:no-underline hover:shadow-lg focus:border-blue-700 focus:outline-none')}>
				<a
					href={props.url}
					onClick={handleCardClick}
					className="pb-[18px]"
				>
					<img
						className="mx-auto mb-[24px] mt-[8px] h-[128px] w-[128px] rounded-full object-cover object-top"
						src={props.photoUrl ? props.photoUrl : profilePictureUrl + props.personId}
						alt={props.fullName + ' profile picture'}
					/>
				</a>
				<div className="flex flex-col">
					<a
						href={props.url}
						className="pb-[18px] hover:no-underline"
					>
						<h3 className="block-uplift pb-[4px] text-lg font-semibold leading-6 text-gray-900 hover:underline">{props.title}</h3>
						<div className="min-h-[36px] pb-[12px] text-sm font-normal uppercase text-gray-600">{props.position}</div>
						{props.departmentName != null && props.departmentName.length > 0 && (
							<div className="mb-[12px] w-max items-center  rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium leading-4 text-yellow-800">{props.departmentName}</div>
						)}
					</a>
					<div>
						{props.email && props.email.length > 0 && (
							<div className="text-sm font-normal leading-5">
								<span className="font-medium text-gray-700">Email:</span>
								<a href={'mailto:' + props.email}>{props.email}</a>
							</div>
						)}
						{props.phone && props.phone.length > 0 && (
							<div className="pt-[1rem] text-sm font-normal leading-5">
								<span className="font-medium text-gray-700">Phone:</span>
								<a href={'tel:' + props.phone}>{formatPhoneNumber(props.phone)}</a>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}

	return (
		<Popover className="relative">
			<Popover.Button className=" flex justify-start">
			
			<button onClick={handleCardClick} >
			{props.listLayout && <div className="flex justify-start">
			<img
				className="h-[64px] w-[64px] rounded-full object-cover object-top"
				src={props.photoUrl ? props.photoUrl : 'https://pictures.staff.unimelb.edu.au/squiz/index_eis.php?id=' + props.personId}
				alt={props.title + ' profile picture'}
			/>
				<div className="ml-[16px] flex flex-col items-start">
					<div className="text-lg  text-blue-700 underline">{props.title}</div>
					<div className="mt-[12px] flex gap-x-[8px]">
						<div
							className="flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-900"
							
						>
							{props.departmentName}
						</div>
					</div>
					<div className="mt-[20px] text-sm font-medium leading-5 text-gray-900">{props.position}</div>
					{props.email && <div className="mt-[12px] text-sm font-normal leading-5 text-gray-600">Email: {props.email}</div>}
					{props.phone && <div className="mt-[12px] text-sm font-normal leading-5 text-gray-600">Phone: {props.phone}</div>}
				</div>
			</div>}
			</button>
			
			</Popover.Button>


			{!props.listLayout && <div className="flex w-full flex-col justify-start rounded-[8px] p-[24px] shadow">
			<button onClick={handleCardClick} >

			<div className="flex items-center">
					<img
						className="h-[48px] w-[48px] rounded-full object-cover object-top"
						src={props.photoUrl ? props.photoUrl : 'https://pictures.staff.unimelb.edu.au/squiz/index_eis.php?id=' + props.personId}
						alt={props.title + ' profile picture'}
					/>
					<div className="ml-[16px] text-left  text-lg text-blue-700 underline">{props.title}</div>
			</div>
			<div className="flex flex-col items-start">
				<div className="mt-[16px] flex gap-x-[8px]">
					<div className="mb-[12px] w-max items-center  rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium leading-4 text-red-900">
						{props.departmentName}
					</div>
				</div>
				<div className="mt-[20px] text-left text-sm font-medium leading-5 text-gray-900">{props.position}</div>
				{props.email && <div className="mt-[12px] text-sm font-normal leading-5 text-gray-600">Email: {props.email}</div>}
				{props.phone && <div className="mt-[12px] text-sm font-normal leading-5 text-gray-600">Phone: {props.phone}</div>}
				{props.listLayout && <div className="mt-[12px] text-sm font-normal leading-5 text-gray-600">ll</div>}
			</div>


			</button>
			</div>}
			
		</Popover>
	)
}
