import {Popover, Transition} from '@headlessui/react'
import React, {Fragment, useRef} from 'react'
import {Icon} from '~/blocks/Icon'
import {GlobalSearchBox} from '~/pages/search/GlobalSearchBox'

export function HeaderSearchMobile() {
	const openMenuButtonRef = useRef<HTMLButtonElement>(null)

	return (
		<Popover className="md:hidden">
			{({open}) => (
				<>
					<div>
						<Popover.Button
							ref={openMenuButtonRef}
							className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-gray-50 p-2 text-sm font-medium leading-5 text-gray-900 hover:text-gray-500 focus:bg-gray-200"
						>
							<span className="sr-only">Open main navigation menu</span>
							<Icon
								icon="search"
								className="block h-6 w-6"
								aria-hidden="true"
							/>
						</Popover.Button>
					</div>
					<Transition
						show={open}
						as={Fragment}
						enter="transition ease-in-out duration-500"
						enterFrom="translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-500"
						leaveFrom="translate-x-0"
						leaveTo="translate-x-full"
						afterEnter={() => document.body.classList.add('header-menu-open')}
						beforeLeave={() => document.body.classList.remove('header-menu-open')}
					>
						<Popover.Panel
							static
							className="absolute left-0 top-0 z-50 h-screen w-full bg-white bg-white px-4 shadow-lg"
						>
							<div className="flex h-[80px] flex-row-reverse items-center text-base font-medium leading-6">
								<button
									onClick={() => openMenuButtonRef.current?.click()}
									className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-50 focus:bg-gray-400 focus:outline-none md:bg-white md:hover:bg-gray-100"
								>
									<Icon
										icon="close"
										className="float-right h-6 w-6 p-0 font-light"
									/>
								</button>
							</div>
							<GlobalSearchBox />
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	)
}
