import React from 'react'
import {Icon} from '~/blocks/Icon'

import {isUrlSameHost} from '~/utils/utils'

interface Props {
	className?: string
	url?: string | null
	isDownload?: boolean
}

export function LinkArrow(props: Props) {
	const isLocal = isUrlSameHost(props.url)

	const combinedClassNames = 'inline mb-[3px] h-[17px] w-auto no-underline ' + props.className

	if (props.isDownload)
		return (
			<Icon
				icon="download"
				className={combinedClassNames}
			/>
		)

	if (isLocal)
		return (
			<Icon
				icon="arrow-forward"
				className={combinedClassNames}
			/>
		)

	return (
		<Icon
			icon="external-link"
			className={combinedClassNames}
		/>
	)
}
