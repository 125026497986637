import React, {useEffect, useState} from 'react'
import {getSectionProps, SectionProps, SectionWrapper} from '~/components/SectionWrapper'
import {Select} from '~/components/Select'
import {queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx, getUrlParam, isUrlSameHost, replaceWithNewDiv, setUrlHash, setUrlParam} from '~/utils/utils'
import './HorizontalTabs.css'

interface Tab {
	label: string
	url?: string
	content?: string
	isLocal: boolean
	classes?: string
}

enum Width {
	Full,
	Content,
}

enum Alignment {
	Left,
	Center,
	Right,
}

interface HorizontalTabsProps extends SectionProps {
	width: Width
	alignment: Alignment
	defaultIndex: number
	tabs: Tab[]
}

export function initHorizontalTabs() {
	document.querySelectorAll<HTMLElement>('.tabbed-nav,.tabbed').forEach((element) => {
		const tabs = Array.from(element.querySelectorAll<HTMLAnchorElement>(':scope > * > nav > a[role="tab"],:scope > nav > a[role="tab"]'))

		// Default index order of precedence is; matches url param 'tab' value, matches the current pages url hash, has data-current attribute, first item
		let defaultIndex = Math.max(
			tabs.findIndex((tab) => tab.dataset.current != null),
			0,
		)
		const tabUrlParam = getUrlParam('tab')
		if (tabUrlParam != null && tabUrlParam.length > 0) {
			defaultIndex = Math.max(
				tabs.findIndex((tab) => tab.getAttribute('href') === '#' + tabUrlParam),
				0,
			)
		} else if (window.location.hash.length > 0) {
			defaultIndex = Math.max(
				tabs.findIndex((tab) => tab.getAttribute('href') === window.location.hash),
				0,
			)
		}

		const width: Width = element.classList.contains('tabbed-nav') || element.classList.contains('full-width') || element.querySelector(':scope > .full-width') != null ? Width.Full : Width.Content

		let alignment: Alignment = Alignment.Left
		if (element.classList.contains('center')) {
			alignment = Alignment.Center
		} else if (element.classList.contains('right')) {
			alignment = Alignment.Right
		}

		const props: HorizontalTabsProps = {
			width: width,
			alignment: alignment,
			defaultIndex: defaultIndex,
			tabs: tabs.map((tab) => {
				const href = tab.getAttribute('href') ?? ''
				const isLocal = href.startsWith('#')
				return {
					label: tab.innerHTML ?? '',
					url: !isLocal ? tab.href : href,
					content: isLocal ? element.querySelector('#' + CSS.escape(href.substring(1)))?.innerHTML : undefined,
					isLocal: isLocal,
					classes: isLocal ? element.querySelector('#' + CSS.escape(href.substring(1)))?.className : undefined,
				}
			}),
		}
		const div = replaceWithNewDiv(element)

		div.classList.add('section-spacing')

		render(<HorizontalTabs {...props} />, div)

		if (element.querySelector<HTMLElement>(':scope .tabbed-nav,:scope .tabbed') != null) {
			initHorizontalTabs()
		}
	})

	document.querySelectorAll<HTMLElement>('.staff-ui-tabs-horizontal').forEach((element) => {
		const sectionProps = getSectionProps(element)
		const tabs = Array.from(element.querySelectorAll<HTMLAnchorElement>('.tab')).map((tab) => ({
			label: queryTextContent('.title', tab) ?? '',
			content: tab.querySelector('.body')?.innerHTML,
			isLocal: true,
		}))
		let defaultIndex = 0
		const tabUrlParam = getUrlParam('tab')
		if (tabUrlParam != null && tabUrlParam.length > 0) {
			defaultIndex = Math.max(
				tabs.findIndex((tab) => tab.label === decodeURIComponent(tabUrlParam.replaceAll('_', ' '))),
				0,
			)
		}
		const props: HorizontalTabsProps = {
			...sectionProps,
			defaultIndex: defaultIndex,
			width: Width.Full,
			alignment: Alignment.Left,
			tabs: tabs,
		}
		render(<HorizontalTabs {...props} />, element)
	})
}

const pageHasJumpNavs = document.querySelector('.jumpnav') != null

function HorizontalTabs({tabs, defaultIndex, width, alignment, ...props}: HorizontalTabsProps) {
	const [currentIndex, setCurrentIndex] = useState(defaultIndex)

	function updateIndex(newIndex: number) {
		const newUrl = tabs[newIndex].url
		if (newUrl != undefined && newUrl.length > 0) {
			changeUrl(newUrl)
		} else {
			setUrlParam('tab', encodeURIComponent(tabs[newIndex].label.replaceAll(' ', '_')))
		}
		setCurrentIndex(newIndex)
	}

	useEffect(() => {
		if (pageHasJumpNavs && window.location.hash.length > 0) {
			// Hack to use the browser's build in scroll to hash functionality
			const oldHash = window.location.hash
			window.location.hash = ''
			window.location.hash = oldHash
		}
	}, [])

	function changeUrl(newUrl: string) {
		if (newUrl.startsWith('#')) {
			if (pageHasJumpNavs) {
				setUrlParam('tab', newUrl.substring(1))
			} else {
				// Change url hash to reflect change in open tab
				setUrlHash(newUrl.substring(1))
			}
			return
		}

		if (isUrlSameHost(newUrl)) {
			// Open url in this window/tab
			window.location.href = newUrl
			return
		}

		// Open in new tab
		window.open(newUrl, '_blank')?.focus()
	}

	return (
		<SectionWrapper {...props}>
			<div className={cx(width == Width.Content ? 'max-w-content' : '', alignment == Alignment.Center ? 'mx-auto' : '', alignment == Alignment.Right ? 'ml-auto' : '', 'horizontal-tabs')}>
				<HorizontalTabsMobile
					tabs={tabs}
					currentIndex={currentIndex}
					updateIndex={updateIndex}
				/>
				<HorizontalTabsDesktop
					tabs={tabs}
					currentIndex={currentIndex}
					updateIndex={updateIndex}
				/>
			</div>

			{tabs.map((tab, index) => (
				<div key={tab.label}>
					<div
						id={'tab-contents-' + tab.url?.substring(1)}
						className={cx(width == Width.Content ? 'max-w-content' : '', alignment == Alignment.Center ? 'mx-auto' : '', alignment == Alignment.Right ? 'ml-auto' : '', 'horizontal-tab-contents')}
					>
						<div
							key={tab.label}
							className={cx(index !== currentIndex ? 'hidden' : '', tab.classes)} // this show hides the active tab
							dangerouslySetInnerHTML={{__html: tab.content ?? ''}}
						/>
					</div>
				</div>
			))}
		</SectionWrapper>
	)
}

interface HorizontalTabsSubComponentProps {
	tabs: Tab[]
	currentIndex: number
	updateIndex: (newIndex: number) => void
}

function HorizontalTabsMobile({tabs, currentIndex, updateIndex}: HorizontalTabsSubComponentProps) {
	return (
		<div className="sm:hidden">
			<label
				htmlFor="tabs"
				className="sr-only"
			>
				Select a tab
			</label>
			<Select
				options={tabs.map((tab) => tab.label ?? '').filter((value) => value.length > 0)}
				onChangeIndex={(index) => (index != null ? updateIndex(index) : 0)}
				currentIndex={currentIndex}
			/>
		</div>
	)
}

function HorizontalTabsDesktop({tabs, currentIndex, updateIndex}: HorizontalTabsSubComponentProps) {
	function handleOnClick(tab: Tab, event: React.MouseEvent<HTMLAnchorElement>, index: number) {
		if (tab.isLocal) {
			event.preventDefault()
			updateIndex(index)
		}
	}

	return (
		<div className="hidden sm:block">
			<div className="border-b border-gray-200">
				<nav
					className="-mb-px flex space-x-8"
					aria-label="Tabs"
				>
					{tabs.map((tab, index) => {
						const selected = index === currentIndex
						return (
							<a
								key={tab.label}
								className={cx(
									selected ? 'border-blue-700 text-blue-700' : 'border-transparent text-gray-600 hover:border-gray-500',
									'group cursor-pointer whitespace-nowrap border-b-2 pb-2 text-sm font-medium hover:no-underline focus:outline-none',
								)}
								aria-current={selected ? 'page' : undefined}
								href={tab.url}
								target={tab.url != null && isUrlSameHost(tab.url) ? '_self' : '_blank'}
								onClick={(event) => handleOnClick(tab, event, index)}
							>
								<div
									className="tab-label rounded-lg px-2 py-2  group-hover:bg-gray-100 group-focus:bg-gray-100"
									dangerouslySetInnerHTML={{__html: tab.label}}
								/>
							</a>
						)
					})}
				</nav>
			</div>
		</div>
	)
}
