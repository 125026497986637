import React from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'

export interface NewsCardProps {
	title?: string
	url?: string
	imageUrl?: string
	imageAlt?: string
	smallImage?: boolean
	description?: string
	topic?: string
	hero?: boolean
	date?: string
}

export function NewsCard(props: NewsCardProps) {
	return (
		<a
			href={props.url}
			className="flex max-w-[792px] flex-col overflow-hidden rounded-lg  text-gray-600 shadow hover:no-underline hover:shadow-lg"
		>
			{!props.smallImage && props.imageUrl != null && props.imageUrl.length > 0 && (
				<div className="flex-shrink-0">
					<img
						className=" h-[356px] w-full object-cover"
						src={props.imageUrl}
						aria-hidden="true"
						alt={props.imageAlt}
					/>
				</div>
			)}

			<div className="flex flex-col gap-[24px] p-[24px] sm:flex-row">
				{props.smallImage && props.imageUrl != null && props.imageUrl.length > 0 && (
					<div className="flex-shrink-0">
						<img
							className="h-[128px] w-[128px] overflow-hidden rounded-lg object-cover"
							src={props.imageUrl}
							aria-hidden="true"
							alt={props.imageAlt}
						/>
					</div>
				)}
				<div>
					<h3 className={cx(props.hero ? 'text-3xl font-extrabold leading-8 tracking-tight' : 'text-lg font-semibold leading-6', 'block-uplift text-gray-900')}>{props.title}</h3>
					<p className="m-0 !mt-[12px] p-0 text-base font-normal leading-6 text-gray-600">{props.description}</p>
					<div className="mt-[24px] flex flex-row-reverse justify-between">
						<div className="text-sm font-medium leading-5 text-blue-700 hover:underline focus:outline-none">
							Read full story{' '}
							<Icon
								icon="arrow-forward"
								className="mb-[4px] inline h-4 w-4"
							/>
						</div>
						{props.topic && props.topic.length > 0 && <div className="rounded-full bg-gray-50 px-[13px] py-[4px] text-xs font-medium leading-4 text-gray-600">{props.topic}</div>}
					</div>
				</div>
			</div>
		</a>
	)
}
