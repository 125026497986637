import './Text.css'

export function initText() {
	document.querySelectorAll<HTMLElement>('section.lead').forEach((element) => {
		if (element.textContent == null || element.textContent.trim().length <= 0) {
			element.remove()
		}
	})

	document.querySelectorAll<HTMLElement>('blockquote').forEach((element) => {
		const blockquoteWrapper = document.createElement('div')
		blockquoteWrapper.className = 'blockquote-wrapper ' + element.className

		element.parentNode?.replaceChild(blockquoteWrapper, element)
		blockquoteWrapper.prepend(element)

		// Move cite out of blockquote
		const cite = element.querySelector('cite')
		if (cite != null) {
			blockquoteWrapper.append(element, cite)
		}

		element.innerHTML = '“' + element.innerHTML + '”'
	})

	document.querySelectorAll<HTMLElement>('ol:not(.block-uplift):not(.simple-listing),ul:not(.block-uplift):not(.simple-listing)').forEach((element) => {
		element.classList.add('max-w-content')
	})
}
