import {create} from 'zustand'

interface UseHideFooterStore {
	isFooterHidden: boolean
	hideFooter: () => void
	showFooter: () => void
}

export const useHideFooter = create<UseHideFooterStore>((set) => ({
	isFooterHidden: false,
	hideFooter: () => set({isFooterHidden: true}),
	showFooter: () => set({isFooterHidden: false}),
}))
