import React from 'react'
import {cx} from '~/utils/utils'
import {sections, useSelfGuidanceToolStore} from './useSelfGuidanceToolStore'
import {Icon} from '~/blocks/Icon'

export const SectionsVerticalMenu = () => {
	const {currentSectionIndex, setCurrentSectionIndex, isSectionComplete} = useSelfGuidanceToolStore()

	return (
		<nav
			className="hidden w-[365px] flex-shrink-0 lg:block"
			aria-label="Progress"
		>
			<div className="sticky top-[156px]">
				<div
					role="list"
					className="tag-section-menu overflow-hidden"
				>
					{sections.map((section, index) => {
						const isCurrent = index === currentSectionIndex
						const isCompleted = isSectionComplete(index)

						return (
							<div
								key={section.title}
								className={cx(index !== sections.length - 1 ? 'pb-[28px]' : '', 'relative')}
							>
								<button
									onClick={() => setCurrentSectionIndex(index)}
									disabled={!isCompleted && currentSectionIndex < index}
									className={cx(
										isCurrent ? 'tag-active text-blue-700' : isCompleted ? 'text-gray-900' : 'text-gray-600',
										'tag-section-menu-item group relative flex place-items-center items-center text-sm',
										`tag-section-index-${index}`,
									)}
									aria-current="step"
								>
									<span
										className="flex h-9 items-center"
										aria-hidden="true"
									>
										{(!isCompleted || isCurrent) && (
											<span className={cx(isCurrent ? 'border-blue-700' : 'border-gray-500', 'relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 bg-white')}>
												{index + 1}
											</span>
										)}
										{isCompleted && !isCurrent && (
											<span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-blue-700">
												<Icon
													icon="check"
													className="h-[16px] text-white"
												/>
											</span>
										)}
									</span>
									<div className="ml-4 h-full min-w-0  font-semibold tracking-wide">{section.title}</div>
								</button>
							</div>
						)
					})}
				</div>
			</div>
		</nav>
	)
}
