import {useEffect, useState} from 'react'
import {config} from '~/pages/grantRecords/GrantRecordsPage'

export interface SearchResultFaculty {
	displayName: string
	shortDisplayName: string,
	bgColour: string
	hoverBgColour: string
	textColour: string
}

export const searchResultFaculties: SearchResultFaculty[] = [
	{
		displayName: 'Architecture, Building and Planning',
		shortDisplayName: 'ABP',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
	},
	{
		displayName: 'Arts',
		shortDisplayName: 'Arts',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
	},
	{
		displayName: 'Education',
		shortDisplayName: 'FoE',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
	},	
	{
		displayName: 'Engineering and IT',
		shortDisplayName: 'FEIT',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
	},
	{
		displayName: 'Fine Arts and Music',
		shortDisplayName: 'FAM',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
	},
	{
		displayName: 'Business and Economics',
		shortDisplayName: 'FBE',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
	},
	{
		displayName: 'Law',
		shortDisplayName: 'Law',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
	},	{
		displayName: 'Medicine, Dentistry & Health Sciences',
		shortDisplayName: 'MDHS',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
	},
	{
		displayName: 'Science',
		shortDisplayName: 'Sci',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
	},
	{
		displayName: 'Non-faculty',
		shortDisplayName: 'Non-faculty',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
	},
]

export interface SearchResultYear {
	displayName: string
	bgColour: string
	hoverBgColour: string
	textColour: string
}

export const searchResultYears: SearchResultYear[] = [
	{
		displayName: '2025',
		bgColour: '#fce7f3',
		hoverBgColour: '#fbcfe8',
		textColour: '#831843',
	},
	{
		displayName: '2024',
		bgColour: '#fce7f3',
		hoverBgColour: '#fbcfe8',
		textColour: '#831843',
	},
	{
		displayName: '2023',
		bgColour: '#fce7f3',
		hoverBgColour: '#fbcfe8',
		textColour: '#831843',
	},
	{
		displayName: '2022',
		bgColour: '#fce7f3',
		hoverBgColour: '#fbcfe8',
		textColour: '#831843',
	},
]


export const useGrantRecordsStore = (searchTerm: string | null, selectedFacultyTypes: SearchResultFaculty[],  selectedYearTypes: SearchResultYear[]) => {
	const [isLoading, setIsLoading] = useState(false)
	const [apiResponse, setApiResponse] = useState<grantApiResponse | null>(null)

	let yearParam: string = "";
	let facultyParam: string = "";
	
	for (const type of selectedFacultyTypes) {
		//f.Type|supportArticle=Support article
		//yearParam += "+ " + type.displayName
		facultyParam += ((facultyParam.length > 0) ?  "+ " + type.shortDisplayName : type.shortDisplayName);
	}
	
	for (const type of selectedYearTypes) {
		//f.Type|supportArticle=Support article
		//yearParam += "+ " + type.displayName
		yearParam += ((yearParam.length > 0) ?  "+ " + type.displayName : type.displayName);
	}

	const isNotEmptySearch =
		(searchTerm != null && searchTerm.length > 0) || (selectedFacultyTypes != null && selectedFacultyTypes.length > 0) || (selectedYearTypes != null && selectedYearTypes.length > 0) 

	useEffect(() => {
		if (!isNotEmptySearch) {
			setApiResponse(null)
			return
		}

		const fetchData = async () => {
			setIsLoading(true)
			const response = await fetch(
				config.apiUrl +
					new URLSearchParams({
						queries_key_word_query: searchTerm ?? '',
						//queries_name__query: '',
						queries_year_query: yearParam ?? '',
						queries_division_query: facultyParam ?? '',
						//queries_code_id_query: '',
					}),
			)
			const data = (await response.json()) as grantApiResponse
			//data.results = data.results.sort((a, b) => a.projectName.toLowerCase().replace('the', '').localeCompare(b.projectName.toLowerCase().replace('the', '')))
			setApiResponse(data)
			setIsLoading(false)
		}

		fetchData().then()
	}, [searchTerm, selectedFacultyTypes, selectedYearTypes])
	return {isLoading, apiResponse}
}

export interface grantApiResponse {
	total_results: number
	query: Query
	results: GrantRecord[]
}

export interface Query {
	keyWord: string
	grantYear: string
	grantFaculty: string
}

export interface GrantRecord {
	title: string
	author: string
	year: string
	international: string
	faculty1: string
	facultyAbbr1: string
	faculty2: string
	facultyAbbr2: string
	faculty3: string
	facultyAbbr3: string
	faculty4: string
	facultyAbbr4: string
	recordAssetID: number
	summary: string
	pdf_url?: string
}