import React from 'react'
import {Icon} from '~/blocks/Icon'
import {Card, CardProps, CardTypes} from '~/cards/Card'
import {getSectionProps, SectionProps, SectionWrapper} from '~/components/SectionWrapper'
import {queryDataSet, queryHref, querySrc, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {useSideScrollButtons} from '~/utils/useSideScrollButtons'
import {cx, HeadingTags, replaceWithNewDiv} from '~/utils/utils'
import './CardGrid.css'

interface CardGridProps extends SectionProps {
	headingTag?: string
	headingId?: string
	headingClasses?: string
	headingInFirstColumn: boolean
	description?: string
	viewAllUrl?: string
	viewAllTitle?: string
	viewAllShortTitle?: string
	collapseToOneRowOnMobile: boolean
	maxColumns: number
	cardType?: CardTypes
	cards: CardProps[]
}

export function initCardGrid() {
	document.querySelectorAll<HTMLElement>('.sh-card-grid, .block-card-grid').forEach((element) => {
		const columnsInt = parseInt(element.dataset.columns ?? '')
		const columns = !isNaN(columnsInt) ? columnsInt : element.classList.contains('cols-2') ? 2 : 3

		const heading = element.querySelector(':scope > h2,:scope > h3,:scope > h4,:scope > h5')

		const props: CardGridProps = {
			heading: heading?.textContent ?? undefined,
			headingTag: heading?.tagName.toLowerCase() ?? undefined,
			headingId: heading?.id ?? undefined,
			headingClasses: heading?.className ?? undefined,
			boarderUnderHeading: !element.classList.contains('no-boarder-under-heading'),
			headingInFirstColumn: false,
			description: queryTextContent(':scope > p', element),
			maxColumns: columns,
			collapseToOneRowOnMobile: !element.classList.contains('dont-collapse'),
			cards: Array.from(element.querySelectorAll<HTMLElement>(':scope > ul > li')).map((card) => {
				let title = queryTextContent(':scope > h2, :scope > h3, :scope > h4, :scope > h5', card)?.trim()
				if ((element.dataset?.cardType === 'link' || element.dataset?.cardType === 'link-with-logo') && (title == null || title.length <= 0)) {
					title = queryTextContent('a', card)
				}
				return {
					url: queryHref('a', card),
					title: title,
					body: queryTextContent('p', card),
					type: element.dataset?.cardType as CardTypes,
					linkLabel: queryTextContent('a, .link-label', card),
					logoUrl: querySrc('img', card),
					logoBorder: card.querySelector('img')?.classList.contains('logo-border') ?? false,
					icon: card.dataset.shIcon ?? card.dataset.icon,
					colour: card.dataset.color ?? card.dataset.colour,
					modalContent: card.querySelector('.modal-content')?.innerHTML,
					hasDownloadClass: card.querySelector('a')?.classList.contains('download'),
				}
			}),
			viewAllUrl: queryHref('a.view-all', element),
			viewAllTitle: queryTextContent('a.view-all', element),
			viewAllShortTitle: queryDataSet('a.view-all', element)?.shortTitle ?? '',
		}

		element.classList.remove('sh-card-grid', 'block-card-grid')
		element.classList.add('rendered-card-grid', 'section-spacing')

		render(<CardGrid {...props} />, element)
	})

	document.querySelectorAll<HTMLElement>('.staff-ui-card-grid').forEach((element) => {
		const sectionProps = getSectionProps(element)
		const props: CardGridProps = {
			...sectionProps,
			description: sectionProps.subheading,
			headingInFirstColumn: element.dataset.headingInFirstColumn === 'true',
			collapseToOneRowOnMobile: element.dataset.collapseToOneRowOnMobile == 'true',
			cardType: (element.dataset?.cardType as CardTypes) ?? 'general',
			maxColumns: element.dataset?.maxColumns != null ? Number(element.dataset?.maxColumns) : 3,
			viewAllTitle: element.dataset.viewAllLable,
			viewAllUrl: element.dataset.viewAllUrl,
			cards: Array.from(element.querySelectorAll<HTMLElement>('.card')).map((card) => {
				return {
					type: (element.dataset?.cardType as CardTypes) ?? 'general',
					linkType: card.dataset.linkType ?? 'link',
					title: queryTextContent('.title', card),
					url: card.querySelector('a')?.href,
					body: queryTextContent('.description', card),
					linkLabel: queryTextContent('.link-label', card),
					icon: card.dataset.icon,
					iconBoxColour: card.dataset.iconBoxColour,
					logoUrl: card.dataset.logo,
					logoBorder: card.dataset.logoBorder === 'true',
					imageUrl: querySrc('img.header-image', card),
					fixedImageHeight: element.dataset.fixedImageHeight === 'true',
				}
			}),
		}
		const newElement = replaceWithNewDiv(element)
		render(<SimplifiedCardGrid {...props} />, newElement)
	})

	document.querySelectorAll<HTMLElement>('.staff-ui-modal').forEach((element) => {
		const sectionProps = getSectionProps(element)
		const props: CardGridProps = {
			...sectionProps,
			description: sectionProps.subheading,
			headingInFirstColumn: element.dataset.headingInFirstColumn === 'true',
			collapseToOneRowOnMobile: element.dataset.collapseToOneRowOnMobile == 'true',
			cardType: (element.dataset?.cardType as CardTypes) ?? 'general',
			maxColumns: element.dataset?.maxColumns != null ? Number(element.dataset?.maxColumns) : 3,
			viewAllTitle: element.dataset.viewAllLabel,
			viewAllUrl: element.dataset.viewAllUrl,
			cards: Array.from(element.querySelectorAll<HTMLElement>('.modal-button')).map((card) => {
				return {
					type: (element.dataset?.cardType as CardTypes) ?? 'general',
					title: queryTextContent('.title', card),
					body: queryTextContent('.description', card),
					linkLabel: queryTextContent('.link-label', card),
					icon: card.dataset.icon,
					iconBoxColour: card.dataset.iconBoxColour,
					logoUrl: card.dataset.logo,
					logoBorder: card.dataset.logoBorder === 'true',
					imageUrl: querySrc('img.header-image', card),
					modalContent: card.querySelector('.modal-content')?.innerHTML,
				}
			}),
		}
		const newElement = replaceWithNewDiv(element)
		render(<SimplifiedCardGrid {...props} />, newElement)
	})
}

function CardGrid(props: CardGridProps) {
	const {sideScrollRef, handleSideScrollLeftPointerDown, handleSideScrollLeftPointerUp, handleSideScrollRightPointerDown, handleSideScrollRightPointerUp} = useSideScrollButtons<HTMLDivElement>()

	const Heading = props.headingTag as HeadingTags

	return (
		<>
			{props.heading && (
				<Heading
					id={props.headingId != null && props.headingId.length > 0 ? props.headingId : props.addHeadingId ? props.heading?.replace(/\s+/g, '-').toLowerCase() : ''}
					className={cx(props.boarderUnderHeading ? 'border-b-2 border-gray-200 !pb-[24px]' : '!pb-0', props.description ? 'mb-0' : '!mb-[24px]', '!max-w-none  text-gray-900', props.headingClasses)}
				>
					{props.heading}
				</Heading>
			)}
			{props.description && <p className="!pb-[24px] pt-0 text-sm font-normal leading-5 text-gray-900">{props.description}</p>}
			<div className={cx(props.collapseToOneRowOnMobile ? 'hidden md:block' : '')}>
				<div className={cx(props.maxColumns == null || props.maxColumns === 3 ? 'md:grid-cols-2 lg:grid-cols-3' : 'lg:grid-cols-2', 'grid  gap-[24px]')}>
					{props.cards.map((cardData, index) => (
						<Card
							key={index}
							{...cardData}
						/>
					))}
				</div>
			</div>
			{props.collapseToOneRowOnMobile && (
				<div
					ref={sideScrollRef}
					className="hide-horizontal-scrollbar -mx-4 flex gap-x-[18px] overflow-y-hidden px-4 pb-[20px] pt-[16px] sm:-mx-6 sm:px-6 md:hidden"
				>
					{props.cards.map((cardData, index) => (
						<Card
							key={index}
							{...cardData}
							className="h-[315px] w-[300px] flex-shrink-0"
						/>
					))}
				</div>
			)}
			{(props.collapseToOneRowOnMobile || props.viewAllUrl) && (
				<div className="flex justify-between pt-[4px] md:pt-[24px]">
					<div className="flex space-x-2 md:hidden">
						{props.collapseToOneRowOnMobile && (
							<>
								<button
									className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
									onPointerDown={handleSideScrollLeftPointerDown}
									onPointerUp={handleSideScrollLeftPointerUp}
									aria-label="scroll left"
								>
									<Icon
										icon="chevron-left"
										className="mr-[2px] h-[22px] w-[22px]"
									/>
								</button>
								<button
									className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
									onPointerDown={handleSideScrollRightPointerDown}
									onPointerUp={handleSideScrollRightPointerUp}
									aria-label="scroll right"
								>
									<Icon
										icon="chevron-right"
										className="ml-[2px] h-[22px] w-[22px]"
									/>
								</button>
							</>
						)}
					</div>
					<div className="ml-auto flex items-center">
						{props.viewAllUrl && (
							<a
								className="items-center text-sm font-medium leading-5 text-blue-700 hover:underline"
								href={props.viewAllUrl}
							>
								<span className="hidden md:inline">{props.viewAllTitle}</span>
								<span className="md:hidden">{props.viewAllShortTitle}</span>
								<Icon
									icon="arrow-forward"
									className="mb-[5px] ml-1 inline h-5 w-5"
								/>
							</a>
						)}
					</div>
				</div>
			)}
		</>
	)
}

const SimplifiedCardGrid = (props: CardGridProps) => {
	const {sideScrollRef, handleSideScrollLeftPointerDown, handleSideScrollLeftPointerUp, handleSideScrollRightPointerDown, handleSideScrollRightPointerUp} = useSideScrollButtons<HTMLDivElement>()

	return (
		<SectionWrapper
			{...props}
			dontShowHeading={props.headingInFirstColumn}
		>
			<div className={cx(props.heading && props.headingInFirstColumn && 'grid gap-x-[24px] md:grid-cols-2 lg:grid-cols-3', props.collapseToOneRowOnMobile && 'hidden md:grid')}>
				{props.heading && props.headingInFirstColumn && (
					<div className={cx('col-end-2 hidden pb-[24px] lg:block')}>
						<h3
							id={props.headingId != null && props.headingId.length > 0 ? props.headingId : props.addHeadingId ? props.heading?.replace(/\s+/g, '-').toLowerCase() : ''}
							className="!mt-0 max-w-content !pb-0 !text-2xl !font-semibold leading-8 text-gray-900"
						>
							{props.heading}
						</h3>
						{props.description && (
							<div
								className="description mt-[16px] max-w-content text-lg font-normal leading-6 text-gray-600"
								dangerouslySetInnerHTML={{__html: props.description}}
							/>
						)}
					</div>
				)}

				<div
					className={cx(
						'grid gap-x-[24px] gap-y-[32px]',
						props.heading && props.headingInFirstColumn ? 'md:col-span-2 md:grid-cols-2' : 'md:grid-cols-2',
						!props.headingInFirstColumn && props.cards.length >= 3 && Math.min(props.cards.length, props.maxColumns) === 3 && 'lg:grid-cols-3',
						!props.headingInFirstColumn && props.cards.length >= 4 && props.maxColumns === 4 && 'lg:grid-cols-4',

						props.cardType === 'left-border' && '!pt-[12px]',
					)}
				>
					{props.cards.map((card, index) => (
						<Card
							key={index}
							type={props.cardType}
							lineClamp={false}
							className=""
							{...card}
						/>
					))}
				</div>
			</div>
			{props.collapseToOneRowOnMobile && (
				<div
					ref={sideScrollRef}
					className="hide-horizontal-scrollbar -mx-4 flex gap-x-[18px] overflow-y-hidden px-4 pb-[20px] pt-[16px] sm:-mx-6 sm:px-6 md:hidden"
				>
					{props.cards.map((cardData, index) => (
						<Card
							key={index}
							{...cardData}
							lineClamp={false}
							horizontalLayout={true}
							className="flex-shrink-0"
						/>
					))}
				</div>
			)}
			{(props.collapseToOneRowOnMobile || props.viewAllUrl) && (
				<div className="flex justify-between pt-[4px] md:pt-[24px]">
					<div className="flex space-x-2 md:hidden">
						{props.collapseToOneRowOnMobile && (
							<>
								<button
									className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
									onPointerDown={handleSideScrollLeftPointerDown}
									onPointerUp={handleSideScrollLeftPointerUp}
									aria-label="scroll left"
								>
									<Icon
										icon="chevron-left"
										className="mr-[2px] h-[22px] w-[22px]"
									/>
								</button>
								<button
									className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
									onPointerDown={handleSideScrollRightPointerDown}
									onPointerUp={handleSideScrollRightPointerUp}
									aria-label="scroll right"
								>
									<Icon
										icon="chevron-right"
										className="ml-[2px] h-[22px] w-[22px]"
									/>
								</button>
							</>
						)}
					</div>
					<div className="ml-auto flex items-center">
						{props.viewAllUrl && (
							<a
								className="items-center text-sm font-medium leading-5 text-blue-700 hover:underline"
								href={props.viewAllUrl}
							>
								<span className="hidden md:inline">{props.viewAllTitle}</span>
								<span className="md:hidden">{props.viewAllShortTitle}</span>
								<Icon
									icon="arrow-forward"
									className="mb-[5px] ml-1 inline h-5 w-5"
								/>
							</a>
						)}
					</div>
				</div>
			)}
		</SectionWrapper>
	)
}
