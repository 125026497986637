import {useFetch} from 'usehooks-ts'
import type {CardProps} from '~/cards/Card'
import {highlight} from '~/utils/utils'

interface ApiResult {
	title: string
	docNum: number
	summary: string
	url: string
	clickTrackingUrl: string
	displayUrl: string
	collection: string
	gscopesSet: string[]
	metaData: {
		personId: string
		position: string
		departmentName: string
		email: string
		articleType: 'Support article ' | 'Form '
		scShortDescription: string
		scContent: string
		kbType: string
		faqQuestion: string
		howToIntroduction: string
		kcsArticleIssue: string
		knownErrorDescription: string
		whatIsIntroduction: string
		imageUrl: string
	}
}

interface ApiFeaturedResult {
	titleHtml: string
	displayUrl: string
	linkUrl: string
	descriptionHtml: string
}

interface HookReturnValue {
	isLoading: boolean
	results: CardProps[]
	featured?: CardProps[]
	totalMatching?: number
	enabled?: string
	suggestedQueries: string[]
}

export interface SearchResultType {
	displayName: string
	shortDisplayName: string
	apiName: string
	apiLongName: string
	color?: string
	bgColour: string
	hoverBgColour: string
	textColour: string
}

export const searchResultTypes: SearchResultType[] = [
	{
		displayName: 'People & Contacts',
		shortDisplayName: 'People',
		apiName: 'profile',
		apiLongName: 'Profile',
		color: 'pink',
		bgColour: '#fce7f3',
		hoverBgColour: '#fbcfe8',
		textColour: '#831843',
	},
	{
		displayName: 'Support Articles',
		shortDisplayName: 'Support articles',
		apiName: 'supportArticle',
		apiLongName: 'Support article',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
	},
	/*	{
		displayName: 'Forms',
		shortDisplayName: 'Forms',
		apiName: 'form',
		apiLongName: 'Form',
		bgColour: '#fef3c7',
		hoverBgColour: '#fde68a',
		textColour: '#78350f',
	},*/
	{
		displayName: 'General Information',
		shortDisplayName: 'General',
		apiName: 'general',
		apiLongName: 'General information',
		bgColour: '#daeef3',
		hoverBgColour: '#b3dce7',
		textColour: '#19444f',
	},
]

export interface Topic {
	displayName: string
	shortName: string
	apiName: string
}

export const topics: Topic[] = [
	{displayName: 'Contracts and agreements', shortName: 'contracts+agreements', apiName: 'FUN7kh9bl5c8mlb3obdvsdbfcr7g'},
	{displayName: 'Ethics and integrity', shortName: 'ethics+integrity', apiName: 'FUNbal7xw4pmdh0p19zgb37nj7cw'},
	{displayName: 'Funding and grants', shortName: 'funding+grants', apiName: 'FUN3iki0bf8h6sl1mx0a9f0tcrdx'},
	{displayName: 'Innovation and enterprise', shortName: 'innovation+enterprise', apiName: 'FUNbx6zstyywlhqbeu3s2ejk4atp'},
	{displayName: 'Platforms and equipment', shortName: 'platforms+equipment', apiName: 'FUN6j7ilcn24wpq35plmi71eac4z'},
	{displayName: 'Reporting', shortName: 'reporting', apiName: 'FUN8zb5row46m1wauwagy9ls918f'},
	{displayName: 'Research at Melbourne', shortName: 'researchAtMelbourne', apiName: 'FUN83qq21gq1a2ex0zktdq004ot5'},
	{displayName: 'Research impact and engagement', shortName: 'researchImpact+engagement', apiName: 'FUNb3tpafuc8jjsb8mj0uge6su81'},
	{displayName: 'Research outputs and open access', shortName: 'researchOutputs+openAccess', apiName: 'FUN2p0rt4mlk8mg133h31ce5c1w2'},
	{displayName: 'Services and contacts', shortName: 'services+contacts', apiName: 'FUN4b9ztem345gpo6mzv8wvk2yx'},
	{displayName: 'Systems, tools and guides', shortName: 'systems+tools+guides', apiName: 'FUNb9lth0dzt69o3xjc7aqprp65k'},
]

const baseUrl = (window as any).searchUrlBase //'https://staff.unimelb.edu.au/rg_build/_web-services/search.json'

export function useRgSearch(query: string, startAt = 1, topics: Topic[], types: SearchResultType[]): HookReturnValue {
	//`${baseUrl}?collection=staff-hub-research-gateway&start_rank=${startAt}&num_ranks=15&query=${query.length > 0 ? query : '!padrenull'}&f.Type|${types[0]?.apiName}=${types[0]?.apiLongName}`,
	const searchParams = new URLSearchParams({
		collection: 'staff-hub-research-gateway',
		start_rank: startAt.toString(),
		num_ranks: '15',
		query: query.length > 0 ? query : '!padrenull',
		profile: '_default_preview',
	})

	let enabled = 'all'

	for (const type of types) {
		//f.Type|supportArticle=Support article
		searchParams.append('f.Type|' + type.apiName, type.apiLongName)
	}

	for (const topic of topics) {
		//f.Topic|FUNbal7xw4pmdh0p19zgb37nj7cw=Ethics+and+integrity
		searchParams.append('f.Topic|' + topic.apiName, topic.displayName)
		enabled = topic.apiName
	}

	const {data, error} = useFetch<any>(`${baseUrl}?${searchParams.toString()}`)

	return {
		isLoading: data == null && error == null && query.length > 0,
		results:
			(data?.response?.resultPacket?.results as ApiResult[])?.map((result) => {
				if (result.metaData?.personId?.length > 0) {
					const staffProfile: CardProps = {
						type: 'profile',
						url: result.clickTrackingUrl,
						title: result.title,
						position: result.metaData.position,
						departmentName: result.metaData.departmentName,
						email: result.metaData.email,
						personId: result.metaData.personId,
						photoUrl: result.metaData.imageUrl,
					}
					return staffProfile
				}
				const cardData: CardProps = {
					url: result.clickTrackingUrl,
					displayUrl: result.displayUrl,
					type: 'general',
					title: result.title,
					body: highlight(result.summary, query),
					linkLabel: '',
				}
				if (result.collection.includes('service-now-kba')) {
					cardData.type = 'supportArticle'
					if (result.metaData.articleType == 'Support article '){  //old servicenow kba's had: result.metaData.articleType
						if (typeof result.metaData.scContent != 'undefined') {
							cardData.body = result.metaData.scContent.replace(/<\/?[^>]+(>|$)/g, '') // remove html tags 
						} 
						else {
							cardData.body = ''
						}
					} else {
						if (result.metaData.kbType != 'undefined') {
							switch(result.metaData.kbType) {
								case 'FAQ':
									cardData.body = result.metaData.faqQuestion.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								case 'How To':
									cardData.body = result.metaData.howToIntroduction.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								case 'KCS Article':
									cardData.body = result.metaData.kcsArticleIssue.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								case 'Knowledge':
									cardData.body = result.metaData.scContent.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								case 'Known Error article':
									cardData.body = result.metaData.knownErrorDescription.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								case 'What Is':
									cardData.body = result.metaData.whatIsIntroduction.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								default:
									cardData.body = '';
									break;
							}
						} 
					}
				}
				if (result.collection.includes('service-now-forms')) {
					cardData.type = 'form'
					cardData.body = result.metaData.scShortDescription
				}
				return cardData
			}) ?? [],
		featured:
			(data?.response?.curator?.exhibits as ApiFeaturedResult[])?.map((result) => ({
				url: result.linkUrl,
				type: 'general',
				title: result.titleHtml,
				body: highlight(result.descriptionHtml, query),
				linkLabel: 'View',
			})) ?? [],
		totalMatching: data?.response?.resultPacket?.resultsSummary?.totalMatching,
		enabled: enabled,
		suggestedQueries: (data?.response?.resultPacket?.contextualNavigation?.categories?.find((category: any) => category.name === 'topic')?.clusters?.map((cluster: any) => cluster.query) ?? [])
			.concat([data?.response?.resultPacket?.spell?.text ?? null])
			.filter((item: string | null) => item != null && item.length > 0),
	}
}
