import type React from 'react'
import {Card} from '~/cards/Card'
import {getSectionProps, SectionProps, SectionWrapper} from '~/components/SectionWrapper'
import {queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx} from '~/utils/utils'
import './Event.css'

interface ContactCardsProps extends SectionProps {
	cards: {
		linkType: string
		title?: string
		url?: string
		body?: string
		linkLabel?: string
		icon?: string
	}[]
}

export function initContactCards() {
	document.querySelectorAll<HTMLElement>('.staff-ui-contact-cards').forEach((element) => {
		const sectionProps = getSectionProps(element)
		const props: ContactCardsProps = {
			...sectionProps,
			cards: Array.from(element.querySelectorAll<HTMLElement>('.card')).map((card) => {
				return {
					linkType: card.dataset.linkType ?? 'link',
					title: queryTextContent('.title', card),
					url: card.querySelector('a')?.href,
					body: queryTextContent('.description', card),
					linkLabel: queryTextContent('.link-label', card),
					icon: card.dataset.icon,
					iconBoxColour: card.dataset.iconBoxColour,
				}
			}),
		}
		element.classList.add('section-spacing')
		render(<ContactCards {...props} />, element)
	})
}

export function ContactCards(props: ContactCardsProps) {
	return (
		<SectionWrapper {...props}>
			<div className={cx('grid gap-x-[24px] gap-y-[32px]', props.cards.length >= 2 && 'md:grid-cols-2', props.cards.length > 2 && (props.cards.length == 3 ? 'lg:grid-cols-3' : 'lg:grid-cols-4'))}>
				{props.cards.map((card, index) => (
					<Card
						key={index}
						type="contact"
						className=""
						{...card}
					/>
				))}
			</div>
		</SectionWrapper>
	)
}
