import './Form.css'

export function initForm() {
	// I know this should be doable with css :after, but I couldn't get it to work
	Array.from(
		document.querySelectorAll<HTMLElement>(
			'.sq-form-question-error .sq-form-question-answer input:not([type="radio"]):not([type="checkbox"]), .sq-form-question-error .sq-form-question-answer textarea',
		),
	).forEach((element) => {
		const errorIconDiv = document.createElement('div')
		errorIconDiv.className = 'error-icon-div absolute inset-y-0 right-0 pr-3 flex pointer-events-none'

		errorIconDiv.innerHTML =
			'<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" viewBox="0 0 48 48" fill="currentColor" aria-hidden="true"><path d="M24 34q.7 0 1.175-.475.475-.475.475-1.175 0-.7-.475-1.175Q24.7 30.7 24 30.7q-.7 0-1.175.475-.475.475-.475 1.175 0 .7.475 1.175Q23.3 34 24 34Zm-1.35-7.65h3V13.7h-3ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z"/></svg>'

		element.after(errorIconDiv)
	})
}
