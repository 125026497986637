import React from 'react'

export interface FrontpageNewsArticle {
	postType?: string
	title?: string
	url?: string
	imageUrl?: string
	description?: string
	publishedDate?: string
}

interface FrontpageNewsCardProps {
	article: FrontpageNewsArticle
	imageClass?: string
}

export function FrontpageNewsCard({article, imageClass}: FrontpageNewsCardProps) {
	return (
		<a
			href={article.url}
			className="inline-flex w-[300px] flex-shrink-0 flex-col overflow-hidden rounded-lg text-gray-600 shadow hover:no-underline hover:shadow-lg md:w-auto md:flex-1"
		>
			<div className="flex-shrink-0">
				<img
					className={'w-full object-cover ' + imageClass}
					src={article.imageUrl}
					aria-hidden="true"
					alt=""
				/>
			</div>
			<div className="flex flex-1 flex-col justify-between bg-white p-6">
				<div className="flex-1">
					<div className="inline rounded-full bg-gray-100 px-[13px] py-[4px] text-xs font-medium">{article.postType}</div>
					<div className="mt-2 block">
						<h3 className="block-uplift text-lg font-semibold leading-6 text-gray-900">{article.title}</h3>
						<div
							className="mt-3 text-base"
							dangerouslySetInnerHTML={{__html: article.description ?? ''}}
						/>
					</div>
				</div>
				<div className="mt-6">
					<time className="flex space-x-1 text-sm text-gray-500">{article.publishedDate}</time>
				</div>
			</div>
		</a>
	)
}
