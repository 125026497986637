import React from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'
import type {CardProps} from './Card'
import {CommonCardProps} from './Card'
import {CardWrapper} from './CardWrapper'

export interface IconCardProps extends CommonCardProps {
	icon?: string
}

export function IconCard(props: CardProps) {
	return (
		<CardWrapper
			{...props}
			className={cx(
				'flex gap-x-[24px] rounded-lg border-2 border-transparent bg-white p-[24px] shadow hover:no-underline hover:shadow-lg active:border-blue-700',
				props.horizontalLayout && 'min-w-[400px]',
			)}
		>
			<div className="my-auto">
				<div className="flex h-10 w-10 items-center justify-center rounded-md bg-uom-navy-900 text-white sm:h-12 sm:w-12">
					<Icon
						icon={props.icon}
						className="h-6 w-6 flex-shrink-0"
					/>
				</div>
			</div>
			<div className="text-left">
				<div className="text-sm font-semibold uppercase leading-5 text-gray-900">{props.title}</div>
				<div className="pt-[8px] text-sm font-medium leading-5 text-gray-600">{props.body}</div>
			</div>
		</CardWrapper>
	)
}
