import '@total-typescript/ts-reset/filter-boolean'
import {ModalProfileCard} from '~/cards/ModalProfileCard'
import type {StaffProfile} from '~/cards/StaffProfileCard'
import {StaffProfileCard} from '~/cards/StaffProfileCard'
import {getSectionProps, type SectionProps, SectionWrapper} from '~/components/SectionWrapper'
import {querySrc, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx} from '~/utils/utils'
import './Form.css'

interface PeopleProps extends SectionProps {
	listOrGrid?: 'list' | 'grid'
	personProfiles?: StaffProfile[]
	personIds?: string[]
}

export function initPeople() {
	Array.from(document.querySelectorAll<HTMLFormElement>('.sh-person-grid')).forEach((element) => {
		const props: PeopleProps = {
			personProfiles: Array.from(element.querySelectorAll<HTMLAnchorElement>('.sh-person-card')).map((card) => {
				return {
					fullName: queryTextContent('.name', card),
					position: queryTextContent('.title', card),
					departmentName: queryTextContent('.department', card),
					email: queryTextContent('.email', card),
					phone: queryTextContent('.phone', card),
					photoUrl: querySrc('.profile-picture', card),
					url: card.href,
				}
			}),
		}
		render(<PersonGrid {...props} />, element)
	})

	Array.from(document.querySelectorAll<HTMLFormElement>('.sh-person-list')).forEach((element) => {
		const props: PeopleProps = {
			personProfiles: Array.from(element.querySelectorAll<HTMLAnchorElement>('.sh-person-card')).map((card) => {
				return {
					fullName: queryTextContent('.name', card),
					position: queryTextContent('.title', card),
					departmentName: queryTextContent('.department', card),
					email: queryTextContent('.email', card),
					phone: queryTextContent('.phone', card),
					photoUrl: querySrc('.profile-picture', card),
					url: card.href,
				}
			}),
		}
		render(<PersonList {...props} />, element)
	})

	document.querySelectorAll<HTMLElement>('.staff-ui-people').forEach((element) => {
		const sectionProps = getSectionProps(element)
		const props: PeopleProps = {
			...sectionProps,
			listOrGrid: element.dataset.listOrGrid as PeopleProps['listOrGrid'],
			personIds: element.dataset.staffIds
				?.split(',')
				.map((id) => id.trim()),
				//.map((id) => parseInt(id, 10)),
		}
		render(<People {...props} />, element)
	})
}

const PersonGrid = (props: PeopleProps) => {
	return (
		<div className={cx(props.personProfiles?.length === 4 ? 'lg:grid-cols-2' : 'md:grid-cols-2 lg:grid-cols-3', 'section-spacing  grid gap-[24px]')}>
			{props.personProfiles?.map((person, index) => (
				<StaffProfileCard
					key={index}
					{...person}
				/>
			))}
		</div>
	)
}

const PersonList = (props: PeopleProps) => {
	return (
		<div className={cx('section-spacing flex flex-col gap-y-[24px]')}>
			{props.personProfiles?.map((person, index) => (
				<StaffProfileCard
					key={index}
					{...person}
					borderLessSmall
				/>
			))}
		</div>
	)
}

const People = (props: PeopleProps) => {
	return (
		<SectionWrapper {...props}>
			<div className={cx(props.listOrGrid === 'grid' && 'md:grid-cols-2', 'grid gap-[24px]')}>
				{props.personIds?.map((personId, index) => {
					if (!personId) return null
					return (
						<ModalProfileCard
							key={index}
							staffId={personId}
						/>
					)
				})}
			</div>
		</SectionWrapper>
	)
}
