import type React from 'react'
import {CopyButton} from '~/components/CopyButton'
import {getSectionProps, SectionWrapper} from '~/components/SectionWrapper'
import {IconInBox} from '~/images/IconInBox'
import {queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import './Event.css'

interface ContactEmailPhoneProps {
	heading?: string
	subheading?: string
	backgroundColour: string
	email?: string
	phone?: string
}

export function initContactEmailPhone() {
	document.querySelectorAll<HTMLElement>('.staff-ui-contact-email').forEach((element) => {
		const sectionProps = getSectionProps(element)
		const props: ContactEmailPhoneProps = {
			...sectionProps,
			heading: queryTextContent('.heading', element),
			subheading: queryTextContent('.subheading', element),
			backgroundColour: element.dataset.backgroundColour != null ? element.dataset.backgroundColour : 'white',
			email: element.querySelector('a')?.href,
			phone: element.dataset.phone,
		}
		element.classList.add('doesnt-need-gap-without-padding')
		render(<ContactEmailPhone {...props} />, element)
	})
}

export function ContactEmailPhone(props: ContactEmailPhoneProps) {
	const emailAddress = props.email?.split(':')[1]

	return (
		<SectionWrapper {...props}>
			<div className="!mt-[48px] flex flex-col gap-y-[24px]">
				{emailAddress != null && emailAddress.length > 0 && (
					<div className=" flex items-center gap-x-[24px]">
						<IconInBox
							colour="navy"
							icon="mail"
						/>
						<a
							href={props.email}
							className="!underline hover:decoration-2"
						>
							{emailAddress}
						</a>
						<CopyButton
							text={emailAddress ?? ''}
							classNames=""
						/>
					</div>
				)}
				{props.phone != null && props.phone.length > 0 && (
					<div className="flex items-center gap-x-[24px]">
						<IconInBox
							colour="navy"
							icon="phone"
						/>
						<div>{props.phone}</div>
					</div>
				)}
			</div>
		</SectionWrapper>
	)
}
