import React from 'react'
import {Icon} from '~/blocks/Icon'
import {ListingCard} from '~/cards/ListingCard'
import {queryHref} from '~/utils/query'
import {render} from '~/utils/render'
import {useSideScrollButtons} from '~/utils/useSideScrollButtons'
import {cx, getBackgroundImage} from '~/utils/utils'
import './Listing.css'

interface ListingProps {
	textOnly: boolean
	mobileHorizontal: boolean
	columns: number
	noCardBoarder: boolean
	cards: {
		url?: string
		imageUrl?: string
		title?: string
		body?: string
		linkLabel: string
		titleTag?: string
		titleId?: string
	}[]
}

export function initListing() {
	document.querySelectorAll<HTMLElement>('.navigation-text-listing li > a, .navigation-block-listing li > a').forEach((element) => {
		element.innerHTML =
			element.innerHTML +
			`<span class="arrow"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
</svg></span>`
	})

	document.querySelectorAll<HTMLElement>('ul.simple-listing').forEach((element) => {
		element.classList.add('no-list', 'section-spacing', 'p-0')

		let columns = 3
		if (element.classList.contains('simple-listing--one')) {
			columns = 1
		} else if (element.classList.contains('simple-listing--two')) {
			columns = 2
		} else if (element.classList.contains('simple-listing--four')) {
			columns = 4
		}

		const props: ListingProps = {
			columns: columns,
			textOnly: element.classList.contains('simple-listing--text'),
			mobileHorizontal: element.classList.contains('simple-listing--mobile-horizontal') && columns != 1,
			noCardBoarder: element.classList.contains('no-card'),
			cards: Array.from(element.querySelectorAll<HTMLAnchorElement>(':scope > li')).map((card) => {
				const title = card.querySelector(':scope > h2, :scope > h3, .simple-listing__heading')
				return {
					title: title?.textContent ?? undefined,
					titleId: title?.id ?? undefined,
					titleTag: title?.tagName ?? undefined,
					url: queryHref(':scope > a', card),
					imageUrl: getBackgroundImage(card.querySelector<HTMLElement>('.simple-listing__img')),
					linkLabel: card.querySelector<HTMLAnchorElement>(':scope > a')?.dataset.linkLabel ?? card.dataset.linkLabel ?? 'View',
					body: card.querySelector(':scope > a > p, :scope > p, .simple-listing__excerpt')?.innerHTML ?? card.querySelector(':scope > ul')?.outerHTML,
				}
			}),
		}

		render(<Listing {...props} />, element)
	})
}

export function Listing({textOnly, mobileHorizontal, noCardBoarder, columns, cards}: ListingProps) {
	const {sideScrollRef, handleSideScrollLeftPointerDown, handleSideScrollLeftPointerUp, handleSideScrollRightPointerDown, handleSideScrollRightPointerUp} = useSideScrollButtons<HTMLDivElement>()

	let imageClass = 'h-[219px] md:h-[330px] '
	if (columns === 3) {
		imageClass += 'lg:h-[256px]'
	}
	if (columns === 4) {
		imageClass += 'lg:h-[188px]'
	}

	return (
		<>
			<div
				className={cx(
					mobileHorizontal ? 'hidden md:grid' : 'grid',
					columns === 1 ? 'max-w-content' : '',
					columns === 2 ? 'md:grid-cols-2' : '',
					columns === 3 ? 'md:grid-cols-2 lg:grid-cols-3' : '',
					columns === 4 ? 'md:grid-cols-2 lg:grid-cols-4' : '',
					'gap-[24px]',
				)}
			>
				{cards.map((card, index) => (
					<ListingCard
						key={index}
						{...card}
						imageHeightClass={imageClass}
						textOnly={textOnly}
						noCardBoarder={noCardBoarder}
					/>
				))}
			</div>
			{mobileHorizontal && (
				<>
					<div
						ref={sideScrollRef}
						className="hide-horizontal-scrollbar -mx-4 flex gap-x-[18px] overflow-y-hidden px-4 pt-[16px] pb-[20px] sm:-mx-6 sm:px-6 md:hidden"
					>
						{cards.map((card, index) => (
							<ListingCard
								key={index}
								{...card}
								mobileFixedWidth={true}
								imageHeightClass="h-[200px]"
								textOnly={textOnly}
								noCardBoarder={noCardBoarder}
							/>
						))}
					</div>
					<div className="flex gap-x-2 pt-[4px] md:hidden">
						<button
							className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
							onPointerDown={handleSideScrollLeftPointerDown}
							onPointerUp={handleSideScrollLeftPointerUp}
							aria-label="scroll left"
						>
							<Icon
								icon="chevron-left"
								className="mr-[2px] h-[22px] w-[22px]"
							/>
						</button>
						<button
							className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
							onPointerDown={handleSideScrollRightPointerDown}
							onPointerUp={handleSideScrollRightPointerUp}
							aria-label="scroll right"
						>
							<Icon
								icon="chevron-right"
								className="ml-[2px] h-[22px] w-[22px]"
							/>
						</button>
					</div>
				</>
			)}
		</>
	)
}
