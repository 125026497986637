import {Popover} from '@headlessui/react'
import React, {useEffect, useState} from 'react'
import PlaceholderLoading from 'react-placeholder-loading'
import {Icon} from '~/blocks/Icon'
import {CopyButton} from '~/components/CopyButton'
import {IconInBox} from '~/images/IconInBox'
import {cx} from '~/utils/utils'

export interface StaffProfile {
	id: string
	name?: string
	department?: string
	position?: string
	email?: string
	faeExists?: string
	photoUrl?: string
	faeProfileUrl?: string	
}

const staffDetailsLightApiUrl = (window as any).staffDetailsLightApiUrl //https://staff.unimelb.edu.au/rg_dev/_web-services/api-conectors/staff-details-api/
export const ModalProfileCard = ({staffId}: {staffId: string}) => {
	const [contact, setContact] = useState<StaffProfile | null>(null)

	useEffect(() => {
		const fetchData = async () => {
			const cachedProfile = localStorage.getItem('staffId-' + staffId)
			if (cachedProfile != null) {
				setContact(JSON.parse(cachedProfile))
				return
			}
			try {
				const response = await fetch(`${staffDetailsLightApiUrl}?id=${staffId}`, {
					headers: {
						Accept: 'application/json',
					},
				})
				const json = await response.json()
				localStorage.setItem('staffId-' + staffId, JSON.stringify(json))
				setContact(json)
			} catch (e) {
				console.error(e)
			}
		}
		void fetchData()
	}, [])

	return (
		<Popover className="relative">
			<div className="flex items-center gap-x-[24px] !no-underline">
				<img
					className="aspect-square h-[64px] rounded-full object-cover object-top"
					// src={'https://pictures.staff.unimelb.edu.au/squiz/index_eis.php?id=' + staffId}
					src={contact != null && contact.photoUrl ? (contact.photoUrl) : ('https://pictures.staff.unimelb.edu.au/squiz/index_eis.php?cid=0')}
					alt={(contact != null && contact.name) + ' profile picture'}
				/>

				<div className="flex flex-col justify-center gap-y-[12px]">
					<div className="flex flex-wrap gap-x-[16px] gap-y-[8px]">
						{contact == null ? (
							<PlaceholderLoading
								shape="rect"
								width={102}
								height={24}
							/>
						) : (
							<Popover.Button className="relative text-blue-700 !underline hover:decoration-2">{contact.name}</Popover.Button>
						)}
						{contact != null && (
							<Popover.Panel className="absolute top-[36px] z-10">
								{({close}) => (
									<div className={cx('items-top flex min-w-max gap-x-[24px] rounded-[8px] bg-white p-[24px] !no-underline shadow-xl')}>
										<img
											className="aspect-square h-[64px] rounded-full object-cover object-top"
											//src={'https://pictures.staff.unimelb.edu.au/squiz/index_eis.php?id=' + staffId}
											src={contact.photoUrl}
											alt={contact.name + ' profile picture'}
										/>
										<div className="flex flex-col justify-center">
											<div className="flex flex-wrap items-center gap-x-[16px] gap-y-[8px]">
												<div className="text-lg font-medium leading-8 text-gray-900">{contact.name}</div>
												{contact.department && contact.department.length > 0 && (
													<div className="flex items-center rounded-full bg-yellow-100 px-[8px] py-[4px] text-xs font-medium leading-4 text-yellow-900">{contact.department.trim()}</div>
												)}
											</div>
											<div className="mt-[8px] text-left text-sm font-normal leading-5 text-gray-600">{contact.position}</div>
											<div className="mt-[16px] flex items-center gap-x-[24px]">
												<IconInBox
													colour="gray"
													icon="mail"
												/>
												<div>
													<a
														href={'mailto:' + contact.email}
														className="text-blue-700 !underline hover:decoration-2"
													>
														{contact.email}
													</a>
												</div>
												<div className="min-w-[88px]">
													<CopyButton
														text={contact.email ?? ''}
														classNames="h-[30px]"
													/>
												</div>
											</div>
											{contact.faeExists === 'Y' && (
												<div className="mt-[8px] flex items-center gap-x-[24px]">
													<IconInBox
														colour="gray"
														icon="face"
													/>
													<div>
														<a
															// href={'https://findanexpert.unimelb.edu.au/profile/' + contact.id}
															href={contact.faeProfileUrl}
															className="text-blue-700 !underline hover:decoration-2"
														>
															Find an Expert profile
														</a>
													</div>
												</div>
											)}
										</div>
										<button
											onClick={() => close()}
											className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full !bg-gray-50 focus:bg-gray-400 focus:outline-none"
										>
											<Icon
												icon="close"
												className="float-right h-6 w-6 p-0 font-light"
											/>
										</button>
									</div>
								)}
							</Popover.Panel>
						)}
						{contact == null ? (
							<PlaceholderLoading
								shape="rect"
								width={108}
								height={24}
							/>
						) : (
							contact.department &&
							contact.department.length > 0 && (
								<div className="flex items-center rounded-full bg-yellow-100 px-[8px] py-[4px]  text-xs font-medium leading-4 text-yellow-900">{contact.department.trim()}</div>
							)
						)}
					</div>
					{contact == null ? (
						<PlaceholderLoading
							shape="rect"
							width={270}
							height={20}
						/>
					) : (
						<div className="text-left text-sm font-normal leading-5 text-gray-600">{contact.position}</div>
					)}
				</div>
			</div>
		</Popover>
	)
}
