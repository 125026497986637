import React from 'react'
import {Icon} from '~/blocks/Icon'
import {ContactMethod, ContactMethodCard} from '~/cards/ContactMethodCard'
import {FormIconInBox} from '~/images/FormIconInBox'
import {IconInBox} from '~/images/IconInBox'
import {queryDataSet, queryHref, queryTarget, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {useSideScrollButtons} from '~/utils/useSideScrollButtons'

export interface ContactInformationProps {
	title?: string
	subtitle?: string
	contactMethods: ContactMethod[]
}

export function initContactInformation() {
	document.querySelectorAll<HTMLElement>('.sh-contact-information, .block-contact-information').forEach((element) => {
		element.classList.add('doesnt-need-gap')

		const props: ContactInformationProps = {
			title: queryTextContent('h2', element),
			subtitle: queryTextContent('h3.subtitle', element),
			contactMethods: Array.from(element.querySelectorAll<HTMLElement>('ul > li')).map((contactMethod) => ({
				icon: queryDataSet('h3', contactMethod)?.icon,
				title: queryTextContent('h3', contactMethod),
				body: queryTextContent('p', contactMethod),
				linkText: queryTextContent('a', contactMethod),
				linkUrl: queryHref('a', contactMethod),
				linkTarget: queryTarget('a', contactMethod),
			})),
		}

		render(<ContactInformation {...props} />, element)
	})
}

export function ContactInformation({title, subtitle, contactMethods}: ContactInformationProps) {
	const {sideScrollRef, handleSideScrollLeftPointerDown, handleSideScrollLeftPointerUp, handleSideScrollRightPointerDown, handleSideScrollRightPointerUp} = useSideScrollButtons<HTMLDivElement>()

	return (
		<div className="full-width-background before:bg-gray-50">
			<div className="relative py-12">
				<h2 className="block-uplift text-2xl font-semibold leading-8 text-gray-900">{title}</h2>
				{subtitle && <h3 className="block-uplift pt-[16px] text-lg font-normal leading-6">{subtitle}</h3>}
				<div className="border-b-2 border-gray-200 pt-[24px]" />
				<div className="hidden justify-between gap-[24px] md:flex">
					{contactMethods.map((contactMethod) => (
						<a
							key={contactMethod.linkUrl}
							href={contactMethod.linkUrl}
							className="flex flex-1 flex-col justify-between rounded-md border-transparent pt-[24px] text-base hover:no-underline"
							target={contactMethod.linkTarget}
						>
							<div className="flex-grow-0">
								{contactMethod.icon != null && contactMethod.icon !== 'form' && contactMethod.icon !== 'check-circle' && (
									<IconInBox
										colour="navy"
										icon={contactMethod.icon === 'mobile' ? 'smartphone' : contactMethod.icon}
									/>
								)}
								{(contactMethod.icon === 'form' || contactMethod.icon === 'check-circle') && <FormIconInBox />}
								<div className="pt-[16px] font-medium text-gray-900">{contactMethod.title}</div>
							</div>
							<div className="flex flex-col">
								<div className="h-[44px] pt-[4px] text-sm font-normal text-gray-600">{contactMethod.body}</div>
								<div className="pt-[24px] text-sm font-medium leading-5 text-blue-700 hover:underline">
									{contactMethod.linkText}
									<Icon
										icon="arrow-forward"
										className="ml-[6px] mb-[4px] inline h-[16px]"
									/>
								</div>
							</div>
						</a>
					))}
				</div>
				<div className="md:hidden">
					<div
						ref={sideScrollRef}
						className="hide-horizontal-scrollbar -mx-4 flex space-x-2 overflow-y-hidden px-4 pt-[16px] sm:-mx-6 sm:px-6"
					>
						{contactMethods.map((contactMethod) => (
							<ContactMethodCard
								key={contactMethod.linkUrl}
								classes=""
								contactMethod={contactMethod}
							/>
						))}
					</div>
					<div className="flex space-x-2 pt-[24px]">
						<button
							className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
							onPointerDown={handleSideScrollLeftPointerDown}
							onPointerUp={handleSideScrollLeftPointerUp}
							aria-label="scroll left"
						>
							<Icon
								icon="chevron-left"
								className="mr-[2px] h-[22px] w-[22px]"
							/>
						</button>
						<button
							className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
							onPointerDown={handleSideScrollRightPointerDown}
							onPointerUp={handleSideScrollRightPointerUp}
							aria-label="scroll right"
						>
							<Icon
								icon="chevron-right"
								className="ml-[2px] h-[22px] w-[22px]"
							/>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
