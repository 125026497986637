import React, {useEffect, useLayoutEffect, useState} from 'react'
import {initAccordion} from '~/blocks/Accordion'
import {initCardGrid} from '~/blocks/CardGrid'
import {initJumpNav} from '~/blocks/JumpNav'
import {initTable} from '~/blocks/Table'
import {getUrlParam, removeUrlParam, setUrlParam} from '~/utils/utils'
import {SlideOverModal} from './SlideOverModal'

export interface ModalButtonProps {
	title?: string
	className?: string
	buttonContent?: string
	modalContent?: string
	children?: React.ReactNode
}

export const ModalButton: React.FC<ModalButtonProps> = (props) => {
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		if (props.title != null && getUrlParam('modal') === props.title && !document.body.classList.contains('slide-over-modal-open')) {
			setIsOpen(true)
			document.body.classList.add('slide-over-modal-open')
		}
	}, [props.title])

	useEffect(() => {
		if (isOpen) {
			setTimeout(() => initModalDependencies(), 1)
			document.body.classList.add('slide-over-modal-open')
		}
	}, [isOpen])

	function initModalDependencies() {
		initCardGrid()
		initAccordion()
		initJumpNav()
		initTable()
	}

	const open = () => {
		setIsOpen(true)
		document.body.classList.add('slide-over-modal-open')
		if (props.title != null) {
			setUrlParam('modal', props.title)
		}
	}

	const close = () => {
		setIsOpen(false)
		document.body.classList.remove('slide-over-modal-open')
		removeUrlParam('modal')
	}

	return (
		<>
			{props.buttonContent != null ? (
				<button
					className={props.className}
					onClick={open}
					dangerouslySetInnerHTML={{__html: props.buttonContent}}
				/>
			) : (
				<button
					className={props.className}
					onClick={open}
				>
					{props.children}
				</button>
			)}
			<SlideOverModal
				isOpen={isOpen}
				close={close}
				content={props.modalContent}
			/>
		</>
	)
}
