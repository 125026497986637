import {Popover, Tab, Transition} from '@headlessui/react'
import {Fragment, useRef} from 'react'
import {Icon} from '~/blocks/Icon'
import {HeaderQuickAccessNudge} from '~/global/header/HeaderQuickAccessNudge'
import HeaderQuickAccessUpdateNotice from '~/global/header/HeaderQuickAccessUpdateNotice'
import {cx} from '~/utils/utils'

interface QuickAccessConfig {
	systemTabTitle: string
	intranetsTabTitle: string
	systems: {title: string; subTitle?: string; url: string; logoUrl: string; logoBorder: boolean}[]
	moreSystemsLabel: string
	moreSystemsUrl: string
	intranets: {title: string; url: string}[]
}

const quickAccessConfig: QuickAccessConfig = (window as any).quickAccessConfig

export function HeaderQuickAccess() {
	if (quickAccessConfig == null) return null
	const buttonRef = useRef<HTMLButtonElement>(null)
	const clickQuickAccessButton = () => {
		buttonRef.current?.click()
	}

	return (
		<Popover.Group className="ml-4 flex-shrink-0 md:relative">
			<Popover>
				<div role="navigation">
					<Popover.Button
						ref={buttonRef}
						aria-controls="quick-access-popover-menu"
						className="hover z-[920] flex h-[40px] w-[40px] items-center justify-center rounded-full bg-gray-50 p-2 text-sm font-medium leading-5 text-gray-900 hover:text-gray-500 focus:bg-gray-200 md:h-auto md:w-auto md:rounded-lg md:border md:border-gray-600 md:bg-white md:px-[11px] md:py-[7px] md:text-gray-600 md:hover:bg-gray-100 md:hover:text-gray-900 md:focus:bg-white md:hover:focus:bg-gray-100 md:focus-visible:border-transparent"
					>
						<span className="hidden md:block">Quick Access</span>
						<Icon
							icon="apps"
							className="h-[24px] md:ml-2 md:inline md:h-[18px] md:text-blue-700"
							aria-hidden="true"
						/>
					</Popover.Button>
				</div>
				<Transition
					as={Fragment}
					enter="transition ease-in-out duration-500 md:transform-none md:transition md:ease-out md:duration-100"
					enterFrom="translate-x-full md:translate-x-0 md:md:opacity-0 md:scale-95"
					enterTo="translate-x-0  opacity-100  scale-100"
					leave="transition ease-in-out duration-500 md:transition md:ease-in md:duration-75"
					leaveFrom="translate-x-0 opacity-100 scale-100"
					leaveTo="translate-x-full md:translate-x-0 md:md:opacity-0 md:scale-95"
					afterEnter={() => document.body.classList.add('header-menu-open')}
					beforeLeave={() => document.body.classList.remove('header-menu-open')}
				>
					<Popover.Panel
						id="quick-access-popover-menu"
						className="absolute absolute left-0 left-0 top-0 top-0 z-50 h-screen w-full overflow-hidden border border-gray-400 bg-white md:left-auto md:right-0 md:top-full md:mt-2 md:h-auto md:w-[408px] md:origin-top-right md:rounded-[10px]"
					>
						{({close}) => (
							<Tab.Group>
								{({selectedIndex}) => (
									<>
										{/* Header */}
										<div className=" flex h-[48px] items-center border-b border-gray-400  bg-gray-50 px-3 text-gray-900">
											<div className="flex-1" />
											<h4 className="block-uplift text-xs font-medium uppercase leading-4 tracking-wider ">Quick Access</h4>
											<div className="flex-1">
												<button
													onClick={() => close()}
													className="float-right mr-2 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-gray-50 focus:bg-gray-400 focus:outline-none md:bg-white md:hover:bg-gray-100"
													aria-label="close quick access menu"
												>
													<Icon
														icon="close"
														className="float-right h-[24px] w-[24px] p-0 font-light"
													/>
												</button>
											</div>
										</div>
										{/* Body */}
										<div className="pl-[16px] pt-[22px]">
											<Tab.List className="flex space-x-[16px] text-xs font-medium leading-5 text-gray-600">
												<Tab className="border-blue-700 pb-2 outline-offset-2 ui-selected:border-b-2">
													<div className="rounded px-[4px] py-[2px] hover:bg-gray-50 ui-selected:!bg-blue-100 ui-selected:text-blue-700">{quickAccessConfig.systemTabTitle}</div>
												</Tab>
												<Tab className="border-blue-700 pb-2 outline-offset-2 ui-selected:border-b-2">
													<div className="rounded px-[4px] py-[2px] hover:bg-gray-50 ui-selected:!bg-blue-100 ui-selected:text-blue-700">{quickAccessConfig.intranetsTabTitle}</div>
												</Tab>
											</Tab.List>
											<Tab.Panels>
												<Tab.Panel
													tabIndex={-1}
													className="skinny-scrollbar mr-[12px] h-[calc(100vh-153px)] max-h-[calc(100vh-153px)] overflow-y-auto py-2 pl-[2px] pt-[22px] md:h-full md:max-h-[calc(100vh-260px)]"
												>
													{quickAccessConfig.systems.map((link) => (
														<a
															key={link.title}
															href={link.url}
															target="_blank"
															className="mr-[12px] flex items-center justify-between rounded border-2 border-transparent p-[8px] !no-underline hover:bg-gray-50 focus:border-blue-700 focus:bg-gray-50 focus:outline-none"
														>
															<div className="flex items-center gap-x-[8px]">
																<img
																	className={cx(link.logoBorder && 'border border-gray-300', 'block h-[32px] w-[32px] rounded-full border border-gray-300 object-scale-down')}
																	src={link.logoUrl}
																	aria-hidden="true"
																	alt=""
																/>
																<div className="text-sm font-semibold leading-5 text-gray-800">
																	{link.title} {link.subTitle && <span className="font-normal">({link.subTitle})</span>}
																</div>
															</div>
															<Icon
																icon="external-link"
																className="h-[18px]"
															/>
														</a>
													))}
												</Tab.Panel>
												<Tab.Panel
													tabIndex={-1}
													className="skinny-scrollbar mr-[12px] h-full max-h-[calc(100vh-104px)] overflow-y-auto py-2 pl-[2px] pt-[22px] md:max-h-[calc(100vh-212px)]"
												>
													{quickAccessConfig.intranets.map((link) => (
														<a
															key={link.title}
															href={link.url}
															target="_blank"
															className="mr-[12px] flex items-center justify-between rounded border-2 border-transparent p-[8px] !no-underline hover:bg-gray-50 focus:border-blue-700 focus:bg-gray-50 focus:outline-none"
														>
															<div className="text-sm font-semibold leading-5 text-gray-800">{link.title}</div>
															<Icon
																icon="external-link"
																className="h-[18px]"
															/>
														</a>
													))}
												</Tab.Panel>
											</Tab.Panels>
										</div>
										{/* Footer only for System and tools  */}
										{selectedIndex === 0 && (
											<div className="flex h-[48px] w-full items-center justify-end border-t border-gray-400 bg-gray-50 px-[16px] text-sm font-medium leading-5 text-blue-700 md:rounded-b-[10px]">
												<a href={quickAccessConfig.moreSystemsUrl}>{quickAccessConfig.moreSystemsLabel}</a>
												<Icon
													icon="arrow-forward"
													className="ml-[4px] h-[18px]"
												/>
											</div>
										)}
									</>
								)}
							</Tab.Group>
						)}
					</Popover.Panel>
				</Transition>
			</Popover>
			<HeaderQuickAccessNudge />
			<HeaderQuickAccessUpdateNotice openQuickAccess={clickQuickAccessButton} />
		</Popover.Group>
	)
}
