import {Listbox, Popover} from '@headlessui/react'
import React, {useEffect, useRef, useState} from 'react'
import Masonry from 'react-masonry-css'
import {Icon} from '~/blocks/Icon'
import {CardProps} from '~/cards/Card'
import {StaffProfile} from '~/cards/ModalProfileCard'
import {CopyButton} from '~/components/CopyButton'
import {IconAttachedToLastWord} from '~/components/IconAttachedToLastWord'
import {Pagination} from '~/components/Pagination'
import {IconInBox} from '~/images/IconInBox'
import {render} from '~/utils/render'
import {breadcrumbList, cx, isUrlSameHost, removeUrlParam, setUrlParam, urlHost} from '~/utils/utils'
import {GlobalSearchBox} from './GlobalSearchBox'
import './ResearchGatewaySearchPage.css'
import './SearchPage.css'
import {SearchResultType, searchResultTypes, Topic, topics, useRgSearch} from './useRgSearch'

const RESULTS_PER_PAGE = 15

export interface SearchPageProps {
	heading: string
	subheading: string
	searchBoxPlaceholder: string
	typePlaceholder: string
	topicPlaceholder: string
	noQueryMessage: string
	emptyMessage: string
	searchApiUrl?: string
}

export const initResearchGatewaySearchPage = () => {
	Array.from(document.querySelectorAll<HTMLElement>('.staff-ui-rg-search')).forEach((element) => {
		const props: SearchPageProps = {
			heading: element.dataset.heading ?? '',
			subheading: element.dataset.subheading ?? '',
			searchBoxPlaceholder: element.dataset.searchBoxPlaceholder ?? '',
			typePlaceholder: element.dataset.typePlaceholder ?? '',
			topicPlaceholder: element.dataset.topicPlaceholder ?? '',
			noQueryMessage: element.dataset.noQueryMessage ?? '',
			emptyMessage: element.dataset.emptyMessage ?? '',
			searchApiUrl: element.dataset.searchApiUrl ?? '',
		}
		document.body.classList.add('navy-breadcrumbs')
		render(<ResearchGatewaySearchPage {...props} />, element)
	})

	if (breadcrumbList.length == 2 && breadcrumbList[1].href == '/people') {
		const personIdParam = new URLSearchParams(window.location.search).get('id')
		location.href = `/search?type=profile${personIdParam != null && personIdParam.length > 0 ? '&personId=' + Number(personIdParam) : ''}`
	}
}
export default function ResearchGatewaySearchPage(props: SearchPageProps) {
	const queryParam = new URLSearchParams(window.location.search).get('query') ?? ''
	const [query, setQuery] = useState(queryParam)

	const typeParam = new URLSearchParams(window.location.search).get('types') ?? ''
	const [selectedTypes, setSelectedTypes] = useState<SearchResultType[]>(searchResultTypes.filter((type) => typeParam.includes(type.apiName)) ?? [])

	const topicParam = new URLSearchParams(window.location.search).get('topics') ?? ''
	const [selectedTopics, setSelectedTopics] = useState<Topic[]>(topics.filter((topic) => topicParam.includes(topic.shortName)) ?? [])

	const pageParam = parseInt(new URLSearchParams(window.location.search).get('page') ?? '') || 1
	const [currentPage, setCurrentPage] = useState(pageParam)

	const layoutParam = new URLSearchParams(window.location.search).get('layout') ?? ''
	const [layout, setLayout] = useState(layoutParam === 'grid' ? 'grid' : 'list')

	const [inputValue, setInputValue] = useState(query)

	const topOfResultsRef = useRef<HTMLDivElement>(null)

	const startAt = (currentPage - 1) * RESULTS_PER_PAGE + 1

	const {isLoading, results, totalMatching, enabled} = useRgSearch(query, startAt, selectedTopics, selectedTypes)

	useEffect(() => {
		changePage(1)
		if (!query.length) {
			removeUrlParam('query')
		} else {
			setUrlParam('query', query)
		}
	}, [query])

	useEffect(() => {
		if (selectedTypes.length === 0) {
			removeUrlParam('types')
		} else {
			setUrlParam('types', selectedTypes.map((type) => type.apiName).join('.'))
		}
	}, [selectedTypes])

	useEffect(() => {
		if (selectedTopics.length === 0) {
			removeUrlParam('topics')
		} else {
			setUrlParam('topics', selectedTopics.map((topic) => topic.shortName).join('.'))
		}
	}, [selectedTopics])

	useEffect(() => {
		if (layout === 'list') {
			removeUrlParam('layout')
		} else {
			setUrlParam('layout', 'grid')
		}
	}, [layout])

	function changePage(newPage: number) {
		setCurrentPage(newPage)
		if (newPage < 2) {
			removeUrlParam('page')
		} else {
			setUrlParam('page', newPage.toString())
		}
	}

	function changePageAndScroll(newPage: number) {
		changePage(newPage)
		topOfResultsRef.current?.scrollIntoView()
	}

	function clear() {
		setQuery('')
		setSelectedTypes([])
		setSelectedTopics([])
	}

	return (
		<div>
			<div className="relative">
				<div className="full-width-background   pb-[48px] before:bg-uom-navy-900">
					<h1 className=" block-uplift relative pt-[16px] text-3xl font-extrabold leading-8 tracking-tight text-white">{props.heading}</h1>
					<div className="relative pb-[48px] pt-[16px] text-lg font-normal leading-6 text-white">{props.subheading}</div>
					{/* Search input */}
					<div className="relative">
						<div className=" z-10 flex w-full flex-col items-center gap-y-[16px] md:flex-row  md:rounded md:bg-white">
							<div className="w-full flex-shrink-0  border-gray-600 md:w-[40%] md:border-r">
								<GlobalSearchBox
									query={query}
									onNewQuery={setQuery}
									mainSearchPage={true}
									onNewInputValue={setInputValue}
								/>
							</div>
							<div className="--pl-[8px] flex w-full flex-grow rounded-md bg-white">
								<Listbox
									as="div"
									value={selectedTypes}
									onChange={setSelectedTypes}
									multiple
									className="relative flex flex-grow"
								>
									<Listbox.Button className="ml-[16px] flex flex-grow items-center justify-between py-[12px] md:ml-[24px] md:py-[14px]">
										{selectedTypes.length > 0 ? (
											<>
												<div className="hidden items-center gap-x-[8px] min-[610px]:flex md:hidden min-[1210px]:flex">
													{selectedTypes
														//.sort((a, b) => a.displayName.localeCompare(b.displayName))
														.map((type) => (
															<SearchTypeBadge
																key={type.apiName}
																type={type}
																removeType={(typeApiName) => {
																	setSelectedTypes(selectedTypes.filter((t) => t.apiName !== typeApiName))
																}}
															/>
														))}
												</div>
												<div className="hidden items-center gap-x-[8px] min-[541px]:flex min-[609px]:hidden min-[1100px]:flex min-[1210px]:hidden">
													{selectedTypes
														.sort((a, b) => a.apiName.localeCompare(b.apiName))
														.slice(0, 3)
														.map((type) => (
															<SearchTypeBadge
																key={type.apiName}
																type={type}
																removeType={(typeApiName) => {
																	setSelectedTypes(selectedTypes.filter((t) => t.apiName !== typeApiName))
																}}
															/>
														))}
													{selectedTypes.length > 3 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+1 more</span>}
												</div>
												<div className="hidden items-center gap-x-[8px] min-[395px]:flex min-[540px]:hidden min-[860px]:flex min-[1100px]:hidden">
													{selectedTypes
														.sort((a, b) => a.apiName.localeCompare(b.apiName))
														.slice(0, 2)
														.map((type) => (
															<SearchTypeBadge
																key={type.apiName}
																type={type}
																removeType={(typeApiName) => {
																	setSelectedTypes(selectedTypes.filter((t) => t.apiName !== typeApiName))
																}}
															/>
														))}
													{selectedTypes.length > 2 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+{selectedTypes.length - 2} more</span>}
												</div>
												<div className="flex items-center gap-x-[8px]  min-[394px]:hidden md:flex min-[860px]:hidden">
													{selectedTypes
														.sort((a, b) => a.apiName.localeCompare(b.apiName))
														.slice(0, 1)
														.map((type) => (
															<SearchTypeBadge
																key={type.apiName}
																type={type}
																removeType={(typeApiName) => {
																	setSelectedTypes(selectedTypes.filter((t) => t.apiName !== typeApiName))
																}}
															/>
														))}
													{selectedTypes.length > 1 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+{selectedTypes.length - 1} more</span>}
												</div>
											</>
										) : (
											<div className="text-sm font-normal leading-5 text-gray-600">{props.typePlaceholder}</div>
										)}
										<span className="pointer-events-none flex items-center pr-[16px] md:pr-[36px]">
											<Icon
												icon="chevron-down"
												className="h-5 w-5 text-gray-400"
												aria-hidden="true"
											/>
										</span>
									</Listbox.Button>
									<Listbox.Options
										as="div"
										className="absolute inset-x-0 bottom-[-136px] left-0 z-50 rounded bg-white py-[4px]  shadow-lg md:mr-3"
									>
										{searchResultTypes.map((type) => (
											<Listbox.Option
												key={type.apiName}
												as="div"
												value={type}
											>
												{({selected}) => (
													<div className="flex cursor-pointer items-center justify-between py-[8px] pl-[16px] pr-[12px] hover:bg-gray-100">
														<div className="flex items-center">
															<input
																type="checkbox"
																checked={selected}
																readOnly={true}
															/>
															<div className="pl-[12px] text-sm font-normal leading-5 text-gray-700">{type.displayName}</div>
														</div>
														<div
															className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
															style={{backgroundColor: type.bgColour, color: type.textColour}}
														>
															{type.displayName}
														</div>
													</div>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Listbox>
							</div>
							<button
								className="mx-[12px] w-full rounded-md bg-white px-[18px] py-[9px] text-base font-medium leading-6 text-uom-navy-900 hover:bg-gray-50 md:my-[9px] md:w-auto md:bg-uom-navy-900 md:text-white md:hover:bg-uom-navy-dark"
								onClick={() => setQuery(inputValue)}
							>
								Search
							</button>
						</div>
					</div>
					<div className="mt-[16px] flex items-center  justify-between md:mt-[8px] ">
						<Listbox
							as="div"
							value={selectedTopics}
							onChange={setSelectedTopics}
							multiple
							className="relative flex max-w-[486px] flex-grow"
						>
							<Listbox.Button className=" flex items-center justify-between rounded-md border border-gray-200 bg-white px-[18px] py-[9px] text-sm font-normal leading-5 text-gray-600">
								{selectedTopics.length > 0 ? (
									<div className="gap-x-[8px]">
										Filtering on {selectedTopics[0].displayName}
									</div>
								) : (
									<div className="">{props.topicPlaceholder}</div>
								)}
								<span className="pointer-events-none ml-[8px] flex items-center">
									<Icon
										icon="chevron-down"
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</span>
							</Listbox.Button>
							<Listbox.Options
								as="div"
								className="absolute bottom-[-412px] left-0 z-[1000] mr-3 rounded  bg-white py-[4px] shadow-lg"
							>
								{topics.map((topic) => (
									<Listbox.Option
										key={topic.apiName}
										as="div"
										value={topic}
										disabled={((topic.apiName != enabled) && (enabled != 'all')) && true}
									>
										{({selected}) => (
											<>
											{((topic.apiName == enabled) || (enabled == 'all'))? (
												
												<div className="flex cursor-pointer items-center justify-between px-[16px] py-[8px] hover:bg-gray-100">
													<div className="flex items-center">
														<input
															type="checkbox"
															checked={selected}
															readOnly={true}
														/>
														<div className="pl-[12px] text-sm font-normal leading-5 text-gray-700">{topic.displayName}</div>
													</div>
												</div>
												
											): (

												<div className="flex cursor-not-allowed items-center justify-between px-[16px] py-[8px] hover:bg-gray-150">
														<div className="flex items-center">
															<input
																type="checkbox"
																checked={selected}
																readOnly={true}
															/>
															<div className="pl-[12px] text-sm font-normal leading-5 text-gray-200">{topic.displayName}</div>
														</div>
													</div>
												
											)}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Listbox>
						<button
							onClick={clear}
							className="z-10 block text-sm text-white underline"
						>
							Clear
						</button>
					</div>
				</div>
				{/* Results */}
				<h2 className="block-uplift sr-only">Results</h2>
				<div
					ref={topOfResultsRef}
					className="min-h-[240px] w-full pt-[24px]"
				>
					{isLoading && (
						<svg
							className="mx-auto mt-[100px] h-8 w-8 animate-spin text-gray-800"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle
								className="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								strokeWidth="4"
							/>
							<path
								className="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							/>
						</svg>
					)}
					{!isLoading && query.length <= 0 && (
						<div className="">
							<div className="mx-auto mt-[64px] flex h-[64px] w-[64px] items-center justify-center rounded-full bg-gray-100">
								<Icon
									icon="search"
									className="mx-auto h-[24px] w-[24px] text-uom-navy-900"
								/>
							</div>
							<div className="mt-[24px] w-full text-center text-base font-normal leading-6 text-gray-600">{props.noQueryMessage}</div>
						</div>
					)}
					{!isLoading && totalMatching != null && query.length > 0 && (
						<div>
							<div className="flex justify-between pb-8">
								<div className="text-xs font-normal  leading-4">
									{totalMatching ?? 0} results found {query && `"${query}"`} for the filters applied
								</div>
								<div className="hidden text-sm font-medium leading-5 text-gray-700 md:flex">
									<button
										onClick={() => setLayout('list')}
										className={cx(layout === 'list' ? 'bg-blue-700 text-white' : 'border border-gray-500 text-gray-500', 'flex items-center gap-x-[8px] rounded-l px-[12px] py-[7px]')}
									>
										<Icon
											icon="menu"
											className="h-[17px]"
										/>
										<div>List</div>
									</button>
									<button
										onClick={() => setLayout('grid')}
										className={cx(layout === 'grid' ? 'bg-blue-700 text-white' : 'border border-gray-500 text-gray-500', 'flex items-center gap-x-[8px] rounded-r px-[12px] py-[7px]')}
									>
										<Icon
											icon="grid"
											className="h-[16px]"
										/>
										<div>Grid</div>
									</button>
								</div>
							</div>
							{!isLoading && (
								<>
									<div className={cx(layout !== 'list' && 'md:hidden', 'flex flex-col gap-y-[32px]')}>
										{results.map((result, index) =>
											result.type === 'profile' ? (
												<ListResultPerson
													key={index}
													result={result}
												/>
											) : (
												<ListResult
													key={index}
													{...result}
												/>
											),
										)}
									</div>
									<Masonry
										breakpointCols={{
											default: 3,
											768: 2,
											640: 1,
										}}
										className={cx(layout === 'grid' && 'md:flex', 'card-masonry hidden')}
										columnClassName="card-masonry-column"
									>
										{results.map((result, index) =>
											result.type === 'profile' ? (
												<GridResultPerson
													key={index}
													result={result}
												/>
											) : (
												<GridResult
													key={index}
													{...result}
												/>
											),
										)}
									</Masonry>
								</>
							)}
							{totalMatching > RESULTS_PER_PAGE && (
								<Pagination
									currentPage={currentPage}
									goToPage={changePageAndScroll}
									numberOfPages={Math.ceil(totalMatching / RESULTS_PER_PAGE)}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

function SearchTypeBadge(props: {type: SearchResultType; removeType: (typeApiName: string) => void}) {
	return (
		<div
			className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
			style={{backgroundColor: props.type.bgColour, color: props.type.textColour}}
		>
			<div>{props.type.displayName}</div>
			<div
				onClick={(event) => {
					event.preventDefault()
					props.removeType(props.type.apiName)
				}}
				className="remove-type-button ml-[2px] rounded p-[2px]"
				style={{'--hover-bg-colour': props.type.hoverBgColour} as React.CSSProperties}
			>
				<Icon
					icon="close"
					className="h-[12px] w-[12px]"
				/>
			</div>
		</div>
	)
}

function ListResult(props: CardProps) {
	const type = searchResultTypes.find((type) => type.apiName === props.type)
	return (
		<a
			href={props.url}
			className="max-w-content no-underline hover:no-underline"
		>
			<div className="flex items-center gap-x-[16px] text-blue-700">
				<div className="text-lg  underline">
					{isUrlSameHost(props.displayUrl ?? '') ? (
						props.title
					) : (
						<IconAttachedToLastWord
							text={props.title ?? ''}
							icon="external-link"
							className="ml-[16px] inline h-[20px] "
						/>
					)}
				</div>
			</div>
			<div className="mt-[12px] flex gap-x-[8px]">
				<div
					className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
					style={{backgroundColor: type?.bgColour, color: type?.textColour}}
				>
					{type?.shortDisplayName}
				</div>
				<div className="flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700">{urlHost(props.displayUrl ?? '')}</div>
			</div>
			<div
				className="mt-[16px] text-sm font-normal leading-5 text-gray-600"
				dangerouslySetInnerHTML={{__html: props.body ?? ''}}
			/>
		</a>
	)
}

function ListResultPerson(props: {result: CardProps}) {
	const type = searchResultTypes.find((type) => type.apiName === 'profile')
	return (
		<Popover className="relative">
			<Popover.Button className=" flex justify-start">
				<img
					className="h-[64px] w-[64px] rounded-full object-cover object-top"
					src={props.result.photoUrl ? props.result.photoUrl : 'https://pictures.staff.unimelb.edu.au/squiz/index_eis.php?id=' + props.result.personId}
					alt={props.result.title + ' profile picture'}
				/>
				<div className="ml-[16px] flex flex-col items-start">
					<div className="text-lg  text-blue-700 underline">{props.result.title}</div>
					<div className="mt-[12px] flex gap-x-[8px]">
						<div
							className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
							style={{backgroundColor: type?.bgColour, color: type?.textColour}}
						>
							{type?.shortDisplayName}
						</div>
						<div
							className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
							style={{backgroundColor: type?.bgColour, color: type?.textColour}}
						>
							{props.result.departmentName}
						</div>
						<div className="flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700">{window.location.host}</div>
					</div>
					<div className="mt-[20px] text-sm font-medium leading-5 text-gray-900">{props.result.position}</div>
					{props.result.email && <div className="mt-[12px] text-sm font-normal leading-5 text-gray-600">Email: {props.result.email}</div>}
					{props.result.phone && <div className="mt-[12px] text-sm font-normal leading-5 text-gray-600">Phone: {props.result.phone}</div>}
				</div>
			</Popover.Button>
			<Popover.Panel className="absolute top-[36px] z-10">
				{({close}) => (
					<ProfileModal
						staffId={props.result.personId ?? ''}
						close={close}
					/>
				)}
			</Popover.Panel>
		</Popover>
	)
}

function GridResult(props: CardProps) {
	const type = searchResultTypes.find((type) => type.apiName === props.type)
	return (
		<a
			href={props.url}
			className="flex max-w-content flex-col justify-start rounded-[8px] p-[24px] no-underline shadow hover:no-underline"
		>
			<div className=" flex justify-between gap-x-[8px] ">
				<div className="flex flex-wrap gap-[8px]">
					<div
						className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
						style={{backgroundColor: type?.bgColour, color: type?.textColour}}
					>
						{type?.shortDisplayName}
					</div>
					<div className="flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700">{urlHost(props.displayUrl ?? '')}</div>
				</div>
				{!isUrlSameHost(props.displayUrl ?? '') && (
					<Icon
						icon="external-link"
						className="h-[20px] flex-shrink-0"
					/>
				)}
			</div>
			<div className="mt-[16px] flex items-center justify-between gap-x-[16px] text-blue-700">
				<div className="text-lg  underline">{props.title}</div>
			</div>
			<div
				className="mt-[16px] text-sm font-normal leading-5 text-gray-600"
				dangerouslySetInnerHTML={{__html: props.body ?? ''}}
			/>
		</a>
	)
}

function GridResultPerson(props: {result: CardProps}) {
	const type = searchResultTypes.find((type) => type.apiName === 'profile')
	return (
		<Popover className="relative">
			<Popover.Button className="flex w-full flex-col justify-start rounded-[8px] p-[24px] shadow">
				<div className="flex items-center">
					<img
						className="h-[48px] w-[48px] rounded-full object-cover object-top"
						src={props.result.photoUrl ? props.result.photoUrl : 'https://pictures.staff.unimelb.edu.au/squiz/index_eis.php?id=' + props.result.personId}
						alt={props.result.title + ' profile picture'}
					/>
					<div className="ml-[16px] text-left  text-lg text-blue-700 underline">{props.result.title}</div>
				</div>
				<div className="flex flex-col items-start">
					<div className="mt-[12px] flex gap-x-[8px]">
						<div
							className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
							style={{backgroundColor: type?.bgColour, color: type?.textColour}}
						>
							{type?.shortDisplayName}
						</div>

						<div className="flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700">{window.location.host}</div>
					</div>
					<div
						className="mt-[8px] flex items-center rounded-md px-2 py-1 text-xs font-medium"
						style={{backgroundColor: type?.bgColour, color: type?.textColour}}
					>
						{props.result.departmentName}
					</div>
					<div className="mt-[20px] text-left text-sm font-medium leading-5 text-gray-900">{props.result.position}</div>
					{props.result.email && <div className="mt-[12px] text-sm font-normal leading-5 text-gray-600">Email: {props.result.email}</div>}
					{props.result.phone && <div className="mt-[12px] text-sm font-normal leading-5 text-gray-600">Phone: {props.result.phone}</div>}
				</div>
			</Popover.Button>
			<Popover.Panel className="absolute top-[36px] z-10">
				{({close}) => (
					<ProfileModal
						staffId={props.result.personId ?? ''}
						close={close}
					/>
				)}
			</Popover.Panel>
		</Popover>
	)
}

const staffDetailsLightApiUrl = (window as any).staffDetailsLightApiUrl
const ProfileModal = ({staffId, close}: {staffId: string; close: () => void}) => {
	const [contact, setContact] = useState<StaffProfile | null>(null)

	useEffect(() => {
		const fetchData = async () => {
			const cachedProfile = localStorage.getItem('staffId-' + staffId)
			if (cachedProfile != null) {
				setContact(JSON.parse(cachedProfile))
				return
			}
			try {
				const response = await fetch(`${staffDetailsLightApiUrl}?id=${staffId}`, {
					headers: {
						Accept: 'application/json',
					},
				})
				const json = await response.json()
				localStorage.setItem('staffId-' + staffId, JSON.stringify(json))
				setContact(json)
			} catch (e) {
				console.error(e)
			}
		}
		void fetchData()
	}, [])
	return (
		<div className={cx('items-top flex min-w-max gap-x-[24px] rounded-[8px] bg-white p-[24px] !no-underline shadow-xl')}>
			{contact == null && (
				<div>
					<svg
						className="mx-auto h-10 w-10 animate-spin text-gray-800"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<circle
							className="opacity-25"
							cx="12"
							cy="12"
							r="10"
							stroke="currentColor"
							strokeWidth="4"
						/>
						<path
							className="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
						/>
					</svg>
				</div>
			)}
			{contact != null && (
				<>
					<img
						className="aspect-square h-[64px] rounded-full object-cover object-top"
						// src={'https://pictures.staff.unimelb.edu.au/squiz/index_eis.php?id=' + contact.id}
						src={contact.photoUrl}
						alt={contact.name + ' profile picture'}
					/>
					<div className="flex flex-col justify-center">
						<div className="flex flex-wrap items-center gap-x-[16px] gap-y-[8px]">
							<div className="text-lg font-medium leading-8 text-gray-900">{contact.name}</div>
							{contact.department && contact.department.length > 0 && (
								<div className="flex items-center rounded-full bg-yellow-100 px-[8px] py-[4px] text-xs font-medium leading-4 text-yellow-900">{contact.department.trim()}</div>
							)}
						</div>
						<div className="mt-[8px] text-left text-sm font-normal leading-5 text-gray-600">{contact.position}</div>
						<div className="mt-[16px] flex items-center gap-x-[24px]">
							<IconInBox
								colour="gray"
								icon="mail"
							/>
							<div>
								<a
									href={'mailto:' + contact.email}
									className="text-blue-700 !underline hover:decoration-2"
								>
									{contact.email}
								</a>
							</div>
							<div className="min-w-[88px]">
								<CopyButton
									text={contact.email ?? ''}
									classNames="h-[30px]"
								/>
							</div>
						</div>
						{contact.faeExists === 'Y' && (
							<div className="mt-[8px] flex items-center gap-x-[24px]">
								<IconInBox
									colour="gray"
									icon="face"
								/>
								<div>
									<a
										// href={'https://findanexpert.unimelb.edu.au/profile/' + contact.id}
										href={contact.faeProfileUrl}
										className="text-blue-700 !underline hover:decoration-2"
									>
										Find an Expert profile
									</a>
								</div>
							</div>
						)}
					</div>
					<button
						onClick={() => close()}
						className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full !bg-gray-50 focus:bg-gray-400 focus:outline-none"
					>
						<Icon
							icon="close"
							className="float-right h-6 w-6 p-0 font-light"
						/>
					</button>
				</>
			)}
		</div>
	)
}
