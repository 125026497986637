import React from 'react'
import {Icon} from '~/blocks/Icon'
import type {NewsCardProps} from '~/cards/NewsCard'
import {NewsCard} from '~/cards/NewsCard'
import {queryAlt, queryBackgroundImage, queryHref, querySrc, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx} from '~/utils/utils'

interface Props {
	articles?: NewsCardProps[]
}

const parseNewsCardProps = (element: HTMLElement): NewsCardProps => {
	const hero = element.classList.contains('news-hero')
	return {
		title: queryTextContent('h1,h2,h3,h4', element),
		url: queryHref('a', element),
		imageUrl: hero ? queryBackgroundImage('a', element) : querySrc('img', element),
		imageAlt: hero ? undefined : queryAlt('img', element),
		smallImage: element.querySelector('img:first-child') != null,
		description: queryTextContent('p:not(.topic)', element),
		topic: queryTextContent('p.topic', element),
		hero: hero,
		date: queryTextContent('.date', element),
	}
}

export function initNews() {
	Array.from(document.querySelectorAll<HTMLElement>('article.news-hero')).forEach((element) => {
		const props = parseNewsCardProps(element)
		render(<NewsCard {...props} />, element)
	})

	Array.from(document.querySelectorAll<HTMLFormElement>('.news-index')).forEach((element) => {
		const props: Props = {
			articles: Array.from(element.querySelectorAll<HTMLAnchorElement>('article')).map((article) => parseNewsCardProps(article)),
		}
		render(<NewsList {...props} />, element)
	})

	Array.from(document.querySelectorAll<HTMLFormElement>('.staff-ui-news-grid')).forEach((element) => {
		const props: Props = {
			articles: Array.from(element.querySelectorAll<HTMLAnchorElement>('article')).map((article) => parseNewsCardProps(article)),
		}
		render(<NewsGrid {...props} />, element)
	})
}

const NewsList = (props: Props) => {
	return (
		<div className={cx('section-spacing flex flex-col gap-y-[24px]')}>
			{props.articles?.map((article, index) => (
				<NewsCard
					key={index}
					{...article}
				/>
			))}
		</div>
	)
}

const NewsGrid = (props: Props) => {
	return (
		<div className={cx('section-spacing grid gap-[24px] sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4')}>
			{props.articles?.map((article, index) => (
				<a
					key={index}
					href={article.url}
					className="flex max-w-[792px] flex-col overflow-hidden rounded-lg  text-gray-600 shadow hover:no-underline hover:shadow-lg"
				>
					{article.imageUrl != null && article.imageUrl.length > 0 && (
						<div className="flex-shrink-0">
							<img
								className=" h-[200px] w-full object-cover"
								src={article.imageUrl}
								aria-hidden="true"
								alt={article.imageAlt}
							/>
						</div>
					)}

					<div className="flex grow flex-col justify-between gap-[24px] p-[24px]">
						<div>
							<h3 className={cx(article.hero ? 'text-3xl font-extrabold leading-8 tracking-tight' : 'text-lg font-semibold leading-6', 'block-uplift text-gray-900')}>{article.title}</h3>
							<p className="m-0 !mt-[12px] p-0 text-base font-normal leading-6 text-gray-600">{article.description}</p>
						</div>
						<div className="flex items-center justify-between">
							{article.date?.length && <div className="text-sm font-medium leading-4 text-gray-600">{article.date}</div>}
							{article.topic && article.topic.length > 0 && <div className="rounded-full bg-gray-50 px-[13px] py-[4px] text-xs font-medium leading-4 text-gray-600">{article.topic}</div>}
							<div className="flex flex-row-reverse justify-between">
								<div className="text-sm font-medium leading-5 text-blue-700 hover:underline focus:outline-none">
									Read full story{' '}
									<Icon
										icon="arrow-forward"
										className="mb-[4px] inline h-4 w-4"
									/>
								</div>
							</div>
						</div>
					</div>
				</a>
			))}
		</div>
	)
}
