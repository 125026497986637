// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TableSort from 'tablesort'
import {wrap} from '~/utils/utils'
import './Table.css'

export function initTable() {
	document.querySelectorAll('table:not([data-wrapped])').forEach((tableElement) => {
		const table = tableElement as HTMLElement
		const tableWrapper = document.createElement('div')
		tableWrapper.className = 'table-wrapper'

		tableWrapper.style.width = table.style.width
		table.style.removeProperty('width')

		table.setAttribute('data-wrapped', 'true')

		wrap(table, tableWrapper)
	})

	document.querySelectorAll('table[data-sortable], table.sortable').forEach((table) => {
		if (!table.hasAttribute('data-sorted')) {
			new TableSort(table)
			table.setAttribute('data-sorted', 'true')
		}
	})
}
