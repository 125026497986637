import {Dialog, Transition} from '@headlessui/react'
import {uniqueId} from 'lodash'
import React, {FC, Fragment, useState} from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'
import './SlideOverModal.css'

interface SlideOverModalProps {
	content?: string
	isOpen: boolean
	close: () => void
	fullScreenOnMobile?: boolean
	children?: React.ReactNode
}

export const SlideOverModal: FC<SlideOverModalProps> = ({content, isOpen, close, children, fullScreenOnMobile}) => {
	/* This id and .slide-over-modal-body are needed so that jump nav can be used inside a slide over modal and only show links for content within the modal body*/
	const [id] = useState(uniqueId('modal-body-id-'))

	return (
		<Transition.Root
			show={isOpen}
			as={Fragment}
		>
			<Dialog
				as="div"
				className="slide-over-modal fixed inset-0 z-[1000] overflow-hidden"
				onClose={close}
			>
				<div className="absolute inset-0 overflow-hidden">
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className={cx(fullScreenOnMobile ? 'md:pl-10' : 'pl-10', 'fixed inset-y-0 right-0 flex max-w-full')}>
						<Transition.Child
							unmount={false}
							as={Fragment}
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enterFrom="translate-x-full"
							enterTo="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leaveFrom="translate-x-0"
							leaveTo="translate-x-full"
						>
							<div className={cx(fullScreenOnMobile ? 'md:max-w-[840px]' : 'max-w-[840px]', 'w-screen')}>
								<div className="flex h-full flex-col overflow-y-auto bg-white pb-6 shadow-xl">
									<div className="px-4 sm:px-6">
										<div className="flex items-start justify-end">
											<div className="ml-3 flex h-[64px] items-center">
												<button
													className="h-[40px] w-[40px] rounded-full bg-gray-50 text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:!outline-none"
													onClick={close}
												>
													<span className="sr-only">Close panel</span>
													<Icon
														icon="close"
														className="m-auto h-[22px] w-auto"
														aria-hidden="true"
													/>
												</button>
											</div>
										</div>
									</div>
									{content && (
										<div
											id={id}
											className="slide-over-modal-body relative flex-1 px-4 sm:px-6"
											dangerouslySetInnerHTML={{__html: content ?? ''}}
										/>
									)}
									{children}
								</div>
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
