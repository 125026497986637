import React from 'react'
import {SocialIcon} from '~/images/SocialIcon'
import {queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import './NewsArticle.css'

interface NewsArticleProps {
	datePublished?: string
	categories: {
		url?: string
		label?: string
	}[]
	socialLinks: {
		url?: string
		icon?: string
	}[]
	bodyHtml?: string
	moreInformation: {
		name?: string
		contacts: {
			type?: string
			label?: string
			url?: string
		}[]
	}
}

export function initNewsArticle() {
	Array.from(document.querySelectorAll<HTMLElement>('article.news')).forEach((element) => {
		// If there are header images or videos, move it under the heading before it is copied by to bodyHtml

		const headerImage = element.querySelector(':scope > figure')
		if (headerImage) {
			element.querySelector('.article > h1:first-of-type, .article > h2:first-of-type,.article > h3:first-of-type,.article > h4:first-of-type')?.after(headerImage)
		}

		const headerVideo = element.querySelector('.article figure:first-child .embed')?.parentElement
		if (headerVideo) {
			headerVideo.remove()
			element.querySelector('.article > h1:first-of-type, .article > h2:first-of-type,.article > h3:first-of-type,.article > h4:first-of-type')?.after(headerVideo)
		}

		const props: NewsArticleProps = {
			datePublished: queryTextContent('.aside time', element),
			categories: Array.from(element.querySelectorAll<HTMLAnchorElement>('ul.categories > li > a')).map((category) => ({
				url: category.getAttribute('href') ?? undefined,
				label: category.textContent ?? undefined,
			})),
			socialLinks: Array.from(element.querySelectorAll<HTMLAnchorElement>('ul.social-links > li > a')).map((category) => ({
				url: category.getAttribute('href') ?? undefined,
				icon: category.querySelector<HTMLElement>('[data-icon]')?.dataset.icon ?? undefined,
			})),
			bodyHtml: element.querySelector(':scope > div.article')?.innerHTML,
			moreInformation: {
				name: queryTextContent('.more-information .name', element),
				contacts: Array.from(element.querySelectorAll<HTMLAnchorElement>('.more-information a')).map((contact) => ({
					url: contact.getAttribute('href') ?? undefined,
					type: contact.getAttribute('href')?.includes('mailto') ? 'Email' : 'Phone',
					label: contact.textContent ?? undefined,
				})),
			},
		}
		render(<NewsArticle {...props} />, element)
	})
}

const NewsArticle = (props: NewsArticleProps) => {
	return (
		<div>
			<div className="flex flex-col gap-x-[24px] gap-y-[16px] md:flex-row md:items-center">
				<div className="h-full text-lg font-normal leading-5 text-green-900">{props.datePublished}</div>
				<div className="flex flex-wrap gap-[8px]">
					{props.categories.map((category, index) => (
						<a
							key={index}
							href={category.url}
							className="rounded-full border border-gray-300 bg-gray-50 px-[13px] py-[4px] text-xs font-medium leading-4 text-blue-700"
						>
							{category.label}
						</a>
					))}
				</div>
			</div>
			<div className="my-[32px] flex flex-col gap-y-[12px] text-base font-semibold leading-6 text-green-900">
				<div>
					More Information: <span className="text-base font-normal leading-6 text-gray-600">{props.moreInformation.name}</span>
				</div>
				{props.moreInformation.contacts.map((contact, index) => (
					<div key={index}>
						{contact.type}:{' '}
						<a
							className="text-base font-normal leading-6 text-blue-700"
							href={contact.url}
						>
							{contact.label}
						</a>
					</div>
				))}
			</div>
			<div
				className="news-article-body"
				dangerouslySetInnerHTML={{__html: props.bodyHtml ?? ''}}
			/>
			<div className="mt-[48px] flex gap-x-[8px]">
				{props.socialLinks.map((socialLink, index) => (
					<a
						key={index}
						className="flex h-[24px] w-[24px] items-center justify-center rounded-sm bg-uom-navy-900 text-white"
						href={socialLink.url}
					>
						<SocialIcon
							name={socialLink.icon}
							className="w-[18px]"
						/>
					</a>
				))}
			</div>
		</div>
	)
}
