import React from 'react'
import {LinkArrow} from '~/images/LinkArrow'
import {cx, urlLooksLikeAFile} from '~/utils/utils'
import type {CardProps} from './Card'
import {CardWrapper} from './CardWrapper'

export function LeftBorderCard(props: CardProps) {
	if (props.title === undefined) return null

	const parsedUrl = new URL(props.url ?? '', document.baseURI)
	const host = parsedUrl.hostname

	const titleLastSpaceIndex = props.title.lastIndexOf(' ')

	const isDownload = props.hasDownloadClass || urlLooksLikeAFile(props.url)

	return (
		<CardWrapper
			{...props}
			className={cx(
				'flex flex flex-col gap-y-[8px] rounded-r-lg border-2 border-y-transparent border-l-blue-700 border-r-transparent px-[18px] py-[8px] text-left text-sm font-normal leading-5 text-blue-700 hover:bg-gray-50 hover:no-underline focus:border-y-blue-700 focus:border-r-blue-700 focus:bg-gray-50 focus:outline-none',
				props.horizontalLayout && 'min-w-[300px]',
			)}
		>
			<div className="font-medium text-blue-700 hover:underline">
				{props.title.substring(0, titleLastSpaceIndex + 1)}
				<span className="whitespace-nowrap">
					{props.title.substring(titleLastSpaceIndex)}
					{props.url != null && (
						<LinkArrow
							className="ml-1.5 text-blue-700"
							isDownload={isDownload}
							url={props.url}
						/>
					)}
				</span>
			</div>
			{props.body != null && props.body.length > 0 && <div className="text-gray-900">{props.body}</div>}
			{props.modalContent == null && props.url != null && (
				<div className="mt-auto max-w-min whitespace-nowrap rounded-sm bg-yellow-50 px-[8px] py-[2px] text-yellow-700">
					{host}
					{isDownload && '...' + parsedUrl.pathname.slice(parsedUrl.pathname.length - 12)}
				</div>
			)}
		</CardWrapper>
	)
}
