import React from 'react'
import {LinkArrow} from '~/images/LinkArrow'
import {cx} from '~/utils/utils'
import type {CardProps} from './Card'
import {CommonCardProps} from './Card'
import {CardWrapper} from './CardWrapper'

export interface LogoCardProps extends CommonCardProps {
	logoUrl?: string
	logoBorder?: boolean
}

export function LogoCard(props: CardProps) {
	return (
		<CardWrapper
			{...props}
			className={cx(
				'focus:outline-blue flex flex h-max flex-row items-center rounded-lg border-2 border-transparent !bg-white p-[24px] text-left text-sm font-normal leading-5 text-blue-700 shadow hover:no-underline hover:shadow-lg active:border-blue-700',
				props.horizontalLayout && 'min-w-max pr-[42px]',
			)}
		>
			<img
				className={cx(props.logoBorder ? 'border border-gray-200' : '', 'mr-[24px] !h-[48px] !w-[48px] rounded-lg bg-white object-cover')}
				src={props.logoUrl}
				aria-hidden="true"
				alt=""
			/>
			<div className="font-medium text-blue-700 hover:underline">
				{props.title}{' '}
				{props.url != null && (
					<LinkArrow
						className="ml-3 text-blue-700"
						url={props.url}
					/>
				)}
			</div>
		</CardWrapper>
	)
}
