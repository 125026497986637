import {Listbox, Transition} from '@headlessui/react'
import React from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'

interface Props {
	options: {name: string; tag_name: string}[]
	selectedIndexes: number[]
	disabledIndexes?: number[]
	onChangeSelection: (newSelectedIndexes: number[]) => void
	placeholder?: string
	selectionLabel?: string
	blankOption?: string
	colour?: 'blue' | 'teal' | 'orange' | 'pink'
	className?: string
	error?: boolean
	disabled?: boolean
	dropdownAlign?: 'left' | 'right'
}

export function NewsMultipleSelect({
	options,
	selectedIndexes,
	disabledIndexes,
	onChangeSelection,
	placeholder,
	selectionLabel,
	blankOption,
	colour = 'blue',
	className,
	error,
	disabled,
	dropdownAlign = 'left',
}: Props) {
	const buttonRef = React.useRef<HTMLButtonElement>(null)

	return (
		<Listbox
			value={selectedIndexes}
			onChange={(newSelection) => {
				const isBlankOptionSelected = newSelection.some((value) => value == null)
				if (isBlankOptionSelected) {
					buttonRef.current?.click()
				}
				onChangeSelection(isBlankOptionSelected ? [] : newSelection)
			}}
			disabled={disabled}
			multiple
		>
			{({open}) => (
				<div className={cx('group relative', className)}>
					<Listbox.Button
						ref={buttonRef}
						className={cx(
							'border border-gray-300 bg-white text-gray-700',
							'hover:border-gray-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500',
							'relative w-full cursor-default rounded-md py-2 pl-3 pr-10 text-left shadow-sm',
						)}
					>
						<span
							className={cx('block truncate', selectedIndexes.length === 0 ? 'text-gray-500' : 'text-gray-700')}
							dangerouslySetInnerHTML={{
								__html: selectedIndexes.length > 0 ? selectionLabel ?? '' : placeholder ?? '',
							}}
						/>
						<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
							<Icon
								className="h-5 w-5"
								icon={open ? 'arrow-drop-up' : 'arrow-drop-down'}
								aria-hidden="true"
							/>
						</span>
					</Listbox.Button>

					<Transition
						show={open}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Listbox.Options
							static
							className={cx(
								'absolute z-10 min-w-full w-full sm:w-auto rounded-md bg-white py-1 px-2 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
								dropdownAlign === 'right' ? 'right-0' : 'left-0',
								'overflow-x-auto'
							)}
						>
							<div className="min-w-max">
								{blankOption && (
									<Listbox.Option
										className={({ active }) =>
											cx(
												active ? 'bg-gray-100 text-gray-900' : 'text-gray-600',
												'relative cursor-default select-none py-1 pl-2 pr-2 text-[14px]',
											)
										}
										value={null}
									>
										<span className="block">{blankOption}</span>
									</Listbox.Option>
								)}
								{options.map((option, index) => {
									const isDisabled = disabledIndexes?.includes(index)
									const isSelected = selectedIndexes.includes(index)
									const tagColorClass =
										colour === 'pink'
											? 'bg-pink-100 text-pink-800'
											: colour === 'blue'
											? 'bg-blue-100 text-blue-800'
											: colour === 'orange'
											? 'bg-orange-100 text-orange-800'
											: 'bg-teal-100 text-teal-800'

									return (
										<Listbox.Option
											key={index}
											value={index}
											disabled={isDisabled}
											className={({ active }) =>
												cx(
													active ? 'bg-gray-100 text-gray-900' : 'text-gray-600',
													'relative flex cursor-default select-none items-center py-1 pl-2 pr-2 text-[14px]',
													'whitespace-nowrap',
												)
											}
										>
											<div className="flex w-full min-w-max items-center justify-between gap-4">
												<div className="flex flex-grow items-center gap-2">
													<input
														type="checkbox"
														checked={isSelected}
														className="h-4 w-4"
														readOnly
													/>
													<span className={cx(
														'whitespace-nowrap',
														isSelected ? 'font-semibold' : 'font-normal',
														isDisabled ? 'text-gray-400' : 'text-gray-900'
													)}>
														{option.name}
													</span>
												</div>
												<span
													className={cx(
														'ml-2 inline-flex items-center whitespace-nowrap rounded-[3px] px-1 py-[1px] text-[14px] font-medium',
														tagColorClass,
														'flex-none ml-auto'
													)}
												>
													{option.tag_name}
												</span>
											</div>
										</Listbox.Option>
									)
								})}
							</div>
						</Listbox.Options>
					</Transition>
				</div>
			)}
		</Listbox>
	)
}
