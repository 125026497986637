import type React from 'react'
import {queryAlt, queryHref, querySrc, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx, notEmpty} from '~/utils/utils'
import './Event.css'
import {Icon} from '~/blocks/Icon'

interface EventListingProps {
	title?: string
	dates: DateTime[]
	datesAreRange: boolean
	events: SubEvent[]
}

interface DateTime {
	dayOfWeek?: string
	dayOfMonth?: string
	month?: string
	year?: string
	time?: string
}

interface SubEvent {
	url?: string
	time?: string
	title?: string
	description?: string
	tags: string[]
	presenters: {
		url?: string
		name?: string
	}[]
	ribbonText?: string
	ribbonLevel?: RibbonLevel
}

interface EventPageProps {
	title?: string
	dates: DateTime[]
	datesAreRange: boolean
	time?: string
	imageUrl?: string
	imageAlt?: string
	imageCaption?: string
	bodyHtml?: string
	presenters: {
		url?: string
		name?: string
	}[]
	ribbonText?: string
	ribbonLevel?: RibbonLevel
	address?: string[]
	addressLinkUrl?: string
	addressLinkLabel?: string
	shareUrl?: string
	shareLabel?: string
	contactPerson: string[]
	telephone: string[]
	email: string[]
	accessibility?: string
}

type RibbonLevel = 'danger' | 'warning' | 'info'

export function initEvent() {
	document.querySelectorAll<HTMLElement>('ul.event-listing').forEach((element) => {
		element.classList.add('no-list')
	})

	document.querySelectorAll<HTMLElement>('ul.event-listing > li').forEach((element) => {
		const props: EventListingProps = {
			title: queryTextContent('h1', element),
			dates: Array.from(element.querySelectorAll<HTMLElement>(':scope > h2')).map(parseDateTimeFromElement).filter(notEmpty),
			datesAreRange: element.querySelector('.when.range') != null,
			events: Array.from(element.querySelectorAll<HTMLElement>(':scope > ul > li')).map((event) => ({
				title: queryTextContent('h3', event),
				time: queryTextContent('.time', event),
				description: queryTextContent('p', event),
				tags: Array.from(event.querySelectorAll<HTMLElement>('.tag')).map((tag) => tag.textContent ?? ''),
				presenters: Array.from(event.querySelectorAll<HTMLElement>(':scope > .presenters > a'))
					.map((presenter) => {
						if (presenter.textContent == null || presenter.textContent.length <= 0) return null
						return {
							name: presenter.textContent ?? undefined,
							url: presenter.getAttribute('href') ?? undefined,
						}
					})
					.filter(notEmpty),
				ribbonText: queryTextContent('.ribbon', event),
				ribbonLevel: parseRibbonLevel(event.querySelector('.ribbon')),
				url: queryHref('a', event),
			})),
		}

		render(<EventListing {...props} />, element)
	})

	// Find gen3 style events that have no easy/clear/semantic way to identify them and add the new sh-event class
	document.querySelectorAll<HTMLElement>('.detail > .upper').forEach((element) => {
		element.parentElement?.parentElement?.classList.add('sh-event')
	})

	document.querySelectorAll<HTMLElement>('.sh-event').forEach((element) => {
		const props: EventPageProps = {
			title: queryTextContent('h1', element),
			dates: Array.from(element.querySelectorAll<HTMLElement>('.when > time:not(.time)')).map(parseDateTimeFromElement).filter(notEmpty),
			datesAreRange: element.querySelector('.when.range') != null,
			time: queryTextContent('time.time', element),
			imageUrl: querySrc('.detail .upper img', element),
			imageAlt: queryAlt('.detail .upper img', element),
			imageCaption: queryTextContent('.photo-caption', element),
			bodyHtml: element.querySelector('.event-body')?.innerHTML ?? '',
			presenters: Array.from(element.querySelectorAll<HTMLElement>('.presenters > a'))
				.map((presenter) => {
					if (presenter.textContent == null || presenter.textContent.length <= 0) return null
					return {
						name: presenter.textContent ?? undefined,
						url: presenter.getAttribute('href') ?? undefined,
					}
				})
				.filter(notEmpty),
			ribbonText: queryTextContent('.ribbon', element),
			ribbonLevel: parseRibbonLevel(element.querySelector('.ribbon')),
			address:
				element
					.querySelector('.location > p:first-child')
					?.innerHTML?.split('<br>')
					.map((addressLine) => addressLine.trim()) ?? [],
			addressLinkUrl: queryHref('.location > a', element),
			addressLinkLabel: queryTextContent('.location > a', element),
			shareUrl: queryHref('.share > a', element),
			shareLabel: queryTextContent('.share > a', element),
			contactPerson: Array.from(element.querySelectorAll<HTMLElement>('.more-info  .contact-person'))
				.map((contactPerson) => contactPerson.textContent)
				.filter(notEmpty),
			telephone: Array.from(element.querySelectorAll<HTMLElement>('.more-info  .contact-telephone'))
				.map((contactPerson) => contactPerson.textContent)
				.filter(notEmpty),
			email: Array.from(element.querySelectorAll<HTMLElement>('.more-info  .contact-email'))
				.map((contactPerson) => contactPerson.textContent)
				.filter(notEmpty),
			accessibility: queryTextContent('.more-info  .accessibility', element),
		}

		render(<EventPage {...props} />, element)
	})
}

export function EventListing(props: EventListingProps) {
	return (
		<div className="section-spacing text-gray-900">
			<div className="flex flex-col border-l border-gray-300 pl-[16px] md:flex-row md:border-none md:pl-0">
				<div className="w-[180px] border-gray-300 pr-[12px] md:border-r md:text-right">
					<ul className="no-list">
						{props.dates.map((date, index) => (
							<li
								key={index}
								className="!p-0"
							>
								<div className="text-sm font-normal leading-5">{date.dayOfWeek}</div>
								<div className="my-[4px] text-lg font-semibold leading-6">
									<div>{date.dayOfMonth}</div>
									<div>{date.month}</div>
								</div>
								<div className="text-sm font-normal leading-5">{date.year}</div>
							</li>
						))}
					</ul>
				</div>
				<div className="mt-[24px] md:ml-[12px] md:mt-0">
					{props.events.map((event, index) => (
						<div
							key={index}
							className="mt-[24px] flex flex-col gap-x-[24px] border-t  border-gray-300 pt-[24px] first:mt-0 first:border-t-0 first:pt-0 lg:flex-row"
						>
							<div className="relative w-full flex-shrink-0 overflow-hidden md:max-w-[564px]">
								{event.ribbonText != null && event.ribbonText.length > 0 && (
									<div className="absolute right-0 top-0">
										<div
											className={cx(
												event.ribbonLevel === 'danger' ? 'bg-red-600 text-white' : '',
												event.ribbonLevel === 'warning' ? 'bg-yellow-100 text-yellow-900' : '',
												event.ribbonLevel === 'info' ? 'bg-uom-navy-100 text-uom-navy-900' : '',
												'absolute right-[-50px] top-[18px] w-[170px] rotate-45 transform py-[8px] text-center text-xs font-semibold leading-4',
											)}
										>
											{event.ribbonText}
										</div>
									</div>
								)}
								<div className="text-sm font-normal leading-5">{event.time}</div>
								<h3 className="!mt-[4px] !p-0 text-lg font-semibold leading-6 first-letter:uppercase first:mt-0">{event.title}</h3>
								<p className="mt-[28px] !p-0 text-sm font-normal leading-5 text-gray-600 first:mt-0">{event.description}</p>
								{event.tags.length > 0 && (
									<div className="mt-[24px] flex flex-wrap gap-[8px]">
										{event.tags.map((tag, index) => (
											<div
												key={index}
												className="rounded-full bg-uom-navy-100 px-[14px] py-[4px] text-xs font-medium uppercase leading-4 tracking-wider text-uom-navy-900"
											>
												{tag}
											</div>
										))}
									</div>
								)}
								<a
									className="mt-[24px] block cursor-pointer text-sm font-medium leading-5 text-blue-700 hover:underline"
									href={event.url}
								>
									View details{' '}
									<Icon
										icon="arrow-forward"
										className="mb-[4px] inline h-[16px]"
									/>
								</a>
							</div>
							{event.presenters.length > 0 && (
								<div className="mt-[24px] w-full md:max-w-[384px] lg:mt-0">
									<h4 className="!mt-0 !pb-[8px]">Presenters</h4>
									<div className="grid grid-cols-2 gap-x-[24px] gap-y-[8px] lg:grid-cols-1  xl:grid-cols-2">
										{event.presenters.map((presenter, index) => (
											<a
												key={index}
												className="underline"
												href={presenter.url}
											>
												{presenter.name}
											</a>
										))}
									</div>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export function EventPage(props: EventPageProps) {
	return (
		<div className="section-spacing text-gray-900">
			<div className="event-page-grid flex flex-col md:grid">
				<div className="col-start-2">
					<h1 className="!pb-[24px] text-3xl font-semibold leading-tight">{props.title}</h1>
				</div>
				<div className="md:text-right">
					<ul className="no-list flex gap-y-[24px] gap-x-[46px] md:flex-col">
						{props.dates.map((date, index) => (
							<li
								key={index}
								className="!p-0"
							>
								<div className="text-sm font-normal leading-5">{date.dayOfWeek}</div>
								<div className="my-[4px] text-lg font-semibold leading-6">
									<div>{date.dayOfMonth}</div>
									<div>{date.month}</div>
								</div>
								<div className="text-sm font-normal leading-5">{date.year}</div>
							</li>
						))}
					</ul>

					<div className="pt-[4px] text-sm font-normal leading-5 md:pt-[24px]">{props.time}</div>
				</div>
				<div className="mt-[24px] md:mt-0">
					<img
						className="w-full"
						src={props.imageUrl}
						alt={props.imageAlt}
					/>
				</div>
				<div className="order-5 md:order-none md:text-right">
					{((props.address != null && props.address.length > 0) || (props.addressLinkUrl != null && props.addressLinkUrl.length > 0)) && (
						<div className="flex flex-col gap-y-[24px] border-t border-gray-300 py-[24px]">
							{props.address != null && props.address.length > 0 && (
								<div className="flex flex-col gap-y-[4px] ">
									{props.address.map((address, index) => (
										<div
											className="text-sm font-normal leading-5"
											key={index}
										>
											{address}
										</div>
									))}
								</div>
							)}
							{props.addressLinkUrl != null && props.addressLinkUrl.length > 0 && (
								<div>
									<a
										href={props.addressLinkUrl}
										className="button brand"
									>
										{props.addressLinkLabel ?? 'Map'}
									</a>
								</div>
							)}
						</div>
					)}
					{props.shareUrl != null && props.shareUrl.length > 0 && (
						<div className="border-t border-gray-300 py-[24px]">
							<a
								href={props.shareUrl}
								className="button brand"
							>
								{props.shareLabel ?? 'Share'}
							</a>
						</div>
					)}
					{((props.contactPerson != null && props.contactPerson.length > 0) ||
						props.email.length > 0 ||
						props.telephone.length > 0 ||
						(props.accessibility != null && props.accessibility?.length > 0)) && (
						<div className="flex flex-col gap-y-[4px] border-t border-gray-300 py-[24px] text-sm font-normal leading-5 md:items-end">
							<div>{props.contactPerson}</div>
							{props.telephone.map((telephone, index) => (
								<div key={index}>{telephone}</div>
							))}
							{props.email.map((email, index) => (
								<a
									className="underline md:text-right"
									href={'mailto:' + email}
									key={index}
								>
									{email}
								</a>
							))}
							<div>{props.accessibility}</div>
						</div>
					)}
				</div>
				<div className="pt-[24px] text-sm font-normal  leading-5 text-gray-600">
					<div className="italic">{props.imageCaption}</div>
					<div dangerouslySetInnerHTML={{__html: props.bodyHtml ?? ''}} />
				</div>
				<div className="order-6 md:order-none md:col-start-2">
					{props.presenters.length > 0 && (
						<div className="max-w-min">
							<h4 className="!mt-0  !pb-[8px]">Presenters</h4>
							<div className={cx(props.presenters.length > 20 ? 'lg:grid-cols-3' : '', 'grid min-w-max gap-x-[160px] gap-y-[8px] sm:grid-cols-2')}>
								{props.presenters.map((presenter, index) => (
									<a
										key={index}
										className="underline"
										href={presenter.url}
									>
										{presenter.name}
									</a>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

const parseDateTimeFromElement = (heading: HTMLElement | null): DateTime | null => {
	if (heading == null) return null

	const wholeTextContent = heading.textContent ?? ''

	const dayOfMonth = queryTextContent('.date', heading) ?? ''
	const month = queryTextContent('.month', heading) ?? ''
	const dayOfWeek = wholeTextContent.split(dayOfMonth)[0].trim()
	const year = wholeTextContent.split(month)[1].trim()

	return {
		dayOfWeek,
		dayOfMonth,
		month,
		year,
	}
}

const parseRibbonLevel = (element: HTMLElement | null): RibbonLevel | undefined => {
	if (element == null) return undefined
	if (element.classList.contains('ribbon--danger')) return 'danger'
	if (element.classList.contains('ribbon--warning')) return 'warning'
	if (element.classList.contains('ribbon--info')) return 'info'
	return undefined
}
