import React, {useEffect} from 'react'
import {useWindowSize} from 'usehooks-ts'
import {render} from '~/utils/render'
import {cx} from '~/utils/utils'
import './RightSidebar.css'

export function initRightSidebar() {
	const rightSidebar = document.querySelector<HTMLElement>('.layout-sidebar--right .layout-sidebar__side')

	if (rightSidebar == null || document.body.classList.contains('block-right-sidebar')) {
		return
	}

	const rightSidebarText = rightSidebar.textContent?.trim() ?? ''

	if (rightSidebarText.length <= 0) {
		rightSidebar.remove()
		return
	}

	const jumpNavOnLeft = document.body.classList.contains('jumpnav-active')
	document.body.classList.add('right-sidebar-active')

	rightSidebar?.querySelectorAll('li.no-li').forEach((listItem) => listItem.classList.remove('no-li'))

	const sidebarInnerHtml = rightSidebar?.innerHTML ?? ''

	render(
		<RightSidebar
			sidebarInnerHtml={sidebarInnerHtml}
			jumpNavOnLeft={jumpNavOnLeft}
		/>,
		rightSidebar,
	)
}

function RightSidebar({sidebarInnerHtml, jumpNavOnLeft}: {sidebarInnerHtml: string; jumpNavOnLeft: boolean}) {
	const sidebarRef = React.useRef<HTMLDivElement>(null)
	const size = useWindowSize()

	useEffect(() => {
		setTimeout(() => {
			if (sidebarRef.current == null) {
				return
			}
			const sidebarHeight = sidebarRef.current.getBoundingClientRect().height
			if (sidebarHeight > 0) {
				document.querySelector('[role="main"]')?.setAttribute('style', `min-height: ${sidebarHeight + 120}px`)
			}
		}, 100)
	}, [sidebarRef, size])

	return (
		<>
			{/*  Desktop  */}
			{!jumpNavOnLeft && (
				<div className="absolute top-0 right-0 z-[4] hidden h-max w-[300px] bg-white xl:block">
					<div
						ref={sidebarRef}
						className="right-sidebar-content z-[5] mt-[200px] border-l border-gray-100 px-[24px]"
						dangerouslySetInnerHTML={{__html: sidebarInnerHtml}}
					/>
				</div>
			)}

			{/*  Mobile  */}
			<div className={cx(jumpNavOnLeft ? '' : 'xl:hidden', 'mb-6')}>
				<div
					className="mx-[-1rem] mt-[32px] px-[1rem] pb-2 sm:-mx-6 sm:px-6 lg:mx-0 lg:px-0"
					dangerouslySetInnerHTML={{__html: sidebarInnerHtml}}
				/>
			</div>
		</>
	)
}
