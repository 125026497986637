import React from 'react'
import {useSideScrollButtons} from '~/utils/useSideScrollButtons'
import {cx} from '~/utils/utils'
import {Icon} from '~/blocks/Icon'

export interface Issue {
	title: string
	url: string
	active?: boolean
	status?: string
}

interface RecentIssuesProps {
	issues: Issue[]
}

export function RecentIssues({issues}: RecentIssuesProps) {
	const {sideScrollRef, handleSideScrollLeftPointerDown, handleSideScrollLeftPointerUp, handleSideScrollRightPointerDown, handleSideScrollRightPointerUp} = useSideScrollButtons<HTMLDivElement>()

	return (
		<>
			<div className="flex justify-between pt-6">
				<h3 className="block-uplift text-xs font-medium uppercase leading-4 tracking-wider">RECENT ISSUES</h3>
				<div className="md:hidden">
					<button
						className=""
						onMouseDown={handleSideScrollLeftPointerDown}
						onMouseUp={handleSideScrollLeftPointerUp}
					>
						<Icon
							icon="chevron-left"
							className="mt-[-3px] h-6 w-6"
						/>
					</button>
					<button
						className=""
						onMouseDown={handleSideScrollRightPointerDown}
						onMouseUp={handleSideScrollRightPointerUp}
					>
						<Icon
							icon="chevron-right"
							className="mt-[-3px] h-6 w-6"
						/>
					</button>
				</div>
			</div>
			<div
				ref={sideScrollRef}
				className="hide-horizontal-scrollbar -mx-4 flex items-center overflow-y-hidden px-4 pt-[8px] pb-2 sm:-mx-6 sm:px-6 md:mx-0 md:block md:space-y-2 md:px-0"
			>
				{issues.map((issue) => (
					<a
						key={issue.title}
						href={issue.url}
						aria-label={'view issue ' + issue.title}
						className={cx(
							issue.active
								? 'border-blue-700 bg-blue-700 text-white hover:bg-blue-800 focus:border-blue-900 focus:bg-blue-800 focus:ring-blue-900'
								: 'border-gray-300 bg-gray-50 text-blue-700 hover:bg-gray-100 focus:border-blue-700 focus:ring-blue-700',
							'my-1 mr-2 whitespace-nowrap rounded-full  border px-[13px] py-1 text-xs font-medium leading-4 ring-inset hover:underline  focus:outline-none focus:ring-1 ',
						)}
					>
						{issue.title}
					</a>
				))}
			</div>
		</>
	)
}
