import React from 'react'
import {IconInBox} from '~/images/IconInBox'

export function FormIconInBox() {
	return (
		<IconInBox
			colour="yellow"
			icon="form"
		/>
	)
}
