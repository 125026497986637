import type {StaffNewsArticle} from '~/cards/StaffNewsArticleCard'

export function getNewsArticles(query: string): StaffNewsArticle[] {
	return Array.from(document.querySelectorAll<HTMLElement>(query)).map((article) => ({
		title: article.querySelector('[data-title]')?.textContent ?? '',
		url: article.querySelector<HTMLAnchorElement>('[data-url]')?.href ?? '',
		summary: article.querySelector('[data-summary]')?.textContent ?? '',
		datePublished: article.querySelector('[data-date-published]')?.textContent ?? '',
		issueTitle: article.querySelector('[data-issue-title]')?.textContent ?? '',
		issueUrl: article.querySelector<HTMLAnchorElement>('[data-issue-url]')?.href?.split('#')[0] ?? '',
	}))
}
