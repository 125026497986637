import {Popover, Transition} from '@headlessui/react'
import React, {Fragment, useEffect, useRef} from 'react'
import {Icon} from '~/blocks/Icon'

interface QuickAccessNudgeConfig {
	enabled?: boolean
	title?: string
	body?: string
	buttonLabel?: string
	icon?: string
	reshowIfLastSeenBefore?: string
}

const firstTimeLoadedKey = 'quickAccessNudgeFirstTimeLoaded'
const firstTimeShownKey = 'quickAccessNudgeFirstTimeShown'
const secondTimeShownKey = 'quickAccessNudgeSecondTimeShown'
const reshownKey = 'quickAccessNudgeReshown'
const oneDayInMilliseconds = 86400000
const oneWeekInMilliseconds = 604800000
const oneMonthInMilliseconds = 2592000000

// These are rendered into a script tag in Squiz Matrix in asset #3232308
const config: QuickAccessNudgeConfig = (window as any).quickAccessNudge ?? {}

export function HeaderQuickAccessNudge() {
	if (!config.enabled) return null

	// Used a Popover with a hidden button so that the Popover.Group will close the nudge if the users clicks the quick access button.
	// Without having to manage both states manually including click outside.
	const buttonRef = useRef<HTMLButtonElement>(null)
	buttonRef.current?.click()

	useEffect(() => {
		if (window.innerWidth < 767) return

		const secondTimeShown = window.localStorage.getItem(secondTimeShownKey)
		const reshown = window.localStorage.getItem(reshownKey)
		if (secondTimeShown != null) {
			if (config.reshowIfLastSeenBefore != null) {
				const reshowIfLastSeenBeforeParsed = Date.parse(config.reshowIfLastSeenBefore)
				if (
					Number(secondTimeShown) + oneMonthInMilliseconds < Date.now() &&
					Number(secondTimeShown) < reshowIfLastSeenBeforeParsed &&
					(reshown == null || Number(reshown) < reshowIfLastSeenBeforeParsed)
				) {
					window.localStorage.setItem(reshownKey, Date.now().toString())
					buttonRef.current?.click()
				}
			}
			return
		}

		const firstTimeShown = window.localStorage.getItem(firstTimeShownKey)
		if (firstTimeShown != null) {
			if (Number(firstTimeShown) + oneWeekInMilliseconds < Date.now()) {
				window.localStorage.setItem(secondTimeShownKey, Date.now().toString())
				buttonRef.current?.click()
			}
			return
		}

		const firstTimeLoaded = window.localStorage.getItem(firstTimeLoadedKey)
		if (firstTimeLoaded != null) {
			if (Number(firstTimeLoaded) + oneDayInMilliseconds < Date.now()) {
				window.localStorage.setItem(firstTimeShownKey, Date.now().toString())
				buttonRef.current?.click()
			}
			return
		}

		window.localStorage.setItem(firstTimeLoadedKey, Date.now().toString())
	}, [])

	return (
		<Popover className="hidden md:block">
			<Popover.Button
				ref={buttonRef}
				className="hidden"
				aria-hidden
			/>
			<Transition
				as={Fragment}
				static
				enter="transition ease-in-out duration-500 md:transform-none md:transition md:ease-out md:duration-100"
				enterFrom="translate-x-full md:translate-x-0 md:md:opacity-0 md:scale-95"
				enterTo="translate-x-0  opacity-100  scale-100"
				leave="transition ease-in-out duration-500 md:transition md:ease-in md:duration-75"
				leaveFrom="translate-x-0 opacity-100 scale-100"
				leaveTo="translate-x-full md:translate-x-0 md:md:opacity-0 md:scale-95"
			>
				<div
					id="quick-access-nudge"
					className="absolute left-auto top-full right-0 z-50 mt-2 h-auto w-[384px] origin-top-right overflow-hidden rounded-lg shadow-lg"
				>
					<div className="flex gap-x-[16px] bg-uom-navy-900 px-[24px] pt-[24px] pb-[16px] text-white">
						<div className="flex h-[40px] w-[40px] shrink-0 items-center justify-center rounded-md bg-white text-uom-navy-900">
							{config.icon != null && (
								<Icon
									icon={config.icon}
									className="h-[24px] w-[24px] flex-shrink-0"
								/>
							)}
						</div>
						<div className="">
							<div className="text-lg font-semibold leading-6">{config.title}</div>
							<div className="mt-[8px] text-sm font-normal leading-5">{config.body}</div>
						</div>
					</div>
					<div className="bg-uom-navy-800 px-[24px] py-[12px] text-right">
						<button
							className="button-sm soft !m-0 w-auto"
							aria-label="close quick access nudge"
						>
							{config.buttonLabel}
						</button>
					</div>
				</div>
			</Transition>
		</Popover>
	)
}
