import {Listbox, Transition} from '@headlessui/react'
import React, {Fragment, useState} from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'

export interface LinkDropdownOption {
	url: string
	label: string
	group: boolean
}

interface LinkDropdownProps {
	label?: string
	placeholder?: string
	options: LinkDropdownOption[]
	buttonLabel?: string
}

export function LinkDropdown({label, placeholder, options, buttonLabel}: LinkDropdownProps) {
	const [currentDropdownValue, setCurrentDropdownValue] = useState<LinkDropdownOption | null>(null)

	return (
		<Listbox
			value={currentDropdownValue}
			onChange={setCurrentDropdownValue}
		>
			{({open}) => (
				<div className="link-dropdown relative z-[100]">
					{label && <label className="block pt-[27px] text-xs font-medium uppercase leading-4 tracking-wider">{label}</label>}
					<div className="relative relative mt-1">
						<Listbox.Button className="relative mb-[8px] mr-[16px] box-border w-full cursor-default rounded-md border border-gray-500 bg-white py-2 pl-3 pr-10 text-left text-sm text-sm font-normal leading-5 shadow-sm focus:border-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-700 md:w-[384px]">
							<span className="block truncate text-gray-700">{currentDropdownValue?.label ?? placeholder}</span>
							<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-500">
								{open ? (
									<Icon
										icon="chevron-up"
										className="w-6"
										aria-hidden="true"
									/>
								) : (
									<Icon
										icon="chevron-down"
										className="w-6"
										aria-hidden="true"
									/>
								)}
							</span>
						</Listbox.Button>
						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options
								static
								className="no-list absolute mt-[-5px] w-full w-full overflow-visible rounded-md bg-white py-1 text-base text-sm font-normal leading-5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:w-[384px]"
							>
								{options.map((option) => (
									<Listbox.Option
										key={option.url + option.label}
										disabled={option.group}
										className={({active}) =>
											cx(
												active ? 'bg-gray-100 text-sm font-normal leading-5 text-gray-900' : '',
												option.group ? 'board-gray-100 border-t text-xs font-medium uppercase leading-4 tracking-wider text-gray-900' : '',
												!option.group && !active ? 'text-sm font-normal leading-5 text-gray-600' : '',
												'cursor-default select-none py-2 pl-3 pr-9',
											)
										}
										value={option}
									>
										{() => <span>{option.label}</span>}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
						<a
							href={currentDropdownValue?.url}
							className={cx(
								currentDropdownValue != null && currentDropdownValue.url.length > 0 ? '' : 'disabled',
								'link-dropdown-button box-border !inline-block w-full whitespace-nowrap rounded-lg border border-gray-500 px-[17px] py-[9px] align-top text-sm font-medium leading-5 text-gray-600 shadow-sm hover:no-underline md:w-auto',
							)}
						>
							{buttonLabel}
						</a>
					</div>
				</div>
			)}
		</Listbox>
	)
}
