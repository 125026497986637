import {Popover} from '@headlessui/react'
import React from 'react'
import {Icon} from '~/blocks/Icon'
import {IconAttachedToLastWord} from '~/components/IconAttachedToLastWord'
import {HeaderMenuItemCard} from '~/global/header/HeaderMenuItemCard'
import {IconInBox} from '~/images/IconInBox'
import {cx, isUrlSameHost} from '~/utils/utils'

export interface NavSection {
	title: string
	url?: string
	linkSectionHeading?: string
	linkSectionHeadingUrl?: string
	linkSections?: {
		title: string
		url?: string
		links: {
			title: string
			url?: string
		}[]
	}[]
	target?: string
	menuItemSectionHeading?: string
	menuItemSectionHeadingUrl?: string
	menuItems?: MenuItem[]
	additionalMenuItemSectionHeading?: string
	additionalMenuItemSectionHeadingUrl?: string
	additionalMenuItems?: MenuItem[]
	footerMenuItems?: MenuItem[]
}

export interface MenuItem {
	icon?: 'kb' | 'form' | 'phone' | 'chat'
	title: string
	description: string
	url?: string
	openInNewTab?: boolean
	linkText?: string
}

// These are rendered into a script tag in Quiz Matrix in this asset  #3232308
export const headerNavConfig: NavSection[] = (window as any).headerNav ?? (window as any).staffHubHeaderNav ?? []

export function HeaderNavDesktop() {
	return (
		<Popover.Group
			as="nav"
			className="hidden space-x-8 md:flex"
			aria-label="Global"
		>
			{headerNavConfig.map((section) => (
				<Popover key={section.title}>
					<Popover.Button className="ui-open:focus:outline-black-900 my-2 inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-900 hover:bg-blue-100 ui-open:bg-blue-100 ui-open:text-blue-700 ui-open:outline-1 ui-open:outline-blue-700">
						<span>{section.title}</span>
						<Icon
							icon="chevron-down"
							className="ml-[4px] mr-[-2px] inline h-[22px]"
						/>
					</Popover.Button>
					<Popover.Panel className="skinny-scrollbar absolute inset-x-0 top-full z-10 max-h-[calc(100vh-137px)] overflow-y-auto bg-white shadow-lg md:block">
						{section.linkSections != null && (
							<div className="container my-[32px]">
								{section.linkSectionHeading != null &&
									(section.linkSectionHeadingUrl != null ? (
										<a href={section.linkSectionHeadingUrl}>
											<h3 className="mb-[24px] text-xl font-semibold leading-7 text-gray-900 underline">
												{section.linkSectionHeading}
												<Icon
													icon="chevron-right"
													className="ml-[8px] inline h-[20px]"
												/>
											</h3>
										</a>
									) : (
										<h3 className="text-xl font-semibold leading-7 text-gray-900">{section.linkSectionHeading}</h3>
									))}
								<div
									className={cx(
										'grid gap-[32px]',
										section.linkSections.length === 2
											? 'grid-cols-2'
											: section.linkSections.length === 3
											? 'grid-cols-2 lg:grid-cols-3'
											: section.linkSections.length === 4 && 'grid-cols-2 lg:grid-cols-4',
									)}
								>
									{section.linkSections.map((linkSection, index) => (
										<div key={index}>
											{linkSection.title != null && linkSection.url != null ? (
												<a href={linkSection.url}>
													<h4 className="!pb-0 text-lg font-semibold leading-6 text-gray-900 underline">
														{linkSection.title}
														<Icon
															icon="chevron-right"
															className="ml-[8px] inline h-[20px]"
														/>
													</h4>
												</a>
											) : (
												<h4 className="!pb-0 text-lg font-semibold leading-6 text-gray-900">{linkSection.title}</h4>
											)}
											{section.linkSections != null &&
												(section.linkSections.length > 2 ? (
													<LinkGrid
														links={linkSection.links}
														linkSectionHeading={section.linkSectionHeading}
													/>
												) : (
													<SplitLinkGrid
														links={linkSection.links}
														linkSectionHeading={section.linkSectionHeading}
													/>
												))}
										</div>
									))}
								</div>
							</div>
						)}
						{section.menuItems != null && (
							<>
								{section.menuItemSectionHeading != null &&
									(section.menuItemSectionHeadingUrl != null ? (
										<div className="container">
											<a href={section.menuItemSectionHeadingUrl}>
												<h3 className="mb-[12px] mt-0 !p-0 pt-[24px] text-xl font-semibold leading-7 text-gray-900 underline">
													{section.menuItemSectionHeading}
													<Icon
														icon="chevron-right"
														className="ml-[8px] inline h-[20px]"
													/>
												</h3>
											</a>
										</div>
									) : (
										<h3 className="mt-[24px] text-xl font-semibold leading-7 text-gray-900">{section.menuItemSectionHeading}</h3>
									))}
								<div
									className={cx(
										'container !grid grid-cols-2 gap-[8px]',
										section.menuItemSectionHeading == null ? 'pb-[48px] pt-[51px]' : 'pb-[8px]',
										section.menuItems.length === 3 && ' lg:grid-cols-3',
										section.menuItems.length >= 4 && ' lg:grid-cols-4',
									)}
								>
									{section.menuItems.map((menuItem) => (
										<HeaderMenuItemCard
											key={menuItem.title}
											menuItem={menuItem}
										/>
									))}
								</div>
							</>
						)}
						{section.additionalMenuItems != null && (
							<>
								{section.additionalMenuItemSectionHeading != null &&
									(section.additionalMenuItemSectionHeadingUrl != null ? (
										<div className="container">
											<a href={section.additionalMenuItemSectionHeadingUrl}>
												<h3 className="mb-[12px] mt-0 !p-0 pt-[24px] text-xl font-semibold leading-7 text-gray-900 underline">
													{section.additionalMenuItemSectionHeading}
													<Icon
														icon="chevron-right"
														className="ml-[8px] inline h-[20px]"
													/>
												</h3>
											</a>
										</div>
									) : (
										<h3 className="mt-[24px] text-xl font-semibold leading-7 text-gray-900">{section.additionalMenuItemSectionHeading}</h3>
									))}
								<div
									className={cx(
										'container !grid grid-cols-2 gap-[8px] pb-[32px]',
										section.additionalMenuItems.length === 3 && ' lg:grid-cols-3',
										section.additionalMenuItems.length >= 4 && ' lg:grid-cols-4',
									)}
								>
									{section.additionalMenuItems.map((menuItem) => (
										<HeaderMenuItemCard
											key={menuItem.title}
											menuItem={menuItem}
										/>
									))}
								</div>
							</>
						)}
						{section.footerMenuItems != null && (
							<div className="bg-gray-50">
								<div className="container !grid grid-cols-2 gap-[8px] py-[8px] lg:flex lg:grid-cols-3">
									{section.footerMenuItems?.map((menuItem) => (
										<a
											key={menuItem.url}
											href={menuItem.url}
											className="flex rounded-md border-2 border-transparent p-[12px] text-base hover:bg-white hover:no-underline focus:border-blue-700 focus:bg-white focus:outline-none xl:w-[360px]"
										>
											{menuItem.icon != null && (
												<IconInBox
													colour={menuItem.icon === 'form' ? 'yellow' : 'navy'}
													icon={menuItem.icon === 'kb' ? 'support-article' : menuItem.icon}
												/>
											)}
											<div className="ml-4">
												<div className="font-medium text-gray-900">{menuItem.title}</div>
												<div className="pt-[4px] text-sm font-normal text-gray-600">{menuItem.description}</div>
											</div>
										</a>
									))}
								</div>
							</div>
						)}
					</Popover.Panel>
				</Popover>
			))}
		</Popover.Group>
	)
}

function LinkGrid({links, linkSectionHeading}: {links: {title: string; url?: string}[]; linkSectionHeading?: string}) {
	return (
		<div className={cx('ml-[8px] flex flex-col gap-y-[8px]', linkSectionHeading == null ? 'mt-[16px]' : 'mt-[4px]')}>
			{links.map((link, index) => (
				<Link
					key={index}
					link={link}
				/>
			))}
		</div>
	)
}

function SplitLinkGrid({links, linkSectionHeading}: {links: {title: string; url?: string}[]; linkSectionHeading?: string}) {
	const halfwayThrough = Math.ceil(links.length / 2)
	// or instead of floor you can use ceil depending on what side gets the extra data

	const linksFirstHalf = links.slice(0, halfwayThrough)
	const linksSecondHalf = links.slice(halfwayThrough, links.length)
	return (
		<div className="flex flex-col gap-x-[8px] lg:flex-row">
			<div className={cx('ml-[8px] flex flex-col gap-y-[8px]', linkSectionHeading == null ? 'mt-[16px]' : 'mt-[4px]')}>
				{linksFirstHalf.map((link, index) => (
					<Link
						key={index}
						link={link}
					/>
				))}
			</div>
			<div className={cx('ml-[8px] flex flex-col gap-y-[8px]', linkSectionHeading == null ? 'mt-[16px]' : 'mt-[4px]')}>
				{linksSecondHalf.map((link, index) => (
					<Link
						key={index}
						link={link}
					/>
				))}
			</div>
		</div>
	)
}

export function Link(props: {link: {title: string; url?: string}}) {
	const target = isUrlSameHost(props.link.url)? '' : '_blank'
	return (
		<a
			href={props.link.url}
			className="text-sm font-medium text-gray-600 underline"
			target={target}
		>
			{props.link.url == null || isUrlSameHost(props.link.url) ? (
				props.link.title
			) : (
				<IconAttachedToLastWord
					text={props.link.title}
					icon="external-link"
					className="mb-[2px] ml-[8px] inline h-[16px]"
				/>
			)}
		</a>
	)
}
