import React, {useEffect, useRef, useState} from 'react'
import {useWindowSize} from 'usehooks-ts'
import {Select} from '~/components/Select'
import {queryDataSet, queryHref, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx, notEmpty, removeUrlParam, setUrlParam} from '~/utils/utils'
import './FilteredListing.css'

interface FilteredListingProps {
	description?: string
	colour: 'blue' | 'teal'
	sections: {
		id?: string
		selectLabel?: string
		title?: string
		description?: string
		items: {
			url?: string
			heading?: string
			body?: string
			tag?: string
		}[]
	}[]
	tags: {
		tag?: string
		label?: string
	}[]
}

export function initFilteredListing() {
	document.querySelectorAll<HTMLElement>('.filtered-listing-wrapper').forEach((element) => {
		element.classList.add('section-spacing')

		const props: FilteredListingProps = {
			description: queryTextContent('.filtered-listing-description', element),
			colour: element.classList.contains('teal') ? 'teal' : 'blue',
			sections: Array.from(element.querySelectorAll<HTMLOptionElement>('option'))
				.map((sectionOption) => {
					const sectionElement = document.querySelector<HTMLElement>(`.filtered-listing-section[data-section="${sectionOption.value}"]`)
					if (sectionElement == null) return null
					const section = {
						id: sectionOption.value,
						selectLabel: sectionOption.label,
						title: queryTextContent('.aside h2, .aside h3, .aside h4, .aside h5', sectionElement),
						description: queryTextContent('.aside p', sectionElement),
						items: Array.from(sectionElement.querySelectorAll<HTMLElement>('.filtered-listing-grid li.filtered-listing-item')).map((item) => {
							const heading = queryTextContent('strong', item)?.trim() ?? ''
							return {
								url: queryHref('a', item),
								heading: heading,
								body: queryTextContent('a', item)?.trim().replace(heading, '').trim(),
								tag: item.dataset.tag,
							}
						}),
					}
					sectionElement.remove()
					return section
				})
				.filter(notEmpty),
			tags: Array.from(element.querySelectorAll<HTMLInputElement>('.filtered-listing-tag'))
				.map((tagListing) => ({
					tag: queryDataSet('input[type="checkbox"]', tagListing)?.tag ?? undefined,
					label: tagListing.textContent?.trim() ?? undefined,
				}))
				.filter((tag) => tag.tag?.toLowerCase() !== 'all'),
		}

		render(<FilteredListing {...props} />, element)
	})
}

function FilteredListing({colour, tags, description, sections}: FilteredListingProps) {
	const urlParams = new URLSearchParams(window.location.search)

	const tagsParam = urlParams.get('tags')
	const [selectedTags, setSelectedTags] = useState<string[]>(tagsParam?.split('|') ?? [])

	const sectionParam = urlParams.get('section')
	const [selectedSection, setSelectedSection] = useState<string | null>(sectionParam != null && sectionParam?.length > 0 ? sectionParam : null)

	// Height transition -- this is needed because transition doesn't work with height auto
	const sectionsContainerRef = useRef<HTMLDivElement>(null)
	const [height, setHeight] = useState(0)

	useEffect(() => {
		setHeight(sectionsContainerRef?.current?.clientHeight ?? 0)
	}, [selectedSection, selectedTags, useWindowSize()])

	const borderColours = [
		'border-green-500',
		'border-teal-500',
		'border-yellow-500',
		'border-red-500',
		'border-uom-navy-700',
		'border-purple-500',
		'border-lime-500',
		'border-indigo-500',
		'border-violet-500',
	]
	const noTags = tags.length <= 1

	useEffect(() => {
		if (selectedTags.length <= 0) {
			removeUrlParam('tags')
		} else {
			setUrlParam('tags', selectedTags.join('|'))
		}
	}, [selectedTags])

	function handleChangeTag(tag: string | undefined) {
		if (tag == null) return

		if (selectedTags.includes(tag)) {
			setSelectedTags(selectedTags.filter((id) => id !== tag))
		} else {
			setSelectedTags([...selectedTags, tag])
		}
	}

	function handleChangeSection(section: string | null) {
		if (section == null || section?.length <= 0) {
			removeUrlParam('section')
			setSelectedSection(null)
		} else {
			setUrlParam('section', section)
			setSelectedSection(section)
		}
	}

	return (
		<>
			<div className={cx('full-width-background banner-background banner', colour)}>
				<div className="relative py-[48px]">
					{description != null && <p className="max-w-content py-0 pb-[48px] md:pb-[64px]">{description}</p>}
					<div className="flex flex-col gap-x-6 gap-y-[48px] md:flex-row">
						{tags.length > 0 && (
							<fieldset className="">
								<legend className="block pb-[16px]">FILTER BY TYPE</legend>
								<div className="flex flex-wrap gap-[24px]">
									<div className="flex items-center">
										<input
											className={cx(
												colour === 'teal'
													? 'checked:bg-teal-500 hover:bg-teal-500 focus:bg-teal-500  checked:focus:bg-teal-500'
													: 'checked:bg-uom-navy-700 hover:bg-uom-navy-700 focus:bg-uom-navy-700  checked:focus:bg-uom-navy-700',
												'tag-checkbox mr-[8px] rounded-[3px] border-2 border-transparent checked:hover:border-gray-200 checked:focus:border-gray-200',
											)}
											id={'tag-all'}
											checked={selectedTags.length <= 0}
											onChange={() => setSelectedTags([])}
											type="checkbox"
										/>
										<label htmlFor={'tag-all'}>All</label>
									</div>
									{tags.map((tag) => (
										<div
											key={tag.tag}
											className="flex items-center"
										>
											<input
												className={cx(
													colour === 'teal'
														? 'checked:bg-teal-500 hover:bg-teal-500 focus:bg-teal-500  checked:focus:bg-teal-500'
														: 'checked:bg-uom-navy-700 hover:bg-uom-navy-700 focus:bg-uom-navy-700  checked:focus:bg-uom-navy-700',
													'tag-checkbox mr-[8px] rounded-[3px] border-2 border-transparent checked:hover:border-gray-200 checked:focus:border-gray-200',
												)}
												id={`tag-${tag.tag}`}
												value={tag.tag}
												checked={selectedTags.includes(tag.tag ?? '')}
												onChange={() => handleChangeTag(tag.tag)}
												type="checkbox"
											/>
											<label htmlFor={`tag-${tag.tag}`}>{tag.label}</label>
										</div>
									))}
								</div>
							</fieldset>
						)}
						{sections.length > 1 && (
							<div className="w-full max-w-[384px] text-gray-600">
								<label
									htmlFor="location"
									className="block pb-[16px] text-white"
								>
									FILTER BY SECTION
								</label>
								<Select
									options={sections.map((section) => section?.selectLabel ?? '')}
									currentIndex={sections.findIndex((section) => section.id === selectedSection)}
									onChangeIndex={(index) => {
										handleChangeSection(index != null ? sections[index]?.id ?? null : null)
									}}
									blankOption="All Section"
									placeholder="All Section"
									colour={colour}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			<div
				className="overflow-y-hidden transition-height duration-300 ease-out"
				style={{height: height > 0 ? height + 'px' : 'auto'}}
			>
				<div
					ref={sectionsContainerRef}
					className=""
				>
					{sections.map((section) => {
						const show =
							(selectedTags.length <= 0 || section.items.some((item) => selectedTags.includes(item?.tag ?? ''))) && // If filtering by tag the selection must have an item with a selected tag
							(selectedSection == null || section.id === selectedSection)

						return (
							<div
								key={section.id}
								className={cx(show ? '' : 'hidden', 'grid gap-[24px] !pt-[24px] pb-6 md:grid-cols-3 md:!pt-[83px] lg:grid-cols-3')}
							>
								<div>
									<h4 className="!pb-[16px] !text-2xl !font-semibold !leading-8">{section.title}</h4>
									<p className="!p-0 text-base font-normal leading-6">{section.description}</p>
								</div>
								<div className="grid gap-[4px] md:col-span-2 md:grid-cols-2 lg:grid-cols-4">
									{section.items.map((item, index) => {
										const show = noTags || selectedTags.length <= 0 || selectedTags.includes(item?.tag ?? '')
										const borderColourClass = noTags ? borderColours[0] : borderColours[tags.findIndex((tag) => tag.tag === item.tag)]

										return (
											<div
												key={index}
												className={cx(show ? '' : 'hidden', 'rounded-[2px] border-2 border-transparent focus-within:border-blue-700 focus:outline-none')}
											>
												<a
													href={item.url}
													className={cx(
														borderColourClass,
														'group group flex h-full w-full flex-col gap-y-[8px] rounded-[2px] border-l-[4px] bg-white p-6 text-sm leading-5 shadow hover:no-underline hover:shadow-lg',
													)}
												>
													<div className="font-semibold text-gray-900">{item.heading}</div>
													<div className="font-medium text-blue-700 group-hover:underline">{item.body}</div>
												</a>
											</div>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</>
	)
}
