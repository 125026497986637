import React from 'react'
import {IconInBox} from '~/images/IconInBox'

export function LinkIconInBox() {
	return (
		<IconInBox
			colour="green"
			icon="link"
		/>
	)
}
