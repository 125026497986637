import {Popover, Transition} from '@headlessui/react'
import React from 'react'
import {Icon} from '~/blocks/Icon'
import {breadcrumbList, cx} from '~/utils/utils'
import './BreadCrumbs.css'

export default function BreadCrumbs() {
	const navyBackground = document.body.classList.contains('navy-breadcrumbs')

	if (breadcrumbList.length < 2) {
		return null
	}
	return (
		<nav
			className="breadcrumbs"
			aria-label="BreadCrumbs"
		>
			{/* Desktop */}
			<div className="container relative z-[899] hidden py-[32px] md:flex">
				<ul className="no-list block-uplift padding-0 flex items-center space-x-4 text-sm font-medium leading-5">
					<li>
						<div>
							<a
								href={(window as any).siteRoot ?? '/'}
								className={cx(navyBackground ? 'text-white hover:text-gray-200' : 'text-gray-600 hover:text-gray-400', '!no-underline')}
							>
								<Icon
									icon="home"
									className="h-5 w-5 flex-shrink-0"
									aria-hidden="true"
								/>
								<span className="sr-only">Home</span>
							</a>
						</div>
					</li>
					{breadcrumbList.slice(1).map((page) => (
						<li key={page.name}>
							<div className="flex items-center">
								<svg
									className="h-5 w-5 flex-shrink-0 text-gray-300"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20"
									aria-hidden="true"
								>
									<path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
								</svg>
								<a
									href={page.href}
									className={cx(
										navyBackground ? 'text-white hover:text-gray-200' : page.current ? 'text-gray-900' : 'text-gray-600',
										'ml-4 text-sm font-medium hover:text-gray-400 hover:no-underline',
									)}
									aria-current={page.current ? 'page' : undefined}
								>
									{page.name}
								</a>
							</div>
						</li>
					))}
				</ul>
			</div>
			{/* Mobile */}
			<div className="md:hidden">
				<Popover>
					{({open}) => (
						<div>
							<Popover.Button
								className="w-full py-[20px] text-sm font-medium leading-5 hover:text-gray-400 focus:bg-gray-100 focus:outline-none"
								aria-current="page"
							>
								<div className="container text-left">
									{breadcrumbList[breadcrumbList.length - 1].name}
									<Icon
										icon="chevron-down"
										className="mb-[4px] ml-[10px] inline h-5 w-5"
									/>
								</div>
							</Popover.Button>
							<Transition
								show={open}
								className="relative"
								enter="transition-opacity duration-75"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="transition-opacity duration-150"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Popover.Panel
									static
									as="div"
									className="absolute z-[101] w-full bg-white shadow-md"
								>
									{breadcrumbList
										.slice(1)
										.reverse()
										.slice(1)
										.map((page) => (
											<a
												key={page.href}
												href={page.href}
												className="block py-[24px] text-sm font-medium leading-5 hover:text-gray-400 focus:bg-gray-100 focus:outline-none"
											>
												<span className="px-4 text-left">{page.name}</span>
											</a>
										))}
									<a
										href="/"
										className="block py-[24px] text-sm font-medium leading-5 hover:text-gray-400 focus:bg-gray-100 focus:outline-none"
									>
										<span className="px-4 text-left">Home</span>
									</a>
								</Popover.Panel>
							</Transition>
						</div>
					)}
				</Popover>
			</div>
		</nav>
	)
}
