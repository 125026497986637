import React from 'react'
import {cx} from '~/utils/utils'
import {labels, sections, useSelfGuidanceToolStore} from './useSelfGuidanceToolStore'

interface Props {
	onChangeSectionByFooterButton: () => void
}

export function Footer({onChangeSectionByFooterButton}: Props) {
	const {currentSectionIndex, isSectionComplete, setCurrentSectionIndex, setShowResults} = useSelfGuidanceToolStore()
	const isLastSection = currentSectionIndex === sections.length - 1

	return (
		<div className="section-menu fixed inset-x-0 bottom-0 z-[999999] h-[128px] bg-white md:h-[68px]">
			<div className="container h-[128px] md:h-[68px]">
				{/* Desktop */}
				<div className="flex h-full flex-col-reverse justify-between py-[24px] md:flex-row md:py-0 lg:ml-[365px]">
					<div className="flex items-center justify-between md:justify-items-start md:gap-x-[49px]">
						<div className="text-sm font-medium leading-4 text-gray-900">
							<span className="hidden md:inline">{labels.footerSectionLabel} </span>
							{`${currentSectionIndex + 1} of ${sections.length}`}
						</div>
						<ol
							role="list"
							className="no-list m-0 flex items-center space-x-5 p-0"
						>
							{sections.map((step, index) => (
								<li
									key={index}
									className="m-0 !p-0"
								>
									{index === currentSectionIndex ? (
										<div
											className="relative flex items-center justify-center"
											aria-current="step"
										>
											<span
												className="absolute flex h-5 w-5 p-px"
												aria-hidden="true"
											>
												<span className="h-full w-full rounded-full bg-blue-200" />
											</span>
											<span
												className="relative block h-2.5 w-2.5 rounded-full bg-blue-700"
												aria-hidden="true"
											/>
											<span className="sr-only">{sections[index].title}</span>
										</div>
									) : isSectionComplete(index) ? (
										<div className="block h-2.5 w-2.5 rounded-full bg-blue-600 hover:bg-blue-700">
											<span className="sr-only">{sections[index].title}</span>
										</div>
									) : (
										<div className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
											<span className="sr-only">{sections[index].title}</span>
										</div>
									)}
								</li>
							))}
						</ol>
					</div>
					<div className="flex items-center justify-between gap-x-[16px] md:justify-items-start">
						<button
							disabled={currentSectionIndex === 0}
							className={cx(currentSectionIndex === 0 ? 'boarder-gray-300 text-gray-300' : 'border-gray-500 text-gray-600', 'rounded-lg border px-[13px] py-[9px] text-sm font-medium leading-5 ')}
							onClick={() => {
								setCurrentSectionIndex(currentSectionIndex - 1)
								onChangeSectionByFooterButton()
							}}
						>
							{labels.footerBackButtonLabel}
						</button>

						<button
							disabled={!isSectionComplete(currentSectionIndex)}
							className={cx(
								!isSectionComplete(currentSectionIndex)
									? 'border-gray-300 bg-white text-gray-300'
									: isLastSection
									? 'tag-results boarder-transparent bg-green-700 text-white'
									: 'boarder-transparent bg-uom-navy-900 text-white',
								'tag-next-section-button rounded-lg border px-[13px] py-[9px] text-sm font-medium leading-5',
							)}
							onClick={() => {
								if (!isLastSection) {
									setCurrentSectionIndex(currentSectionIndex + 1)
								} else {
									setShowResults(true)
								}
								onChangeSectionByFooterButton()
							}}
						>
							{isLastSection ? labels.footerViewResultsButtonLabel : labels.footerNextButtonLabel}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
