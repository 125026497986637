import {useFetch} from 'usehooks-ts'
import type {CardProps} from '~/cards/Card'
import {highlight} from '~/utils/utils'

interface ApiResult {
	title: string
	docNum: number
	summary: string
	url: string
	clickTrackingUrl: string
	displayUrl: string
	collection: string
	gscopesSet: string[]
	metaData: {
		personId: string
		position: string
		departmentName: string
		email: string
		articleType: 'Support article ' | 'Form '
		scShortDescription: string
		scContent: string
		kbType: string
		faqQuestion: string
		howToIntroduction: string
		kcsArticleIssue: string
		knownErrorDescription: string
		whatIsIntroduction: string
		imageUrl: string	
	}
}

interface ApiFeaturedResult {
	titleHtml: string
	displayUrl: string
	linkUrl: string
	descriptionHtml: string
}

interface HookReturnValue {
	isLoading: boolean
	results: CardProps[]
	featured?: CardProps[]
	totalMatching?: number
	enabledArea?: string
	suggestedQueries: string[]
}

export interface SearchResultType {
	displayName: string
	shortDisplayName: string
	apiName: string
	apiLongName: string
	color?: string
	bgColour: string
	hoverBgColour: string
	textColour: string
	area: string
}

export const searchResultTypes: SearchResultType[] = [
	{
		displayName: 'Staff Hub pages',
		shortDisplayName: 'Staff Hub pages',
		apiName: 'general',
		apiLongName: 'General information',
		bgColour: '#daeef3',
		hoverBgColour: '#fbcfe8',
		textColour: '#19444f',
		area: 'areaSection',
	},	
	{
		displayName: 'People and contacts',
		shortDisplayName: 'People',
		apiName: 'profile',
		apiLongName: 'Profile',
		color: 'Pink',
		bgColour: '#FEE2E2',
		hoverBgColour: '#FEF2F2',
		textColour: '#7F1D1D',
		area: 'areaSection',
	},
	{
		displayName: 'Support articles',
		shortDisplayName: 'Support articles',
		apiName: 'supportArticle',
		apiLongName: 'Support article',
		bgColour: '#c2e5ff',
		hoverBgColour: '#99d5ff',
		textColour: '#094183',
		area: 'areaSection',
	},
	{
		displayName: 'Forms',
		shortDisplayName: 'Forms',
		apiName: 'form',
		apiLongName: 'Form',
		color: 'Yellow',
		bgColour: '#FEF3C7',
		hoverBgColour: '#FFFBEB',
		textColour: '#78350F',
		area: 'areaSection',
	},
	{
		displayName: 'Research Gateway pages',
		shortDisplayName: 'Research Gateway',
		apiName: 'general',
		apiLongName: 'General information',
		bgColour: '#E4F2EA',
		hoverBgColour: '#F5FAF7',
		textColour: '#142A1D',
		area: 'areaRG',
	},	
	{
		displayName: 'Faculty intranets - all',
		shortDisplayName: 'All',
		apiName: 'all',
		apiLongName: '',
		bgColour: '#fff',
		hoverBgColour: '#ddd',
		textColour: '#333',
		area: 'areaFaculty',
	},	
	{
		displayName: 'Architecture, Building and Planning',
		shortDisplayName: 'ABP intranet',
		apiName: 'abp',
		apiLongName: '',
		bgColour: '#eee',
		hoverBgColour: '#ddd',
		textColour: '#333',
		area: 'areaFaculty',
	},
	{
		displayName: 'Arts',
		shortDisplayName: 'Arts intranet',
		apiName: 'arts',
		apiLongName: '',
		bgColour: '#eee',
		hoverBgColour: '#ddd',
		textColour: '#333',
		area: 'areaFaculty',
	},
	{
		displayName: 'Business and Economics',
		shortDisplayName: 'FBE intranet',
		apiName: 'fbe',
		apiLongName: '',
		bgColour: '#eee',
		hoverBgColour: '#ddd',
		textColour: '#333',
		area: 'areaFaculty',
	},
	{
		displayName: 'Engineering and IT',
		shortDisplayName: 'FEIT intranet',
		apiName: 'engineering',
		apiLongName: '',
		bgColour: '#eee',
		hoverBgColour: '#ddd',
		textColour: '#333',
		area: 'areaFaculty',
	},
	{
		displayName: 'Fine Arts and Music',
		shortDisplayName: 'FFAM intranet',
		apiName: 'FUNcc8rccs2irwctcn9n0pkakopj',
		apiLongName: '',
		bgColour: '#eee',
		hoverBgColour: '#ddd',
		textColour: '#333',
		area: 'areaFaculty',
	},
	{
		displayName: 'Law',
		shortDisplayName: 'MLS intranet',
		apiName: 'mis',
		apiLongName: '',
		bgColour: '#eee',
		hoverBgColour: '#ddd',
		textColour: '#333',
		area: 'areaFaculty',
	},
	{
		displayName: 'Medicine, Dentistry and Health Sciences',
		shortDisplayName: 'MDHS intranet',
		apiName: 'mdhs',
		apiLongName: '',
		bgColour: '#eee',
		hoverBgColour: '#ddd',
		textColour: '#333',
		area: 'areaFaculty',
	},
	{
		displayName: 'Science',
		shortDisplayName: 'Science intranet',
		apiName: 'science',
		apiLongName: '',
		bgColour: '#eee',
		hoverBgColour: '#ddd',
		textColour: '#333',
		area: 'areaFaculty',
	},
]


const baseUrl = 'https://uom-search.squiz.cloud/s/search.json'
//#3483646 
///search/_webservices/search.json?collection=staff&MBL=3000&gscope1=${faculty != null ? faculty.apiName + ',local!|' : 'local!'}
//&start_rank=${startAt}&num_ranks=15&query=${query.length > 0 ? encodeURIComponent(query) : '!padrenull'}&f.Type|${type?.apiName}=${type?.apiLongName}
export function useSearch(query: string, startAt = 1, types: SearchResultType[]): HookReturnValue {
	//`${baseUrl}?collection=staff-hub-research-gateway&start_rank=${startAt}&num_ranks=15&query=${query.length > 0 ? query : '!padrenull'}&f.Type|${types[0]?.apiName}=${types[0]?.apiLongName}`,
	const searchParams = new URLSearchParams({
		collection: 'staff',
		start_rank: startAt.toString(),
		num_ranks: '15',
		query: query.length > 0 ? query : '!padrenull',
		profile: '_default_preview',
	})

	let noAreaTypes = 0
	const aAreaTypes = []
	let enabledArea = 'allAreas'
	let excludeRGResults = false
	let excludeRGResultsTotal = 0
	let featuredResultsTotal = 0

	for (const type of types) {
		if ((type.area == 'areaFaculty') && (type.shortDisplayName != 'All')) {
			noAreaTypes++
			if (noAreaTypes < 2) {
				searchParams.append('MBL','3000')
			} 
			aAreaTypes.push(type.apiName);
			enabledArea = 'areaFaculty'
		} else if ((type.area == 'areaFaculty') && (type.shortDisplayName == 'All')) {
			searchParams.append('MBL','3000')
			searchParams.append('gscope1', 'local')
			enabledArea = 'areaFaculty'
		} else if (type.area == 'areaRG') {
			searchParams.set('collection', 'staff-hub-research-gateway')
			searchParams.append('f.Type|' + type.apiName, type.apiLongName)
			enabledArea = 'areaRG'
		} else if (type.area == 'areaSection') {
			searchParams.append('gscope1', 'local!')
			searchParams.append('f.Type|' + type.apiName, type.apiLongName)
			enabledArea = 'areaSection'
			if (type.apiName == 'general') {
				excludeRGResults = true
			}
		}
	}

	if (aAreaTypes.length > 0) {
		let strPipes = ""
		let strAreaTypes = ""
		let strAreaTypesPipes = ""
		for (let i = 1; i < aAreaTypes.length; i++) {
			strPipes = strPipes + "|"
		  }
		strAreaTypes = aAreaTypes.toString()
		strAreaTypesPipes = strAreaTypes + strPipes
		searchParams.append('gscope1', strAreaTypesPipes)
	}

	const {data, error} = useFetch<any>(`${baseUrl}?${searchParams.toString()}`)
	
	/*const {data, error} = useFetch<any>(
		`/search/_webservices/search.json?collection=staff&MBL=3000&gscope1=${faculty != null ? faculty.apiName + ',local!|' : 'local!'}&start_rank=${startAt}&num_ranks=15&query=${
			query.length > 0 ? encodeURIComponent(query) : '!padrenull'
		}&f.Type|${type?.apiName}=${type?.apiLongName}`,
	)*/

	return {
		isLoading: data == null && error == null && query.length > 0,
		results:
			(data?.response?.resultPacket?.results as ApiResult[])?.map((result) => {
				if (result.metaData?.personId?.length > 0) {
					const staffProfile: CardProps = {
						type: 'profile',
						url: result.clickTrackingUrl,
						title: result.title,
						position: result.metaData.position,
						departmentName: result.metaData.departmentName,
						email: result.metaData.email,
						personId: result.metaData.personId,
						photoUrl: result.metaData.imageUrl,
					}
					return staffProfile
				}

				const cardData: CardProps = {
					url: result.clickTrackingUrl,
					displayUrl: result.displayUrl,
					type: 'general',
					title: result.title,
					body: highlight(result.summary, query),
					linkLabel: '',
				}
				if (result.collection.includes('service-now-kba')) {
					cardData.type = 'supportArticle'
					if (result.metaData.articleType == 'Support article '){  //old servicenow kba's had: result.metaData.articleType
						if (typeof result.metaData.scContent != 'undefined') {
							cardData.body = result.metaData.scContent.replace(/<\/?[^>]+(>|$)/g, '') // remove html tags 
						} 
						else {
							cardData.body = ''
						}
					} else {
						if (result.metaData.kbType != 'undefined') {
							switch(result.metaData.kbType) {
								case 'FAQ':
									cardData.body = result.metaData.faqQuestion.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								case 'How To':
									cardData.body = result.metaData.howToIntroduction.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								case 'KCS Article':
									cardData.body = result.metaData.kcsArticleIssue.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								case 'Knowledge':
									cardData.body = result.metaData.scContent.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								case 'Known Error article':
									cardData.body = result.metaData.knownErrorDescription.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								case 'What Is':
									cardData.body = result.metaData.whatIsIntroduction.replace(/<\/?[^>]+(>|$)/g, '');
									break;
								default:
									cardData.body = '';
									break;
							}
						} 
					}
				}
				if (result.collection.includes('service-now-forms')) {
					cardData.type = 'form'
					cardData.body = result.metaData.scShortDescription
				}
				if (((result.collection == 'gateway-research-prod') && (excludeRGResults == true)) ) {
					cardData.title = 'excludeRGResults'
					excludeRGResultsTotal++
				}
				return cardData
				

			}) ?? [],
		featured:
			(data?.response?.curator?.exhibits as ApiFeaturedResult[])?.map((result) => ({
				url: result.linkUrl,
				type: 'feature',
				title: result.titleHtml,
				body: highlight(result.descriptionHtml, query),
				linkLabel: 'View',
				featuredResultsTotal:featuredResultsTotal++,
			})) ?? [],
		totalMatching: data?.response?.resultPacket?.resultsSummary?.totalMatching + featuredResultsTotal - excludeRGResultsTotal,
		enabledArea: enabledArea,
		suggestedQueries: (data?.response?.resultPacket?.contextualNavigation?.categories?.find((category: any) => category.name === 'topic')?.clusters?.map((cluster: any) => cluster.query) ?? [])
			.concat([data?.response?.resultPacket?.spell?.text ?? null])
			.filter((item: string | null) => item != null && item.length > 0),
	}
}
