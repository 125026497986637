import React from 'react'
import {IconInBox} from '~/images/IconInBox'

export function SupportArticleIconInBox() {
	return (
		<IconInBox
			colour="blue"
			icon="support-article"
		/>
	)
}
