import React, {useEffect, useRef} from 'react'
import {useHideFooter} from '~/global/footer/useHideFooter'
import {render} from '~/utils/render'
import {cx} from '~/utils/utils'
import {Footer} from './Footer'
import {Questions} from './Questions'
import {Results} from './Results'
import {SectionsVerticalMenu} from './SectionsVerticalMenu'
import './SelfGuidanceToolPage.css'
import {labels, sections, useSelfGuidanceToolStore} from './useSelfGuidanceToolStore'

export interface SelfGuidanceToolPageProps {
	privacySectionBody?: string
}

export const initSelfGuidanceToolPage = () => {
	document.querySelectorAll('#self-guidance-tool').forEach((element) => {
		const props: SelfGuidanceToolPageProps = {
			privacySectionBody: element.querySelector('.privacy-section-body')?.innerHTML,
		}

		render(<SelfGuidanceToolPage {...props} />, element)
	})
}
export default function SelfGuidanceToolPage(props: SelfGuidanceToolPageProps) {
	const {showResults, completed} = useSelfGuidanceToolStore()
	const {hideFooter, showFooter} = useHideFooter()
	const headingRef = useRef<HTMLHeadingElement>(null)
	const {currentSectionIndex} = useSelfGuidanceToolStore()
	const currentSection = sections[currentSectionIndex]

	useEffect(() => {
		if (showResults) {
			showFooter()
			document.querySelector('#self-guidance-tool-banner')?.classList.add('hidden')
		} else {
			hideFooter()
			document.querySelector('#self-guidance-tool-banner')?.classList.remove('hidden')
		}
	}, [showResults])

	return (
		<div className={cx(completed && 'tag-self-guidance-tool-completed')}>
			{showResults ? (
				<Results />
			) : (
				<div className="">
					<div className="full-width-background before:bg-gray-50">
						<div className="relative">
							<h3 className="!mt-[0px] !pb-[0] pt-[24px] text-2xl font-semibold leading-8 text-gray-900">{labels.privacySectionHeading}</h3>
							<p
								className="pb-[24px]"
								dangerouslySetInnerHTML={{__html: props.privacySectionBody ?? ''}}
							/>
						</div>
					</div>
					<div className="flex pt-[24px] lg:pt-[68px]">
						<SectionsVerticalMenu />
						<div className="w-full flex-grow">
							<h2
								ref={headingRef}
								className="!mt-0 !p-0"
							>
								{currentSection.title}
							</h2>
							<p className="!max-w-full pb-[24px] pt-[16px] text-base font-normal leading-6 text-gray-600 lg:pb-[48px] lg:pt-[8px]">{currentSection.description}</p>
							<Questions />
							<Footer onChangeSectionByFooterButton={() => headingRef.current?.scrollIntoView()} />
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
