import React, {PropsWithChildren} from 'react'
import {ConditionalBackgroundColour} from '~/components/ConditionalBackgroundColour'
import {queryTextContent} from '~/utils/query'
import {cx} from '~/utils/utils'

export interface SectionProps {
	isInSection?: boolean
	heading?: string
	headingUrl?: string
	subheading?: string
	boarderUnderHeading?: boolean
	backgroundColour?: string
	verticalSpacing?: VerticalSpacing
	addHeadingId?: boolean

	// Customisations
	dontShowHeading?: boolean
}

type VerticalSpacing = 'none' | 'small' | 'default' | 'large'

export const getSectionProps = (element: HTMLElement): SectionProps => {
	return {
		isInSection: element.dataset.isInSection !== 'false',
		heading: queryTextContent(':scope > .heading, :scope > a > .heading', element),
		subheading: element.querySelector(':scope > .subheading')?.innerHTML,
		boarderUnderHeading: element.dataset.underlineHeading == 'true',
		backgroundColour: element.dataset.backgroundColour ?? 'white',
		verticalSpacing: (element.dataset.verticalSpacing as VerticalSpacing) ?? 'default',
		addHeadingId: element.dataset.addHeadingId === 'true',
	}
}

export const SectionWrapper = (props: PropsWithChildren<SectionProps>) => {
	return (
		<ConditionalBackgroundColour
			backgroundColour={props.backgroundColour}
			classNames="doesnt-need-gap-without-padding"
		>
			<div className={cx('section-wrapper ', props.verticalSpacing === 'small' ? 'py-[16px]' : props.heading === '' ? 'py-[24]' : props.verticalSpacing === 'default' ? 'py-[48px]' : props.verticalSpacing === 'large' && 'py-[52px]')}>
				{((props.heading?.length && !props.dontShowHeading) || props.subheading?.length) && (
					<div className={cx(props.subheading != null ? 'pb-[24px]' : 'pb-[32px]', props.boarderUnderHeading && 'mb-[32px] border-b-2 border-gray-200 pb-[24px]')}>
						{(props.heading !== '') && (props.heading?.length && !props.dontShowHeading) && (
								<h2
									id={props.addHeadingId ? props.heading?.replace(/\s+/g, '-').replace(/,/g, '_').toLowerCase() : ''}
									className={cx(
										'!mt-0  max-w-content !pb-0 !text-2xl !font-semibold leading-8',
										props.backgroundColour !== 'white' && props.backgroundColour !== 'gray' ? '!text-white' : 'text-gray-900',
									)}
								>
									{props.heading}
								</h2>
							)
						}
						{props.subheading && (
							<div
								className={cx(
									'description mt-[16px] max-w-content text-lg font-normal leading-6',
									props.backgroundColour !== 'white' && props.backgroundColour !== 'gray' ? '!text-white' : 'text-gray-600',
								)}
								dangerouslySetInnerHTML={{__html: props.subheading}}
							/>
						)}
					</div>
				)}
				{props.children}
			</div>
		</ConditionalBackgroundColour>
	)
}
