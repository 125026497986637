import {getBackgroundImage} from './utils'

export function queryTextContent(query: string, element?: Element) {
	const queryWithIn = element ?? document
	return ifNullOrEmptyStringUndefined(queryWithIn.querySelector(query)?.textContent)
}

export function queryHref(query: string, element?: Element) {
	const queryWithIn = element ?? document
	return ifNullOrEmptyStringUndefined(queryWithIn.querySelector<HTMLAnchorElement>(query)?.getAttribute('href'))
}

export function queryTarget(query: string, element?: Element) {
	const queryWithIn = element ?? document
	return ifNullOrEmptyStringUndefined(queryWithIn.querySelector<HTMLAnchorElement>(query)?.getAttribute('target'))
}

export function querySrc(query: string, element?: Element) {
	const queryWithIn = element ?? document
	return ifNullOrEmptyStringUndefined(queryWithIn.querySelector<HTMLImageElement>(query)?.src)
}

export function queryAlt(query: string, element?: Element) {
	const queryWithIn = element ?? document
	return ifNullOrEmptyStringUndefined(queryWithIn.querySelector<HTMLImageElement>(query)?.alt)
}

export function queryImgAltText(query: string, element?: Element) {
	const queryWithIn = element ?? document
	return ifNullOrEmptyStringUndefined(queryWithIn.querySelector<HTMLImageElement>(query)?.alt)
}

export function queryDataSet(query: string, element?: Element) {
	const queryWithIn = element ?? document
	return queryWithIn.querySelector<HTMLElement>(query)?.dataset
}

export function queryBackgroundImage(query: string, element?: Element) {
	const queryWithIn = element ?? document
	return getBackgroundImage(queryWithIn.querySelector<HTMLElement>(query))
}

export function queryAllArray(query: string, element?: Element): Element[] {
	const queryWithIn = element ?? document
	return Array.from(queryWithIn.querySelectorAll(query))
}

function ifNullOrEmptyStringUndefined(input: string | null | undefined) {
	return input != undefined && input.length > 0 ? input.trim() : undefined
}
