import React, {useRef} from 'react'
import {Icon} from '~/blocks/Icon'
import {useLineCount} from '~/pages/search/useLineCount'
import {autoLink, cx} from '~/utils/utils'
import type {CardProps} from './Card'
import {CardWrapper} from './CardWrapper'

const defaultLinkLabel = 'Read'

export function GeneralCard(props: CardProps) {
	const titleRef = useRef<HTMLDivElement>(null)
	const headerLineCount = useLineCount(titleRef, 25)

	return (
		<CardWrapper
			{...props}
			className={cx(
				props.lineClamp ? 'h-full max-h-[316px]' : '--mb-6',
				'group flex flex-col rounded-lg border-2 border-transparent bg-white p-6 text-left shadow hover:no-underline hover:shadow-lg  focus:border-blue-700 focus:outline-none',
				props.className,
			)}
		>
			<h3
				ref={titleRef}
				className="block-uplift text-lg font-semibold leading-6 text-gray-900"
			>
				{props.title}
			</h3>
			{props.body != null && (
				<div
					className={cx(
						props.lineClamp ? `flex-grow overflow-ellipsis line-clamp-${6 - headerLineCount}` : 'flex-shrink',
						props.horizontalLayout && 'w-[300px]',
						'break-words pt-2 text-base font-normal leading-6 text-gray-600',
					)}
					dangerouslySetInnerHTML={{
						__html: props.autoLinkBody ? autoLink(props.body) : props.body,
					}}
				/>
			)}
			<div className="mt-auto pt-6 text-sm font-medium leading-5 text-blue-700 hover:underline">
				{props.linkLabel ? props.linkLabel : defaultLinkLabel}{' '}
				<Icon
					icon="arrow-forward"
					className="mb-[4px] inline h-4 w-4"
				/>
			</div>
		</CardWrapper>
	)
}
