import {Listbox, Transition} from '@headlessui/react'
import React, {Fragment} from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'

interface SelectProps {
	options: string[]
	currentIndex?: number
	onChangeIndex: (index: number | undefined) => void
	disabledIndexes?: number[]
	placeholder?: string
	blankOption?: string
	colour?: 'blue' | 'teal'
	className?: string
	error?: boolean
	disabled?: boolean
}

export function Select({options, currentIndex, onChangeIndex, placeholder, blankOption, colour, className, error, disabled, disabledIndexes}: SelectProps) {
	return (
		<Listbox
			value={currentIndex ?? -1}
			onChange={(index) => {
				onChangeIndex(index)
			}}
			disabled={disabled}
		>
			{({open}) => (
				<div className={cx('group relative', className)}>
					<Listbox.Button
						className={cx(
							colour == null && !error ? 'focus:outline-blue border-gray-500 bg-white text-gray-500' : open ? 'border-gray-500 !bg-white text-gray-500' : 'boarder-white text-white',
							colour === 'blue' && !error ? 'bg-uom-navy-900 hover:bg-uom-navy-700 focus:bg-uom-navy-700 focus:outline-none' : '',
							colour === 'teal' && !error ? 'bg-teal-700 hover:bg-teal-500 focus:bg-teal-500 focus:outline-none' : '',
							error ? '!border-red-600 text-gray-500 focus:outline-red-600' : '',
							disabled ? '!border-gray-300 !text-gray-400' : '',
							'relative w-full cursor-default rounded-md border py-2 pl-3 pr-10 text-left text-sm text-sm font-normal leading-5 shadow-sm',
						)}
					>
						<span
							className="select-label block min-h-[1.25rem] truncate"
							dangerouslySetInnerHTML={{
								__html: currentIndex != null && currentIndex >= 0 ? options[currentIndex] : placeholder ?? '',
							}}
						/>
						<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
							<Icon
								className="h-[27px] w-[27px]"
								icon={open ? 'arrow-drop-up' : 'arrow-drop-down'}
								aria-hidden="true"
							/>
						</span>
					</Listbox.Button>

					<Transition
						show={open}
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Listbox.Options
							static
							className="select-ul block-uplift no-list absolute z-[100] z-10 !mt-[4px] w-full w-full overflow-auto rounded-md bg-white py-1 text-sm text-base font-normal leading-5 !shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:w-[384px]"
						>
							{blankOption != null && blankOption.length > 0 && (
								<Listbox.Option
									className={({active}) => cx(active ? 'bg-gray-100 text-gray-900' : 'text-gray-600', 'relative cursor-default select-none py-2 pl-3 pr-9')}
									value={null}
								>
									<span className="block truncate text-sm font-normal">{blankOption}</span>
								</Listbox.Option>
							)}
							{options.map((option, index) => {
								const disabled = disabledIndexes?.includes(index)
								return (
									<Listbox.Option
										key={index}
										disabled={disabled}
										className={({active}) => cx(active ? 'bg-gray-100 text-gray-900' : 'text-gray-600', 'relative cursor-default select-none py-2 pl-3 pr-9')}
										value={index}
									>
										{({selected}) => (
											<span
												className={cx(selected ? 'font-semibold' : 'font-normal', disabled ? '!text-gray-400' : '', '--w-[20px] select-label block h-[20px] truncate text-sm')}
												dangerouslySetInnerHTML={{__html: option}}
											/>
										)}
									</Listbox.Option>
								)
							})}
						</Listbox.Options>
					</Transition>
				</div>
			)}
		</Listbox>
	)
}
