import React, {StrictMode} from 'react'
import {flushSync} from 'react-dom'
import {createRoot} from 'react-dom/client'

export function render(children: React.ReactNode | Iterable<React.ReactNode>, container: Element | null | undefined) {
	if (container == null) return
	flushSync(() => createRoot(container).render(<StrictMode>{children}</StrictMode>))
}

export function renderToMain(children: React.ReactNode | Iterable<React.ReactNode>) {
	render(children, document.querySelector('[role=main]'))
}
