import {useEffect, useRef, useState} from 'react'

// Docs for this endpoint are here https://docs.squiz.net/funnelback/archive/customise/standard-options/auto-completion/auto-completion_sources/auto-completion_web_service_suggest.json.html
//'/search/_webservices/suggest.json?collection=staff&show=10&sort=0&alpha=0.5&fmt=json++&profile=_default&partial_query='
const searchSuggestApiUrl = `${(window as any).searchSuggestUrlBase}?collection=${(window as any).searchSuggestCollection}&show=10&sort=0&alpha=0.5&fmt=json++&profile=_default&partial_query=`
export function useSearchSuggest(query: string, execute: boolean) {
	const [data, setData] = useState<string[]>([])
	const lastQuerySent = useRef('')

	useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(searchSuggestApiUrl + query)
			// This is required for when requests return out of order
			// this happens fairly often I assume because short query terms have more hits
			if (!response.url.endsWith('&partial_query=' + encodeURIComponent(lastQuerySent.current))) {
				return
			}
			const data: {disp: string}[] = await response.json()
			setData(data.map((item) => item.disp))
		}
		if (!execute || query?.length < 3) {
			setData([])
			return
		}
		lastQuerySent.current = query
		fetchData().catch(console.error)
	}, [query])

	return data
}
