import React from 'react'
import {Icon} from '~/blocks/Icon'
import {CopyButton} from '~/components/CopyButton'
import {IconInBox} from '~/images/IconInBox'
import {CommonCardProps} from './Card'

export interface ContactCardProps extends CommonCardProps {
	icon?: string
	title?: string
	description?: string
	linkLabel?: string
	linkType?: string
	iconBoxColour?: string
}

export function ContactCard(props: ContactCardProps) {
	return (
		<div className="group flex flex-col justify-between gap-x-[24px] !no-underline">
			<div>
				<IconInBox
					colour={props.iconBoxColour ?? 'navy'}
					icon={props.icon ?? ''}
				/>
				<div className="mt-[16px] mb-[6px] text-lg font-semibold leading-6 text-gray-900">{props.title}</div>
				<div className="pt-[8px] text-sm font-medium leading-5 text-gray-600">{props.body}</div>
			</div>
			<div className="mt-[24px] flex items-center text-sm font-medium leading-5 text-blue-700">
				{props.linkType === 'phone' ? (
					<a href={props.url}>{props.url?.split(':')[1]}</a>
				) : props.linkType === 'email' ? (
					<>
						<a href={props.url}>{props.url?.split(':')[1]}</a>
						<CopyButton
							text={props.url?.split(':')[1] ?? ''}
							classNames="ml-[24px]"
						/>
					</>
				) : (
					<>
						<a href={props.url}>{props.linkLabel ?? 'View'}</a>
						<Icon
							icon="arrow-forward"
							className="ml-[6px] h-[16px]"
						/>
					</>
				)}
			</div>
		</div>
	)
}
