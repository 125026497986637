import autoLinker from 'autolinker'

export function cx(...classes: Array<string | boolean | null | undefined>) {
	return classes.filter(Boolean).join(' ')
}

export function getUrlParam(name: string) {
	const urlParams = new URLSearchParams(window.location.search)
	return urlParams.get(name)
}

export function setUrlParam(name: string, newValue: string) {
	const urlParams = new URLSearchParams(window.location.search)
	urlParams.set(name, newValue)
	window.history.pushState({}, '', `${location.pathname}?${urlParams}`)
}

export function removeUrlParam(name: string) {
	const urlParams = new URLSearchParams(window.location.search)
	urlParams.delete(name)
	if (Array.from(urlParams.keys()).length > 0) {
		window.history.pushState({}, '', `${location.pathname}?${urlParams}${window.location.hash}`)
	} else {
		window.history.pushState({}, '', location.pathname + window.location.hash)
	}
}

export function setUrlHash(hash: string) {
	history.replaceState(undefined, '', '#' + hash)
}

export function range(start: number, end: number): number[] {
	return Array.from({length: end}, (_, i) => i + start)
}

export function unique(array: any[]): any[] {
	return [...new Set(array)]
}

export function highlight(stringToHighlightWithin: string, term?: string) {
	if (stringToHighlightWithin.length <= 0 || term == null || term.length <= 0) {
		return stringToHighlightWithin
	} else {
		const tempElement = document.createElement('div')
		tempElement.innerHTML = stringToHighlightWithin
		return tempElement.innerHTML.replace(new RegExp(term, 'gi'), (match) => `<strong>${match}</strong>`)
	}
}

export function convertStringToTitleCase(string: string) {
	string = string.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
	})

	// they are the first or last words in the string
	const lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At', 'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With']
	for (let i = 0, j = lowers.length; i < j; i++)
		string = string.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'), function (txt) {
			return txt.toLowerCase()
		})

	// Certain words such as initialism or acronyms should be left uppercase
	const uppers = ['Id', 'Tv']
	for (let i = 0, j = uppers.length; i < j; i++) string = string.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'), uppers[i].toUpperCase())

	return string
}

export function formatPhoneNumber(phone?: string) {
	if (phone == null) return

	return phone.substring(0, 3) + ' (' + phone.substring(3, 4) + ') ' + phone.substring(4, 8) + ' ' + phone.substring(8)
}

export interface BreadcrumbPage {
	name: string | null
	href: string
	current: boolean
}

const pageLocalHistory = Array.from(document.querySelectorAll<HTMLAnchorElement>('.page-local-history > li > a'))

export const breadcrumbList: BreadcrumbPage[] = pageLocalHistory.map((page, index) => ({
	name: page.children[0].textContent,
	href: page.href.replace('https://staff.unimelb.edu.au', ''),
	current: index === pageLocalHistory.length - 1,
}))

export const pageType = document.querySelector<HTMLDivElement>('[data-sh-page-type]')?.dataset.shPageType

export function autoLink(text: string | undefined): string {
	if (text == undefined) {
		return ''
	}
	const autoLinkerOptions = {
		className: 'text-blue-700 underline break-words',
	}
	return autoLinker.link(text, autoLinkerOptions)
}

export function isEven(n: number) {
	return n === 0 || !!(n && !(n % 2))
}

export function wrap(element: HTMLElement, wrapper: HTMLElement) {
	element.parentNode?.insertBefore(wrapper, element)
	wrapper.appendChild(element)
}

export function isUrlSameHost(url: string | null | undefined) {
	// noinspection SpellCheckingInspection
	return url == null || new URL(url, document.baseURI).host === location.hostname || url?.includes(`url=https%3A%2F%2F${location.hostname}`) // This is needed because search result urls use a redirect to track clicks
}

export function isUrlWebPage(url: string | null | undefined) {
	// function to make docs,pdfs etc open in new tab
	const extension = url?.split('.').pop()?.toLowerCase();
	return extension && !(['pdf', 'doc', 'docx', 'xls', 'xlsm', 'txt', 'jpg', 'jpeg', 'gif','png', 'mp4'].includes(extension))
}

export function getBackgroundImage(element: HTMLElement | null) {
	return element?.style.backgroundImage.slice(4, -1).replace(/['"]/g, '') ?? undefined
}

export function replaceWithNewDiv(element: HTMLElement) {
	element.classList.add('hidden')
	const div = document.createElement('div')
	element.parentNode?.replaceChild(div, element)
	return div
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
	return value !== null && value !== undefined
}

/*function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
	if (value === null || value === undefined) return false
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	// noinspection JSUnusedLocalSymbols
	const testDummy: TValue = value
	return true
}*/

export function urlHost(url: string) {
	return new URL(url, document.baseURI).host
}

export function urlLooksLikeAFile(url: string | null | undefined) {
	if (url == null) return false
	const htmlExtensions = ['html', 'htm', 'php', 'asp', 'aspx', 'jsp', 'cfm', 'pl', 'cgi', 'shtml', 'xhtml']
	const lastPathElement = new URL(url, document.baseURI).pathname.split('/').pop() ?? ''
	return lastPathElement.includes('.') && !htmlExtensions.includes(lastPathElement.split('.').pop() ?? '')
}

export function detectMob() {
	const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]

	return toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem)
	})
}

export type HeadingTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
