import {useRef} from 'react'

export function useSideScrollButtons<T extends HTMLElement>() {
	const sideScrollRef = useRef<T>(null)
	let scrollLeftTimeout: any

	function handleSideScrollLeftPointerDown() {
		scrollLeftTimeout = setTimeout(() => {
			if (sideScrollRef.current != null) {
				sideScrollRef.current.scrollLeft -= 20
			}
			handleSideScrollLeftPointerDown()
		}, 50)
	}

	function handleSideScrollLeftPointerUp() {
		clearTimeout(scrollLeftTimeout)
	}

	let scrollRightTimeout: any

	function handleSideScrollRightPointerDown() {
		scrollRightTimeout = setTimeout(() => {
			if (sideScrollRef.current != null) {
				sideScrollRef.current.scrollLeft += 20
			}
			handleSideScrollRightPointerDown()
		}, 50)
	}

	function handleSideScrollRightPointerUp() {
		clearTimeout(scrollRightTimeout)
	}

	return {
		sideScrollRef,
		handleSideScrollLeftPointerDown,
		handleSideScrollLeftPointerUp,
		handleSideScrollRightPointerDown,
		handleSideScrollRightPointerUp,
	}
}
