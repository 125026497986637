import React from 'react'
import {Pagination} from '~/components/Pagination'
import {render} from '~/utils/render'
import {replaceWithNewDiv} from '~/utils/utils'

interface SearchPaginationProps {
	pages: string[]
}

export function initSearchPagination() {
	document.querySelectorAll<HTMLHeadElement>('.search-pagination').forEach((element) => {
		const props: SearchPaginationProps = {
			pages: Array.from(element.querySelectorAll<HTMLAnchorElement>('li a')).map((result) => result.href),
		}

		const div = replaceWithNewDiv(element)
		div.classList.add('doesnt-need-gap')

		render(<SearchPagination {...props} />, div)
	})
}

const SearchPagination = (props: SearchPaginationProps) => {
	function goToPage(newPageNumber: number) {
		window.location.href = props.pages[newPageNumber - 1]
	}

	return (
		<Pagination
			numberOfPages={props.pages.length}
			currentPage={1}
			goToPage={goToPage}
		/>
	)
}
