import React from 'react'
import {CopyButton} from '~/components/CopyButton'
import {render} from '~/utils/render'
import './Code.css'

interface CodeProps {
	code: string
}

export function initCode() {
	document.querySelectorAll<HTMLElement>('.sh-code, .block-code').forEach((element) => {
		const query = element.dataset.query
		const outer = element.dataset.outer != null

		let code: string
		if (query != null && query.length > 0) {
			code = (outer ? document.querySelector(query)?.outerHTML : document.querySelector(query)?.innerHTML) ?? ''
		} else if (element.innerHTML.trim().length > 0) {
			code = element.innerHTML
		} else {
			code = element.previousElementSibling?.innerHTML ?? ''
		}

		if (code == null || code.length <= 0) {
			return
		}

		element.classList.remove('sh-code', 'block-code')
		element.classList.add('sh-code-rendered')

		render(<Code code={code} />, element)
	})

	if (document.querySelector('.sh-code-rendered') != null) {
		setTimeout(function () {
			// eslint-disable-next-line @typescript-eslint/no-extra-semi
			;(window as any).Prism.highlightAll()
		}, 200)
	}

	// This is a hack keep the copy code buttons working when a code section is moved by another element like the HorizontalTabs and the onclick function is lost
	setTimeout(() => {
		document.querySelectorAll<HTMLDivElement>('.sh-code-rendered').forEach((element) => {
			const button = element.querySelector('button')
			if (button != null && button?.onclick == null) {
				const code = element.querySelector('script')?.innerHTML ?? ''
				button.onclick = () => {
					navigator.clipboard.writeText(code).then(() => {
						element.querySelector('.copy-code')?.classList.add('hidden')
						element.querySelector('.copied')?.classList.remove('hidden')
						setTimeout(() => {
							element.querySelector('.copied')?.classList.add('hidden')
							element.querySelector('.copy-code')?.classList.remove('hidden')
						}, 2000)
					})
				}
			}
		})
	}, 100)
}

function Code({code}: CodeProps) {
	return (
		<div className="section-spacing code-section mb-[1.5rem]">
			<div className="mb-[24px] flex items-center justify-between">
				<h3 className="!my-0 !py-0">Code</h3>
				<CopyButton
					text={code}
					label="Copy code"
				/>
			</div>
			<script
				type="text/plain"
				className="language-markup mt-0 max-h-[400px] overflow-y-scroll text-sm shadow-sm"
				dangerouslySetInnerHTML={{__html: code}}
			/>
		</div>
	)
}
