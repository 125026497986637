import React, {useRef} from 'react'
import {Icon} from '~/blocks/Icon'
import {useLineCount} from '~/pages/search/useLineCount'
import {autoLink, cx} from '~/utils/utils'
import type {CardProps} from './Card'
import {CommonCardProps} from './Card'
import {CardWrapper} from './CardWrapper'

export interface ImageCardProps extends CommonCardProps {
	imageUrl?: string
}

export function ImageCard(props: CardProps) {
	const titleRef = useRef<HTMLDivElement>(null)
	const headerLineCount = useLineCount(titleRef, 25)

	return (
		<CardWrapper
			{...props}
			className={cx(
				props.horizontalLayout ? '' : 'flex-col',
				'focus:outline-blue flex items-start gap-[24px] rounded-lg border-2 border-transparent p-[24px] text-left text-sm font-normal leading-5 text-blue-700 shadow hover:no-underline hover:shadow-lg active:border-blue-700',
			)}
		>
			<img
				className={cx(props.horizontalLayout ? '!h-[128px] !w-[128px]' : '!h-[48px] !w-[48px]', ' rounded bg-white object-cover')}
				src={props.imageUrl}
				aria-hidden="true"
				alt=""
			/>
			<div className="">
				<h3
					ref={titleRef}
					className="block-uplift text-lg font-semibold leading-6 text-gray-900"
				>
					{props.title}
				</h3>
				{props.body != null && (
					<div
						className={cx(props.lineClamp ? `flex-grow overflow-ellipsis line-clamp-${6 - headerLineCount}` : 'flex-shrink', 'break-words pt-2 text-base font-normal leading-6 text-gray-600')}
						dangerouslySetInnerHTML={{
							__html: props.autoLinkBody ? autoLink(props.body) : props.body,
						}}
					/>
				)}
				<div className="mt-auto pt-6 text-sm font-medium leading-5 text-blue-700 hover:underline">
					{props.linkLabel ? props.linkLabel : 'View'}{' '}
					<Icon
						icon="arrow-forward"
						className="mb-[4px] inline h-4 w-4"
					/>
				</div>
			</div>
		</CardWrapper>
	)
}
