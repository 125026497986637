import {useEffect, useState} from 'react'
import type {StaffProfile} from '~/cards/StaffProfileCard'

//  API setup in Matrix #4081924 https://staff.unimelb.edu.au/staff-hub/_designs/people-search-support-assets/staff-details-api/_admin/
//const staffProfileApiUrl = '/staff-hub/_designs/people-search-support-assets/staff-details-api?id='

const staffDetailsApiUrl = (window as any).staffProfileApiUrl
export function useStaffProfile(personId?: string) {
	const [data, setData] = useState<any>(null)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (personId == null) {
			setData(null)
			return
		}
		const fetchData = async () => {
			setIsLoading(true)
			const response = await fetch(`${staffDetailsApiUrl}?id=${personId}`)
			const data = await response.json()
			setData(data.data)
			setIsLoading(false)
		}

		void fetchData()
	}, [personId])

	const reports: any[] = data?.reports
	const colleagues: any[] = data?.colleagues
	return {
		isLoading,
		profile: parseStaffProfile(data),
		supervisor: parseStaffProfile(data?.supervisor),
		reports: reports?.map((report) => parseStaffProfile(report)),
		colleagues: colleagues?.map((colleague) => parseStaffProfile(colleague)),
	}
}

function parseStaffProfile(data: any | undefined): StaffProfile | undefined {
	if (data == null) return undefined

	let fullName = undefined
	if ((data.givenName != null && data.givenName?.length > 0) || (data.familyName != null && data.familyName?.length > 0)) {
		fullName = data?.givenName.trim() + ' ' + data?.familyName.trim()
	}

	let email = Array.isArray(data.email) ? data?.email[0] : data?.email
	if (email === 'no-reply@unimelb.edu.au') {
		email = undefined
	}

	return {
		personId: data?.personId,
		fullName: fullName,
		position: data?.position,
		departmentName: data?.departmentName,
		email: email,
		phone: Array.isArray(data.phone) ? data?.phone[0] : data?.phone,
		faeExists: data?.faeExists,
		photoUrl: data.photoUrl,
		faeProfileUrl: data.faeProfileUrl,		
	}
}
