import React from 'react'
import {getSectionProps, SectionProps, SectionWrapper} from '~/components/SectionWrapper'
import {render} from '~/utils/render'
import {cx} from '~/utils/utils'
import './Timeline.css'

interface TimelineProps extends SectionProps {
	events: TimelineEvent[]
}

interface TimelineEvent {
	date?: {
		dayOfWeek?: string
		dayAndMonth?: string
		year?: string
		time?: string
	}
	simplifiedDate?: string
	title?: string
	paragraphs?: string[]
	body?: string
	imageUrl?: string
	imageAlt?: string
}

export function initTimeline() {
	document.querySelectorAll<HTMLDataListElement>('dl.timeline').forEach((element) => {
		const props: TimelineProps = {
			events: Array.from(element.querySelectorAll('dt'))
				.map((dt) => {
					const dd = dt.nextElementSibling
					if (dd == null) return {}

					const dtText = dt.textContent?.trim() ?? ''
					const dtStrongText = dt.querySelector('strong')?.textContent?.trim() ?? ''

					const isSimplified = dtText.length == dtStrongText.length

					let date
					let simplifiedDate
					if (isSimplified) {
						simplifiedDate = dtStrongText
					} else {
						const dayOfWeek = dt.innerHTML.substring(0, dt.innerHTML.indexOf('<strong>')).trim()
						const yearMatch = dtStrongText.match(/\d+$/)
						let dayAndMonth = undefined
						let year = undefined
						if (yearMatch != null) {
							year = yearMatch[0]
							dayAndMonth = dtStrongText.substring(0, dtStrongText.indexOf(year)).trim()
						}
						const time = dd?.querySelector(':scope > span')?.innerHTML ?? undefined

						date = {
							dayOfWeek,
							dayAndMonth,
							year,
							time,
						}
					}

					const paragraphHTML = Array.from(dd?.querySelectorAll(':scope > p')).map((p) => p.innerHTML) ?? []

					let title
					let paragraphs: string[]

					if (paragraphHTML.length > 1) {
						title = paragraphHTML[0]
						paragraphs = paragraphHTML.slice(1)
					} else {
						paragraphs = paragraphHTML
					}

					return {
						date,
						simplifiedDate,
						title,
						paragraphs,
						imageUrl: dd.querySelector('img')?.src,
						imageAlt: dd.querySelector('img')?.alt,
					}
				})
				.filter((value) => value != null),
		}

		const div = document.createElement('div')
		div.classList.add('pt-4')
		element.parentNode?.replaceChild(div, element)

		render(<Timeline {...props} />, div)
	})

	document.querySelectorAll<HTMLDataListElement>('.staff-ui-timeline').forEach((element) => {
		const sectionProps = getSectionProps(element)
		const props: TimelineProps = {
			...sectionProps,
			events: Array.from(element.querySelectorAll<HTMLElement>('.event')).map((event) => {
				const date = new Date(event.dataset.date ?? '')
				return {
					date: {
						dayOfWeek: date.toLocaleString('en-GB', {weekday: 'long'}),
						dayAndMonth: date.toLocaleString('en-GB', {day: 'numeric', month: 'long'}),
						year: date.toLocaleString('en-GB', {year: 'numeric'}),
						time: event.dataset.time,
					},
					title: event.dataset.title,
					body: event.innerHTML.trim(),
					simplifiedDate: event.dataset.simplifiedDate != null && event.dataset.simplifiedDate.length > 0 ? event.dataset.simplifiedDate : undefined,
				}
			}),
		}
		render(<Timeline {...props} />, element)
	})
}

function Timeline(props: TimelineProps) {
	return (
		<SectionWrapper {...props}>
			<ol
				role="list"
				className="no-list overflow-hidden"
			>
				{props.events.map((event, eventIndex) => (
					<li
						key={eventIndex}
						className={cx(eventIndex !== props.events.length - 1 ? 'mb-[48px] pb-0' : '', 'flex')}
					>
						<div className="flex flex-shrink-0 items-start">
							<span className="mr-4  flex w-[150px] flex-col items-end text-gray-900">
								{event.date != null && event.simplifiedDate == null && (
									<>
										<span className="text-sm font-normal leading-5">{event.date.dayOfWeek}</span>
										<span className="text-lg font-semibold leading-6">{event.date.dayAndMonth}</span>
										<span className="text-sm font-normal leading-5">{event.date.year}</span>
									</>
								)}
								{event.simplifiedDate != null && <span className="mt-[4px] text-right text-sm font-semibold leading-5">{event.simplifiedDate}</span>}
							</span>
						</div>
						<div className="relative">
							{eventIndex < props.events.length - 1 ? (
								<div
									className="absolute left-[12px] top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
									aria-hidden="true"
								/>
							) : null}
							<div className="group relative flex items-start">
								<span
									className="flex h-9 items-start"
									aria-hidden="true"
								>
									<span className="relative z-10 mt-[2px] flex h-6 w-6 items-center justify-center rounded-full bg-sh-light-blue">
										<span className="h-2.5 w-2.5 rounded-full bg-uom-navy-900" />
									</span>
								</span>
								<span className="ml-4 flex min-w-0 flex-col text-sm leading-5">
									{event.date?.time != null && <span className="pb-[4px] font-semibold text-gray-900">{event.date?.time}</span>}
									{event.title != null && <span className="pb-[4px] font-semibold text-gray-900">{event.title}</span>}
									{event.body != null && (
										<div
											className="event-body pb-[4px] pt-[2px] font-normal text-gray-600"
											dangerouslySetInnerHTML={{__html: event.body}}
										/>
									)}
									{event.paragraphs?.map((paragraph, index) => (
										<div
											key={index}
											className="max-w-content pb-[4px] font-normal text-gray-600"
											dangerouslySetInnerHTML={{__html: paragraph ?? ''}}
										/>
									))}
									{event.imageUrl != null && (
										<img
											className="pt-4"
											src={event.imageUrl}
											alt={event.imageAlt}
										/>
									)}
								</span>
							</div>
						</div>
					</li>
				))}
			</ol>
		</SectionWrapper>
	)
}
