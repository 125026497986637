import React from 'react'
import {useFetch} from 'usehooks-ts'
import {SectionWrapper} from '~/components/SectionWrapper'
import {render} from '~/utils/render'
import {cx} from '~/utils/utils'
import './ResearchEvents.css'

type Background = 'white' | 'gray'

interface ResearchEventsProps {
	backgroundColour?: Background
	heading?: string
	apiUrl?: string
	viewAllUrl?: string
	viewAllLabel?: string
}

export function initResearchEvents() {
	document.querySelectorAll<HTMLElement>('.staff-ui-researcher-events').forEach((element) => {
		const props: ResearchEventsProps = {
			backgroundColour: element.dataset.backgroundColour as Background,
			heading: element.dataset.heading,
			apiUrl: element.dataset.apiUrl,
			viewAllUrl: element.dataset.viewAllUrl,
			viewAllLabel: element.dataset.viewAllLabel,
		}

		element.classList.add('doesnt-need-gap-without-padding')
		render(<ResearchEvents {...props} />, element)
	})
}

export function ResearchEvents(props: ResearchEventsProps) {
	if (props.apiUrl === undefined) return null

	const {data} = useFetch<Event[]>(props.apiUrl)

	return (
		<SectionWrapper
			{...props}
			verticalSpacing="default"
		>
			<div className="overflow-x-auto">
				<div className="flex gap-x-[24px]">
					{data?.map((event, index) => {
						// The thumbnail was too small and cropped in, so this code edits the url to get a larger one
						// Original format https://rduevents.unimelb.edu.au/live/image/gid/21/width/80/height/80/crop/1/src_region/0,0,436,217/184_ResCon_R.rev.1650411280.jpg
						// New format https://rduevents.unimelb.edu.au/live/image/gid/21/width/400/184_ResCon_R.rev.1650411280.jpg

						let thumbnail
						if (event.thumbnail != undefined) {
							const start = event.thumbnail?.split('80/height/80')[0]
							const splitOnForwardSlash = event.thumbnail?.split('/')
							thumbnail = start + '400/' + splitOnForwardSlash[splitOnForwardSlash.length - 1]
						}

						const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

						return (
							<a
								key={index}
								href={event.url}
								className="inline-flex w-[300px] flex-shrink-0 flex-col overflow-hidden rounded-lg text-gray-600 shadow hover:no-underline hover:shadow-lg md:w-auto md:flex-1"
							>
								<div className={cx('event-thumbnail h-[200px] w-full', !thumbnail && 'bg-gray-300')}>
									{thumbnail && (
										<img
											className={'h-[200px] w-full object-cover'}
											src={thumbnail}
											aria-hidden="true"
											alt=""
										/>
									)}
								</div>
								<div className="flex flex-1 flex-col justify-between bg-white p-[24px]">
									<div className="flex-1">
										{event.event_types && <div className="inline rounded-full bg-gray-100 px-[13px] py-[4px] text-xs font-medium">{event.event_types && event.event_types[0]}</div>}
										<div className={cx(event.event_types ? 'mt-[32px]' : 'mt-[56px]')}>
											<h3 className="block-uplift whitespace-normal text-lg font-semibold leading-6 text-gray-900">{event.title}</h3>
										</div>
									</div>
									<div className="mt-[12px]">
										<div className="text-sm text-gray-500">{weekday[new Date(event.date_ts * 1000).getDay()]}</div>
										<div className="mt-[4px] text-lg font-semibold leading-6 text-gray-900">{event.date}</div>
										<div className="mt-[4px] text-sm text-gray-500">{event.date_time}</div>
									</div>
								</div>
							</a>
						)
					})}
				</div>
			</div>
			<div className="mt-[24px] flex w-full justify-end">
				<a
					href={props.viewAllUrl}
					className="inline-flex items-center text-sm font-medium underline hover:decoration-2"
				>
					{props.viewAllLabel}
				</a>
			</div>
		</SectionWrapper>
	)
}

export interface Event {
	id: number
	gid: number
	eid: any
	group: string
	title: string
	description: any
	url: string
	date: string
	date_time: string
	date_ts: number
	tz_offset: number
	date_utc: string
	timezone: string
	is_all_day: any
	repeats: string
	repeats_until: any
	repeats_start: string
	repeats_end: string
	thumbnail: string
	thumbnail_caption: string
	location: string
	location_title: any
	location_latitude: any
	location_longitude: any
	location_id: any
	tags: string[]
	tags_starred: string[]
	tags_global: string[]
	contact_info?: string
	has_registration?: number
	registration_owner_email: string
	search_categories: any
	has_wait_list?: number
	registration_limit?: number
	wait_list_limit?: number
	rsvp_total?: number
	rsvp_waitlist_total: any
	cost: string
	is_starred: any
	last_modified: number
	last_editor: string
	is_online: any
	online_type: any
	online_url: any
	online_button_label: any
	online_instructions: any
	custom_contact_email: string
	custom_custom_rsvp_fields?: CustomCustomRsvpFields
	date2_ts: number
	tz2_offset: number
	date2_utc: string
	date2_time: string
	rsvp_remaining?: number
	event_types: string[]
	event_types_audience: string[]
	event_types_campus: string[]
	'thumbnail:html'?: string
	summary: string
	custom_booking_info?: string
	custom_presenter1_bio?: string
	custom_presenter1_name?: string
}

export interface CustomCustomRsvpFields {
	fac_division: FacDivision
	iam: Iam
	staff_id?: StaffId
	how_marketing?: HowMarketing
	accessibility?: Accessibility
}

export interface FacDivision {
	is_required: number
	custom_instruction: string
	custom_options: string
}

export interface Iam {
	is_required: number
	custom_instruction: string
	custom_options: string
}

export interface StaffId {
	is_required: string
	custom_instruction: string
	custom_options: string
}

export interface HowMarketing {
	is_required: number
	custom_instruction: string
	custom_options: string
}

export interface Accessibility {
	is_required: string
	custom_instruction: string
	custom_options: string
}

/* 
		<div className={cx(props.backgroundColor === 'gray' && 'full-width-background before:bg-gray-50', 'mb-[-48px]')}>
			<div className="relative py-[48px]">
				<h2 className="block-uplift pb-[24px] text-2xl font-semibold leading-8 text-gray-900 md:pt-0">{props.title}</h2>
				<div className="hidden md:block">
					<div className="grid space-y-[24px] md:grid-cols-2 md:space-x-[24px] md:space-y-[0]">
						{props.articles.slice(0, 2).map((article) => (
							<FrontpageNewsCard
								key={article.title}
								article={article}
								imageClass="h-[330px]"
							/>
						))}{' '}
					
</div>
<div className="grid space-y-[24px] !pt-[21px] md:grid-cols-3 md:space-x-[24px] md:space-y-[0]">
	{props.articles.slice(2, 5).map((article) => (
		<FrontpageNewsCard
			key={article.title}
			article={article}
			imageClass="h-[256px]"
		/>
	))}{' '}
	
</div>
</div>
<div
	ref={sideScrollRef}
	className="hide-horizontal-scrollbar -mx-4 flex gap-x-[18px] overflow-y-hidden px-4 pb-[20px] pt-[16px] sm:-mx-6 sm:px-6 md:hidden"
>
	{props.articles.map((article) => (
		<FrontpageNewsCard
			key={article.title}
			article={article}
			imageClass="h-[200px]"
		/>
	))}
</div>
<div className="flex justify-between md:pt-[24px]">
	<div className="flex space-x-2 md:invisible">
		<button
			className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
			onPointerDown={handleSideScrollLeftPointerDown}
			onPointerUp={handleSideScrollLeftPointerUp}
			aria-label="scroll left"
		>
			<Icon
				icon="chevron-left"
				className="mr-[2px] h-[22px] w-[22px]"
			/>
		</button>
		<button
			className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
			onPointerDown={handleSideScrollRightPointerDown}
			onPointerUp={handleSideScrollRightPointerUp}
			aria-label="scroll right"
		>
			<Icon
				icon="chevron-right"
				className="ml-[2px] h-[22px] w-[22px]"
			/>
		</button>
	</div>
	<div className="flex items-center">
		<a
			className="items-center text-sm font-medium leading-5 text-blue-700 hover:underline"
			href={props.viewAllUrl}
		>
			<span className="hidden md:inline">{props.viewAllLabel}</span>
			<span className="md:hidden">{props.viewAllMobileLabel}</span>
			<Icon
				icon="arrow-forward"
				className="mb-[5px] ml-[4px] inline h-[16px]"
			/>
		</a>
	</div>
</div>
</div>
</div> 
*/
