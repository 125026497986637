import React from 'react'
import {ContactInformation, ContactInformationProps} from '~/blocks/ContactInformation'
import {IconAttachedToLastWord} from '~/components/IconAttachedToLastWord'
import {getSectionProps, SectionProps, SectionWrapper} from '~/components/SectionWrapper'
import {queryDataSet, queryHref, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx, replaceWithNewDiv, wrap} from '~/utils/utils'
import './Pathfinder.css'

interface PathfinderProps extends SectionProps {
	cardBackgroundColour: string
	useTitledButtons: boolean
	maxColumns: number
	card: {
		url?: string
		title?: string
		body?: string
		buttonLabel?: string
		featureCardColour?: string
	}[]
}

export function initPathfinder() {
	// Most of the Pathfinder blocks are done just with CSS, but staff-ui-pathfinder has headings etc.
	document.querySelectorAll<HTMLElement>('.staff-ui-pathfinder').forEach((element) => {
		const sectionProps = getSectionProps(element)
		const props: PathfinderProps = {
			...sectionProps,
			cardBackgroundColour: element.dataset.cardBackgroundColour ?? 'white',
			useTitledButtons: element.dataset.useTitledButtons == 'true',
			maxColumns: element.dataset?.maxColumns != null ? Number(element.dataset?.maxColumns) : 4,
			card: Array.from(element.querySelectorAll<HTMLAnchorElement>('.card')).map((card) => {
				return {
					url: queryHref('a', card),
					title: queryTextContent('.title', card),
					body: queryTextContent('.description', card),
					buttonLabel: queryTextContent('.link-label', card),
					featureCardColour: card.dataset.featureCardColour,
				}
			}),
		}
		element.classList.add('doesnt-need-gap-without-padding')
		render(<Pathfinder {...props} />, element)
	})

	document.querySelectorAll<HTMLElement>('ul[class^="pathfinder-"]:not(.buttons)').forEach((element) => {
		if (element.classList.contains('fullwidth')) {
			element.classList.add('doesnt-need-gap')
		} else {
			element.classList.add('section-spacing')
		}

		element.classList.add('no-list')

		if (element.querySelector('[data-icon="computer"],[data-icon="chat"],[data-icon="phone"]') != null) {
			let title
			const headings: string[] = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6']
			const previousSibling = element.previousElementSibling
			if ((previousSibling != null && headings.includes(previousSibling.tagName)) || previousSibling?.classList.contains('lead')) {
				title = previousSibling.textContent ?? undefined
				previousSibling.remove()
			}

			const props: ContactInformationProps = {
				title: title,
				contactMethods: Array.from(element.querySelectorAll<HTMLAnchorElement>(':scope > li > a')).map((contactMethod) => ({
					icon: queryDataSet('[data-icon]', contactMethod)?.icon,
					title: queryTextContent(':scope > strong', contactMethod),
					body: queryTextContent(':scope > p', contactMethod),
					linkText: queryTextContent(':scope > span.button-small', contactMethod),
					linkUrl: contactMethod.href,
				})),
			}

			const div = replaceWithNewDiv(element)
			div.classList.add('doesnt-need-gap')

			render(<ContactInformation {...props} />, div)
		}
	})

	document.querySelectorAll<HTMLElement>('ul[class^="pathfinder-"].buttons').forEach((element) => {
		if (!element.parentElement?.classList.contains('fullwidth')) {
			const section = document.createElement('section')
			section.className = 'fullwidth short fixed-background doesnt-need-gap'
			wrap(element, section)
		} else {
			element.parentElement?.classList.add('doesnt-need-gap')
		}

		element.querySelectorAll('li a').forEach((link) => {
			if (!link.innerHTML.includes('<span>')) {
				link.innerHTML = '<span>' + link.innerHTML + '</span>'
			}
		})

		element.classList.add('no-list')
	})

	// Pathfinders with a background which are not buttons must be soft
	document.querySelectorAll<HTMLElement>('section.fullwidth.fixed-background > ul[class^="pathfinder-"]:not(.soft):not(.buttons)').forEach((element) => {
		element.classList.add('soft')
	})
}

export function Pathfinder(props: PathfinderProps) {
	const cols = Math.min(props.card.length, props.maxColumns)
	return (
		<SectionWrapper {...props}>
			<>
				{/* Cards */}
				{!props.useTitledButtons && (
					<div className={cx('grid w-full !max-w-full items-center gap-[24px]', cols === 2 ? 'md:grid-cols-2' : cols === 3 ? 'md:grid-cols-2 lg:grid-cols-3' : 'md:grid-cols-2 lg:grid-cols-4')}>
						{props.card.map((card, index) => (
							<a
								key={index}
								href={card.url}
								className={cx(
									'flex h-full flex-col justify-between rounded-lg p-[24px] !no-underline shadow',
									props.cardBackgroundColour === 'navy' || card.featureCardColour === 'navy'
										? 'bg-uom-navy-900'
										: card.featureCardColour === 'teal'
										? 'bg-teal-700'
										: card.featureCardColour === 'green'
										? 'bg-green-700'
										: 'bg-white',
								)}
							>
								<div>
									<h3
										className={cx(
											'',
											props.cardBackgroundColour === 'navy' || (card.featureCardColour != null && card.featureCardColour.length > 0 && card.featureCardColour !== 'white')
												? '!text-white'
												: 'text-gray-900',
										)}
									>
										{card.title}
									</h3>
									<p
										className={cx(
											'text-base font-normal leading-6',
											props.cardBackgroundColour === 'navy' || (card.featureCardColour != null && card.featureCardColour.length > 0 && card.featureCardColour !== 'white')
												? 'text-white'
												: 'text-gray-600',
										)}
									>
										{card.body}
									</p>
								</div>
								<span className="button-small soft with-arrow">{card.buttonLabel}</span>
							</a>
						))}
					</div>
				)}
				{/* Titled Buttons */}
				{props.useTitledButtons && (
					<div
						className={cx(
							'm-0 grid !w-full max-w-none  divide-y divide-gray-200  rounded-lg bg-white p-0 shadow md:grid-flow-col md:divide-x md:divide-y-0',
							cols === 2 ? 'md:grid-cols-2' : cols === 3 ? 'md:grid-cols-3' : 'md:grid-cols-4',
						)}
					>
						{props.card.map((card, index) => (
							<a
								key={index}
								href={card.url}
								className={cx('--md:first-of-type:rounded-l-lg --md:last-of-type:rounded-r-lg p-[24px] !text-base font-medium leading-6 !text-blue-700 !no-underline hover:!underline')}
							>
								<IconAttachedToLastWord
									text={card.title ?? ''}
									icon="arrow-forward"
									className="ml-1.5 inline h-[1em] w-[1em]"
								/>
							</a>
						))}
					</div>
				)}
			</>
		</SectionWrapper>
	)
}
