import React, {KeyboardEvent, useState} from 'react'
import {Icon} from '~/blocks/Icon'
import {render} from '~/utils/render'
import {replaceWithNewDiv} from '~/utils/utils'

interface SearchProps {
	actionUrl?: string
	actionMethod?: string
	placeholder?: string
	inputName?: string
}

export function initSearch() {
	document.querySelectorAll<HTMLElement>('header.darker').forEach((element) => {
		const form = element.querySelector('form')
		const input = element.querySelector<HTMLInputElement>('input:not([type="hidden"])')

		if (form == null || input == null) return

		const props: SearchProps = {
			actionUrl: form?.action,
			actionMethod: form?.method,
			placeholder: input?.placeholder,
			inputName: input?.name,
		}

		const div = replaceWithNewDiv(element)
		div.classList.add('doesnt-need-gap')

		render(<Search {...props} />, div)
	})
}

function Search(props: SearchProps) {
	const [inputValue, setInputValue] = useState('')

	function onInputKeyDown(event: KeyboardEvent<HTMLInputElement>) {
		if (event.code === 'Enter') {
			submit()
		}
	}

	const submit = () => {
		if (inputValue.length <= 0) return
		window.location.href = `${props.actionUrl}?${props.inputName}=${inputValue}`
	}

	return (
		<div className="full-width-background relative text-white before:bg-uom-navy-900 ">
			<div className="relative py-[38px]">
				<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
					<Icon
						icon="search"
						className="h-5 w-5 text-gray-500"
						aria-hidden="true"
					/>
				</div>
				<input
					id="search"
					name="search"
					value={inputValue}
					onChange={(event) => setInputValue(event.target.value)}
					onKeyDown={onInputKeyDown}
					className="peer block w-full rounded-md border border-gray-500 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-600 focus:border-blue-700 focus:text-gray-900 focus:outline-none sm:text-sm md:w-[384px]"
					placeholder={props.placeholder}
					type="search"
					aria-label="Search staff information, services, and profiles"
				/>
				<button
					type="submit"
					value="Submit"
					onClick={submit}
					className="sr-only"
					aria-label="Submit search query"
				/>
			</div>
		</div>
	)
}
