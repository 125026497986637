import React, {useEffect} from 'react'
import {cx} from '~/utils/utils'
import {QuestionComponent} from './Question'
import './Questions.css'
import {sections, useSelfGuidanceToolStore} from './useSelfGuidanceToolStore'

export function Questions() {
	const {currentSectionIndex, answersInitialized, initializeAnswers} = useSelfGuidanceToolStore()
	const currentSection = sections[currentSectionIndex]

	useEffect(() => {
		initializeAnswers()
	}, [])

	return (
		<div className="remove-pb-firefox-only pb-[48px]">
			{answersInitialized &&
				currentSection.questionGroups.map((questionGroup, index) => (
					<div
						key={index}
						className={cx('tag-question-group mb-[52px] md:mb-16', `tag-question-group-index-${index}`)}
					>
						<h3 className="tag-question-group-title mb-[24px] !mt-0 !p-0">{questionGroup.title}</h3>
						<div className="flex flex-col gap-y-[24px]">
							{questionGroup.questions.map((question, index) => (
								<QuestionComponent
									key={index}
									question={question}
								/>
							))}
						</div>
					</div>
				))}
		</div>
	)
}
