import React from 'react'
import {Icon} from '~/blocks/Icon'
import {queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx} from '~/utils/utils'
import './Notice.css'

interface NoticeProps {
	level?: Level
	flash?: boolean
	center?: boolean
	marginTop?: boolean
	body?: string
}

type Level = 'success' | 'info' | 'warning' | 'danger' | undefined

export function initNotice() {
	document.querySelectorAll<HTMLElement>('.notice, .flash').forEach((element) => {
		let level: Level = undefined
		if (element.className.includes('--success')) {
			level = 'success'
		} else if (element.className.includes('--info')) {
			level = 'info'
		} else if (element.className.includes('--warning')) {
			level = 'warning'
		} else if (element.className.includes('--danger')) {
			level = 'danger'
		}

		const props: NoticeProps = {
			level: level,
			flash: element.classList.contains('flash'),
			center: element.className.includes('center'),
			marginTop: element.className.includes('notice--margin-top'),
			body: element.innerHTML,
		}

		render(<Notice {...props} />, placeNotice(!!props.flash, element))
	})

	document.querySelectorAll<HTMLElement>('.staff-ui-notice').forEach((element) => {
		const title = queryTextContent('strong', element)
		const subtitle = element.querySelector('.subtitle > p')?.innerHTML ?? ''
		const bodyHtml = element.querySelector('.body')?.innerHTML

		let body = `<p>
        		<strong>${title ?? ''}</strong><span class="ml-2">${subtitle}</span>
    		</p>
		`
		if (bodyHtml) {
			body += bodyHtml
		}

		const props: NoticeProps = {
			level: element.dataset.level as Level,
			flash: element.dataset.flash === 'true',
			center: element.dataset.flash === 'true',
			body: body,
		}

		render(<Notice {...props} />, placeNotice(!!props.flash, element))
	})
}

function placeNotice(flash: boolean, element: HTMLElement) {
	const div = document.createElement('div')
	if (flash) {
		document.querySelector('header')?.append(div)
	} else {
		element.parentNode?.replaceChild(div, element)
	}

	element.remove()
	return div
}

// My first version of this had flash and non-flash combined and there was lots of conditional and responsive classes it worked but
// it was confusing, so I split them into two to make it easier to understand

function Notice({level, flash, center, marginTop, body}: NoticeProps) {
	const flashNotice = () => (
		<div className={level + ' notice flash mb-[24px] border-l-4 py-4'}>
			<div className={cx(center && 'justify-center', 'container flex')}>
				<div
					className="flash-inner prose-sm"
					dangerouslySetInnerHTML={{__html: body ?? ''}}
				/>
			</div>
		</div>
	)

	const nonFlashNotice = () => (
		<div className={cx(marginTop ? 'mt-[24px]' : '', level, 'notice border-l-4')}>
			<div className={' my-[24px] rounded-r-md p-4'}>
				<div className={cx(center && 'justify-center', 'flex')}>
					{!flash && (
						<div className="mt-[3px] flex-shrink-0 pr-3">
							{level === 'success' && (
								<Icon
									icon="check-circle-outline"
									className="h-[18px] w-auto"
									aria-hidden="true"
								/>
							)}
							{level === 'info' && (
								<Icon
									icon="info-outline"
									className="h-[18px] w-auto"
									aria-hidden="true"
								/>
							)}
							{level === 'warning' && (
								<Icon
									icon="warning-outline"
									className="h-[18px] w-auto"
									aria-hidden="true"
								/>
							)}
							{level === 'danger' && (
								<Icon
									icon="danger-outline"
									className="h-[18px] w-auto"
									aria-hidden="true"
								/>
							)}
						</div>
					)}
					<div
						className="prose-sm"
						dangerouslySetInnerHTML={{__html: body ?? ''}}
					/>
				</div>
			</div>
		</div>
	)

	return flash ? flashNotice() : nonFlashNotice()
}
