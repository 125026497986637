import React from 'react'
import {Icon} from '~/blocks/Icon'
import {queryAlt, queryHref, querySrc, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'

import './HeroBanner.css'

interface HeroBannerProps {
	heading?: string
	bodyHtml?: string
	buttonLabel?: string
	buttonUrl?: string
	tipMessage?: string
	backgroundImageUrl?: string
	showBackgroundGradient: boolean
	featureImageUrl?: string
	featureImageAltText?: string
	buttonTarget?: string
	boxColor?: string
	boxWidth?: string
}

export function initHeroBanner() {
	document.querySelectorAll<HTMLElement>('.staff-ui-hero-banner').forEach((element) => {
		const props: HeroBannerProps = {
			heading: queryTextContent('h1,h2', element),
			bodyHtml: element.querySelector<HTMLElement>('.body')?.innerHTML,
			buttonLabel: queryTextContent('a.button', element),
			buttonUrl: queryHref('a.button', element),
			buttonTarget: element.querySelector<HTMLLinkElement>('a.button')?.target ?? undefined,
			tipMessage: element.dataset.tipMessage,
			backgroundImageUrl: querySrc('img.background-image', element),
			showBackgroundGradient: element.dataset.showBackgroundGradient != null ? element.dataset.showBackgroundGradient === 'true' : true,
			featureImageUrl: querySrc('img.feature-image', element),
			featureImageAltText: queryAlt('img.feature-image', element),
			boxColor: element.dataset.boxColor != null ? element.dataset.boxColor : 'bg-white',
			boxWidth: element.dataset.boxWidth != null ? element.dataset.boxWidth : 'default',
		}
		element.classList.add('no-container')
		render(<HeroBanner {...props} />, element)
	})
}

export function HeroBanner(props: HeroBannerProps) {
	function cardContent() {
		return (
			<>
				<h1 className="block-uplift text-3xl font-extrabold leading-8 tracking-tight text-gray-900">{props.heading}</h1>
				<div
					className="underline-links remove-child-paragraph-padding mt-[16px]"
					dangerouslySetInnerHTML={{__html: props.bodyHtml ?? ''}}
				/>
				{props.buttonLabel != null && props.buttonLabel.length > 0 && (
					<a
						className="mt-[24px] block max-w-max rounded-md bg-uom-navy-800 px-[15px] py-[9px] text-white"
						href={props.buttonUrl}
						target={props.buttonTarget}
					>
						{props.buttonLabel}
					</a>
				)}
			</>
		)
	}

	return (
		<>
			{/* Desktop */}
			<div className="relative hidden md:block">
				{(props.featureImageUrl == null || props.featureImageUrl.length <= 0) && (
					<div
						className="background-wider absolute bottom-0 top-0 bg-cover"
						style={{backgroundImage: `url(${props.backgroundImageUrl})`}}
					/>
				)}
				{props.showBackgroundGradient && (
					<div
						className="hero-banner-background-gradient background-wider absolute bottom-0 top-0"
						aria-hidden="true"
					/>
				)}
				<div className="relative">
					<div className="grid grid-cols-2">
						<div className="py-[95px]">
							{(props.boxWidth == 'wide') ? (
								<div className={`max-w-[650px] rounded-lg p-[24px] shadow ${props.boxColor}`}>{cardContent()}</div>
							) : (
								<div className={`max-w-[588px] rounded-lg p-[24px] shadow ${props.boxColor}`}>{cardContent()}</div>
							)}
						</div>
						{props.featureImageUrl != null && props.featureImageUrl.length > 0 && (
							<img
								className="mx-auto my-auto h-[90%] max-h-[400px]  object-cover"
								src={props.featureImageUrl}
								alt={props.featureImageAltText}
							/>
						)}
					</div>
					{props.tipMessage != null && props.tipMessage.length > 0 && (
						<div className="absolute bottom-[8px] right-0 mr-4 flex items-center rounded bg-black/70 p-[4px] pr-[6px] text-xs font-normal leading-5 text-white sm:mr-6 lg:mr-8">
							<Icon
								icon="light-bulb"
								className="mr-[4px] inline h-[14px] text-yellow-300"
							/>
							{props.tipMessage}
						</div>
					)}
				</div>
			</div>
			{/* Mobile */}
			<div className="md:hidden">
				{(props.featureImageUrl == null || props.featureImageUrl.length <= 0) && (
					<div className="relative flex min-h-[120px] flex-col items-end px-[16px] py-[8px]">
						<div
							className="background-wider absolute bottom-0 top-0 bg-cover"
							style={{backgroundImage: `url(${props.backgroundImageUrl})`}}
						/>
						{props.showBackgroundGradient && (
							<div
								className="hero-banner-background-gradient background-wider absolute bottom-0 top-0"
								aria-hidden="true"
							/>
						)}
						{props.tipMessage != null && props.tipMessage.length > 0 && (
							<div className="z-10 mt-auto flex items-center rounded bg-black/70 p-[4px] pr-[6px] text-xs font-medium leading-5 text-white sm:mr-6 lg:mr-8">
								<Icon
									icon="light-bulb"
									className="mr-[4px] inline h-[16px] flex-shrink-0 text-yellow-300"
								/>
								{props.tipMessage}
							</div>
						)}
					</div>
				)}
				<div className="container">
					{props.featureImageUrl != null && props.featureImageUrl.length > 0 && (
						<img
							className="mx-auto my-auto h-[90%] max-h-[400px] object-cover"
							src={props.featureImageUrl}
							alt={props.featureImageAltText}
						/>
					)}
					{props.tipMessage != null && props.tipMessage.length > 0 && props.featureImageUrl != null && props.featureImageUrl.length > 0 && (
						<div className="pt-[24px]">
							<div className="mx-auto flex max-w-max items-center rounded bg-black/70 p-[4px] pr-[6px] text-xs font-medium leading-5 text-white">
								<Icon
									icon="light-bulb"
									className="mr-[4px] inline h-[16px] flex-shrink-0 text-yellow-300"
								/>
								{props.tipMessage}
							</div>
						</div>
					)}
					<div className={`my-[32px] w-full rounded-lg p-[24px] shadow ${props.boxColor}`}>{cardContent()}</div>
				</div>
			</div>
		</>
	)
}
