import React from 'react'
import {render} from '~/utils/render'
import {Icon} from '~/blocks/Icon'

interface LinkRowProps {
	links: {
		url?: string
		label?: string
	}[]
}

export function initLinkRow() {
	document.querySelectorAll<HTMLElement>('.sh-link-row, .block-link-row').forEach((element) => {
		const props: LinkRowProps = {
			links: Array.from(element.querySelectorAll<HTMLAnchorElement>('a')).map((link) => ({
				url: link.href ?? undefined,
				label: link.textContent ?? undefined,
			})),
		}

		render(<CardGrid {...props} />, element)
	})
}

function CardGrid({links}: LinkRowProps) {
	return (
		<div className="mt-6 flex justify-end gap-x-[32px]">
			{links.map((link) => (
				<a
					key={link.url}
					className="text-sm font-medium leading-5 text-blue-700 hover:underline"
					href={link.url}
				>
					{link.label}{' '}
					<Icon
						icon="arrow-forward"
						className="mb-[4px] inline h-4 w-4"
					/>
				</a>
			))}
		</div>
	)
}
