import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, ReactNode} from 'react'
import {Icon} from '~/blocks/Icon'

interface ModalProps {
	title: string
	children: ReactNode
	open: boolean
	close: () => void
}

export default function Modal(props: ModalProps) {
	return (
		<Transition.Root
			show={props.open}
			as={Fragment}
		>
			<Dialog
				as="div"
				className="relative z-[1000]"
				onClose={props.close}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>
				<div className="fixed inset-0 z-[1001] overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-[24px] text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[588px] sm:p-6">
								<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
									<button
										type="button"
										className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-50 hover:bg-gray-100"
										onClick={props.close}
									>
										<span className="sr-only">Close</span>
										<Icon
											icon="close"
											className="h-7 w-7"
											aria-hidden="true"
										/>
									</button>
								</div>
								{props.children}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
