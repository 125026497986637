import React from 'react'
import {cx} from '~/utils/utils'

export const ConditionalBackgroundColour = (props: {children: React.ReactNode; backgroundColour: string | undefined | null; classNames?: string}) => {
	if (props.backgroundColour == 'gray') {
		return (
			<div className="full-width-background before:bg-gray-50">
				<div className={cx('relative', props.classNames)}>{props.children}</div>
			</div>
		)
	}
	if (props.backgroundColour == 'navy') {
		return (
			<div className="full-width-background before:bg-uom-navy-900">
				<div className={cx('relative', props.classNames)}>{props.children}</div>
			</div>
		)
	}
	if (props.backgroundColour == 'teal') {
		return (
			<div className="full-width-background before:bg-teal-700">
				<div className={cx('relative', props.classNames)}>{props.children}</div>
			</div>
		)
	}
	if (props.backgroundColour == 'green') {
		return (
			<div className="full-width-background before:bg-green-700">
				<div className={cx('relative', props.classNames)}>{props.children}</div>
			</div>
		)
	}
	return <>{props.children}</>
}
