import React, {useEffect, useState} from 'react'
import {Listbox, Popover} from '@headlessui/react'
import {SearchBox} from '~/components/SearchBox'
import {GrantRecordCard} from '~/pages/grantRecords/GrantRecordCard'
import {ListGridViewButtons} from '~/pages/grantRecords/ListGridViewButtons'
import {SearchResultYear, searchResultYears, SearchResultFaculty, searchResultFaculties, useGrantRecordsStore} from '~/pages/grantRecords/useGrantRecordsStore'
import {render} from '~/utils/render'
import {cx, getUrlParam, removeUrlParam, setUrlParam} from '~/utils/utils'
import {LoadingAnimation} from '~/blocks/LoadingAnimation'
import {Icon} from '~/blocks/Icon'


interface GrantRecordsConfig {
	apiUrl: string
	faculties: Faculty[]
	grantYears: GrantYear[]
	pageLabels: {
		searchPlaceholder?: string
		foundRecordCountLabel?: string
		searchTermLabel?: string
		facultyFilterLabel?: string
		allFacultiesName?: string
		yearFilterLabel?: string
		allYearsName?: string
	}
}

interface GrantYear {
	id: string
	name: string
}

interface Faculty {
	id: string
	name: string
}

export const config: GrantRecordsConfig = (window as any).GrantRecordsConfig ?? {}

export const initGrantRecords = () => {
	document.querySelectorAll('#grant-records').forEach((element) => {
		render(<GrantRecordsPage />, element)
	})
}

const GrantRecordsPage = () => {
	const [searchTerm, setSearchTerm] = useState<string | null>(getUrlParam('search'))
	const [isGridView, setIsGridView] = useState(getUrlParam('view') === 'grid')

	const typeParam = new URLSearchParams(window.location.search).get('types') ?? ''
	const [selectedYearTypes, setSelectedYearTypes] = useState<SearchResultYear[]>(searchResultYears.filter((year) => typeParam.includes(year.displayName)) ?? [])
	const [selectedFacultyTypes, setSelectedFacultyTypes] = useState<SearchResultFaculty[]>(searchResultFaculties.filter((faculty) => typeParam.includes(faculty.shortDisplayName)) ?? [])

	const {isLoading, apiResponse} = useGrantRecordsStore(searchTerm, selectedFacultyTypes, selectedYearTypes)

	const hasResults = (apiResponse?.total_results != null && apiResponse.total_results > 0) ?? false

	function handleSearch(newTerm: string) {
		if (newTerm != null && newTerm.trim().length > 0) {
			setUrlParam('search', newTerm)
		} else {
			removeUrlParam('search')
		}
		setSearchTerm(newTerm)
	}

	function handleGridViewChange(newIsGridView: boolean) {
		setIsGridView(newIsGridView)
		if (newIsGridView) {
			setUrlParam('view', 'grid')
		} else {
			removeUrlParam('view')
		}
	}

	useEffect(() => {
		if (selectedYearTypes.length === 0) {
			removeUrlParam('year')
		} else {
			setUrlParam('year', selectedYearTypes.map((year) => year.displayName).join('+'))
		}
	}, [selectedYearTypes])

	useEffect(() => {
		if (selectedFacultyTypes.length === 0) {
			removeUrlParam('faculty')
		} else {
			setUrlParam('faculty', selectedFacultyTypes.map((faculty) => faculty.shortDisplayName).join('+'))
		}
	}, [selectedFacultyTypes])

	return (
		<div>

			<div className="mt-[24px] flex flex-col gap-[24px] md:grid md:grid-cols-3">

				<div className="w-full">
					<label className="mb-[16px] block text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">Search</label>
					<SearchBox
						handleSearch={handleSearch}
						initialValue={searchTerm ?? undefined}
						placeholder={config.pageLabels.searchPlaceholder}
					/>
				</div>		

				<div className="w-full">
					<label className="mb-[16px] block text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">{config.pageLabels.facultyFilterLabel}</label>

					<Listbox
						as="div"
						value={selectedFacultyTypes}
						onChange={setSelectedFacultyTypes}
						multiple
						className="relative flex flex-grow"
					>

						{/* z-10 flex w-full flex-col items-center gap-y-[16px] md:flex-row  md:rounded md:bg-white */}
						<Listbox.Button className="rounded-md border border-gray-500 bg-white py-2 pl-7 pr-0 ml-0 flex flex-grow items-center justify-between md:ml-0">
							{selectedFacultyTypes.length > 0 ? (
								<>
									<div className="hidden items-center gap-x-[8px] min-[610px]:flex md:hidden min-[3495px]:flex">
										{selectedFacultyTypes
											.map((faculty) => (
												<SearchFacultyBadge
													key={faculty.shortDisplayName}
													faculty={faculty}
													removeFaculty={(facultyName) => {
														setSelectedFacultyTypes(selectedFacultyTypes.filter((t) => t.shortDisplayName !== faculty.shortDisplayName))
													}}
												/>
											))}
									</div>
									<div className="hidden items-center gap-x-[8px] min-[541px]:flex min-[609px]:hidden min-[1100px]:flex min-[3495px]:hidden">
										{selectedFacultyTypes
											.sort((a, b) => a.shortDisplayName.localeCompare(b.shortDisplayName))
											.slice(0, 3)
											.map((faculty) => (
												<SearchFacultyBadge
													key={faculty.shortDisplayName}
													faculty={faculty}
													removeFaculty={(facultyName) => {
														setSelectedFacultyTypes(selectedFacultyTypes.filter((t) => t.shortDisplayName !== faculty.shortDisplayName))
													}}
												/>
											))}
										{selectedFacultyTypes.length > 3 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+{selectedFacultyTypes.length - 3} more</span>}
									</div>
									<div className="hidden items-center gap-x-[8px] min-[395px]:flex min-[540px]:hidden min-[860px]:flex min-[1100px]:hidden">
										{selectedFacultyTypes
											.sort((a, b) => a.shortDisplayName.localeCompare(b.shortDisplayName))
											.slice(0, 2)
											.map((faculty) => (
												<SearchFacultyBadge
													key={faculty.shortDisplayName}
													faculty={faculty}
													removeFaculty={(facultyName) => {
														setSelectedFacultyTypes(selectedFacultyTypes.filter((t) => t.shortDisplayName !== faculty.shortDisplayName))
													}}
												/>
											))}
										{selectedFacultyTypes.length > 2 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+{selectedFacultyTypes.length - 2} more</span>}
									</div>
									<div className="flex items-center gap-x-[8px]  min-[394px]:hidden md:flex min-[860px]:hidden">
										{selectedFacultyTypes
											.sort((a, b) => a.shortDisplayName.localeCompare(b.shortDisplayName))
											.slice(0, 1)
											.map((faculty) => (
												<SearchFacultyBadge
													key={faculty.shortDisplayName}
													faculty={faculty}
													removeFaculty={(facultyName) => {
														setSelectedFacultyTypes(selectedFacultyTypes.filter((t) => t.shortDisplayName !== faculty.shortDisplayName))
													}}
												/>
											))}
										{selectedFacultyTypes.length > 1 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+{selectedFacultyTypes.length - 1} more</span>}
									</div>
								</>
							) : (
								<div className="text-sm font-normal leading-5 text-gray-600">{config.pageLabels.allFacultiesName}</div>
							)}
							<span className="pointer-events-none flex items-center pr-[16px] md:pr-[36px]">
								<Icon
									icon="chevron-down"
									className="h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							</span>
						</Listbox.Button>
						<Listbox.Options
							as="div"
							className="absolute inset-x-0 bottom-[-136px] left-0 z-50 rounded bg-white py-[4px] shadow-lg md:mr-3"
						>
							{searchResultFaculties.map((faculty) => (
								<Listbox.Option
									key={faculty.shortDisplayName}
									as="div"
									value={faculty}
								>
									{({selected}) => (
										<div className="flex cursor-pointer items-center justify-between py-[8px] pl-[16px] pr-[12px] hover:bg-gray-100">
											<div className="flex items-center">
												<input
													type="checkbox"
													checked={selected}
													readOnly={true}
												/>
												<div className="pl-[12px] text-sm font-normal leading-5 text-gray-700">{faculty.displayName}</div>
											</div>
											<div
												className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
												style={{backgroundColor: faculty.bgColour, color: faculty.textColour}}
											>
												{faculty.shortDisplayName}
											</div>
										</div>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Listbox>
				</div>

				<div className="w-full">
					<label className="mb-[16px] block text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">{config.pageLabels.yearFilterLabel}</label>
					
					<Listbox
						as="div"
						value={selectedYearTypes}
						onChange={setSelectedYearTypes}
						multiple
						className="relative flex flex-grow"
					>
						<Listbox.Button className="rounded-md border border-gray-500 bg-white py-2 pl-7 pr-0 ml-0 flex flex-grow items-center justify-between md:ml-0">
							{selectedYearTypes.length > 0 ? (
								<>
									<div className="hidden items-center gap-x-[8px] min-[610px]:flex md:hidden min-[3495px]:flex">
										{selectedYearTypes
											//.sort((a, b) => a.displayName.localeCompare(b.displayName))
											.map((year) => (
												<SearchYearBadge
													key={year.displayName}
													year={year}
													removeYear={(yearName) => {
														setSelectedYearTypes(selectedYearTypes.filter((t) => t.displayName !== yearName))
													}}
												/>
											))}
									</div>
									<div className="hidden items-center gap-x-[8px] min-[541px]:flex min-[609px]:hidden min-[1100px]:flex min-[3495px]:hidden">
										{selectedYearTypes
											.sort((a, b) => a.displayName.localeCompare(b.displayName))
											.slice(0, 3)
											.map((year) => (
												<SearchYearBadge
													key={year.displayName}
													year={year}
													removeYear={(yearName) => {
														setSelectedYearTypes(selectedYearTypes.filter((t) => t.displayName !== yearName))
													}}
												/>
											))}
										{selectedYearTypes.length > 3 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+{selectedYearTypes.length - 3} more</span>}
									</div>
									<div className="hidden items-center gap-x-[8px] min-[395px]:flex min-[540px]:hidden min-[860px]:flex min-[1100px]:hidden">
										{selectedYearTypes
											.sort((a, b) => a.displayName.localeCompare(b.displayName))
											.slice(0, 2)
											.map((year) => (
												<SearchYearBadge
													key={year.displayName}
													year={year}
													removeYear={(yearName) => {
														setSelectedYearTypes(selectedYearTypes.filter((t) => t.displayName !== yearName))
													}}
												/>
											))}
										{selectedYearTypes.length > 2 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+{selectedYearTypes.length - 2} more</span>}
									</div>
									<div className="flex items-center gap-x-[8px]  min-[394px]:hidden md:flex min-[860px]:hidden">
										{selectedYearTypes
											.sort((a, b) => a.displayName.localeCompare(b.displayName))
											.slice(0, 1)
											.map((year) => (
												<SearchYearBadge
													key={year.displayName}
													year={year}
													removeYear={(yearName) => {
														setSelectedYearTypes(selectedYearTypes.filter((t) => t.displayName !== yearName))
													}}
												/>
											))}
										{selectedYearTypes.length > 1 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+{selectedYearTypes.length - 1} more</span>}
									</div>
								</>
							) : (
								<div className="text-sm font-normal leading-5 text-gray-600">{config.pageLabels.allYearsName}</div>
							)}
							<span className="pointer-events-none flex items-center pr-[16px] md:pr-[36px]">
								<Icon
									icon="chevron-down"
									className="h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							</span>
						</Listbox.Button>
						<Listbox.Options
							as="div"
							className="absolute inset-x-0 bottom-[-136px] left-0 z-50 rounded bg-white py-[4px]  shadow-lg md:mr-3"
						>
							{searchResultYears.map((year) => (
								<Listbox.Option
									key={year.displayName}
									as="div"
									value={year}
								>
									{({selected}) => (
										<div className="flex cursor-pointer items-center justify-between py-[8px] pl-[16px] pr-[12px] hover:bg-gray-100">
											<div className="flex items-center">
												<input
													type="checkbox"
													checked={selected}
													readOnly={true}
												/>
												<div className="pl-[12px] text-sm font-normal leading-5 text-gray-700">{year.displayName}</div>
											</div>
											<div
												className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
												style={{backgroundColor: year.bgColour, color: year.textColour}}
											>
												{year.displayName}
											</div>
										</div>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Listbox>
				</div>


			</div>

			<div className="flex justify-between pb-4 pt-4">
				
				<div className={cx(hasResults ? '' : '', 'mt-[24px] min-h-[16px] text-xs font-normal leading-4 text-gray-600')}>
					{apiResponse != null && config.pageLabels.foundRecordCountLabel?.replace('$record-count', apiResponse?.total_results.toString() ?? '')}
					{searchTerm != null && searchTerm.length > 0 && config.pageLabels.searchTermLabel?.replace('$search-term', searchTerm ?? '')}
				</div>

				<div>
					<ListGridViewButtons
						gridView={isGridView}
						setGridView={handleGridViewChange}
					/>
				</div>	

			</div>
			
			<div className="min-h-[400px]">
				{isLoading ? (
					<div className="mx-auto mt-[100px]">
						<LoadingAnimation />
					</div>
				) : apiResponse == null ? (
					<div className="mt-[100px] flex flex-col items-center text-center">
						<div className="mb-[18px] flex h-[50px] w-[50px] items-center justify-center rounded-full bg-gray-100">
							<Icon
								icon="search"
								className="mt-[4px] h-[22px] w-[22px] text-uom-navy-800"
							/>
						</div>
						<div className="text-gray-500">Find grant records using the search and filters above</div>
					</div>
				) : apiResponse?.total_results != null && apiResponse?.total_results <= 0 ? (
					<div className="mt-[100px] text-center text-gray-500">No results found</div>
				) : (
					<div className="mt-[24px] grid gap-[24px] sm:grid-cols-2 md:grid-cols-3">
						{apiResponse?.results.map((grantRecord, index) => (
							<GrantRecordCard
								key={index}
								grantRecord={grantRecord}
								isGridView={isGridView}
								className={cx(isGridView ? '' : 'col-span-2')}
								searchTerm={(searchTerm != null && searchTerm.length > 0)? searchTerm:''}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	)
}
export default GrantRecordsPage

function SearchFacultyBadge(props: {faculty: SearchResultFaculty; removeFaculty: (facultyName: string) => void}) {
	return (
		<div
			className="flex items-center rounded-md px-2 py-[2px] text-xs font-medium"
			style={{backgroundColor: props.faculty.bgColour, color: props.faculty.textColour}}
		>
			<div>{props.faculty.shortDisplayName}</div>
			<div
				onClick={(event) => {
					event.preventDefault()
					props.removeFaculty(props.faculty.displayName)
				}}
				className="remove-faculty-button ml-[2px] rounded p-[2px]"
				style={{'--hover-bg-colour': props.faculty.hoverBgColour} as React.CSSProperties}
			>
				<Icon
					icon="close"
					className="h-[12px] w-[12px]"
				/>
			</div>
		</div>
	)
}

function SearchYearBadge(props: {year: SearchResultYear; removeYear: (yearName: string) => void}) {
	return (
		<div
			className="flex items-center rounded-md px-2 py-[2px] text-xs font-medium"
			style={{backgroundColor: props.year.bgColour, color: props.year.textColour}}
		>
			<div>{props.year.displayName}</div>
			<div
				onClick={(event) => {
					event.preventDefault()
					props.removeYear(props.year.displayName)
				}}
				className="remove-year-button ml-[2px] rounded p-[2px]"
				style={{'--hover-bg-colour': props.year.hoverBgColour} as React.CSSProperties}
			>
				<Icon
					icon="close"
					className="h-[12px] w-[12px]"
				/>
			</div>
		</div>
	)
}