import type React from 'react'
import {ModalButton} from '~/components/ModalButton'
import {isUrlSameHost} from '~/utils/utils'

interface CardWrapperProps {
	className?: string
	modalContent?: string
	title?: string
	url?: string
	children?: React.ReactNode
	target?: string
}

export const CardWrapper = (props: CardWrapperProps) => {
	const className = props.className + ' block'
	const target = isUrlSameHost(props.url)? '' : '_blank'

	if (props.modalContent != null && props.modalContent.length > 0) {
		return (
			<ModalButton
				className={className}
				modalContent={props.modalContent}
				title={props.title}
			>
				{props.children}
			</ModalButton>
		)
	}

	if (props.url != null && props.url.length > 0) {
		return (
			<a
				href={props.url}
				className={className}
				target={target}
			>
				{props.children}
			</a>
		)
	}

	return <div className={className}>{props.children}</div>
}
