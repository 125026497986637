import React from 'react'
import {CopyToClipboardButton} from '~/components/CopyToClipboardButton'
import {SlideOverModal} from '~/components/SlideOverModal'
import type {GiftRecord} from '~/pages/giftGovernanceRecords/usesGiftGovernanceRecordsStore'
import {config} from '~/pages/giftGovernanceRecords/GiftGovernanceRecordsPage'

export function GiftRecordModal(props: {openModalGiftRecord: GiftRecord | null; close: () => void}) {
	return (
		<SlideOverModal
			isOpen={props.openModalGiftRecord != null}
			close={props.close}
		>
			<div className="border-t border-gray-200" />
			<div className="px-4 text-base leading-6 sm:px-6">
				<div className="mt-[24px] mb-[8px] flex justify-between">
					<div className="font-normal text-gray-900">{props.openModalGiftRecord?.gift_id}</div>
					<div>
						<CopyToClipboardButton
							value={window.location.href}
							label="Copy link"
						/>
					</div>
				</div>
				<div className="mb-[48px] text-3xl font-extrabold leading-8 tracking-tight text-gray-900">{props.openModalGiftRecord?.projectName}</div>
				<div className="font-semibold text-gray-900">{props.openModalGiftRecord?.year}</div>
				<div
					className="font-normal text-gray-600"
					dangerouslySetInnerHTML={{__html: props.openModalGiftRecord?.value ?? ''}}
				/>
				{props.openModalGiftRecord?.description != null && props.openModalGiftRecord?.description.length > 0 && (
					<div className="mt-[48px] font-semibold text-gray-900">{config.modalLabels.descriptionLabel}:</div>
				)}
				<div className="mt-[8px] font-normal text-gray-600">{props.openModalGiftRecord?.description}</div>
				{props.openModalGiftRecord?.recitalText != null && props.openModalGiftRecord.recitalText.length > 0 && (
					<>
						<div className="mt-[48px] font-semibold text-gray-900">{config.modalLabels.detailsLabel}:</div>
						<div
							className="mt-[8px] font-normal text-gray-600"
							dangerouslySetInnerHTML={{__html: props.openModalGiftRecord.recitalText}}
						/>
					</>
				)}
			</div>
		</SlideOverModal>
	)
}
