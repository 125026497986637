import React from 'react'
import {Icon} from '~/blocks/Icon'

interface SocialIconProps {
	name?: string
	className?: string
}

export const SocialIcon = ({name, className}: SocialIconProps) => (
	<>
		{name === 'facebook' && (
			<Icon
				icon="bi:facebook"
				className={className}
			/>
		)}
		{name === 'twitter' && (
			<Icon
				icon="bi:twitter-x"
				className={className}
			/>
		)}
		{name?.toLowerCase() === 'linkedin' && (
			<Icon
				icon="bi:linkedin"
				className={className}
			/>
		)}
		{name === 'instagram' && (
			<Icon
				icon="bi:instagram"
				className={className}
			/>
		)}
	</>
)
