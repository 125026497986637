import React, {useState} from 'react'
import {Icon} from '~/blocks/Icon'
import type {CardProps, CardTypes} from '~/cards/Card'
import {CardList} from '~/components/CardList'
import {CardMasonry} from '~/components/CardMasonry'
import {queryHref, querySrc, queryTextContent} from '~/utils/query'

import {render} from '~/utils/render'
import {cx, replaceWithNewDiv} from '~/utils/utils'

interface SearchResultsProps {
	recommendedResults?: CardProps[]
	allResults?: CardProps[]
}

export function initSearchResults() {
	document.querySelectorAll<HTMLHeadElement>('.search-result-group').forEach((element) => {
		function getCardData(result: HTMLDivElement): CardProps {
			let type: CardTypes = 'general'
			if (result.querySelector('img') != null) {
				type = 'image'
			}
			if (result.classList.contains('person')) {
				type = 'staff-profile'
			}

			return {
				type: type,
				url: queryHref('.url a', result) ?? queryHref('h3 a, h2 a', result),
				linkLabel: 'view',
				title: queryTextContent('h2,h3,h4,h5', result),
				body: queryTextContent(':scope > p', result),
				imageUrl: querySrc('img', result),
				position: queryTextContent('.position', result),
				departmentName: queryTextContent('.department', result),
				email: queryTextContent('.person__email', result),
				phone: queryTextContent('.person__phone', result),
				photoUrl: querySrc('.person__photo', result),
			}
		}

		const props: SearchResultsProps = {
			recommendedResults: Array.from(document.querySelectorAll<HTMLDivElement>('.search-results.search-results--promoted > li')).map((result) => getCardData(result)),
			allResults: Array.from(document.querySelectorAll<HTMLDivElement>('.search-results:not(.search-results--promoted) > li')).map((result) => getCardData(result)),
		}

		const div = replaceWithNewDiv(element)
		div.classList.add('doesnt-need-gap')

		render(<SearchResults {...props} />, div)
	})
}

const SearchResults = (props: SearchResultsProps) => {
	const [layout, setLayout] = useState('list')

	return (
		<div className="">
			<div className="flex items-center justify-between">
				<h2>Search results</h2>
				<div className="flex gap-x-[8px] text-xs font-medium leading-4">
					<button
						onClick={() => setLayout('grid')}
						className={cx(layout === 'grid' ? 'bg-blue-700 text-white' : 'border border-gray-500 text-gray-500', 'flex items-center gap-x-[12px] rounded px-[12px] py-[7px]')}
					>
						<Icon
							icon="grid"
							className="h-[12px] w-[12px]"
						/>
						<div>Grid</div>
					</button>
					<button
						onClick={() => setLayout('list')}
						className={cx(layout === 'list' ? 'bg-blue-700 text-white' : 'border border-gray-500 text-gray-500', 'flex items-center gap-x-[12px] rounded px-[12px] py-[7px]')}
					>
						<Icon
							icon="menu"
							className="h-[13px] w-[13px]"
						/>
						<div>List</div>
					</button>
				</div>
			</div>
			<h3>Recommended</h3>
			{layout === 'list' && <CardList cards={props.recommendedResults} />}
			{layout === 'grid' && <CardMasonry cards={props.recommendedResults} />}
			<h3>All Results</h3>
			{layout === 'list' && <CardList cards={props.allResults} />}
			{layout === 'grid' && <CardMasonry cards={props.allResults} />}
		</div>
	)
}
