import React, {useState} from 'react'
import Lightbox from 'yet-another-react-lightbox'
import {Captions, Fullscreen, Zoom} from 'yet-another-react-lightbox/plugins'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/styles.css'
import {queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx, replaceWithNewDiv} from '~/utils/utils'
import './Gallery.css'

interface Image {
	url?: string
	thumbnailUrl?: string
	width: number
	height: number
	alt?: string
	caption?: string
	inGalleryCaption?: string
}

export interface GalleryProps {
	size: 'small' | 'medium' | 'large'
	images: Image[]
}

export function initGallery() {
	Array.from(document.querySelectorAll<HTMLAnchorElement>('.image-gallery')).forEach(async (element) => {
		;(window as any).global = window

		const props: GalleryProps = {
			size: element.classList.contains('image-gallery--medium') ? 'medium' : element.classList.contains('image-gallery--small') ? 'small' : 'large',
			images: Array.from(element.querySelectorAll<HTMLAnchorElement>('li.item a')).map((link) => ({
				url: link.href,
				thumbnailUrl: link.querySelector<HTMLImageElement>('img')?.src,
				width: parseInt(link.getAttribute('data-size')?.split('x')[0] ?? '0'),
				height: parseInt(link.getAttribute('data-size')?.split('x')[1] ?? '0'),
				alt: link.querySelector<HTMLImageElement>('img')?.alt,
				caption: queryTextContent('figcaption', link),
				inGalleryCaption: queryTextContent('.image-gallery__caption', link),
			})),
		}

		render(<Gallery {...props} />, replaceWithNewDiv(element))
	})
}

export default function Gallery(props: GalleryProps) {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalCurrentIndex, setModalCurrentIndex] = useState(0)

	const openModal = (index: number) => {
		setIsModalOpen(true)
		setModalCurrentIndex(index)
	}

	return (
		<div>
			<GalleryGrid
				{...props}
				openModal={openModal}
			/>
			<GalleryLightBox
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				currentIndex={modalCurrentIndex}
				onSetCurrentIndex={(newIndex: number) => setModalCurrentIndex(newIndex)}
				images={props.images}
			/>
		</div>
	)
}

type GalleryGridProps = GalleryProps & {
	openModal: (index: number) => void
}

const GalleryGrid = (props: GalleryGridProps) => {
	return (
		<div
			className={cx(
				props.size === 'large' ? 'grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6' : '',
				props.size === 'medium' ? 'max-w-content grid-cols-2 md:grid-cols-4' : '',
				props.size === 'small' ? 'grid-cols-2 md:grid-cols-3' : '',
				'grid grid-flow-row-dense gap-[8px] p-0',
			)}
		>
			{props.images.map((image, index) => {
				const ratio = image.width / image.height
				return (
					<button
						onClick={() => props.openModal(index)}
						key={index}
						className={cx(
							ratio > 1 && ratio < 2 ? 'col-span-2' : '',
							ratio > 3 ? 'col-span-2 md:col-span-3' : '',
							'flex h-[260px] flex-col justify-end overflow-hidden rounded-md bg-cover bg-center p-0',
						)}
						style={{
							backgroundImage: `url(${image.thumbnailUrl})`,
						}}
					>
						<img
							alt={image.alt}
							src={image.thumbnailUrl}
							className="sr-only"
						/>
						{image.inGalleryCaption != null && image.inGalleryCaption.length > 0 && (
							<div className="bg-white/60 px-[16px] py-[8px] text-left text-sm font-normal leading-5 text-gray-900">{image.inGalleryCaption}</div>
						)}
					</button>
				)
			})}
		</div>
	)
}

interface GalleryLightBoxProps {
	onClose: () => void
	onSetCurrentIndex: (newIndex: number) => void
	currentIndex: number
	isOpen: boolean
	images: Image[]
}

function GalleryLightBox(props: GalleryLightBoxProps) {
	const {isOpen, onClose, images, currentIndex} = props
	return (
		<Lightbox
			open={isOpen}
			close={() => onClose()}
			index={currentIndex}
			slides={images.map((image) => ({src: image.url ?? '', alt: image.alt, width: image.width, height: image.height, description: image.caption}))}
			plugins={[Fullscreen, Captions, Zoom]}
		/>
	)
}
