import type React from 'react'
import {useEffect, useState} from 'react'
import {queryHref, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx} from '~/utils/utils'
import './Event.css'

interface ChecklistProps {
	id?: string
	listItems: {
		id: string
		label?: string
		defaultOn: boolean
	}[]
	continueButtonLabel?: string
	continueUrl?: string
}

export function initChecklist() {
	document.querySelectorAll<HTMLElement>('ul.checklist').forEach((element) => {
		if (element.parentElement?.parentElement?.tagName === 'FORM') {
			element.parentElement?.parentElement?.classList.add('checklist')
		}
	})

	document.querySelectorAll<HTMLElement>('form.checklist').forEach((element) => {
		const props: ChecklistProps = {
			id: element.id,
			listItems: Array.from(element.querySelectorAll<HTMLElement>('li')).map((li) => {
				const input = li.querySelector<HTMLInputElement>('input')
				return {
					id: input?.id || Math.random().toString(36).slice(2),
					label: input?.labels?.[0]?.innerText,
					defaultOn: input?.checked ?? false,
				}
			}),
			continueButtonLabel: queryTextContent('a.button', element),
			continueUrl: queryHref('a.button', element),
		}

		if (element.parentElement?.parentElement?.tagName === 'FORM') {
			render(<Checklist {...props} />, element.parentElement?.parentElement)
		}
		render(<Checklist {...props} />, element)
	})
}

export function Checklist(props: ChecklistProps) {
	const [checked, setChecked] = useState(props.listItems.reduce((accumulator, value) => ({...accumulator, [value.id]: value.defaultOn}), {} as Record<string, boolean>))

	async function handleChanged(id: string) {
		const currentValue = checked[id]
		const newCheckedState = {...checked, [id]: !currentValue}
		setChecked(newCheckedState)

		if (props.id != null && props.id.length > 0) {
			localStorage.setItem('checklist-' + props.id, JSON.stringify(newCheckedState))
		}
	}

	useEffect(() => {
		if (props.id != null && props.id.length > 0) {
			const localStorageValue = localStorage.getItem('checklist-' + props.id)
			if (localStorageValue != null) {
				setChecked(JSON.parse(localStorageValue))
			}
		}
	}, [setChecked])

	const allChecked = props.listItems.every((item) => checked[item.id])

	return (
		<div className="">
			<fieldset>
				<ul className="no-list flex flex-col divide-y">
					{props.listItems.map((listItem) => (
						<li
							key={listItem.id}
							className="flex gap-x-[16px] !py-[24px] !px-0 text-sm font-normal leading-5 text-gray-600"
						>
							<input
								className="border-gray-300"
								id={listItem.id}
								type="checkbox"
								checked={checked[listItem.id]}
								onChange={() => handleChanged(listItem.id)}
							/>
							<label
								className={cx(checked[listItem.id] ? 'line-through' : '')}
								htmlFor={listItem.id}
							>
								{listItem.label}
							</label>
						</li>
					))}
				</ul>
			</fieldset>
			{props.continueUrl != null && props.continueUrl?.length > 0 && (
				<section>
					<a
						className={cx(allChecked ? '' : 'disabled', 'button-xs !mt-[12px] ')}
						aria-disabled={!allChecked}
						href={allChecked ? props.continueUrl : undefined}
						id="continue"
					>
						{props.continueButtonLabel ?? 'Continue'}
					</a>
				</section>
			)}
		</div>
	)
}
