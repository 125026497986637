import {Listbox} from '@headlessui/react'
import React, {useEffect, useRef, useState} from 'react'
import Masonry from 'react-masonry-css'
import {Icon} from '~/blocks/Icon'
import {IconAttachedToLastWord} from '~/components/IconAttachedToLastWord'
import {Pagination} from '~/components/Pagination'
import {render} from '~/utils/render'
import {breadcrumbList, cx, isUrlSameHost, removeUrlParam, setUrlParam, urlHost, formatPhoneNumber} from '~/utils/utils'
import {GlobalSearchBox} from './GlobalSearchBox'
import './SearchPage.css'
import {Card} from '~/cards/Card'
import {SearchResultType, searchResultTypes, useSearch} from './useSearch'
import {StaffProfileCard, StaffProfileCardProps} from '~/cards/StaffProfileCard'
import {SlideOverModal} from '~/components/SlideOverModal'
import {useStaffProfile} from './useStaffProfile'

const RESULTS_PER_PAGE = 15

export interface SearchPageProps {
	heading: string
	subheading: string
	searchBoxPlaceholder: string
	typePlaceholder: string
	facultyPlaceholder: string
	noQueryMessage: string
	emptyMessage: string
	searchApiUrl?: string
}

export type CardTypes =
	| 'general'
	| 'form'
	| 'support-article'
	| 'supportArticle'
	| 'left-border'
	| 'logo'
	| 'icon'
	| 'link'
	| 'image'
	| 'staff-profile'
	| 'profile'
	| 'contact'
	| 'header-image'
	/* Types below are old and should be removed once they are no longer used on the site */
	| 'left-boarder'
	| 'link-with-left-boarder'
	| 'link-with-logo'
	| 'modal-button'

export type CardProps = StaffProfileCardProps

export interface CommonCardProps {
	type?: CardTypes
	url?: string
	displayUrl?: string
	title?: string
	body?: string
	linkLabel?: string
	autoLinkBody?: boolean
	modalContent?: string
	className?: string
	lineClamp?: boolean
	horizontalLayout?: boolean
	linkType?: string // link, email, phone
}

export const initSearchPage = () => {
	Array.from(document.querySelectorAll<HTMLElement>('.staff-ui-search')).forEach((element) => {
		const props: SearchPageProps = {
			heading: element.dataset.heading ?? '',
			subheading: element.dataset.subheading ?? '',
			searchBoxPlaceholder: element.dataset.searchBoxPlaceholder ?? '',
			typePlaceholder: element.dataset.typePlaceholder ?? '',
			facultyPlaceholder: element.dataset.facultyPlaceholder ?? '',
			noQueryMessage: element.dataset.noQueryMessage ?? '',
			emptyMessage: element.dataset.emptyMessage ?? '',
			searchApiUrl: element.dataset.searchApiUrl ?? '',
		}
		document.body.classList.add('navy-breadcrumbs')
		render(<SearchPage {...props} />, element)
	})

	if (breadcrumbList.length == 2 && breadcrumbList[1].href == '/people') {
		const personIdParam = new URLSearchParams(window.location.search).get('id')
		location.href = `/search?type=profile${personIdParam != null && personIdParam.length > 0 ? '&personId=' + Number(personIdParam) : ''}`
	}
}
export default function SearchPage(props: SearchPageProps) {
	// convert old &f.Type|form=Form url params to type=form for each type
	searchResultTypes.forEach((type) => {
		const paramName = 'f.Type|' + type.apiName
		if (new URLSearchParams(window.location.search).get(paramName) != null) {
			setUrlParam('type', type.apiName)
			removeUrlParam(paramName)
		}
	})

	const queryParam = new URLSearchParams(window.location.search).get('query') ?? ''
	const [query, setQuery] = useState(queryParam)

	const typeParam = new URLSearchParams(window.location.search).get('types') ?? ''	
	const [selectedTypes, setSelectedTypes] = useState<SearchResultType[]>(searchResultTypes.filter((type) => typeParam.includes(type.apiName)) ?? [])

	const pageParam = parseInt(new URLSearchParams(window.location.search).get('page') ?? '') || 1
	const [currentPage, setCurrentPage] = useState(pageParam)

	const layoutParam = new URLSearchParams(window.location.search).get('layout') ?? ''
	const [layout, setLayout] = useState(layoutParam === 'grid' ? 'grid' : 'list')

	const [inputValue, setInputValue] = useState(query)

	const topOfResultsRef = useRef<HTMLDivElement>(null)

	const startAt = (currentPage - 1) * RESULTS_PER_PAGE + 1

	const typeIsAll = true

	const {isLoading, results, featured, totalMatching, enabledArea} = useSearch(query, startAt, selectedTypes)
		

	useEffect(() => {
		changePage(1)
		if (!query.length) {
			removeUrlParam('query')
		} else {
			setUrlParam('query', query)
		}
	}, [query])

	useEffect(() => {
		if (selectedTypes.length === 0) {
			removeUrlParam('types')
			removeUrlParam('page')
			changePage(1)
		} else {
			setUrlParam('types', selectedTypes.map((type) => type.apiName).join('.'))
			removeUrlParam('page')
			changePage(1)
		}
	}, [selectedTypes])

	useEffect(() => {
		if (layout === 'list') {
			removeUrlParam('layout')
		} else {
			setUrlParam('layout', 'grid')
		}
	}, [layout])

	function changePage(newPage: number) {
		setCurrentPage(newPage)
		if (newPage < 2) {
			removeUrlParam('page')
		} else {
			setUrlParam('page', newPage.toString())
		}
	}

	function changePageAndScroll(newPage: number) {
		changePage(newPage)
		topOfResultsRef.current?.scrollIntoView()
	}

	function clear() {
		setQuery('')
		setSelectedTypes([])
	}

	return (
		<div>
			<div className="relative">
				<div className="full-width-background   pb-[48px] before:bg-uom-navy-900">
					<h1 className=" block-uplift relative pt-[16px] text-3xl font-extrabold leading-8 tracking-tight text-white">{props.heading}</h1>
					<div className="relative pb-[48px] pt-[16px] text-lg font-normal leading-6 text-white">{props.subheading}</div>
					{/* Search input */}
					<div className="relative">
						<div className=" z-10 flex w-full flex-col items-center gap-y-[16px] md:flex-row  md:rounded md:bg-white">
							<div className="w-full flex-shrink-0  border-gray-600 md:w-[40%] md:border-r">
								<GlobalSearchBox
									query={query}
									onNewQuery={setQuery}
									mainSearchPage={true}
									onNewInputValue={setInputValue}
								/>
							</div>
							<div className="--pl-[8px] flex w-full flex-grow rounded-md bg-white">
								<Listbox
									as="div"
									value={selectedTypes}
									onChange={setSelectedTypes}
									multiple
									className="relative flex flex-grow"
								>
									<Listbox.Button className="ml-[16px] flex flex-grow items-center justify-between py-[12px] md:ml-[24px] md:py-[14px]">
										{selectedTypes.length > 0 ? (
											<>
												<div className="hidden items-center gap-x-[8px] min-[610px]:flex md:hidden min-[1210px]:flex">
													{selectedTypes
														//.sort((a, b) => a.displayName.localeCompare(b.displayName))
														.map((type) => (
															<SearchTypeBadge
																key={type.apiName}
																type={type}
																removeType={(typeApiName) => {
																	setSelectedTypes(selectedTypes.filter((t) => t.apiName !== typeApiName))
																}}
															/>
														))}
												</div>
												<div className="hidden items-center gap-x-[8px] min-[541px]:flex min-[609px]:hidden min-[1100px]:flex min-[1210px]:hidden">
													{selectedTypes
														.sort((a, b) => a.apiName.localeCompare(b.apiName))
														.slice(0, 3)
														.map((type) => (
															<SearchTypeBadge
																key={type.apiName}
																type={type}
																removeType={(typeApiName) => {
																	setSelectedTypes(selectedTypes.filter((t) => t.apiName !== typeApiName))
																}}
															/>
														))}
													{selectedTypes.length > 3 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+1 more</span>}
												</div>
												<div className="hidden items-center gap-x-[8px] min-[395px]:flex min-[540px]:hidden min-[860px]:flex min-[1100px]:hidden">
													{selectedTypes
														.sort((a, b) => a.apiName.localeCompare(b.apiName))
														.slice(0, 2)
														.map((type) => (
															<SearchTypeBadge
																key={type.apiName}
																type={type}
																removeType={(typeApiName) => {
																	setSelectedTypes(selectedTypes.filter((t) => t.apiName !== typeApiName))
																}}
															/>
														))}
													{selectedTypes.length > 2 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+{selectedTypes.length - 2} more</span>}
												</div>
												<div className="flex items-center gap-x-[8px]  min-[394px]:hidden md:flex min-[860px]:hidden">
													{selectedTypes
														.sort((a, b) => a.apiName.localeCompare(b.apiName))
														.slice(0, 1)
														.map((type) => (
															<SearchTypeBadge
																key={type.apiName}
																type={type}
																removeType={(typeApiName) => {
																	setSelectedTypes(selectedTypes.filter((t) => t.apiName !== typeApiName))
																}}
															/>
														))}
													{selectedTypes.length > 1 && <span className="pl-1.5 text-sm font-medium leading-5 text-gray-600">+{selectedTypes.length - 1} more</span>}
												</div>
											</>
										) : (
											<div className="text-sm font-normal leading-5 text-gray-600">{props.typePlaceholder}</div>
										)}
										<span className="pointer-events-none flex items-center pr-[16px] md:pr-[36px]">
											<Icon
												icon="chevron-down"
												className="h-5 w-5 text-gray-400"
												aria-hidden="true"
											/>
										</span>
									</Listbox.Button>											
									<Listbox.Options
										as="div"
										className="absolute inset-x-0 bottom-[-436px] left-0 z-50 rounded bg-white py-[4px]  shadow-lg md:mr-3"
									>											
										{searchResultTypes.map((type) => ( 

											<Listbox.Option
												key={type.apiName}
												as="div"
												value={type}
												disabled={((type.area != enabledArea) && (enabledArea != 'allAreas')) && true}
											>
												{({selected}) => (
													<>
													{((type.shortDisplayName == 'All') || (type.area == 'areaRG')) && <div className="pl-[12px] text-sm font-normal leading-5 text-gray-700 border-t border-slate-400/20 px-3.5 pt-0 w-full"></div>}
													{((type.area == enabledArea) || (enabledArea == 'allAreas'))?
													<>
													<div className="flex cursor-pointer items-center justify-between py-[8px] pl-[16px] pr-[12px] hover:bg-gray-100 align-baseline">
														<div className="flex items-center">
															{((type.area == 'areaFaculty') && (type.shortDisplayName != 'All')) && <div className='w-6'></div>}<input
																	type="checkbox"
																	checked={selected}
																	readOnly={true}
																/>
															<div className="pl-[12px] text-sm font-normal leading-5 text-gray-700">{type.displayName}</div>
														</div>															
														<div className="flex items-center rounded-md px-2 py-1 text-xs font-medium"	style={{backgroundColor: type.bgColour, color: type.textColour}}>
																{type.area != 'areaFaculty' && <span>{type.displayName}</span>}
																{((type.area == 'areaFaculty') && (type.shortDisplayName != 'All')) && <span>{type.shortDisplayName}</span>}
														</div>
													</div>
													</>
													:
													<>
													<div className="flex cursor-not-allowed items-center justify-between py-[8px] pl-[16px] pr-[12px] hover:bg-gray-50 align-baseline">
														<div className="flex items-center">
															{((type.area == 'areaFaculty') && (type.shortDisplayName != 'All')) && <div className='w-6'></div>}<input
																	type="checkbox"
																	checked={selected}
																	readOnly={true}
																/>
															<div className="pl-[12px] text-sm font-normal leading-5 text-gray-200">{type.displayName}</div>
														</div>															
														<div className="flex items-center rounded-md px-2 py-1 text-xs font-medium"	style={{backgroundColor: type.bgColour, color: type.textColour}}>
														{type.area != 'areaFaculty' && <span>{type.displayName}</span>}
																{((type.area == 'areaFaculty') && (type.shortDisplayName != 'All')) && <span>{type.shortDisplayName}</span>}
														</div>
													</div>
													</>
													}
													</>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Listbox>
							</div>
							<button
								className="mx-[12px] w-full rounded-md bg-white px-[18px] py-[9px] text-base font-medium leading-6 text-uom-navy-900 hover:bg-gray-50 md:my-[9px] md:w-auto md:bg-uom-navy-900 md:text-white md:hover:bg-uom-navy-dark"
								onClick={() => setQuery(inputValue)}
							>
								Search
							</button>
							
						</div>
					</div>
					
				</div>
				{/* Results */}
				<h2 className="block-uplift sr-only">Results</h2>
				<div
					ref={topOfResultsRef}
					className="min-h-[240px] w-full pt-[24px]"
				>
					{isLoading && (
						<svg
							className="mx-auto mt-[100px] h-8 w-8 animate-spin text-gray-800"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle
								className="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								strokeWidth="4"
							/>
							<path
								className="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							/>
						</svg>
					)}
					{!isLoading && query.length <= 0 && (
						<div className="">
							<div className="mx-auto mt-[64px] flex h-[64px] w-[64px] items-center justify-center rounded-full bg-gray-100">
								<Icon
									icon="search"
									className="mx-auto h-[24px] w-[24px] text-uom-navy-900"
								/>
							</div>
							<div className="mt-[24px] w-full text-center text-base font-normal leading-6 text-gray-600">{props.noQueryMessage}</div>
						</div>
					)}
					{!isLoading && totalMatching != null && query.length > 0 && (
						<div>
							<div className="flex justify-between pb-8">
								<div className="text-xs font-normal  leading-4">
									{totalMatching ?? 0} results found for the filters applied
								</div>
								<div className="hidden text-sm font-medium leading-5 text-gray-700 md:flex">
									<button
										onClick={() => setLayout('list')}
										className={cx(layout === 'list' ? 'bg-blue-700 text-white' : 'border border-gray-500 text-gray-500', 'flex items-center gap-x-[8px] rounded-l px-[12px] py-[7px]')}
									>
										<Icon
											icon="menu"
											className="h-[17px]"
										/>
										<div>List</div>
									</button>
									<button
										onClick={() => setLayout('grid')}
										className={cx(layout === 'grid' ? 'bg-blue-700 text-white' : 'border border-gray-500 text-gray-500', 'flex items-center gap-x-[8px] rounded-r px-[12px] py-[7px]')}
									>
										<Icon
											icon="grid"
											className="h-[16px]"
										/>
										<div>Grid</div>
									</button>
								</div>
							</div>
							{!isLoading && (
								<>
									<div className={cx(layout !== 'list' && 'md:hidden', 'flex flex-col gap-y-[32px]')}>
									{typeIsAll &&
										featured?.map((featuredResult) => (
											<div
												key={featuredResult.url + (featuredResult.title ?? '')}
												className="pb-1"
											>
												<ListResult
												key={featuredResult.url + (featuredResult.title ?? '')}
												{...featuredResult}
												/>
											</div>
										))}
										{results.map((result, index) => (
											(() => {
												if(result.type === 'profile') {
													return (
														<ListResultPerson
														key={index}
														result={result}
													/>
													)
												} else if (result.title != 'excludeRGResults') {
													return (
														<ListResult
														key={index}
														{...result}
													/>
													)
												}
											})()  
										)
										)}
									</div>
									<Masonry
										breakpointCols={{
											default: 3,
											768: 2,
											640: 1,
										}}
										className={cx(layout === 'grid' && 'md:flex', 'card-masonry hidden')}
										columnClassName="card-masonry-column"
									>
										{typeIsAll &&
										featured?.map((featuredResult) => (
											<div
												key={featuredResult.url + (featuredResult.title ?? '')}
												className="pb-1"
											>
												<GridResult
												key={featuredResult.url + (featuredResult.title ?? '')}
												{...featuredResult}
												/>
											</div>
										))}
										{results.map((result, index) =>(
											(() => {
												if(result.type === 'profile') {
													return (
														<GridResultPerson
														key={index}
														result={result}
													/>
													)
												} else if (result.title != 'excludeRGResults') {
													return (
														<GridResult
														key={index}
														{...result}
													/>
													)
												}
											})()  
										)
										)}
									</Masonry>
								</>
							)}
							{totalMatching > RESULTS_PER_PAGE && (
								<Pagination
									currentPage={currentPage}
									goToPage={changePageAndScroll}
									numberOfPages={Math.ceil(totalMatching / RESULTS_PER_PAGE)}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

function SearchTypeBadge(props: {type: SearchResultType; removeType: (typeApiName: string) => void}) {
	return ( 
		<div
			className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
			style={{backgroundColor: props.type.bgColour, color: props.type.textColour}}
		>
			<div>{props.type.shortDisplayName} {props.type.shortDisplayName == 'All' && 'intranets'}</div>
			<div
				onClick={(event) => {
					event.preventDefault()
					props.removeType(props.type.apiName)
				}}
				className="remove-type-button ml-[2px] rounded p-[2px]"
				style={{'--hover-bg-colour': props.type.hoverBgColour} as React.CSSProperties}
			>
				<Icon
					icon="close"
					className="h-[12px] w-[12px]"
				/>
			</div>
		</div>
	)
}

function getAreas (url: string) {
	let strSearch = ''
	let areaFaculty = ''
	let areaRG = ''
	let aAreaTypes: { [key: string]: string } = {
		abp: "ABP intranet",
		arts: "Arts intranet",
		fbe: "FBE intranet",
		engineering: "FEIT intranet",
		finearts: "FFAM intranet",
		mls: "MLS intranet",
		mdhs: "MDHS intranet",
		science: "Science intranet",
	};
	for (let key in aAreaTypes) {
		if (aAreaTypes.hasOwnProperty(key)) {
			if (areaFaculty == '') {
				strSearch = '//staff.unimelb.edu.au/' + key
				areaFaculty = (url.search(strSearch) > 0) ? aAreaTypes[key] : ''
			}
		}
	}
	areaRG = (url.search('//gateway.research.unimelb.edu.au') > 0) ? 'areaRG' : ''
	return (
		[areaFaculty, areaRG]
	)
}

function ListResult(props: CardProps) {
	const type = searchResultTypes.find((type) => type.apiName === props.type)
	let [areaFaculty, areaRG] = (typeof props.displayUrl !== 'undefined')? getAreas(props.displayUrl):''
	return (
		<a
			href={props.url}
			className="max-w-content no-underline hover:no-underline"
		>
			<div className="flex items-center gap-x-[16px] text-blue-700">
				<div className="text-lg  underline">
					{isUrlSameHost(props.displayUrl ?? '') ? (
						props.title
					) : (
						<IconAttachedToLastWord
							text={props.title ?? ''}
							icon="external-link"
							className="ml-[16px] inline h-[20px] "
						/>
					)}
				</div>
			</div>
			<div className="mt-[12px] flex gap-x-[8px]">
				{
					(() => {
						if (props.type == 'feature') {
							return (
								<div
								className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
								style={{backgroundColor: '#256576', color: '#EDF7F9'}}
								>
									Featured result
								</div>
							)
						} else if (areaFaculty?.length > 0) {
							return (
								<div
								className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
								style={{backgroundColor: '#eee', color: '#333'}}
								>
								{areaFaculty}
								</div>
							)
						} else if (areaRG === 'areaRG') {
							return (
								<div
								className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
								style={{backgroundColor: '#E4F2EA', color: '#142A1D'}}
								>
								Research Gateway pages
								</div>
							)
						} else {
							return (
								<div
									className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
									style={{backgroundColor: type?.bgColour, color: type?.textColour}}
								>
									{type?.shortDisplayName}
								</div>
							)
						}
					})()  
				}  
			</div>
			<div
				className="mt-[16px] text-sm font-normal leading-5 text-gray-600"
				dangerouslySetInnerHTML={{__html: props.body ?? ''}}
			/>
		</a>
	)
}

function ListResultPerson(props: {result: CardProps}) {

	const personIdParam = new URLSearchParams(window.location.search).get('personId')
	const typeParam = new URLSearchParams(window.location.search).get('type') ?? ''
	const [personId, setPersonId] = useState(personIdParam ?? undefined)
	const [currentType, setCurrentType] = useState(searchResultTypes.find((type) => type.apiName === typeParam) ?? searchResultTypes[0])
	const typeIsAll = true 
	//const profilePictureUrl = 'https://pictures.staff.unimelb.edu.au/squiz/index_eis.php?id='

	function changePersonId(newPersonId?: string) {
		setPersonId(newPersonId)
		if (newPersonId == null) {
			removeUrlParam('personId')
		} else {
			//setUrlParam('personId', newPersonId.trim())
			setUrlParam('personId', decodeURIComponent(newPersonId.trim()))
		}
	}

	return (
		<div
			key={props.result.url}
			className="pb-1"
		>

			<Card
				{...props.result}
				lineClamp={!typeIsAll || props.result.type == 'support-article'}
				listLayout={true}
				showPersonModal={changePersonId}
			/>

			<StaffProfileModal
				personId={personId}
				showPersonModal={changePersonId}
			/>
										
		</div>
	)
}

function GridResult(props: CardProps) {
	const type = searchResultTypes.find((type) => type.apiName === props.type)
	let [areaFaculty, areaRG] = (typeof props.displayUrl !== 'undefined')? getAreas(props.displayUrl):''
	return (
		<a
			href={props.url}
			className="flex max-w-content flex-col justify-start rounded-[8px] p-[24px] no-underline shadow hover:no-underline"
		>
			<div className=" flex justify-between gap-x-[8px] ">
				<div className="flex flex-wrap gap-[8px]">
					{
						(() => {
							if (props.type == 'feature') {
								return (
									<div
									className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
									style={{backgroundColor: '#256576', color: '#EDF7F9'}}
									>
										Featured result
									</div>
								)
							} else if (areaFaculty?.length > 0) {
								return (
									<div
									className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
									style={{backgroundColor: '#eee', color: '#333'}}
								>
									{areaFaculty}
								</div>
								)
							} else if (areaRG === 'areaRG') {
								return (
									<div
									className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
									style={{backgroundColor: '#E4F2EA', color: '#142A1D'}}
								>
									Research Gateway pages
								</div>
								)
							} else {
								return (
									<div
										className="flex items-center rounded-md px-2 py-1 text-xs font-medium"
										style={{backgroundColor: type?.bgColour, color: type?.textColour}}
									>
										{type?.shortDisplayName}
									</div>
								)
							}
						})()  
					}  
				</div>
				{!isUrlSameHost(props.displayUrl ?? '') && (
					<Icon
						icon="external-link"
						className="h-[20px] flex-shrink-0"
					/>
				)}
			</div>
			<div className="mt-[16px] flex items-center justify-between gap-x-[16px] text-blue-700">
				<div className="text-lg  underline">{props.title}</div>
			</div>
			<div
				className="mt-[16px] text-sm font-normal leading-5 text-gray-600"
				dangerouslySetInnerHTML={{__html: props.body ?? ''}}
			/>
		</a>
	)
}



function GridResultPerson(props: {result: CardProps}) {

	const personIdParam = new URLSearchParams(window.location.search).get('personId')
	const typeParam = new URLSearchParams(window.location.search).get('type') ?? ''
	const [personId, setPersonId] = useState(personIdParam ?? undefined)
	const [currentType, setCurrentType] = useState(searchResultTypes.find((type) => type.apiName === typeParam) ?? searchResultTypes[0])
	const typeIsAll = true

	function changePersonId(newPersonId?: string) {
		setPersonId(newPersonId)
		if (newPersonId == null) {
			removeUrlParam('personId')
		} else {
			setUrlParam('personId', newPersonId.trim())
		}
	}

	return (

		<div
			key={props.result.url}
		>

			<Card
				{...props.result}
				lineClamp={!typeIsAll || props.result.type == 'support-article'}
				listLayout={false}
				showPersonModal={changePersonId}
			/>

			<StaffProfileModal
				personId={personId}
				showPersonModal={changePersonId}
			/>
										
		</div>

	)
}

const staffDetailsLightApiUrl = 'https://gateway.research.unimelb.edu.au/_webservices/_web-services/staff-details-api'
//https://staff.unimelb.edu.au/staff-hub/_designs/people-search-support-assets/staff-details-api?id=884

interface Props {
	personId?: string
	showPersonModal: (personId?: string) => void
}
	
export function StaffProfileModal(props: Props) {

	const {isLoading, profile, supervisor, reports, colleagues} = useStaffProfile(props.personId)

	return (
		<SlideOverModal
			isOpen={props.personId != null && props.personId.length > 0}
			close={() => props.showPersonModal(undefined)}
			fullScreenOnMobile={true}
		>
		{isLoading ? (
			<svg
				className="mx-auto mt-[25%] h-10 w-10 animate-spin text-gray-800"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
			>
				<circle
					className="opacity-25"
					cx="12"
					cy="12"
					r="10"
					stroke="currentColor"
					strokeWidth="4"
				/>
				<path
					className="opacity-75"
					fill="currentColor"
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
				/>
			</svg>
		) : (
			<>
				<div className="border-b border-gray-200 px-[24px] py-[36px] md:border-y md:py-[48px]">
					<StaffProfileCard
						{...profile}
						borderLessLarge={true}
					/>
				</div>
				{supervisor != null && (
					<section className="border-b border-gray-200 px-[24px] py-[48px]">
						<h3 className="!mt-0 !pb-[24px]">Supervisor</h3>
						<StaffProfileCard
							{...supervisor}
							borderLessSmall={true}
							showPersonModal={props.showPersonModal}
						/>
					</section>
				)}
				{reports != null && reports.length > 0 && (
					<section className="px-[24px] py-[48px]">
						<h3 className="!mt-0 !pb-[24px]">Direct reports ({reports.length})</h3>
						<div className="flex flex-col gap-y-[48px]">
							{reports?.map((report, index) => (
								<StaffProfileCard
									key={index}
									{...report}
									borderLessSmall={true}
									showPersonModal={props.showPersonModal}
								/>
							))}
						</div>
					</section>
				)}
				{colleagues != null && colleagues.length > 0 && (
					<section className="px-[24px] py-[48px]">
						<h3 className="!mt-0 !pb-[24px]">Colleagues ({colleagues.length})</h3>
						<div className="flex flex-col gap-y-[48px]">
							{colleagues?.map((colleague, index) => (
								<StaffProfileCard
									key={index}
									{...colleague}
									borderLessSmall={true}
									showPersonModal={props.showPersonModal}
								/>
							))}
						</div>
					</section>
				)}
			</>
		)}
	</SlideOverModal>
	)

}
