import React from 'react'

export const UomLogo = ({className}: {className: string}) => (
	<svg
		className={className}
		version="1.1"
		id="Logo"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 116.22 116.22"
		enableBackground="new 0 0 116.22 116.22"
		xmlSpace="preserve"
		aria-label="University of Melbourne logo"
	>
		<g>
			<rect
				x="0"
				y="0"
				fill="#094183"
				width="116.22"
				height="116.22"
			/>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path
									fill="#FFFFFF"
									d="M12.951,77.253c-0.011,0.033-0.195,0.809-0.195,0.909c0,0.102,0.057,0.138,0.106,0.138
							c0.087,0,0.123-0.071,0.143-0.125c-0.001,0.002,0.136-0.247,0.136-0.247c0.118-0.176,0.297-0.229,0.802-0.238
							c0,0,0.921-0.013,1.171-0.016c0,0.125,0,3.475,0,3.475c0,0.783,0,1.46-0.04,1.806c-0.035,0.249-0.074,0.381-0.193,0.406
							c-0.002,0.001-0.238,0.032-0.238,0.032c-0.089,0-0.129,0.057-0.129,0.114c0,0.13,0.148,0.13,0.197,0.13l0.684-0.02
							l0.136-0.005l0.097,0.003l0.931,0.022c0.179,0,0.198-0.091,0.198-0.13c0-0.055-0.041-0.114-0.13-0.114l-0.367-0.032
							c-0.199-0.028-0.247-0.157-0.271-0.404c-0.043-0.348-0.043-1.024-0.043-1.807c0,0,0-3.35,0-3.475
							c0.114,0.002,0.989,0.016,0.989,0.016c0.805,0.017,0.94,0.214,0.966,0.406l0.008,0.089c0.006,0.08,0.017,0.2,0.131,0.2
							c0.02,0,0.123-0.008,0.123-0.172l0.008-0.267l0.017-0.652c0-0.05,0-0.166-0.106-0.166l-0.099,0.016
							c-0.118,0.024-0.339,0.069-0.728,0.069h-3.137l-0.653-0.041c-0.002,0-0.27-0.07-0.27-0.07l-0.105-0.031
							C13.012,77.07,12.981,77.163,12.951,77.253z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M24.211,77.207l-0.135,0.005l-0.108-0.004l-0.838-0.021c-0.049,0-0.198,0-0.198,0.131
							c0,0.066,0.056,0.114,0.131,0.114l0.271,0.022c0.227,0.05,0.294,0.14,0.31,0.42l0.016,1.802v0.176l-3.532-0.015l0.013-0.161
							l0.017-1.802c0.016-0.284,0.079-0.386,0.259-0.419l0,0l0.197-0.023c0.076,0,0.131-0.048,0.131-0.114
							c0-0.087-0.066-0.131-0.198-0.131l-0.682,0.02l-0.138,0.005l-0.11-0.004l-0.836-0.021c-0.131,0-0.197,0.044-0.197,0.131
							c0,0.065,0.056,0.114,0.13,0.114l0.269,0.022c0.229,0.05,0.296,0.14,0.31,0.42c0,0.001,0.018,1.803,0.018,1.803v1.473
							c0,0.783,0,1.459-0.041,1.806c-0.035,0.249-0.074,0.381-0.192,0.406c-0.002,0.001-0.238,0.032-0.238,0.032
							c-0.09,0-0.131,0.057-0.131,0.114c0,0.13,0.149,0.13,0.198,0.13l0.681-0.02l0.137-0.005l0.098,0.004l0.931,0.021
							c0.177,0,0.196-0.091,0.196-0.13c0-0.057-0.04-0.114-0.129-0.114l-0.369-0.032c-0.197-0.028-0.246-0.157-0.271-0.404
							c-0.043-0.348-0.043-1.024-0.043-1.807v-0.879l3.534,0.014l-0.015,0.865c0,0.783,0,1.459-0.042,1.806
							c-0.032,0.244-0.072,0.381-0.191,0.406c-0.002,0.001-0.237,0.032-0.237,0.032c-0.091,0-0.132,0.059-0.132,0.114
							c0,0.13,0.149,0.13,0.198,0.13l0.685-0.02l0.135-0.005l0.095,0.003l0.933,0.022c0.18,0,0.199-0.091,0.199-0.13
							c0-0.055-0.041-0.114-0.131-0.114l-0.367-0.032c-0.199-0.028-0.248-0.157-0.273-0.404c-0.04-0.347-0.04-1.024-0.04-1.807
							v-1.473l0.016-1.802c0.018-0.287,0.078-0.385,0.258-0.419l0,0l0.196-0.023c0.077,0,0.132-0.048,0.132-0.114
							c0-0.131-0.149-0.131-0.198-0.131L24.211,77.207z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M29.554,77.148l-0.04,0.016l-0.347,0.041c0.001,0-1.912,0.007-1.912,0.007l-0.113-0.004l-0.832-0.021
							c-0.049,0-0.198,0-0.198,0.131c0,0.066,0.054,0.114,0.13,0.114l0.269,0.022c0.229,0.05,0.295,0.14,0.311,0.42l0.017,1.802
							v1.473c0,0.783,0,1.459-0.041,1.806c-0.035,0.25-0.074,0.381-0.193,0.406c-0.002,0.001-0.238,0.032-0.238,0.032
							c-0.089,0-0.129,0.057-0.129,0.114c0,0.13,0.147,0.13,0.196,0.13l0.68-0.02l0.14-0.005l0.318,0.011l1.748,0.039
							c0.33,0,0.441,0,0.512-0.263c0.042-0.177,0.112-0.752,0.112-0.828c0-0.061,0-0.189-0.116-0.189
							c-0.106,0-0.125,0.116-0.135,0.178c-0.056,0.323-0.148,0.473-0.34,0.555C29.149,83.2,28.79,83.2,28.576,83.2
							c-0.81,0-0.885-0.102-0.906-0.625l-0.001-1.059l0.001-0.368v-0.736c0,0.004,1.184,0.046,1.184,0.046
							c0.284,0.029,0.351,0.14,0.379,0.246c0.001,0.001,0.031,0.26,0.031,0.26c0,0.081,0.051,0.137,0.123,0.137
							c0.123,0,0.123-0.149,0.123-0.22l0.033-0.53l0.066-0.475c0-0.121-0.08-0.13-0.104-0.13l-0.169,0.123
							c-0.063,0.071-0.162,0.086-0.356,0.103l-1.324,0.016l0.014-2.328c0.1-0.019,0.989-0.012,1.123,0.004
							c0.458,0.05,0.502,0.15,0.544,0.246c-0.001-0.001,0.045,0.276,0.045,0.276c0,0.155,0.092,0.171,0.131,0.171
							c0.042,0,0.097-0.025,0.122-0.143c0.001-0.003,0.032-0.426,0.032-0.426l0.009-0.134c0,0.001,0.05-0.285,0.05-0.285
							l0.019-0.112c0-0.13-0.075-0.14-0.098-0.14L29.554,77.148z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M39.09,77.206l-0.142,0.006l-0.117-0.004l-0.771-0.021c-0.048,0-0.196,0-0.196,0.131
							c0,0.065,0.056,0.114,0.13,0.114l0.27,0.022c0.245,0.053,0.294,0.157,0.311,0.42c0,0.002,0.016,1.802,0.016,1.802v1.046
							c0,0.999-0.14,1.6-0.47,2.009c-0.299,0.357-0.783,0.579-1.263,0.579c-0.475,0-0.826-0.072-1.22-0.443
							c-0.3-0.286-0.607-0.75-0.607-2.019v-1.17l0.016-1.802c0.017-0.291,0.08-0.393,0.255-0.419c0.001,0,0.199-0.023,0.199-0.023
							c0.076,0,0.132-0.048,0.132-0.114c0-0.131-0.148-0.131-0.197-0.131l-0.622,0.018l-0.208,0.007l-0.173-0.006l-0.789-0.019
							c-0.049,0-0.197,0-0.197,0.131c0,0.065,0.056,0.114,0.131,0.114l0.27,0.022c0.228,0.05,0.294,0.14,0.309,0.42l0.017,1.803
							v1.221c0,1.352,0.399,1.912,0.748,2.247c0.567,0.532,1.349,0.612,1.766,0.612c0.451,0,1.124-0.066,1.766-0.636
							c0.701-0.623,0.791-1.689,0.791-2.547v-0.896l0.016-1.802c0.016-0.287,0.076-0.385,0.258-0.419
							c0.003-0.001,0.197-0.023,0.197-0.023c0.076,0,0.13-0.048,0.13-0.114c0-0.131-0.148-0.131-0.197-0.131L39.09,77.206z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M41.333,77.551l-0.008,4.793c0,0.749-0.042,0.955-0.205,1.002c-0.002,0-0.325,0.046-0.325,0.046
							c-0.09,0-0.13,0.057-0.13,0.114c0,0.13,0.138,0.13,0.197,0.13l0.666-0.019l0.137-0.006l0.122,0.005l0.763,0.02
							c0.059,0,0.214,0,0.214-0.13c0-0.053-0.035-0.114-0.131-0.114l-0.401-0.047c-0.169-0.051-0.26-0.206-0.278-0.911
							c0,0-0.095-3.52-0.103-3.834c0.208,0.229,2.067,2.273,2.067,2.273c0.908,0.978,2.098,2.19,2.186,2.271
							c0.477,0.474,0.56,0.55,0.654,0.55c0.129,0,0.139-0.1,0.139-0.658c0-0.002,0.015-4.899,0.015-4.899
							c0-0.522,0.044-0.637,0.26-0.684c0.001,0,0.188-0.022,0.188-0.022c0.107,0,0.156-0.064,0.156-0.123
							c0-0.121-0.145-0.121-0.214-0.121l-0.561,0.018l-0.15,0.007l-0.179-0.006l-0.691-0.019c-0.081,0-0.231,0-0.231,0.121
							c0,0.078,0.048,0.123,0.131,0.123l0.396,0.04c0.2,0.052,0.268,0.165,0.275,0.733c0,0,0.072,3.299,0.09,4.081
							c-0.162-0.176-0.624-0.677-0.624-0.677l-1.27-1.383l-2.732-2.946c-0.118-0.13-0.195-0.209-0.267-0.209
							C41.344,77.07,41.333,77.261,41.333,77.551z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M49.903,77.207l-0.135,0.005l-0.129-0.005l-0.716-0.02c-0.048,0-0.197,0-0.197,0.131
							c0,0.065,0.056,0.114,0.129,0.114l0.246,0.03c0.129,0.031,0.217,0.112,0.236,0.412c0,0.001,0.016,1.802,0.016,1.802v1.473
							c0,0.783,0,1.459-0.042,1.806c-0.036,0.255-0.073,0.381-0.192,0.406c-0.002,0.001-0.238,0.032-0.238,0.032
							c-0.09,0-0.131,0.059-0.131,0.114c0,0.13,0.149,0.13,0.198,0.13l0.684-0.02l0.136-0.005l0.095,0.003l0.933,0.022
							c0.179,0,0.198-0.091,0.198-0.13c0-0.055-0.04-0.114-0.129-0.114l-0.367-0.032c-0.199-0.027-0.248-0.157-0.274-0.404
							c-0.043-0.347-0.043-1.024-0.043-1.807v-1.473l0.018-1.802c0.016-0.284,0.079-0.386,0.257-0.42
							c0.001,0,0.197-0.023,0.197-0.023c0.077,0,0.132-0.048,0.132-0.114c0-0.131-0.15-0.131-0.199-0.131L49.903,77.207z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M57.384,77.206l-0.123,0.006l-0.194-0.007l-0.625-0.018c-0.086,0-0.247,0-0.247,0.131
							c0,0.043,0.02,0.114,0.155,0.114l0.297,0.029c0.056,0.03,0.066,0.096,0.066,0.132L56.64,77.89
							c-0.169,0.554-1.205,3.264-1.725,4.491c-0.105-0.28-1.55-4.133-1.55-4.133c-0.121-0.313-0.222-0.575-0.222-0.671l0,0
							c0-0.046,0.012-0.082,0.073-0.111l0.291-0.035c0.133,0,0.147-0.086,0.147-0.123c0-0.121-0.182-0.121-0.29-0.121l-0.607,0.018
							l-0.17,0.007l-0.206-0.006l-0.772-0.019c-0.118,0-0.281,0-0.281,0.121c0,0.076,0.05,0.123,0.131,0.123l0.3,0.03
							c0.262,0.076,0.379,0.148,0.56,0.607l2.068,5.178c0.155,0.391,0.202,0.509,0.342,0.509c0.103,0,0.14-0.083,0.264-0.36
							l0.143-0.314l1.034-2.422l0.495-1.195l0.489-1.187c0.219-0.552,0.339-0.69,0.436-0.757c0.142-0.089,0.31-0.089,0.365-0.089
							c0.143,0,0.164-0.077,0.164-0.123c0-0.081-0.069-0.121-0.206-0.121L57.384,77.206z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M61.95,77.149l-0.037,0.015l-0.349,0.041c0.001,0-1.912,0.007-1.912,0.007l-0.11-0.004l-0.834-0.021
							c-0.049,0-0.199,0-0.199,0.131c0,0.066,0.055,0.114,0.132,0.114l0.27,0.022c0.227,0.05,0.294,0.14,0.309,0.42
							c0,0.001,0.018,1.803,0.018,1.803v1.473c0,0.783,0,1.459-0.042,1.806c-0.035,0.25-0.074,0.381-0.192,0.406
							c-0.001,0.001-0.239,0.032-0.239,0.032c-0.089,0-0.13,0.059-0.13,0.114c0,0.13,0.149,0.13,0.198,0.13l0.682-0.02l0.137-0.005
							l0.319,0.011l1.747,0.038c0.331,0,0.443,0,0.513-0.263c0.041-0.178,0.11-0.752,0.11-0.828c0-0.061,0-0.189-0.114-0.189
							c-0.104,0-0.125,0.106-0.138,0.177c-0.055,0.324-0.146,0.474-0.337,0.556C61.546,83.2,61.188,83.2,60.975,83.2
							c-0.81,0-0.885-0.102-0.906-0.625l-0.001-1.059l0.001-0.368v-0.736c0,0.004,1.185,0.046,1.185,0.046
							c0.317,0.032,0.359,0.172,0.38,0.247l0.03,0.259c0,0.083,0.048,0.137,0.123,0.137c0.121,0,0.121-0.149,0.121-0.22l0.035-0.53
							c0-0.001,0.066-0.475,0.066-0.475c0-0.121-0.081-0.13-0.106-0.13l-0.154,0.109c-0.077,0.085-0.175,0.1-0.37,0.117
							l-1.322,0.016l0.013-2.328c-0.013-0.019,1.125,0.004,1.125,0.004c0.455,0.05,0.5,0.15,0.543,0.247
							c0,0.001,0.044,0.276,0.044,0.276c0,0.155,0.092,0.171,0.131,0.171c0.036,0,0.098-0.019,0.12-0.145
							c0,0.002,0.033-0.396,0.033-0.396l0.011-0.16l0.067-0.398c0-0.126-0.067-0.14-0.097-0.14L61.95,77.149z"
								/>
							</g>
							<path
								fill="#FFFFFF"
								d="M64.997,80.45c-0.027-0.018-0.029-0.032-0.029-0.048v-2.734c0-0.022,0.007-0.033,0.026-0.04
						c0.064-0.019,0.278-0.047,0.538-0.047c0.867,0,1.383,0.602,1.383,1.61c0,0.569-0.18,1.029-0.483,1.234
						c-0.186,0.132-0.413,0.148-0.591,0.148C65.447,80.573,65.096,80.509,64.997,80.45z M64.867,77.204l-0.28,0.008l-0.11-0.004
						l-0.835-0.021c-0.049,0-0.197,0-0.197,0.131c0,0.066,0.055,0.114,0.131,0.114l0.268,0.022c0.229,0.05,0.295,0.14,0.311,0.42
						l0.017,1.802v1.473c0,0.783,0,1.459-0.043,1.806c-0.033,0.244-0.072,0.381-0.192,0.406c-0.001,0.001-0.238,0.032-0.238,0.032
						c-0.09,0-0.13,0.057-0.13,0.114c0,0.13,0.149,0.13,0.198,0.13l0.752-0.023c0.033-0.002,0.133,0.002,0.133,0.002l0.929,0.021
						c0.181,0,0.2-0.091,0.2-0.13c0-0.055-0.041-0.114-0.133-0.114l-0.366-0.032c-0.199-0.028-0.247-0.157-0.271-0.404
						c-0.043-0.348-0.043-1.024-0.043-1.807v-0.201c-0.004-0.002,1.024,0.013,1.024,0.013c0.001,0,0.066,0.034,0.066,0.034
						c0,0.002,0.288,0.395,0.288,0.395l0.615,0.835l0.113,0.148c0.417,0.551,0.694,0.915,1.037,1.098
						c0.231,0.126,0.456,0.166,0.925,0.166h0.594c0.105,0,0.265,0,0.265-0.13c0-0.063-0.063-0.114-0.14-0.114l-0.316-0.024
						c-0.218-0.034-0.557-0.132-0.983-0.582c-0.438-0.467-0.949-1.124-1.541-1.886c0,0-0.115-0.149-0.162-0.208
						c0.673-0.635,0.992-1.252,0.992-1.935c0-0.595-0.38-1.028-0.604-1.191c-0.466-0.334-1.054-0.379-1.567-0.379L64.867,77.204z"
							/>
							<g>
								<path
									fill="#FFFFFF"
									d="M70.269,78.639c0,0.571,0.189,1.106,1.11,1.864l0.376,0.309c0.738,0.608,0.94,0.921,0.94,1.458
							c0,0.521-0.389,1.047-1.132,1.047c-0.304,0-1.029-0.078-1.177-0.802l-0.022-0.296c0-0.047,0-0.155-0.122-0.155
							c-0.111,0-0.124,0.123-0.132,0.19c0,0.002-0.006,0.082-0.006,0.082l-0.036,0.812c0,0.225,0.007,0.299,0.161,0.375h0.001
							c0.323,0.158,0.699,0.234,1.15,0.234c0.509,0,0.958-0.126,1.298-0.365c0.545-0.387,0.738-0.998,0.738-1.441
							c0-0.655-0.204-1.194-1.246-2.015l-0.233-0.183c-0.801-0.656-0.996-0.922-0.996-1.355c0-0.542,0.387-0.907,0.963-0.907
							c0.692,0,0.923,0.288,0.99,0.411c0.044,0.082,0.094,0.3,0.102,0.394c0.007,0.06,0.02,0.166,0.129,0.166
							c0.123,0,0.123-0.167,0.123-0.257l0.028-0.823l0.003-0.063c0-0.121-0.11-0.121-0.145-0.121l-0.28-0.04
							c-0.239-0.058-0.491-0.085-0.792-0.085C70.991,77.07,70.269,77.701,70.269,78.639z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M75.882,77.207l-0.139,0.005l-0.128-0.005l-0.715-0.02c-0.132,0-0.198,0.044-0.198,0.131
							c0,0.065,0.056,0.114,0.131,0.114l0.242,0.03c0.114,0.027,0.22,0.095,0.238,0.412l0.016,1.802v1.473
							c0,0.783,0,1.459-0.041,1.806c-0.035,0.249-0.074,0.381-0.193,0.406c-0.002,0.001-0.238,0.032-0.238,0.032
							c-0.089,0-0.13,0.059-0.13,0.114c0,0.13,0.148,0.13,0.196,0.13l0.686-0.02l0.134-0.005l0.099,0.004l0.932,0.021
							c0.177,0,0.195-0.091,0.195-0.13c0-0.057-0.04-0.114-0.13-0.114l-0.366-0.032c-0.199-0.028-0.247-0.157-0.272-0.404
							c-0.043-0.348-0.043-1.024-0.043-1.807v-1.473l0.018-1.802c0.015-0.284,0.078-0.386,0.258-0.42l0,0
							c0.001,0,0.196-0.023,0.196-0.023c0.076,0,0.131-0.048,0.131-0.114c0-0.131-0.15-0.131-0.199-0.131L75.882,77.207z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M77.75,77.271c-0.004,0.015-0.189,0.791-0.189,0.892c0,0.102,0.057,0.138,0.105,0.138
							c0.084,0,0.121-0.066,0.144-0.124l0.137-0.248c0.116-0.176,0.296-0.23,0.799-0.239c0,0,0.924-0.013,1.174-0.016
							c0,0.125,0,3.475,0,3.475c0,0.783,0,1.459-0.041,1.806c-0.032,0.244-0.073,0.381-0.192,0.406
							c-0.002,0.001-0.239,0.032-0.239,0.032c-0.09,0-0.13,0.057-0.13,0.114c0,0.13,0.148,0.13,0.197,0.13l0.682-0.02l0.138-0.005
							l0.099,0.004l0.928,0.021c0.173,0,0.199-0.081,0.199-0.13c0-0.055-0.041-0.114-0.13-0.114l-0.368-0.032
							c-0.197-0.027-0.246-0.157-0.272-0.404c-0.042-0.347-0.042-1.024-0.042-1.807c0,0,0-3.35,0-3.475
							c0.114,0.002,0.989,0.016,0.989,0.016c0.806,0.017,0.941,0.215,0.968,0.406c0,0.001,0.007,0.089,0.007,0.089
							c0.005,0.079,0.014,0.2,0.132,0.2c0.02,0,0.121-0.008,0.121-0.172l0.007-0.268l0.016-0.652c0-0.05,0-0.166-0.105-0.166
							l-0.099,0.015c-0.118,0.024-0.338,0.069-0.728,0.069h-3.136l-0.656-0.041c-0.002,0-0.27-0.07-0.27-0.07l-0.103-0.031
							C77.821,77.07,77.787,77.157,77.75,77.271z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M88.626,77.205l-0.22,0.007l-0.134-0.007l-0.418-0.018c-0.172,0-0.198,0.071-0.198,0.113
							c0,0.087,0.082,0.114,0.162,0.141c0.036,0.01,0.098,0.026,0.098,0.177c0,0.119-0.045,0.252-0.134,0.394
							c-0.142,0.237-1.08,1.851-1.339,2.361c-0.277-0.465-1.355-2.319-1.478-2.543c-0.062-0.118-0.101-0.209-0.101-0.295
							c0-0.037,0.025-0.069,0.074-0.095c0.001,0,0.043-0.016,0.043-0.016c0.054-0.022,0.117-0.046,0.117-0.115
							c0-0.081-0.057-0.121-0.171-0.121l-0.59,0.02l-0.114,0.005l-0.196-0.006l-0.63-0.019c-0.166,0-0.174,0.101-0.174,0.121
							c0,0.072,0.061,0.123,0.148,0.123c0.058,0,0.207,0.044,0.325,0.123c0.165,0.111,0.37,0.335,0.55,0.601
							c0.301,0.445,1.416,2.277,1.467,2.399c0.111,0.246,0.188,0.418,0.188,0.853v0.72l-0.026,0.83
							c-0.011,0.166-0.056,0.372-0.208,0.403c-0.001,0.001-0.238,0.032-0.238,0.032c-0.09,0-0.131,0.057-0.131,0.114
							c0,0.13,0.148,0.13,0.197,0.13l0.685-0.02l0.134-0.005l0.099,0.004l0.929,0.021c0.179,0,0.198-0.091,0.198-0.13
							c0-0.055-0.041-0.114-0.131-0.114l-0.367-0.032c-0.186-0.026-0.269-0.143-0.289-0.402l-0.024-0.83v-0.72
							c0-0.269,0-0.524,0.12-0.811c0.143-0.343,1.307-2.303,1.588-2.645c0.186-0.229,0.274-0.32,0.429-0.411
							c0.116-0.076,0.262-0.109,0.35-0.109c0.128,0,0.173-0.071,0.173-0.132c0-0.052-0.045-0.113-0.173-0.113L88.626,77.205z"
								/>
							</g>
							<path
								fill="#FFFFFF"
								d="M93.129,80.169c0-1.658,0.91-2.688,2.376-2.688c1.194,0,2.48,0.946,2.48,3.023
						c0,2.669-1.87,2.804-2.245,2.804C94.203,83.309,93.129,82.017,93.129,80.169z M92.205,80.402c0,1.615,1.036,3.353,3.31,3.353
						c1.97,0,3.4-1.466,3.4-3.486c0-1.914-1.346-3.199-3.35-3.199C93.117,77.07,92.205,79.114,92.205,80.402z"
							/>
							<g>
								<path
									fill="#FFFFFF"
									d="M103.26,77.151l-0.046,0.022c-0.001,0-0.255,0.026-0.255,0.026l-0.098,0.005
							c0.001,0-2.054,0.007-2.054,0.007l-0.11-0.004l-0.835-0.021c-0.049,0-0.198,0-0.198,0.131c0,0.066,0.055,0.114,0.132,0.114
							l0.268,0.022c0.229,0.049,0.296,0.14,0.311,0.42c0,0.001,0.018,1.803,0.018,1.803v1.473c0,0.783,0,1.459-0.042,1.806
							c-0.035,0.25-0.075,0.381-0.192,0.406c-0.001,0.001-0.238,0.032-0.238,0.032c-0.09,0-0.131,0.057-0.131,0.114
							c0,0.13,0.149,0.13,0.198,0.13l0.682-0.02l0.137-0.005l0.1,0.004l0.928,0.021c0.179,0,0.198-0.091,0.198-0.13
							c0-0.057-0.041-0.114-0.132-0.114l-0.365-0.032c-0.199-0.028-0.248-0.157-0.271-0.404c-0.043-0.348-0.043-1.024-0.043-1.807
							v-0.712c0.311-0.012,1.065-0.009,1.25,0.013c0.221,0.028,0.344,0.153,0.384,0.261l0.04,0.238l0.005,0.05
							c0,0.074,0.05,0.131,0.121,0.131c0.122,0,0.122-0.145,0.122-0.223l0.034-0.553c0,0,0.067-0.475,0.067-0.475
							c0-0.121-0.08-0.13-0.104-0.13c-0.061,0-0.097,0.048-0.129,0.09c-0.07,0.099-0.146,0.127-0.352,0.143l-0.372,0.009
							l-1.071,0.008l0.005-2.32c0.01-0.029,1.297-0.004,1.297-0.004c0.3,0.007,0.473,0.143,0.515,0.231
							c0,0.001,0.068,0.292,0.068,0.292c0,0.155,0.102,0.163,0.122,0.163c0.044,0,0.103-0.024,0.129-0.143
							c0-0.003,0.033-0.415,0.033-0.415l0.009-0.145c0,0.001,0.049-0.285,0.049-0.285l0.018-0.112c0-0.082-0.047-0.14-0.114-0.14
							L103.26,77.151z"
								/>
							</g>
						</g>
						<g>
							<rect
								x="13.789"
								y="88.483"
								fill="#FFFFFF"
								width="89.206"
								height="0.509"
							/>
						</g>
						<g>
							<g>
								<path
									fill="#FFFFFF"
									d="M22.777,93.844c0,0-3.194,6.707-3.484,7.314c-0.057-0.117-3.496-7.223-3.496-7.223
							c-0.162-0.325-0.247-0.418-0.378-0.418c-0.085,0-0.198,0.063-0.251,0.362l-1.278,8.42c-0.07,0.475-0.179,0.924-0.505,0.993
							c-0.001,0-0.381,0.039-0.381,0.039c-0.059,0-0.239,0-0.239,0.121c0,0.175,0.192,0.175,0.265,0.175l0.794-0.023l0.484-0.015
							l0.414,0.013l0.942,0.025c0.165,0,0.342-0.013,0.342-0.175c0-0.077-0.082-0.121-0.225-0.121l-0.536-0.064
							c-0.243-0.057-0.362-0.182-0.362-0.382l0.038-0.603c0,0,0.587-4.498,0.663-5.077c0.106,0.174,0.627,1.283,0.627,1.283
							l0.715,1.53c0.153,0.356,1.157,2.302,1.486,2.934l0.038,0.071c0.196,0.369,0.314,0.592,0.442,0.592
							c0.139,0,0.208-0.129,0.506-0.74c0,0,2.815-5.863,2.815-5.863l0,0c0,0.052,0.766,5.672,0.766,5.672
							c0.016,0.114,0.023,0.207,0.023,0.281c0,0.157-0.031,0.228-0.066,0.24c-0.086,0.037-0.164,0.083-0.164,0.172l0,0
							c0,0.117,0.136,0.15,0.403,0.174c0.509,0.038,2.289,0.078,2.624,0.078c0.237,0,0.367-0.062,0.367-0.175
							c0-0.121-0.161-0.121-0.239-0.121c-0.177,0-0.426-0.014-0.791-0.128c-0.38-0.114-0.499-0.503-0.61-1.278l-1.176-7.98
							c-0.041-0.288-0.127-0.428-0.264-0.428C22.952,93.516,22.88,93.64,22.777,93.844z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M32.884,93.727l-0.076,0.022l-0.552,0.077c-0.219,0.012-2.329,0.013-3.225,0.013l-0.661-0.02
							l-0.151-0.006c-0.001,0-1.047-0.013-1.047-0.013c-0.153,0-0.316,0.011-0.316,0.148c0,0.148,0.17,0.148,0.251,0.148
							l0.496,0.037c0.422,0.09,0.507,0.3,0.531,0.708c0.026,0.412,0.026,0.767,0.026,2.736v2.273c0,1.183,0,2.206-0.064,2.746
							c-0.058,0.418-0.128,0.638-0.35,0.683l-0.459,0.051c-0.102,0-0.238,0.014-0.238,0.135c0,0.106,0.098,0.161,0.29,0.161
							l0.892-0.026c0.001,0,0.736-0.013,0.736-0.013l1.342,0.039l0.375,0.009l1.86,0.03c0.497,0,0.632,0,0.728-0.359
							c0.067-0.265,0.169-1.14,0.169-1.442c0-0.13,0-0.264-0.135-0.264c-0.124,0-0.15,0.092-0.173,0.22
							c-0.092,0.533-0.244,0.786-0.563,0.932c-0.329,0.152-0.875,0.152-1.201,0.152c-1.341,0-1.493-0.213-1.518-1.054l-0.002-1.468
							l0.002-0.532v-1.02c0-0.098,0.023-0.098,0.046-0.098c0.29,0,1.582,0.013,1.83,0.039c0.409,0.044,0.624,0.192,0.696,0.479
							l0.041,0.34l0.01,0.121c0,0.092,0.052,0.145,0.148,0.145c0.148,0,0.148-0.174,0.148-0.277l0.024-0.407l0.028-0.457
							l0.087-0.805l0.016-0.139c0-0.086-0.045-0.135-0.122-0.135c-0.074,0-0.13,0.063-0.213,0.158
							c-0.127,0.142-0.312,0.178-0.616,0.203c-0.191,0.017-0.924,0.026-2.064,0.026c-0.04,0-0.058-0.01-0.058-0.111v-3.267
							c0-0.097,0.031-0.097,0.058-0.097c0.25,0,1.607,0.025,1.83,0.051c0.648,0.078,0.757,0.234,0.84,0.445
							c0.073,0.175,0.075,0.426,0.075,0.453c0,0.064,0,0.213,0.148,0.213c0.126,0,0.151-0.122,0.161-0.168
							c0-0.001,0.047-0.592,0.047-0.592l0.018-0.264c0-0.001,0.085-0.714,0.085-0.714l0.018-0.128c0-0.036,0-0.148-0.11-0.148
							L32.884,93.727z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M37.863,93.824l-0.684,0.016l-0.655-0.016l-1.114-0.023c-0.153,0-0.316,0.011-0.316,0.148
							c0,0.148,0.17,0.148,0.251,0.148l0.419,0.037c0.422,0.09,0.507,0.3,0.531,0.708c0.026,0.412,0.026,0.767,0.026,2.736v2.273
							c0,1.183,0,2.206-0.064,2.746c-0.058,0.418-0.128,0.638-0.349,0.683c-0.001,0-0.459,0.051-0.459,0.051
							c-0.102,0-0.238,0.014-0.238,0.135c0,0.161,0.218,0.161,0.29,0.161l0.891-0.026c0.001,0,0.736-0.013,0.736-0.013l2.016,0.05
							l1.741,0.028c0.679,0,0.761-0.066,0.845-0.373c0.104-0.38,0.194-1.344,0.194-1.493c0-0.124,0-0.251-0.135-0.251
							c-0.149,0-0.165,0.144-0.174,0.222c-0.025,0.242-0.179,0.595-0.328,0.756c-0.303,0.328-0.764,0.351-1.487,0.351
							c-1.033,0-1.339-0.097-1.541-0.276c-0.26-0.224-0.26-1.122-0.26-2.753v-2.273c0-1.969,0-2.324,0.026-2.736
							c0.027-0.471,0.141-0.649,0.452-0.707c0.001,0,0.498-0.038,0.498-0.038c0.137,0,0.265-0.018,0.265-0.148
							c0-0.13-0.146-0.148-0.303-0.148L37.863,93.824z"
								/>
							</g>
							<path
								fill="#FFFFFF"
								d="M45.831,93.817l-0.93,0.022l-0.497-0.013l-1.259-0.026c-0.153,0-0.316,0.011-0.316,0.148
						c0,0.148,0.17,0.148,0.251,0.148l0.496,0.037c0.422,0.09,0.508,0.3,0.531,0.708c0.026,0.412,0.026,0.767,0.026,2.736v2.273
						c0,1.183,0,2.206-0.063,2.746c-0.048,0.345-0.107,0.634-0.349,0.683c-0.001,0-0.459,0.051-0.459,0.051
						c-0.102,0-0.238,0.014-0.238,0.135c0,0.161,0.217,0.161,0.29,0.161l0.891-0.026c0.001,0,0.736-0.013,0.736-0.013l0.967,0.039
						c0.001,0,0.503,0.022,0.503,0.022l0.402,0.016c2.404,0,3.48-1.43,3.48-2.847c0-1.461-1.19-2.399-2.323-2.801
						c0.774-0.626,1.29-1.254,1.29-2.249c0-0.462-0.2-1.968-2.782-1.968L45.831,93.817z M46.257,94.393
						c1.153,0,1.583,1.209,1.583,1.97c0,0.605-0.18,1.07-0.517,1.344c-0.225,0.198-0.527,0.238-0.909,0.238l-0.6-0.038
						c-0.039-0.009-0.066-0.022-0.066-0.111v-3.19c0-0.138,0.024-0.169,0.093-0.189C45.84,94.419,46.257,94.393,46.257,94.393z
						 M46.179,102.868c-0.395-0.16-0.432-0.358-0.432-1.21v-3.061c0-0.054,0.019-0.058,0.046-0.058l0.554,0.013
						c0.488,0.025,0.784,0.11,1.054,0.304l0.001,0c1.119,0.78,1.239,2.039,1.239,2.544c0,1.543-1.275,1.621-1.531,1.621
						C46.816,103.021,46.524,103.009,46.179,102.868z"
							/>
							<path
								fill="#FFFFFF"
								d="M51.597,98.714c0,2.454,1.638,5.094,5.236,5.094c3.166,0,5.378-2.19,5.378-5.327
						c0-2.999-2.011-4.861-5.249-4.861C53.276,93.62,51.597,96.26,51.597,98.714z M53.443,98.313c0-3.489,1.971-4.01,3.146-4.01
						c2.209,0,3.752,1.914,3.752,4.655c0,3.736-2.074,4.14-2.965,4.14C55.06,103.098,53.443,101.131,53.443,98.313z"
							/>
							<g>
								<path
									fill="#FFFFFF"
									d="M72.121,93.828l-0.373,0.012c-0.036-0.002-1.485-0.039-1.485-0.039c-0.146,0-0.302,0.011-0.302,0.148
							c0,0.148,0.169,0.148,0.251,0.148l0.457,0.037c0.422,0.09,0.507,0.3,0.531,0.708c0.026,0.412,0.026,0.767,0.026,2.736v1.614
							c0,1.383-0.054,2.494-0.74,3.18c-0.621,0.621-1.437,0.688-1.759,0.688c-0.446,0-1.031-0.059-1.594-0.522
							c-0.517-0.423-0.996-1.097-0.996-3.152v-1.808c0-1.969,0-2.324,0.026-2.736c0.027-0.471,0.141-0.649,0.453-0.707l0.368-0.038
							c0.071,0,0.238,0,0.238-0.148c0-0.137-0.163-0.148-0.315-0.148l-0.944,0.022l-0.645,0.016l-0.69-0.016l-1.195-0.023
							c-0.153,0-0.315,0.011-0.315,0.148c0,0.148,0.169,0.148,0.251,0.148l0.496,0.037c0.422,0.09,0.508,0.3,0.531,0.708
							c0.025,0.412,0.025,0.767,0.025,2.736v1.885c0,1.637,0.368,2.732,1.16,3.446c0.994,0.899,2.321,0.899,2.888,0.899
							c0.752,0,1.74-0.107,2.707-0.938c1.088-0.932,1.238-2.431,1.238-3.911v-1.381c0-1.969,0-2.324,0.026-2.736
							c0.027-0.471,0.141-0.649,0.451-0.707c0.001,0,0.369-0.038,0.369-0.038c0.137,0,0.264-0.018,0.264-0.148
							c0-0.137-0.156-0.148-0.302-0.148L72.121,93.828z"
								/>
							</g>
							<path
								fill="#FFFFFF"
								d="M77.708,93.819l-0.948,0.02l-0.517-0.013l-1.252-0.026c-0.152,0-0.316,0.011-0.316,0.148
						c0,0.148,0.17,0.148,0.251,0.148l0.496,0.037c0.422,0.09,0.507,0.3,0.531,0.708c0.026,0.412,0.026,0.767,0.026,2.736v2.273
						c0,1.183,0,2.206-0.064,2.746c-0.047,0.345-0.106,0.634-0.349,0.683l-0.459,0.051c-0.102,0-0.239,0.014-0.239,0.135
						c0,0.161,0.218,0.161,0.291,0.161l1.052-0.024l0.524-0.014l0.417,0.01l1.481,0.029c0.072,0,0.29,0,0.29-0.161
						c0-0.041-0.018-0.135-0.186-0.135l-0.629-0.052c-0.345-0.046-0.434-0.275-0.476-0.682c-0.064-0.541-0.064-1.569-0.064-2.76
						v-0.181c0-0.054,0.018-0.071,0.071-0.071l1.123,0.026c0.089,0,0.154,0.014,0.209,0.079c0,0.001,0.618,0.853,0.618,0.853
						l0.659,0.927c0.754,1.038,1.237,1.618,1.78,1.908c0.35,0.189,0.69,0.249,1.397,0.249h1.226c0.091,0,0.278,0,0.278-0.161
						c0-0.041-0.018-0.135-0.187-0.135l-0.356-0.039c-0.209-0.042-0.73-0.145-1.429-0.869c-0.657-0.691-1.403-1.633-2.348-2.824
						c0,0-0.4-0.504-0.434-0.547c1.185-0.968,1.694-1.836,1.694-2.896c0-0.942-0.584-1.586-0.892-1.796
						c-0.722-0.494-1.615-0.561-2.394-0.561L77.708,93.819z M77.66,98.8c-0.07-0.031-0.095-0.065-0.095-0.203v-3.925
						c0-0.096,0.021-0.13,0.093-0.15c0.116-0.035,0.325-0.051,0.676-0.051c0.722,0,1.944,0.491,1.944,2.332
						c0,1.185-0.469,1.706-0.749,1.911c-0.221,0.16-0.383,0.213-0.898,0.213C78.263,98.927,77.9,98.88,77.66,98.8z"
							/>
							<g>
								<path
									fill="#FFFFFF"
									d="M86.474,94.052l-0.116,7.644c-0.013,1.113-0.066,1.475-0.378,1.572
							c-0.192,0.055-0.441,0.063-0.572,0.063c-0.065,0-0.239,0-0.239,0.135c0,0.161,0.182,0.161,0.316,0.161l1.178-0.031
							l0.217-0.008l0.359,0.011l1.242,0.028c0.106,0,0.329,0,0.329-0.161c0-0.135-0.176-0.135-0.251-0.135
							c-0.191,0-0.439-0.01-0.661-0.089c-0.279-0.098-0.408-0.517-0.431-1.405c0,0-0.138-5.248-0.14-5.364
							c0.072,0.063,0.262,0.269,0.262,0.269c0.543,0.597,1.673,1.838,2.794,2.95l1.714,1.715l2.096,2.082
							c0.148,0.148,0.306,0.292,0.461,0.292c0.176,0,0.199-0.218,0.199-0.432l0.143-8.148c0.013-0.722,0.119-0.972,0.454-1.068
							c-0.001,0,0.379-0.037,0.379-0.037c0.176,0,0.265-0.05,0.265-0.148c0-0.148-0.198-0.148-0.329-0.148l-1.03,0.029L94.5,93.84
							l-0.455-0.013l-1.107-0.026c-0.155,0-0.368,0-0.368,0.148c0,0.131,0.128,0.148,0.239,0.148c0.181,0,0.484,0,0.673,0.075
							c0.273,0.114,0.405,0.313,0.418,1.133c0,0,0.121,5.426,0.129,5.766c-0.016,0-0.031,0-0.031,0h0
							c0-0.013-0.906-0.954-0.906-0.954l-1.913-1.981c-1.964-1.95-3.982-4.124-4.002-4.145l-0.12-0.131
							c-0.152-0.168-0.253-0.279-0.38-0.279C86.495,93.581,86.474,93.85,86.474,94.052z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									d="M102.681,93.728l-0.075,0.022c-0.002,0-0.552,0.077-0.552,0.077c-0.219,0.012-2.328,0.013-3.225,0.013
							l-0.66-0.02l-0.153-0.006c-0.001,0-1.047-0.013-1.047-0.013c-0.152,0-0.316,0.011-0.316,0.148
							c0,0.148,0.169,0.148,0.251,0.148l0.496,0.037c0.422,0.09,0.507,0.3,0.531,0.708c0.027,0.412,0.027,0.767,0.027,2.736v2.273
							c0,1.183,0,2.206-0.064,2.746c-0.047,0.344-0.106,0.634-0.349,0.683c0,0-0.459,0.051-0.459,0.051
							c-0.102,0-0.239,0.014-0.239,0.135c0,0.161,0.219,0.161,0.291,0.161l0.891-0.026c0.001,0,0.737-0.013,0.737-0.013l1.341,0.039
							l0.375,0.009l1.861,0.03c0.485,0,0.632,0,0.728-0.359c0.066-0.263,0.169-1.138,0.169-1.442c0-0.13,0-0.264-0.135-0.264
							c-0.125,0-0.15,0.092-0.173,0.22c-0.091,0.532-0.244,0.785-0.563,0.932c-0.33,0.152-0.875,0.152-1.201,0.152
							c-1.34,0-1.492-0.213-1.517-1.054l-0.002-1.468l0.002-0.532v-1.02c0-0.098,0.022-0.098,0.045-0.098
							c0.29,0,1.582,0.013,1.831,0.039c0.409,0.044,0.624,0.192,0.695,0.479l0.042,0.344l0.009,0.117
							c0,0.092,0.052,0.145,0.148,0.145c0.148,0,0.148-0.174,0.148-0.277l0.024-0.418l0.028-0.445l0.087-0.804l0.016-0.141
							c0-0.086-0.045-0.135-0.122-0.135c-0.075,0-0.13,0.063-0.213,0.159c-0.127,0.14-0.312,0.177-0.616,0.202
							c-0.19,0.017-0.923,0.026-2.063,0.026c-0.041,0-0.058-0.01-0.058-0.111v-3.267c0-0.097,0.032-0.097,0.058-0.097
							c0.249,0,1.607,0.025,1.83,0.051c0.649,0.078,0.757,0.234,0.839,0.445c0.073,0.174,0.076,0.425,0.076,0.453
							c0,0.064,0,0.213,0.148,0.213c0.127,0,0.152-0.127,0.16-0.168c0.001-0.001,0.047-0.591,0.047-0.591l0.018-0.265
							c0-0.001,0.086-0.715,0.086-0.715l0.018-0.127c0-0.098-0.037-0.148-0.11-0.148L102.681,93.728z"
								/>
							</g>
						</g>
					</g>
				</g>
				<path
					fill="#FFFFFF"
					d="M58.032,70.012c-2.157-2.157-4.825-3.297-7.404-4.4l-0.206-0.087c-0.034-0.013-1.374-0.514-1.374-0.514
			c-0.164-0.061-0.269-0.101-0.332-0.13l-0.237-0.108L48.47,64.8c-8.449-2.988-13.616-9.945-13.838-18.654
			c-0.013-0.355-0.02-0.73-0.02-1.119c0-1.307,0.078-2.68,0.231-4.081c0.111-0.83,0.371-2.777,0.377-2.827l0.748-4.445
			c0.077-0.455,0.115-0.684,0.141-0.791l0.066-0.271l-0.05-0.003c0.045-0.617,0.09-1.375,0.09-2.125c0-3.065-0.777-5.001-2.52-6.278
			l-0.013-0.01l-0.228-0.228l3.758-4.897l-0.107-0.143c-0.294-0.39-0.464-0.797-0.507-1.21l-0.058-0.555l0.526,0.187
			c0.335,0.119,0.673,0.226,1.033,0.325l0.152,0.042l3.736-4.869l0.171,0.287c0.7,1.18,1.953,1.753,3.828,1.753
			c0.768,0,1.649-0.093,2.774-0.293c0,0,3.691-0.569,3.723-0.574c2.556-0.297,3.042-0.353,3.12-0.362l0.233-0.025
			c0.728-0.071,1.511-0.107,2.329-0.107c1.612,0,3.358,0.136,5.19,0.404c0.011,0.002,2.621,0.39,2.645,0.393l3.252,0.541
			c0.477,0.046,1.023,0.091,1.575,0.091c1.458,0,2.476-0.323,3.205-1.016c0.161-0.143,0.293-0.312,0.42-0.474l0.364-0.458
			l3.439,4.524l0.164-0.063c0.557-0.213,1.32-0.489,2.293-0.839c0,0,0.509-0.183,0.677-0.244c1.261-0.459,2.287-0.77,3.136-0.949
			l0.669-0.141l-0.273,0.627c-0.233,0.534-0.443,0.938-0.661,1.272l-0.408,0.625l0.692-0.279c0.014-0.005,0.502-0.201,0.92-0.367
			l-0.44,0.842c-0.235,0.45-1.141,1.878-1.98,2.497l-0.184,0.136l0.131,0.187c0.006,0.009,0.012,0.017,0.018,0.026
			c0.087,0.139,0.136,0.355,0.136,0.594c0,0.227-0.044,0.453-0.118,0.606c-0.095,0.196-0.238,0.457-0.514,0.738l-0.142,0.145
			l0.924,1.216l-0.18,0.136c-1.776,1.336-2.639,3.395-2.639,6.296l0.001,0.097c-0.006,0.149-0.008,0.307-0.008,0.469
			c0,0.67,0.047,1.398,0.138,2.162l0.452,2.837c0.317,1.896,0.481,2.876,0.487,2.916c0.087,0.834,0.314,2.991,0.332,3.157
			c0.11,2.684,0.119,2.926,0.12,2.942c0.003,0.11,0.004,0.225,0.004,0.339c0,4.697-1.576,9.453-4.112,12.414l-0.05,0.061
			c-1.65,2.009-3.911,4.762-10.079,6.933c-3.461,1.259-6.538,2.492-9.18,4.838l-0.011,0.009l-0.098,0.112l-0.082,0.089l-0.19,0.211
			L58.032,70.012z"
				/>
				<path
					fill="#094183"
					d="M82.314,23.72c-0.049-0.065-0.087-0.093-0.125-0.099C82.227,23.627,82.264,23.654,82.314,23.72z
			 M82.318,23.726c-0.001-0.002-0.003-0.005-0.004-0.006C82.315,23.721,82.317,23.724,82.318,23.726z M62.4,43.748 M84.474,17.305
			c0.233-0.357,0.452-0.785,0.68-1.307l0.455-1.044l-1.114,0.236c-0.86,0.182-1.896,0.495-3.167,0.958
			c-0.177,0.064-0.411,0.149-0.676,0.244c-0.875,0.315-1.705,0.615-2.297,0.841l-3.515-4.625l-0.553,0.696
			c-0.126,0.162-0.246,0.314-0.397,0.449c-0.682,0.649-1.649,0.951-3.043,0.951c-0.546,0-1.089-0.045-1.537-0.088l-3.258-0.541
			c-0.038-0.006-2.644-0.393-2.644-0.393c-1.843-0.27-3.601-0.407-5.224-0.407c-0.914,0-1.784,0.045-2.587,0.133l0,0.001
			c-0.079,0.008-0.514,0.059-3.133,0.363c-0.043,0.007-3.723,0.574-3.727,0.575c-1.111,0.198-1.979,0.289-2.732,0.289
			c-1.786,0-2.972-0.536-3.627-1.639l-0.348-0.584l-3.85,5.018c-0.334-0.092-0.666-0.195-1.016-0.32l-0.877-0.312l0.096,0.925
			c0.05,0.483,0.249,0.924,0.553,1.327l-3.774,4.919l0.383,0.384l0.03,0.023c1.676,1.228,2.424,3.106,2.424,6.088
			c0,0.84-0.057,1.694-0.107,2.343l0.007,0c-0.028,0.115-0.066,0.34-0.145,0.808l-0.749,4.455c-0.006,0.047-0.249,1.869-0.377,2.831
			c-0.154,1.409-0.233,2.791-0.233,4.106c0,0.39,0.007,0.77,0.02,1.125c0.226,8.887,5.542,15.971,14.219,18.948l0.002-0.005
			c0.072,0.033,0.182,0.074,0.348,0.136c0,0,1.354,0.506,1.364,0.51l0.205,0.087c2.559,1.094,5.206,2.226,7.33,4.35l0.343,0.345
			l0.354-0.394l0.083-0.09c0,0,0.051-0.059,0.091-0.104c2.61-2.318,5.668-3.542,9.102-4.792c6.23-2.193,8.514-4.974,10.183-7.006
			l0.046-0.057c2.572-3.001,4.169-7.816,4.169-12.567c0-0.116-0.001-0.232-0.004-0.344c0-0.005,0-0.011-0.12-2.955
			c-0.005-0.047-0.22-2.094-0.334-3.172c-0.001-0.006-0.002-0.021-0.488-2.921l-0.451-2.828c-0.091-0.756-0.137-1.474-0.137-2.134
			c0-0.159,0.002-0.314,0.008-0.472l-0.001-0.094c0-2.822,0.833-4.82,2.545-6.108l0.369-0.277l-0.944-1.242
			c0.291-0.297,0.449-0.575,0.557-0.8c0.178-0.368,0.209-1.045-0.03-1.426c-0.008-0.013-0.016-0.025-0.025-0.037
			c0.865-0.639,1.787-2.075,2.049-2.577l0.734-1.404C85.983,16.702,84.486,17.3,84.474,17.305z M38.996,17.659l2.994-3.892
			c1.055,1.521,2.79,1.629,4.511,1.689l1.144-0.122l4.89-0.769l3.287-0.376c3.182-0.362,6.351,0.135,9.367,0.557l4.345,0.71
			c1.841,0.134,3.815,0.09,5.069-1.284l0.24-0.27l2.77,3.641c-0.004,0.002-0.009,0.004-0.013,0.006
			c-0.058,0.027-0.146,0.065-0.256,0.111c-0.357,0.129-0.761,0.277-1.138,0.428c-0.047,0.017-0.096,0.033-0.144,0.05l-0.047-0.011
			l-1.41-1.858c-1.464,0.83-3.29,0.95-5.054,0.83l-2.536-0.393l-3.135-0.512l-3.348-0.393c-2.006-0.165-4.058-0.09-6.005,0.136
			L51.75,16.33l-4.616,0.694c-1.78,0.149-3.5-0.061-4.934-0.861l-1.432,1.863c-0.186-0.036-0.372-0.072-0.566-0.112
			C39.762,17.826,39.368,17.744,38.996,17.659z M74.071,26.307c-0.042,0.453-0.57,0.554-0.745,0.92l-0.248,0.176
			c0.218-0.014,0.452-0.147,0.601-0.337c0.364-0.116,1.513-0.355,1.565-0.131c0.051,0.223-0.055,0.429-0.395,0.672
			s-1.552,0.762-2.445,0.616c-0.498-0.014-0.966,0.131-1.434,0.233l-2.401,0.703c-0.832,0.22-1.806,0.336-2.706,0.118
			c-1.331-0.248-2.648-0.601-4.055-0.513c-0.158,0.007-0.381,0.132-0.469,0.322c0.147-0.03,0.366-0.102,0.513,0
			c-0.02,0.182-0.046,1.188-0.433,1.568c-0.102-0.116-0.157-0.303-0.208-0.469s-0.633-2.71,0.481-3.513
			c0.666-0.216,1.535,0.189,1.755,0.308c0.86,0.466,2.329,0.713,3.47,1.155c0.497-0.059,0.979-0.22,1.491-0.279l3.44-0.454
			c0.615,0.116,0.705-0.673,1.243-0.73c0.352,0.014,0.572-0.251,0.849-0.41C73.989,26.219,74.071,26.233,74.071,26.307z
			 M45.7,27.859c0.132-0.117,0.59-0.221,1.024-0.159c0.433,0.062,1.209,0.25,1.794,0.661c1.186,0.395,2.113,1.213,3.32,1.59
			c0.045-0.104-0.117-0.192-0.175-0.265c0.747-0.571,1.523-1.098,2.358-1.448c0.54-0.631,1.257-1.258,2.135-1.128l0.199-0.021
			c0,0,0.004,0.202,0,0.283c-0.025,0.457-0.263,0.913-0.522,1.539c0,0-0.729,0.27-1.083,0.483c-0.287,0.173-2.81,1.653-2.81,1.653
			c-0.717,0.57-1.073-0.068-1.814-0.57c-0.865-0.454-1.757-0.893-2.504-1.595c0-0.101,0.219-0.044,0.117-0.161
			c-0.425,0.044-0.741,0.191-1.121,0.309c-0.161,0.074-0.56,0.208-0.689-0.041l0.185-0.049c0.236-0.159,0.321-0.476,0.708-0.569
			c-0.281-0.271-0.832-0.368-1.233-0.394L45.7,27.859z M77.539,28.065c0.03,0.211-0.396,0.714-0.943,0.836
			c-0.665,0.149-1.201,0.02-1.628-0.225c0.02,0.473,0.804,0.651,1.379,0.542c-0.32,0.439-1.294,0.743-2.025,0.715
			c-0.759-0.03-1.27-0.258-1.945-0.675c-0.069,0.457,0.794,0.844,1.409,0.893c-1.222,0.673-2.59,0.184-2.759,0.139
			c-0.337-0.089-0.834-0.298-1.098-0.566c-0.017,0.355,0.462,0.636,0.889,0.735c-0.804,0.377-1.828-0.043-2.232-0.713
			c1.321-0.384,2.328-0.735,3.67-1.069c1.295,0.216,2.535-0.327,3.018-0.893c0.188-0.22,0.315-0.49,0.323-0.559
			c0.122-0.039,0.295,0.007,0.414,0.036C76.133,27.29,77.421,27.581,77.539,28.065z M66.547,29.988
			c0.63,0.074,1.493-0.056,1.787-0.146c0.144,0.247-0.144,0.667-0.386,0.577c0.103,0.351,0.427,0.805,0.369,1.213
			c-0.307-0.115-1.213-0.474-1.213-0.474c0.22,0.366,0.538,1.011,0.423,1.478c-0.367-0.277-1.139-0.301-1.447-0.535
			c0.146,0.557,0.512,1.362,0.029,1.889c-0.452-0.322-0.673-0.835-1.053-1.201c0.073,0.527,0.191,1.346-0.177,1.831
			c-0.233-0.294-1.236-0.683-1.208-1.033c-0.102-0.031-0.371,1.075-0.708,1.28c-0.335-0.467-0.622-1.126-1.086-1.549
			c0.129-0.147,0.291-0.061,0.23-0.31c-0.132-0.317-0.612-0.498-1.141-0.715c0.079-0.165,0.134-0.246,0.302-0.486
			c0.429-0.612,0.697-1.179,0.799-1.473c0.168-0.485,0.224-0.643,0.263-0.995c0.546,0.069,0.923,0.157,1.591,0.232
			C64.616,29.652,65.637,29.875,66.547,29.988z M55.187,29.794c-0.166,0.181-0.268,0.281-0.355,0.496
			c-0.2,0.496,0,1.241,0.511,1.608c0.341,0.245,0.456,0.275,0.731,0.355c-0.672,0.181-1.416,1.148-1.42,1.66
			c-0.26-0.241-0.329-0.715-0.263-1.152c-0.185,0.259-0.595,0.447-0.845,0.475c-0.087-0.22-0.044-0.793,0.212-1.056
			c-0.101-0.174-0.683-0.084-0.952,0.106c-0.058-0.351,0.07-0.627,0.425-0.844c-0.096-0.199-0.313-0.055-0.794,0.007
			c0.307-0.15,0.772-0.4,1.268-0.718C54.547,30.192,55.187,29.794,55.187,29.794z M55.502,43.814
			c0.729,1.168,0.794,1.213,1.189,1.888s2.032,3.199,2.349,3.712c0.317,0.513,2.005,3.374,3.096,3.93
			c-0.839,0.913-0.437,2.044-1.821,2.268c-0.8,0.129-3.044,0.819-3.82-0.479s-1.129-3.374-0.895-5.013
			c0.986,1.532,1.693,2.21,2.236,2.522c-1.801-2.002-1.578-2.42-2.44-3.503c-0.347-0.437-0.421-1.143-0.463-1.677
			c-0.071-0.91-0.056-1.829-0.1-2.741c-0.025-0.493,0-1.017-0.164-1.489c-0.064-0.181-0.194-0.724-0.234-0.913
			c-0.04-0.189-0.02-0.437-0.02-0.437S54.773,42.646,55.502,43.814z M60.957,26.379c-0.76,0.54-0.902,1.033-2.152,1.67
			c-0.693,0.353-1.372,0.643-1.814,0.423c-0.454-0.248-0.312-1.066-0.314-1.173c-0.002-0.092,0.01-0.224,0.014-0.292
			c0.337-0.169,0.823-0.064,0.961-0.483c0.115-0.35,0.086-0.745,0.16-1.112c0.145-0.307,0.585-0.455,0.672-0.79
			c-0.569,0.365-1.023,0.922-1.727,0.891c-0.219-0.057-0.072-0.337-0.189-0.467c-0.142-0.056-0.139-0.167-0.161-0.27
			c0.058,0.008,0.103,0.009,0.107,0.009c0.034,0,0.06-0.026,0.06-0.06c0-0.033-0.026-0.062-0.06-0.062s-0.112-0.007-0.183-0.028
			c-0.006-0.005-0.008-0.009-0.013-0.013c-0.013-0.118,0.045-0.279-0.043-0.365l-0.353-0.061c0.03-0.364,0.28-0.687,0.294-1.081
			c0.059,0.072,0.279,0.056,0.175,0.203c-0.058,0.059-0.043,0.162-0.013,0.221c-0.019-0.174,0.615-0.401,0.277-0.521
			c-0.145-0.028-0.555,0.067-0.585-0.154c0-0.321,0.133-0.599,0.309-0.862c0.367,0.307,0.863,0.57,0.98,1.068
			c0.13,0.147,0.264,0.336,0.454,0.336c0.41-0.161,0.614,0.248,1.009,0.22c0-0.087,0.045-0.176,0.133-0.205
			c0.09-0.046,0.189,0,0.249,0.075c0.045,0.189-0.073,0.306-0.162,0.424c-0.147,0.116-0.322,0.146-0.512,0.175
			c0.321,0.22,0.6-0.132,0.907-0.205c0.145-0.029,0.293,0.029,0.351,0.176c0.133,0.747-0.189,1.828,0.747,2.196
			C60.664,26.32,60.811,26.335,60.957,26.379z M75.539,53.523c-0.605-0.068-1.122-0.035-1.34,0.537
			c-0.306,0.704-0.473,1.219-0.713,1.625c-0.286,0.484-0.75,0.905-1.534,1.123c-0.784,0.218-1.877,0.269-2.012-0.202
			c0.201-0.957,1.502-1.105,2.055-1.373c1.035-0.5,1.13-1.41,1.164-2.348c-1.455-0.936-4.384-3.26-4.384-3.26
			s2.553,2.535,3.912,3.44c0,0.797-0.208,1.25-0.84,1.6c-0.413,0.228-0.884,0.398-1.467,0.692c-0.997,0.566-0.7,1.37-2.049,1.884
			c-0.566,0.216-1.931,0.404-1.574-0.335c0.511-0.767-0.034-0.77-0.034-0.77c-0.311,0.092-1.139,0.322-1.568,0.339
			c-0.263,0.01-0.528-0.181-0.298-0.394c0.084-0.077,0.216-0.141,0.355-0.248c0.206-0.158,0.325-0.261,0.414-0.42
			c0.141-0.253,0.254-0.594,0.102-0.682c-0.183-0.107-0.463-0.033-0.598,0.114c-0.558,0.619-0.604,1.657-2.213,1.358
			c0,0-1.893-0.436-0.366-2.566c0.431-0.573-0.755-1.243-1.452-2.097c-1.332-1.632-5.133-7.795-5.133-7.795
			c-0.395-0.57-0.812-1.257-1.175-1.815c-0.363-0.557-0.482-1.748,0.352-1.633c0.396,0.083,0.525,0.262,0.967,0.075
			c0.318-0.134,0.29,0.342,0,0.581c-0.184,0.191-0.184,0.546,0.066,0.738c0.326,0.188,0.409-0.183,0.779-0.078
			c0.371,0.106,0.633,0.998,1.293,1.188c0.18,0.044,0.294,0.031,0.336-0.087c0.095-0.266-0.21-0.63-0.137-0.955
			c0.11,0.199,0.391,0.407,0.768,0.615c0.459,0.253,0.726,0.315,0.605-0.07c-0.136-0.436-0.203-0.734-0.203-0.734
			c-0.045-0.328,0.247-0.581,0.469-0.581c0.129,0,0.265,0.114,0.355,0.263c0.29,0.527,0.151,0.831,0.814,1.04
			c0.99,2.099,3.545,4.788,4.564,5.625c0.213,0.175,0.596,0.392,0.894,0.505c-0.226-0.221-0.587-0.54-0.748-0.694
			c-2.68-2.569-4.375-4.945-4.33-5.451c0.767-0.32,0.886-1.064,1.109-0.953c0.112,0.067-0.242,0.676-0.179,0.983
			c0.037,0.179,0.216,0.424,0.7,0.417c-0.868,0.866,1.34,1.593,2.977,1.556c0.591,0.368,1.623,0.99,2.858,1.273
			c0,0,0.121,0.59,0.506,0.61c0,0,0.905,1.401,2.142,2.729C72.984,50.223,74.382,52.381,75.539,53.523z M65.987,52.054
			c-2.643-2.821-5.267-7.087-5.617-7.218c0,0,3.257,4.946,3.849,5.784c0.592,0.838,1.397,1.634,1.777,2.309
			C66.115,52.732,66.295,52.383,65.987,52.054z M61.189,26.861c-1.133,1.077,0.206,3.801-0.561,4.91
			c-0.083,0.12-0.171,0.268-0.231,0.387c-0.61-0.201-0.979-0.217-1.496-0.223c-0.306-0.004-0.67,0.068-0.916,0.089
			c-0.61,0.052-1.064-0.007-1.623,0.112c0.042-0.121,0.121-0.22,0.231-0.387c-0.521,0.015-0.916-0.089-1.206-0.469
			c-0.186-0.261-0.223-0.579-0.127-0.841c0.148-0.404,0.575-0.781,0.752-1.154c0,0,0.3-0.468,0.409-1.034
			c0.052,0.186,0.14,0.493,0.648,0.61c0.473,0.109,0.99-0.068,1.83-0.491c-0.384,0.61-0.93,0.991-1.95,1.155
			c0.352,0.072,1.02-0.071,1.705-0.429c0.831-0.436,1.88-1.877,1.88-1.877C60.785,26.997,61.277,26.789,61.189,26.861z
			 M59.916,30.67c-0.186,0.346-0.415,0.615-1.152,0.648c-0.938,0.042-1.156-0.511-1.156-0.511c0.058,0.289,0.26,0.895,1.163,0.855
			C59.92,31.611,59.916,30.67,59.916,30.67z M82.531,24.053c-1.733,1.382-2.267,3.475-2.372,4.111
			c-0.287,1.841-0.349,3.907-0.015,5.717l0.921,5.718l0.269,2.503l0.108,2.926c0.192,6.756-3.06,15.808-14.152,19.446l-2.684,1.039
			c-2.263,0.997-4.48,2.083-6.411,3.832c-2.686-2.399-5.853-3.709-9.051-4.933c-10.182-3.224-13.753-11.823-13.938-17.739
			c-0.089-1.989-0.014-4.117,0.226-6.108l0.424-3.018l0.708-4.088c0.317-2.309,0.334-4.872-0.437-6.983
			c-0.376-0.965-1.072-1.841-1.915-2.594c0.931-1.233,2.007-2.656,2.969-3.907c0.016,0.036,0.031,0.07,0.047,0.107
			c0.008,0.019,0.017,0.039,0.025,0.057c0.162,0.448,0.343,0.793,0.486,1.03c0.072,0.136,0.147,0.274,0.23,0.414l-1.615,2.162
			c1.992,2.247,2.097,5.43,2.006,8.418l-0.24,1.854l-0.876,5.296l-0.345,3.016l-0.107,2.776
			c-0.343,6.779,4.819,15.462,14.315,18.313c2.474,0.994,4.873,2.14,7.09,3.772c2.218-1.615,4.646-2.745,7.135-3.714
			c10.989-3.094,14.315-11.568,14.449-17.164c0.123-2.067-0.059-4.104-0.271-6.079l-0.422-2.807l-0.573-3.499
			c-0.345-1.943-0.274-4.31,0.074-6.195c0.59-0.259,1.223-0.833,1.609-2.06l0.057-0.18c0.642-0.444,1.431-1.161,1.954-1.871
			C82.284,23.652,82.368,23.79,82.531,24.053z M84.597,15.781c-0.762,1.748-1.242,2.254-2.413,2.664c0,0-3.778,1.158-4.744,1.529
			c-1.121,0.43-1.998,0.897-2.57,1.225c-2.011-1.225-5.707-0.312-6.604,0.025c-1.446,0.543-2.928,1.428-4.157,2.305
			c-0.183-0.18-0.428-0.474-0.635-0.489c0,0,0.627,0.381,0.514,1.042c0.159-0.045,0.778-0.511,1.2-0.14
			c0.424,0.37-0.009,0.817-0.157,1.168c0.454-0.351,0.941-0.626,1.436-0.302c0.55,0.36-0.07,1.021-0.253,1.332
			c0.408-0.073,0.829-0.384,1.119,0.038c0.29,0.422-0.146,0.642-0.558,0.875c0.395-0.029,0.424,0.279,1.555,0.349
			c-0.526,0.05-1.072,0.108-1.423,0.139c-0.254-0.057-0.556-0.201-1.06-0.342c-1.306-0.366-2.085-0.771-2.599-0.938
			c-0.514-0.167-1.118-0.22-1.505-0.1c-0.208,0.004-0.211,0.088-0.389,0.102c1.729-0.615,1.212-2.694,2.033-3.952
			c0.834-1.567,2.576-1.941,4.127-2.22c2.019-0.278,5.182-0.584,6.118-0.776c1.688-0.346,3.791-1.034,4.215-1.234
			c0.517-0.243,2.785-1.045,3.661-1.364S83.558,16.001,84.597,15.781z M84.711,17.853c-0.252,0.656-1.117,2.161-2.063,2.641
			c-1.301,0.731-3.143,1.039-4.55,1.197c-1.127,0.162-2.007-0.055-2.415-0.184c0,0-0.419-0.134-0.56-0.24
			c2.108-1.135,4.681-1.903,7.369-2.625C83.984,18.124,84.169,18.069,84.711,17.853z M58.202,20.138
			c-0.561,0.072-1.275,0.206-1.701,0.97c-0.199-0.161-0.132-0.489,0.091-0.779C56.754,20.118,57.336,19.889,58.202,20.138z
			 M82.708,21.91c-0.139,0.288-0.336,0.589-0.742,0.905c-0.846,0.714-4.877,1.296-6.418,0.782c1.635-0.659,1.805-1.208,1.905-1.625
			c1.226-0.046,3.063-0.186,5.118-1.081C82.81,20.831,82.855,21.607,82.708,21.91z M73.917,21.021
			c0.474,0.159,1.196,0.489,0.861,0.591c-2.575,0.432-4.266,1.152-8.711,2.853c-0.151-0.006-0.337,0.022-0.486,0.079
			c0.05-0.228,0.03-0.496-0.228-0.754c-0.208-0.208-0.551-0.313-1.082-0.119c1.302-0.85,2.639-1.702,4.109-2.243
			c1.174-0.432,2.437-0.64,3.801-0.665C73.014,20.748,73.501,20.881,73.917,21.021z M60.68,21.16
			c0.038,0.139,0.009,0.514-0.258,0.654c-0.498,0.232-0.802-0.284-1.211-0.401c-0.763-0.118-1.674,0.014-2.405-0.177
			c0.196-0.282,0.524-0.582,0.919-0.715c0.467-0.146,1.363-0.345,1.998-0.148C60.343,20.565,60.605,20.907,60.68,21.16z
			 M61.289,21.422c0,0.117-0.038,0.155-0.094,0.241c-0.056,0.086-0.18,0.217-0.418,0.155c-0.028-0.207,0.09-0.425,0.279-0.542
			C61.158,21.245,61.289,21.305,61.289,21.422z M77.187,22.038c-0.079,0.357-0.604,0.783-1.015,1.016
			c-0.459,0.261-1.114,0.418-1.336,0.766c-0.294,0.262-2.844,0.997-4.046,1.299s-2.184,0.56-3.337,0.752
			c-0.216-0.177-0.544-0.164-0.752-0.127c0.298-0.615,0.182-1.032-0.354-1.191l5.832-2.103c1.074-0.309,2.419-0.691,3.316-0.719
			C76.081,21.872,76.482,22.038,77.187,22.038z M59.41,21.774c0.382,0.118,1.071,0.808,1.248,1.16
			c0.058,0.278-0.058,0.541-0.235,0.747c-0.162,0.059-0.336,0.235-0.394,0.393c0.028-0.174-0.016-0.334-0.119-0.467
			c-0.162-0.102-0.333-0.282-0.406-0.474c-0.608,0.011-1.505,0.032-1.833-0.268c-0.007-0.119-0.052-0.223-0.171-0.365
			c0.193-0.023,0.906,0.039,1.29,0.052c0.385,0.014,0.913,0.007,1.016,0.162c0,0.072-0.071,0.072-0.117,0.088
			c-0.044,0.014-0.102-0.016-0.13,0.014c0.117,0.191,0.351,0.233,0.556,0.176c0.118-0.058,0.178-0.176,0.103-0.322
			c-0.113-0.222-0.379-0.261-0.718-0.307c-0.339-0.047-2.11-0.073-2.11-0.073c-0.188-0.206-0.605-0.503-0.873-0.637
			c-0.03-0.131,0.127-0.245,0.213-0.201C57.448,21.701,58.621,21.673,59.41,21.774z M61.733,21.879
			c-0.027,0.117,0.045,0.089,0.147,0.206c0,0.22-0.147,0.395-0.321,0.497c-0.161,0.088-0.409,0.146-0.571,0.015
			c-0.089-0.057-0.306-0.117-0.306-0.117c0-0.381,0.454-0.572,0.746-0.703C61.557,21.719,61.691,21.749,61.733,21.879z
			 M44.202,22.178c1.688,0.295,1.604,1.819,2.02,2.471c-1.424,0.077-2.052,0.127-3.146-0.125c-3.871-0.893-4.607-2.716-5.648-5.169
			C39.304,21.048,43.413,22.04,44.202,22.178z M81.849,23.197c-0.85,1.186-2.413,2.178-3.019,2.438
			c-0.439,0.189-1.013,0.33-1.955,0.109c-0.493-0.114-0.954-0.325-1.278-0.584c-0.633-0.506-0.597-0.852-0.586-1.03
			c0.008-0.122,0.218-0.39,0.432-0.321C77.775,24.402,81.076,23.69,81.849,23.197z M61.424,22.882
			c0.074-0.014,0.134-0.059,0.222-0.043c0.1,0.336-0.269,0.442-0.484,0.497c-0.161,0.016-0.25-0.118-0.308-0.233
			c0-0.1,0.002-0.231-0.071-0.313c0.002-0.046,0.02-0.089,0.071-0.126C60.988,22.853,61.191,22.956,61.424,22.882z M46.356,24.902
			c0.119,0.159,0.339,0.7,1.145,1.279c-0.451,0.123-0.937,0.171-1.64,0.208c-0.953,0.05-1.813-0.05-1.813-0.05
			c-1.41-0.114-3.206-1.809-3.652-2.468c0.949,0.499,2.212,0.894,3.198,1.015C44.579,25.005,45.534,25.024,46.356,24.902z
			 M50.943,26.111c-0.009,0.163,0.021,0.416,0.323,0.613c-0.202,0.025-0.649,0.021-0.887,0.102
			c-1.475-0.083-2.954-0.525-3.791-1.944c0.373-0.001,0.714-0.025,1.275,0.096C48.666,25.152,49.875,25.508,50.943,26.111z
			 M76.934,26.034c-0.069,0.298-0.656,0.745-1.274,0.836c-0.017,0.004-0.083-0.472-0.746-0.428
			c-0.373,0.025-0.371,0.077-0.634,0.136c0.117-0.162,0.083-0.401-0.019-0.578c-0.03-0.044-0.106-0.115-0.166-0.115
			c-0.174,0-0.357,0.058-0.576,0.197c-0.223,0.141-0.448,0.104-0.625,0.171c-0.312,0.117-0.525,0.3-0.759,0.663
			c-0.067,0.051-0.161,0.028-0.313,0.017c-0.143-0.062-2.636,0.304-2.636,0.304c-0.466-0.03-1.106,0.016-1.919-0.233
			c0,0,0.674-0.335,0.318-0.929c0,0,3.359-0.734,4.686-1.105s2.043-0.612,2.49-0.83c-0.079,0.308,0.218,0.851,0.749,1.268
			C75.877,25.696,76.627,25.974,76.934,26.034z M79.745,25.381c-0.754,2.398-2.888,2.095-3.649,1.612
			c0.786-0.24,1.119-0.906,1.119-0.906C78.285,26.174,79.001,25.978,79.745,25.381z M50.166,26.961
			c-0.32,0.179-0.237,0.428-0.166,0.594c0.101,0.235,0.366,0.362,0.366,0.362c-0.15,0.092-0.327,0.272-0.461,0.465
			c-0.342-0.182-0.825-0.447-1.246-0.588c-0.784-0.457-1.533-0.729-2.347-0.673c-0.236,0.016-0.753,0.2-0.922,0.27
			c-0.553-0.096-1.385-0.57-1.607-0.834c1.017,0.14,1.677,0.104,2.266,0.064c1.008-0.074,1.666-0.245,1.666-0.245
			C48.549,26.703,49.144,26.972,50.166,26.961z M61.5,33.126c-0.266,0.015-0.096,0.128-0.668,0.567
			c-0.117,0.088-0.531,0.146-0.882,0.11c0.097-0.181,0.246-0.342,0.048-0.744c-0.203-0.414-0.519-0.518-0.668-0.521
			c0.232,0.17,0.414,0.383,0.522,0.632c0.086,0.2,0.021,0.436-0.155,0.611c-0.186,0.045-0.407,0.166-0.563,0.307
			c0.14-0.527-0.324-1.305-0.71-1.415c0.343,0.46,0.643,0.781,0.454,1.577c-0.119,0.268-1.185,1.001-1.508,0.735
			c-0.519-0.459-0.094-0.826-0.286-1.338c-0.043-0.089-0.074-0.216-0.149-0.185c0.146,0.497-0.069,0.713-0.03,1.228
			c-0.357,0.127-0.549,0.199-0.803,0.149c-0.118-0.147-0.208-0.387-0.131-0.769c0.077-0.382,0.376-0.882,0.7-1.28
			c0,0-0.166,0.057-0.32,0.2c-0.462,0.427-0.701,0.985-0.666,1.381c0.016,0.183,0.037,0.339,0.172,0.475
			c-0.096,0.002-0.585-0.024-0.727-0.314c-0.279-0.571,0.13-1.1,0.436-1.48c0.251-0.312,0.664-0.498,0.664-0.498
			c0.644-0.307,1.577-0.187,2.226-0.286c0.65-0.099,1.196,0.002,1.74,0.174S61.09,32.835,61.5,33.126z M71.433,44.46
			c0.077,0.426-0.474,0.717-0.898,0.778l-0.134,0.013c0.013-0.402-0.067-1.166,0.35-1.242C71.169,43.933,71.354,44.135,71.433,44.46
			z M64.826,39.803c1.193,1.459,2.306,2.228,2.54,2.391c0,0-1.632-1.527-1.959-2.123c2.754,2.144,4.903,2.994,5.927,3.725
			c-0.381-0.086-0.851-0.13-1.082,0.178c-0.231,0.308-0.054,0.783-0.199,1.237c-0.079,0.343-0.312,0.401-0.43,0.401
			c-0.205-1.29-1.23-1.492-1.23-1.492c0.258,0.307,0.586,0.605,0.66,1.066c-0.961-0.169-2.777-1.281-2.776-1.296
			c-0.952,0.053-2.107-0.059-2.713-0.453c-0.691-0.452,0.749-0.526,0.159-0.997c-0.261-0.208-0.562,0.017-0.782-0.313
			c-0.149-0.223,0.402-0.707,0.164-1.176c-0.15-0.296-0.44-0.287-0.688-0.049c-0.253,0.237-0.398,1.18-1.135,0.994
			c-0.432-0.109-0.298-0.841-0.759-1.183c-0.301-0.223-0.717-0.224-0.968,0.037c-0.308,0.321-0.238,0.756-0.238,0.756
			c-0.875-1.74-0.03-5.436-0.03-5.436c-0.925,2.403-0.917,4.478-0.209,5.744c-0.226-0.111-0.596-0.472-0.689-0.685
			c-0.197-0.448-0.487-1.072-0.412-1.682c-0.551,0.968,0.511,2.726,0.089,2.717c-0.35-0.007-0.345-0.692-0.782-0.923
			c-0.45-0.238-0.614,0.162-0.796,0.007c-0.202-0.171,0.752-0.849-0.082-1.325c-0.451-0.258-0.654,0.223-1.023,0.012
			c-0.37-0.212-0.846-0.116-0.846-0.116c-0.16-0.846,0.902-4.66,0.902-4.66c0.634,0.317,1.524-0.199,1.524-0.199
			c0.477,0.897,1.432,0.164,1.85-0.184c0.316-0.264,0.416-0.487,0.787-0.651c0.402-0.179,0.871,0.074,1.282-0.104
			c0.441-0.192,0.544-0.458,0.544-0.458c0.57,0.499,1.249,2.313,1.521,2.932C63.881,38.612,64.826,39.803,64.826,39.803z
			 M56.156,26.526c-0.938-0.149-1.749,0.271-2.471,1.224c-0.943,0.29-1.513,0.64-2.246,1.386c-0.521-0.179-1.116-0.508-1.292-0.616
			c0.109-0.271,0.303-0.49,0.56-0.604c-0.238-0.149-0.652-0.353-0.578-0.621c0.14-0.506,0.794-0.209,1.518-0.503
			c-0.29-0.141-0.633-0.361-0.492-0.775c0.139-0.414,0.781-0.348,1.295-0.222c-0.283-0.275-0.346-0.741-0.043-0.941
			c0.304-0.198,0.966-0.129,0.966-0.129c0.015-0.353,0.276-0.603,0.47-0.88c-0.308,0.072-0.486,0.459-0.589,0.692
			c-0.946-0.079-1.374,0.384-1.158,0.979c-0.454-0.089-0.92-0.011-1.098,0.376c-1.156-0.688-2.882-1.186-4.109-1.255
			c-0.183-0.01-0.259-0.008-0.423-0.03c-0.135-0.149-0.265-0.522-0.305-0.672c-0.137-0.52-0.391-1.256-0.931-1.516
			c1.13,0.153,4.168,0.738,6.067,1.528c-1.392-1.028-5.159-1.546-7.453-2.081c-1.717-0.401-4.058-1.136-5.514-2.166
			c-0.824-0.582-1.302-1.262-1.381-2.02c1.044,0.371,1.937,0.565,3.135,0.809c0.811,0.165,1.468,0.275,2.624,0.521
			c1.077,0.23,2.646,0.693,2.646,0.693c2.445,0.704,5.181,0.25,7.581,1.068c1.078,0.415,1.026,1.727,1.186,2.724l0.258,0.257
			C54.378,25.139,55.044,25.691,56.156,26.526z M63.955,39.742c1.161,1.456,1.243,1.46,2.255,2.472
			c-0.551-0.268-1.739-1.376-2.387-2.179c-0.993-1.23-1.455-2.31-1.623-3.454C62.201,36.581,62.794,38.286,63.955,39.742z
			 M51.375,21.979c-1.402-0.749-3.41-0.682-4.547-0.977c-1.154-0.3-1.302-0.373-1.302-0.373c0.449,0.076,0.865,0.207,1.348,0.267
			c1.535,0.191,3.229,0.268,4.513,0.812c0.727,0.308,1.116,1.044,1.293,1.826C52.381,22.93,51.956,22.289,51.375,21.979z"
				/>
				<path
					fill="#094183"
					d="M24.43,53.815l-0.275,0.001l0.068,0.267c0.65,2.543-0.038,5.014-1.838,6.61l-0.568,0.504l0.748-0.131
			c0.574-0.1,1.334-0.208,2.131-0.146c1.876,0.118,3.199,0.503,4.28,1.229c-0.058,0.285-1.13,5.588-1.13,5.588
			c-0.019,0.084-0.028,0.167-0.028,0.246c0,0.357,0.176,0.668,0.359,0.931l0.01,0.014l0.011,0.012
			c0.823,0.866,1.871,1.154,2.883,1.434l0.085,0.023c4.424,1.032,9.094,0.113,13.153-2.585l2.186-1.427l0.961-0.593l1.157-0.71
			c2.228-1.321,4.063-2.04,6.133-2.401c4.283-0.753,8.871,0.159,12.276,2.44c0.013,0.008,0.445,0.26,0.445,0.26
			c-0.011-0.006,2.872,1.883,2.872,1.883c0.001,0.003,1.788,1.132,1.788,1.132c3.957,2.486,9.083,3.224,13.377,1.925
			c0.726-0.242,2.227-0.742,2.44-2.059l0.006-0.038c0,0-1.083-5.632-1.129-5.873c0.003-0.001,0.157-0.114,0.157-0.114l-0.049,0.038
			c1.808-1.011,4.177-1.364,6.335-0.942l0.782,0.153l-0.599-0.526c-1.803-1.585-2.478-4.06-1.807-6.622l0.07-0.267l-0.275-0.003
			c-2.741-0.03-5.296,0.349-7.595,1.128c-1.142,0.439-2.856,1.098-3.361,2.647l-0.01,0.031l-0.06,4.492
			c0,0.002-0.009,1.258-0.012,1.77c-2.669-0.056-4.878-1.394-6.742-2.659c0.001,0.001-0.651-0.45-0.651-0.45
			c-0.004-0.004-2.668-1.745-2.668-1.745c-3.159-1.958-6.29-3.122-9.569-3.564c-5.615-0.734-11.224,0.636-16.224,3.963
			c-0.002,0.001-2.512,1.708-2.512,1.708c-1.972,1.297-4.118,2.558-6.723,2.588c0.003-0.335,0.03-5.947,0.03-5.947
			c-0.015-0.895-0.701-1.723-2.044-2.465C30.842,54.37,27.942,53.798,24.43,53.815z"
				/>
				<g>
					<path
						fill="#FFFFFF"
						d="M32.668,60.282c0.716-0.206,1.45-0.433,2.046-0.992c-0.001,0.304-0.013,4.534-0.013,4.686
				c-1.558-0.043-3.311-0.255-4.628-1.439c-0.001-0.003-0.056-0.075-0.056-0.075c-0.146-0.188-0.269-0.346-0.269-0.542
				c0-0.07,0.016-0.146,0.053-0.227c0.382-0.709,1.139-0.916,1.869-1.116l0.595-0.176C32.263,60.401,32.668,60.282,32.668,60.282z"
					/>
					<path
						fill="#FFFFFF"
						d="M81.053,59.537c0.715,0.626,1.595,0.888,2.453,1.13c0.755,0.211,1.535,0.431,2.18,0.908
				c0.197,0.176,0.29,0.329,0.29,0.489c0,0.071-0.018,0.142-0.053,0.218c-0.415,0.79-1.383,1.182-2.074,1.408
				c-0.917,0.257-1.836,0.388-2.797,0.406C81.053,63.942,81.053,59.82,81.053,59.537z"
					/>
					<path
						fill="#FFFFFF"
						d="M25.057,55.928c0-0.499-0.056-1.006-0.152-1.515c2.669,0.107,5.74,0.415,8.406,1.856
				c0.666,0.425,1.42,1.01,1.42,1.786c0,0.021,0,0.041-0.001,0.061c-0.125,0.993-1.129,1.296-2.014,1.563l-1.154,0.354
				c-0.942,0.262-1.906,0.546-2.34,1.522c-1.518-0.912-3.425-1.331-5.676-1.246C24.522,59.146,25.057,57.592,25.057,55.928z"
					/>
					<path
						fill="#FFFFFF"
						d="M84.861,60.43l-0.094-0.036l-1.119-0.338c-0.912-0.242-1.773-0.471-2.418-1.188
				c-0.118-0.17-0.169-0.389-0.169-0.607c0-0.196,0.041-0.388,0.109-0.542c0.839-1.29,2.273-1.741,3.659-2.176l0.365-0.116
				c1.703-0.463,3.593-0.71,5.764-0.762c-0.096,0.55-0.153,1.088-0.153,1.605c0,1.678,0.508,3.154,1.464,4.292
				c-2.205-0.087-4.119,0.322-5.696,1.213C86.314,60.999,85.516,60.68,84.861,60.43z"
					/>
					<path
						fill="#FFFFFF"
						d="M41.672,62.377l3.879-2.498c4.699-2.988,9.754-4.17,15.022-3.511c3.039,0.393,5.938,1.425,8.859,3.154
				l2.496,1.562l2.554,1.663c2.45,1.665,5.432,2.33,8.395,1.871c1.196-0.263,2.532-0.575,3.432-1.661
				c0.053,0.27,1.025,5.3,1.025,5.3c-0.143,0.698-0.908,1.04-1.522,1.314l-0.156,0.07c-4.451,1.478-9.852,0.593-14.09-2.312
				c-0.001-0.001-3.849-2.528-3.849-2.528c-4.002-2.582-8.789-3.51-13.482-2.614c-2.301,0.44-4.553,1.407-7.09,3.044l-3.551,2.304
				c-3.473,2.221-7.545,3.083-11.465,2.427c-1.201-0.232-2.444-0.47-3.394-1.348c-0.194-0.21-0.293-0.398-0.317-0.606
				c0.003,0,0.925-4.598,1.057-5.256c1.292,1.424,3.159,1.735,5.001,1.835C36.859,64.706,39.145,64.002,41.672,62.377z"
					/>
				</g>
				<path
					fill="#094183"
					d="M31.695,65.505l-1.378-0.203c-0.035,0.026-0.047,0.105-0.047,0.105c0.132,0.068,0.139,0.098,0.129,0.174
			l-0.381,2.572c-0.011,0.075-0.025,0.101-0.139,0.098c-0.033,0.028-0.045,0.11-0.045,0.11c0.026,0.036,0.903,0.165,0.903,0.165
			l0.031,0.006l0.018-0.114c-0.107-0.053-0.131-0.082-0.131-0.13c0-0.013,0.001-0.026,0.003-0.041c0,0,0.151-1.016,0.151-1.024
			c0.003,0.029,0.615,0.12,0.615,0.12c0.792,0.118,1.144-0.316,1.212-0.76C32.701,66.138,32.489,65.621,31.695,65.505z
			 M31.403,66.78c0,0-0.541-0.08-0.546-0.08c0.029-0.003,0.127-0.653,0.14-0.741c0.056,0.009,0.516,0.077,0.516,0.077
			c0.232,0.033,0.37,0.092,0.438,0.183l0.046,0.27C31.957,66.76,31.795,66.839,31.403,66.78z M34.34,65.82
			c-0.39-0.012-0.733,0.109-0.993,0.353c-0.297,0.279-0.471,0.705-0.487,1.198c-0.032,0.955,0.521,1.615,1.375,1.644
			c0.39,0.013,0.732-0.109,0.992-0.352c0.299-0.279,0.472-0.704,0.488-1.198c0.016-0.494-0.129-0.93-0.409-1.229
			C35.063,65.977,34.729,65.834,34.34,65.82z M34.776,68.282c-0.134,0.127-0.311,0.187-0.523,0.18
			c-0.504-0.016-0.769-0.397-0.748-1.07c0.012-0.382,0.115-0.673,0.297-0.841c0.132-0.125,0.308-0.185,0.519-0.177
			c0.212,0.007,0.384,0.078,0.509,0.212c0.169,0.181,0.252,0.478,0.24,0.857C35.059,67.822,34.957,68.113,34.776,68.282z
			 M37.517,66.737c-0.37-0.003-0.688-0.006-0.737-0.233c-0.043-0.208,0.083-0.356,0.356-0.414c0.46-0.097,0.735,0.051,0.758,0.159
			l0.011,0.07c0.029,0.053,0.117,0.07,0.117,0.07c0.036-0.02,0.309-0.816,0.309-0.816l0.037-0.109l-0.147,0.069l-0.096,0.081
			l-0.149-0.021l-0.172-0.034c-0.219-0.04-0.462-0.031-0.723,0.023c-0.818,0.176-1.005,0.721-0.917,1.135
			c0.133,0.627,0.773,0.635,1.287,0.64c0.393,0.005,0.732,0.009,0.785,0.255c0,0.002-0.04,0.28-0.04,0.28
			c-0.08,0.124-0.262,0.217-0.541,0.277c-0.243,0.05-0.602,0.04-0.761-0.065L36.82,68.01l-0.019-0.076l-0.087-0.018
			c-0.038,0.023-0.231,0.802-0.231,0.802s0.02,0.004,0.031,0.006v0.002l0.063,0.024c0.004-0.008,0.008-0.007,0.012-0.014
			c0.01,0.001,0.03,0.004,0.03,0.004c0.019-0.036,0.031-0.056,0.045-0.058c0.001-0.001,0.184,0.017,0.184,0.017l0.179,0.033
			c0.001,0,0.71-0.016,0.71-0.016c0.915-0.194,1.225-0.789,1.125-1.26C38.714,66.747,38.049,66.742,37.517,66.737z M41.16,67.242
			c0,0-0.966-2.115-0.971-2.126c0.014,0.024,0.62-0.25,0.62-0.25c0.118-0.054,0.146-0.05,0.196,0.028
			c0.039,0.011,0.114-0.023,0.114-0.023c0.016-0.041-0.286-0.704-0.286-0.704c-0.042-0.016-0.117,0.018-0.117,0.018
			c-0.004,0.099-0.017,0.124-0.136,0.179l-1.801,0.819c-0.127,0.058-0.151,0.046-0.186-0.003c-0.038-0.01-0.112,0.024-0.112,0.024
			c-0.016,0.041,0.285,0.705,0.285,0.705c0.041,0.016,0.117-0.018,0.117-0.018c-0.001-0.018-0.003-0.034-0.003-0.048
			c0-0.087,0.026-0.112,0.126-0.157c0,0,0.633-0.289,0.635-0.289c0,0,0,0,0,0.002c0,0.071,0.927,2.112,0.927,2.112
			c0.014,0.03,0.022,0.053,0.022,0.072c0,0.03-0.018,0.049-0.053,0.075c-0.001,0.004-0.002,0.011-0.002,0.016
			c0,0.041,0.025,0.098,0.025,0.098c0.043,0.016,0.805-0.332,0.805-0.332l0.029-0.014l-0.049-0.103
			C41.216,67.331,41.193,67.315,41.16,67.242z M44.379,64.841c0.001,0.019,0.003,0.036,0.003,0.05c0,0.056-0.021,0.085-0.113,0.144
			c0,0-1.118,0.723-1.123,0.726c0,0,0.001,0,0.001-0.003c0-0.046-0.349-0.588-0.396-0.663c0.048-0.031,0.598-0.389,0.598-0.389
			c0.116-0.074,0.142-0.068,0.179-0.033c0.039,0.003,0.107-0.041,0.107-0.041c0.009-0.045-0.364-0.621-0.364-0.621
			c-0.045-0.009-0.112,0.036-0.112,0.036c0.003,0.02,0.004,0.036,0.004,0.05c0,0.058-0.024,0.087-0.113,0.144
			c0,0-0.622,0.4-0.626,0.403c0.003-0.001,0.003-0.001,0.003-0.003c0-0.05-0.368-0.617-0.37-0.62c0.018,0.025,1.046-0.64,1.046-0.64
			c0.117-0.077,0.142-0.07,0.178-0.035c0.038,0.005,0.109-0.04,0.109-0.04c0.009-0.044-0.365-0.62-0.365-0.62
			c-0.045-0.011-0.113,0.034-0.113,0.034c0.01,0.094,0.008,0.122-0.107,0.196l-1.714,1.107c-0.01,0.045,0.034,0.112,0.034,0.112
			c0.193-0.067,0.211-0.039,0.251,0.019l1.409,2.181c0.041,0.064,0.05,0.09-0.068,0.188c-0.006,0.042,0.038,0.109,0.038,0.109
			c0.044,0.01,1.816-1.135,1.816-1.135c0.117-0.075,0.141-0.067,0.179-0.024c0.041,0.006,0.112-0.039,0.112-0.039
			c0.009-0.044-0.371-0.631-0.371-0.631C44.446,64.796,44.379,64.841,44.379,64.841z M47,63.446L46.9,63.385l-0.859-0.495
			c0.009-0.01,0.013-0.021,0.021-0.031c0.293-0.27,0.32-0.656,0.068-1.048c-0.296-0.457-0.819-0.52-1.366-0.166l-1.221,0.79
			c-0.011,0.044,0.031,0.112,0.031,0.112c0.133-0.029,0.158-0.016,0.201,0.05l1.413,2.182c0.045,0.071,0.041,0.097-0.022,0.155
			c-0.004,0.041,0.041,0.11,0.041,0.11c0.043,0.01,0.755-0.451,0.755-0.451l0.028-0.018l-0.063-0.096
			c-0.129,0.029-0.153,0.017-0.197-0.052c0,0-0.56-0.859-0.564-0.868c0.019,0.022,0.432-0.244,0.433-0.245
			c0,0.019,0.881,0.52,0.881,0.52l0.146,0.104c0.002,0.002,0.004,0.007,0.004,0.014c0,0.014-0.009,0.036-0.055,0.072v0.01
			c0,0.041,0.038,0.101,0.038,0.101c0.044,0.009,0.769-0.461,0.769-0.461c0.009-0.045-0.036-0.111-0.036-0.111
			C47.233,63.586,47.179,63.554,47,63.446z M45.418,62.775c-0.001,0-0.556,0.361-0.561,0.364c0.001-0.001,0.001-0.001,0.001-0.003
			c0-0.047-0.336-0.573-0.384-0.645c0.048-0.032,0.534-0.349,0.534-0.349c0.27-0.172,0.467-0.153,0.604,0.059
			c0.001,0,0.065,0.266,0.065,0.266C45.655,62.575,45.568,62.677,45.418,62.775z M52.802,60.189
			c-0.035-0.001-0.097,0.041-0.097,0.041c-0.008,0.046,0.045,0.145,0.045,0.145c0.001,0.001-0.031,0.198-0.031,0.198
			c-0.077,0.146-0.242,0.275-0.422,0.331c-0.207,0.061-0.415,0.043-0.601-0.056c-0.23-0.123-0.407-0.361-0.501-0.673
			c-0.117-0.381-0.118-0.685-0.001-0.903c0.094-0.178,0.262-0.301,0.516-0.378c0.275-0.085,0.577,0.005,0.632,0.187l0.006,0.02
			l0.033,0.061l0.056,0.004c0.035-0.024,0.2-0.806,0.2-0.806l0.018-0.09l-0.112,0.043l-0.092,0.077
			c-0.001,0.001-0.131-0.023-0.131-0.023l-0.152-0.037c-0.21-0.042-0.416-0.03-0.632,0.036c-0.714,0.218-1.287,0.92-0.959,1.997
			c0.27,0.884,1.021,1.329,1.827,1.083c0.515-0.156,0.695-0.426,0.804-0.587l0.098-0.113c0.021-0.007,0.05,0.003,0.085,0.023
			c0.046-0.014,0.073-0.079,0.073-0.079C53.455,60.652,52.802,60.189,52.802,60.189z M55.962,60.317l-0.063-0.093
			c0,0-0.562-0.8-0.571-0.814c0.013-0.006,0.023-0.014,0.035-0.02c0.38-0.122,0.563-0.458,0.499-0.918
			c-0.075-0.538-0.524-0.817-1.171-0.728l-1.442,0.205c-0.026,0.035-0.015,0.117-0.015,0.117c0.131,0.028,0.148,0.05,0.159,0.13
			l0.364,2.571c0.009,0.084-0.004,0.107-0.085,0.134c-0.02,0.034-0.01,0.115-0.01,0.115c0.036,0.027,0.878-0.092,0.878-0.092
			l0.031-0.004l-0.014-0.11c-0.133-0.031-0.151-0.054-0.159-0.13c0,0-0.144-1.015-0.146-1.024c0.006,0.029,0.496-0.041,0.497-0.041
			l0,0c0,0.028,0.58,0.841,0.58,0.841l0.088,0.155v0.006c0,0.013-0.009,0.035-0.08,0.051c-0.013,0.019-0.016,0.05-0.016,0.075
			c0,0.023,0.003,0.04,0.003,0.04c0.035,0.028,0.888-0.092,0.888-0.092c0.027-0.036,0.016-0.117,0.016-0.117
			C56.116,60.543,56.078,60.493,55.962,60.317z M55.175,58.876c-0.065,0.088-0.188,0.144-0.365,0.169c0,0-0.655,0.093-0.659,0.094
			c0.003-0.001,0.004-0.011,0.004-0.031c0-0.135-0.069-0.635-0.08-0.718c0.056-0.008,0.63-0.089,0.63-0.089
			c0.319-0.044,0.489,0.056,0.524,0.307L55.175,58.876z M58.637,60.115l-1.34,0c0,0,0.028-0.716,0.03-0.776c0.056,0,0.716,0,0.716,0
			c0.138,0,0.157,0.021,0.166,0.068l0.113,0.025l0.033-0.719l-0.114-0.031c-0.041,0.083-0.058,0.105-0.197,0.105h-0.746l0.031-0.728
			l1.228,0.032c0.137,0,0.154,0.019,0.164,0.069l0.114,0.025l0.031-0.721l-0.113-0.031c-0.043,0.084-0.06,0.105-0.197,0.105
			l-2.042,0l-0.032,0.114c0.2,0.049,0.2,0.081,0.2,0.151l0,2.6c0,0.076-0.008,0.103-0.161,0.122l-0.028,0.112l2.144,0.032
			c0.139,0,0.155,0.021,0.166,0.078l0.113,0.026l0.033-0.73l-0.114-0.031C58.792,60.095,58.777,60.115,58.637,60.115z
			 M61.302,58.774c0.042-0.008,0.565-0.664,0.565-0.664l-0.025-0.019V58.09l-0.003-0.003l-0.063-0.05
			c-0.036,0.033-0.066,0.05-0.116,0.043l-0.134-0.07l-0.151-0.09c-0.194-0.11-0.425-0.183-0.685-0.218
			c-0.827-0.11-1.187,0.339-1.244,0.757c-0.085,0.637,0.515,0.857,0.997,1.035c0.368,0.136,0.685,0.252,0.652,0.502l-0.132,0.25
			c-0.117,0.09-0.321,0.116-0.603,0.079c-0.243-0.033-0.576-0.162-0.691-0.314l-0.039-0.116l0.008-0.076l-0.077-0.045
			c-0.043,0.009-0.486,0.678-0.486,0.678l-0.016,0.024l0.079,0.06c0.049-0.009,0.124-0.062,0.125-0.062
			c0,0.001,0.159,0.078,0.159,0.078l0.161,0.093c0.001,0,0.676,0.223,0.676,0.223c0.588,0.078,0.94-0.078,1.132-0.225
			c0.191-0.147,0.316-0.354,0.346-0.583c0.095-0.718-0.528-0.945-1.029-1.129c-0.345-0.127-0.642-0.237-0.612-0.466l0.11-0.204
			c0.084-0.063,0.206-0.085,0.363-0.063c0.412,0.056,0.574,0.231,0.615,0.284l0.045,0.119c-0.011,0.059-0.019,0.097-0.019,0.097
			C61.227,58.729,61.302,58.774,61.302,58.774z M64.213,61.386c0.048,0.014,0.105-0.024,0.105-0.024
			c0.013-0.039-0.292-0.78-0.292-0.78c-0.031-0.02-0.103-0.016-0.103-0.016c-0.03,0.036-0.038,0.146-0.038,0.146
			c0,0.003-0.137,0.154-0.137,0.154c-0.144,0.082-0.345,0.102-0.525,0.05c-0.479-0.13-0.701-0.625-0.544-1.201
			c0.104-0.385,0.267-0.643,0.482-0.764c0.172-0.098,0.386-0.113,0.634-0.044c0.171,0.047,0.324,0.157,0.4,0.29l0.039,0.218
			l-0.013,0.077l0.045,0.035c0.043-0.002,0.595-0.574,0.595-0.574l0.064-0.065l-0.114-0.023l-0.118,0.016l-0.094-0.085l-0.115-0.117
			c-0.152-0.147-0.333-0.247-0.553-0.307c-0.394-0.11-0.794-0.066-1.125,0.123c-0.359,0.204-0.618,0.568-0.75,1.05
			c-0.243,0.89,0.154,1.667,0.964,1.889c0.519,0.141,0.816,0.009,0.992-0.068l0.142-0.043
			C64.176,61.329,64.197,61.352,64.213,61.386z M66.662,62.485l0.184-2.629l0.02-0.161c0.009-0.02,0.025-0.06,0.112-0.028
			c0.04-0.017,0.072-0.093,0.072-0.093c-0.018-0.041-0.912-0.421-0.912-0.421c-0.041,0.017-0.072,0.093-0.072,0.093
			c0.08,0.069,0.092,0.095,0.092,0.107c0,0.003-0.001,0.006-0.001,0.007l-0.119,0.148c-0.004,0.003-1.76,1.965-1.76,1.965
			c-0.043,0.046-0.085,0.087-0.133,0.072c-0.039,0.019-0.069,0.093-0.069,0.093c0.018,0.041,0.724,0.341,0.724,0.341l0.029,0.012
			l0.044-0.103c-0.066-0.055-0.082-0.095-0.082-0.121c0-0.014,0.004-0.024,0.006-0.029l0.055-0.077
			c0.004-0.002,0.338-0.381,0.339-0.384c0.001,0.022,0.923,0.414,0.928,0.416c-0.019,0.002-0.054,0.536-0.054,0.536
			c-0.001,0.003-0.015,0.09-0.015,0.09c-0.005,0.009-0.009,0.019-0.084-0.001c-0.036,0.018-0.069,0.093-0.069,0.093
			c0.016,0.041,0.746,0.351,0.746,0.351c0.041-0.017,0.075-0.092,0.075-0.092C66.665,62.606,66.653,62.593,66.662,62.485z
			 M66.287,60.033c-0.053,0.23-0.143,1.155-0.146,1.193l-0.025-0.011c-0.068-0.059-0.511-0.251-0.606-0.292
			c0.049-0.056,0.695-0.823,0.78-0.923L66.287,60.033z M69.524,64.262l1.415-2.263c0.026-0.043,0.056-0.06,0.127-0.021
			c0.042-0.011,0.087-0.08,0.087-0.08c-0.011-0.043-0.875-0.584-0.875-0.584c-0.044,0.01-0.088,0.08-0.088,0.08
			c0.029,0.053,0.039,0.08,0.039,0.098c0,0.012-0.004,0.019-0.01,0.029c-0.026,0.038-0.334,0.255-0.661,0.483
			c0,0-0.705,0.501-0.814,0.578c0.003-0.013,0.006-0.029,0.008-0.044c0.06-0.173,0.151-0.684,0.151-0.684
			c0.07-0.418,0.164-0.992,0.193-1.041c0.009-0.014,0.023-0.026,0.087-0.014c0.033-0.014,0.075-0.082,0.075-0.082
			c-0.01-0.043-0.883-0.592-0.883-0.592c-0.045,0.01-0.087,0.079-0.087,0.079c0.066,0.087,0.063,0.119,0.037,0.162l-1.414,2.263
			c-0.031,0.048-0.064,0.056-0.118,0.026c-0.043,0.011-0.086,0.082-0.086,0.082c0.011,0.043,0.683,0.461,0.683,0.461l0.028,0.018
			l0.058-0.093c-0.056-0.063-0.074-0.099-0.074-0.126c0-0.018,0.008-0.033,0.018-0.048c0,0,0.971-1.554,1.102-1.765
			c-0.006,0.019-0.008,0.038-0.012,0.057c-0.1,0.339-0.334,1.49-0.334,1.49l-0.149,0.844c0.014,0.033,0.088,0.078,0.088,0.078
			c0.037-0.001,0.805-0.553,0.805-0.553s1.051-0.781,1.232-0.916l-0.049,0.079c-0.272,0.379-1.088,1.683-1.088,1.683
			c-0.024,0.038-0.049,0.061-0.137,0.014c-0.041,0.011-0.086,0.078-0.086,0.078c0.011,0.044,0.702,0.477,0.702,0.477
			c0.044-0.011,0.088-0.08,0.088-0.08C69.489,64.331,69.502,64.296,69.524,64.262z M73.171,66.139
			c-0.105,0.08-0.133,0.081-0.242,0.009c0,0-1.068-0.7-1.071-0.701c0.036-0.001,1.291-1.912,1.291-1.912
			c0.043-0.065,0.064-0.083,0.207-0.008c0.041-0.011,0.085-0.079,0.085-0.079c-0.008-0.044-0.805-0.566-0.805-0.566
			c-0.044,0.009-0.09,0.076-0.09,0.076c0.115,0.133,0.105,0.16,0.064,0.224l-1.423,2.17c-0.04,0.063-0.064,0.08-0.176,0.024
			c-0.04,0.011-0.087,0.079-0.087,0.079c0.01,0.045,1.704,1.154,1.704,1.154c0.114,0.074,0.117,0.102,0.095,0.157
			c0.012,0.038,0.08,0.084,0.08,0.084c0.045-0.01,0.447-0.623,0.447-0.623C73.239,66.184,73.171,66.139,73.171,66.139z
			 M75.269,64.675c0.098,0.091,0.089,0.108,0.085,0.117l-0.117,0.14c-0.008,0.009-1.838,1.901-1.838,1.901
			c-0.044,0.043-0.09,0.085-0.135,0.068c-0.041,0.016-0.075,0.089-0.075,0.089c0.016,0.043,0.711,0.37,0.711,0.37l0.03,0.013
			l0.046-0.103c-0.052-0.053-0.075-0.087-0.075-0.119c0-0.011,0.003-0.023,0.008-0.034c0-0.001,0.058-0.075,0.058-0.075
			c0.002-0.002,0.35-0.365,0.353-0.368c0,0.023,0.907,0.447,0.912,0.449c-0.02,0.002-0.077,0.535-0.077,0.535
			c0,0.003-0.016,0.089-0.016,0.089c-0.004,0.01-0.008,0.018-0.086-0.004c-0.036,0.017-0.072,0.089-0.072,0.089
			c0.015,0.043,0.734,0.381,0.734,0.381c0.042-0.015,0.076-0.089,0.076-0.089c-0.05-0.066-0.061-0.081-0.049-0.186
			c0.001-0.001,0.275-2.549,0.275-2.549l0.038-0.237c0.009-0.019,0.026-0.055,0.112-0.021c0.04-0.015,0.075-0.092,0.075-0.092
			c-0.015-0.043-0.896-0.453-0.896-0.453C75.304,64.601,75.269,64.675,75.269,64.675z M75.461,65.472
			c-0.062,0.23-0.187,1.149-0.191,1.186l-0.024-0.011c-0.063-0.06-0.502-0.272-0.597-0.316c0.05-0.055,0.728-0.797,0.817-0.893
			L75.461,65.472z M78.722,65.903c0.112,0.077,0.12,0.105,0.101,0.179l-0.414,1.675c-0.094,0.383-0.369,0.548-0.75,0.454
			c-0.19-0.046-0.326-0.137-0.405-0.267c-0.078-0.129-0.094-0.297-0.048-0.486l0.413-1.674c0.019-0.075,0.038-0.097,0.139-0.088
			c0.033-0.024,0.053-0.104,0.053-0.104c-0.023-0.038-0.885-0.25-0.885-0.25l-0.031-0.008l-0.008,0.032l-0.018,0.08
			c0.138,0.082,0.14,0.112,0.121,0.184l-0.411,1.662c-0.187,0.765,0.124,1.252,0.922,1.447c0.407,0.101,0.742,0.075,0.994-0.078
			c0.24-0.144,0.407-0.404,0.497-0.772l0.411-1.662c0.019-0.075,0.035-0.098,0.15-0.087c0.034-0.024,0.053-0.103,0.053-0.103
			c-0.023-0.038-0.825-0.237-0.825-0.237C78.744,65.824,78.722,65.903,78.722,65.903z M81.06,66.035l-1.278-0.005
			c-0.032,0.031-0.034,0.113-0.034,0.113c0.126,0.046,0.141,0.072,0.138,0.152c0,0.001-0.009,2.596-0.009,2.596
			c0,0.079-0.015,0.102-0.117,0.12l-0.026,0.11c0.031,0.031,1.197,0.036,1.197,0.036c0.509,0.003,0.9-0.128,1.163-0.388
			c0.263-0.262,0.398-0.654,0.4-1.166C82.497,66.626,81.96,66.039,81.06,66.035z M81.847,67.601
			c-0.004,0.764-0.25,1.008-1.019,1.004c0,0-0.334-0.001-0.337-0.001v-0.001c0.03-0.008,0.04-1.851,0.041-2.02
			c0.053,0.001,0.305,0.004,0.305,0.004c0.764,0.003,1.01,0.247,1.01,1V67.601z M85.555,67.827
			c-0.039-0.023-0.118-0.001-0.118-0.001c-0.019,0.091-0.03,0.117-0.165,0.151c0,0-1.291,0.333-1.294,0.334
			c0,0,0.003-0.004,0.003-0.01c0-0.09-0.149-0.664-0.171-0.748c0.056-0.015,0.693-0.179,0.693-0.179
			c0.134-0.034,0.156-0.021,0.178,0.024c0.035,0.016,0.115-0.005,0.115-0.005c0.023-0.038-0.149-0.704-0.149-0.704
			c-0.04-0.023-0.119-0.001-0.119-0.001c-0.019,0.091-0.03,0.115-0.163,0.149c0,0-0.716,0.184-0.722,0.186
			c0.002-0.001,0.003-0.004,0.003-0.011c0-0.094-0.154-0.692-0.154-0.696c0.008,0.029,1.196-0.279,1.196-0.279
			c0.134-0.033,0.154-0.019,0.178,0.026c0.036,0.016,0.115-0.006,0.115-0.006c0.023-0.038-0.152-0.704-0.152-0.704
			c-0.039-0.023-0.117-0.001-0.117-0.001c-0.019,0.09-0.031,0.117-0.162,0.151l-1.975,0.512c-0.023,0.038-0.003,0.117-0.003,0.117
			c0.198-0.001,0.212,0.026,0.23,0.095l0.652,2.517c0.018,0.074,0.017,0.101-0.123,0.156c-0.019,0.036,0.001,0.117,0.001,0.117
			c0.038,0.023,2.081-0.507,2.081-0.507c0.133-0.033,0.154-0.018,0.181,0.035c0.035,0.017,0.115-0.004,0.115-0.004
			C85.729,68.503,85.555,67.827,85.555,67.827z M49.756,62.09l-1.868-1.756c-0.039-0.038-0.166-0.169-0.166-0.169
			c-0.014-0.028-0.023-0.06,0.049-0.104c0.012-0.041-0.023-0.114-0.023-0.114c-0.044-0.014-0.907,0.442-0.907,0.442
			c-0.014,0.041,0.024,0.114,0.024,0.114c0.133-0.023,0.142-0.005,0.145,0.001c0.001,0.002,0.042,0.179,0.042,0.179
			c0.003,0.011,0.413,2.616,0.413,2.616c0.008,0.063,0.012,0.122-0.029,0.147c-0.011,0.041,0.026,0.116,0.026,0.116
			c0.044,0.013,0.725-0.346,0.725-0.346l0.028-0.014l-0.053-0.1c-0.101,0.013-0.142,0.005-0.166-0.037
			c0-0.002-0.023-0.092-0.023-0.092c0-0.003-0.084-0.505-0.085-0.506c0.011,0.018,0.903-0.451,0.913-0.455
			c0.001,0.026,0.381,0.381,0.381,0.381c0.001,0.001,0.058,0.067,0.058,0.067c0.001,0.004,0.004,0.008,0.004,0.01
			c0,0.01-0.01,0.024-0.055,0.055c-0.001,0.005-0.003,0.01-0.003,0.015c0,0.041,0.03,0.098,0.03,0.098
			c0.044,0.013,0.745-0.356,0.745-0.356c0.013-0.043-0.026-0.116-0.026-0.116C49.853,62.164,49.835,62.165,49.756,62.09z
			 M48.426,61.71c-0.083,0.013-0.516,0.235-0.611,0.285c-0.014-0.074-0.202-1.089-0.221-1.192l0.027,0.026
			c0.139,0.181,0.802,0.841,0.829,0.868L48.426,61.71z"
				/>
				<g>
					<path
						fill="#FFFFFF"
						d="M41.894,35.093l-1.143-0.41l0.594,1.112l-1.08,0.763l1.218,0.273l-0.092,1.235l1.007-0.838l0.93,0.838
				l-0.062-1.295c0.001,0,1.28-0.108,1.28-0.108l-1.065-0.821l0.608-1.098l-1.25,0.366l-0.47-1.189L41.894,35.093z"
					/>
					<path
						fill="#FFFFFF"
						d="M49.222,36.982l-1.143-0.38l0.593,1.096l-1.082,0.748l1.251,0.273l-0.123,1.251l0.989-0.841l0.96,0.841
				l-0.077-1.295l1.282-0.109l-1.05-0.806l0.593-1.113l-1.25,0.334l-0.457-1.156L49.222,36.982z"
					/>
					<polygon
						fill="#FFFFFF"
						points="41.011,43.29 41.465,44.462 42.717,44.112 42.12,45.195 43.174,46.017 41.894,46.124
				41.955,47.434 41.026,46.58 40.02,47.434 40.125,46.185 38.908,45.91 39.975,45.165 39.395,44.083 40.536,44.462 			"
					/>
					<polygon
						fill="#FFFFFF"
						points="49.678,43.838 50.045,44.92 51.171,44.92 50.258,45.621 50.608,46.734 49.678,46.064
				48.751,46.734 49.083,45.621 48.171,44.936 49.268,44.92 			"
					/>
					<path
						fill="#FFFFFF"
						d="M49.176,52.599l-1.144-0.395l0.58,1.096l-1.082,0.746l1.234,0.275l-0.091,1.25l0.991-0.855l0.945,0.869
				l-0.091-1.28l1.295-0.153l-1.051-0.822l0.595-1.082l-1.235,0.351l-0.457-1.188L49.176,52.599z"
					/>
				</g>
				<g>
					<g>
						<path
							fill="#FFFFFF"
							d="M46.976,29.4c-0.365-0.003-0.94,0.511-1.126,0.707c0.746-0.258,1.026-0.118,1.197-0.342
					C47.057,29.647,47.082,29.471,46.976,29.4z"
						/>
						<path
							fill="#FFFFFF"
							d="M47.442,29.6c-0.089,0.473,0.234,0.935,0.606,1.158l0.068-0.358
					C48.063,30.064,47.709,29.754,47.442,29.6z"
						/>
						<path
							fill="#FFFFFF"
							d="M47.236,30.495c-0.04,0.132-0.157,0.301-0.124,0.447c0.052,0.264,0.469,0.364,0.597,0.757
					C47.935,31.336,47.634,30.6,47.236,30.495z"
						/>
						<path
							fill="#FFFFFF"
							d="M46.939,31.275l-0.183,0.322c-0.304,0.464,0.254,0.829,0.248,1.123c0.169-0.181,0.358-0.664,0.253-0.998
					C47.21,31.546,47.103,31.389,46.939,31.275z"
						/>
						<path
							fill="#FFFFFF"
							d="M45.816,31.346c-0.201,0.079-0.692,0.401-0.942,0.366c0.123,0.156,0.664,0.124,0.857,0.148
					c0.337,0.02,0.566-0.362,0.735-0.63L45.816,31.346z"
						/>
						<path
							fill="#FFFFFF"
							d="M46.349,32.159c-0.664,0.163-0.244,0.943-0.806,1.394c0.386-0.07,0.554-0.075,0.797-0.537
					C46.45,32.793,46.501,32.387,46.349,32.159z"
						/>
						<path
							fill="#FFFFFF"
							d="M41.178,30.682c-0.127,0.507-0.009,0.621,0.232,0.933c-0.117-0.713,0.72-1.01,0.338-1.576
					C41.48,30.108,41.246,30.442,41.178,30.682z"
						/>
						<path
							fill="#FFFFFF"
							d="M43.996,32.646c0.025,0.158,0.657,0.12,0.949-0.008c0.147-0.032,0.641-0.222,0.693-0.4
					C44.493,31.977,44.901,32.402,43.996,32.646z"
						/>
						<path
							fill="#FFFFFF"
							d="M45.346,30.989c0.052,0.15,0.668,0.002,0.935-0.176c0.136-0.059,0.587-0.332,0.609-0.516
					C45.716,30.243,46.191,30.588,45.346,30.989z"
						/>
						<path
							fill="#FFFFFF"
							d="M41.548,29.465c-0.167-0.077-0.636,0.162-0.757,0.249c-0.291,0.133-0.742,0.58-0.64,0.704
					C40.935,29.906,40.98,30.492,41.548,29.465z"
						/>
					</g>
					<g>
						<path
							fill="#FFFFFF"
							d="M43.124,27.467c0.397,0.111,0.577,0.347,0.841,0.385c0.31,0.013,0.42-0.079,0.479-0.192
					C44.319,27.268,43.528,27.309,43.124,27.467z"
						/>
						<path
							fill="#FFFFFF"
							d="M44.959,29.226c-0.031-0.413,0.132-0.66,0.077-0.922c-0.092-0.297-0.214-0.37-0.341-0.386
					C44.369,28.169,44.673,28.897,44.959,29.226z"
						/>
						<path
							fill="#FFFFFF"
							d="M43.068,27.802c-0.331-0.117-0.905,0.121-1.093,0.283c0.425-0.042,0.693,0.431,1.199,0.224l0.325-0.171
					C43.394,27.97,43.242,27.858,43.068,27.802z"
						/>
						<path
							fill="#FFFFFF"
							d="M43.722,28.633c-0.106,0.333,0.149,0.901,0.319,1.084c-0.055-0.425,0.409-0.706,0.186-1.207l-0.184-0.32
					C43.88,28.301,43.771,28.459,43.722,28.633z"
						/>
						<path
							fill="#FFFFFF"
							d="M45.38,32.984l-0.368,0.021c-0.542,0.078-0.531,0.621-0.918,0.803c0.244,0.046,0.86-0.045,1.084-0.314
					C45.301,33.359,45.375,33.182,45.38,32.984z"
						/>
						<path
							fill="#FFFFFF"
							d="M41.657,28.511c-0.494,0.177-0.521,0.341-0.646,0.714c0.527-0.494,1.24,0.03,1.494-0.602
					C42.3,28.439,41.892,28.434,41.657,28.511z"
						/>
						<path
							fill="#FFFFFF"
							d="M42.802,29.473c0.044,0.522,0.195,0.592,0.523,0.807c-0.339-0.635,0.352-1.191-0.196-1.597
					C42.897,28.833,42.786,29.227,42.802,29.473z"
						/>
					</g>
					<path
						fill="#FFFFFF"
						d="M42.192,29.425c-0.027,0.714-0.015,1.262,0.29,1.603c0.194-0.578,0.204-0.839,0.048-1.189
				C42.474,29.685,42.336,29.417,42.192,29.425z"
					/>
				</g>
			</g>
		</g>
	</svg>
)
