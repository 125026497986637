import React, {ChangeEvent, KeyboardEvent} from 'react'
import {Icon} from '~/blocks/Icon'

interface SearchBoxProps {
	placeholder?: string
	initialValue?: string
	handleSearch: (searchValue: string) => void
}

export const SearchBox = (props: SearchBoxProps) => {
	const [fieldValue, setFieldValue] = React.useState(props.initialValue ?? '')

	function handleOnKeyUp(event: KeyboardEvent<HTMLInputElement>) {
		if (event.code === 'Escape') {
			setFieldValue('')
		} else if (event.code === 'Enter' || event.key === 'Enter') {
			// event.key is for mobile
			event.preventDefault()
			props.handleSearch(fieldValue)
		}
	}

	function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
		setFieldValue(event.target.value)
		if (event.target.value === '') {
			props.handleSearch('')
		}
	}

	return (
		<div className="relative">
			<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
				<Icon
					icon="search"
					className="h-[18px] w-[18px] text-gray-500"
					aria-hidden="true"
				/>
			</div>
			<div role="search">
				<input
					id="search"
					name="search"
					value={fieldValue}
					onChange={handleOnChange}
					onKeyUp={handleOnKeyUp}
					className="peer block w-full rounded-md border border-gray-500 bg-white py-2 pl-7 pr-0 text-sm placeholder-gray-600 focus:border-blue-700 focus:text-gray-900 focus:outline-none sm:w-[384px] sm:text-sm"
					placeholder={props.placeholder}
					type="search"
				/>
				<button
					type="submit"
					value="Submit"
					onClick={() => props.handleSearch(fieldValue)}
					className="sr-only"
					aria-label="Submit search query"
				/>
			</div>
		</div>
	)
}
