import React from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'

interface Props {
	colour: string //'gray' | 'navy' | 'red' | 'yellow' | 'blue' | 'green' | 'teal'
	icon: string
}

export function IconInBox({colour, icon}: Props) {
	return (
		<div
			className={cx(
				colour === 'gray'
					? 'bg-gray-100 text-gray-900'
					: colour === 'navy'
					? 'bg-uom-navy-900 text-white'
					: colour === 'red'
					? 'bg-red-100 text-red-900'
					: colour === 'yellow'
					? 'bg-yellow-100 text-yellow-900'
					: colour === 'blue'
					? 'bg-blue-100 text-uom-navy-900'
					: colour === 'green'
					? 'bg-green-100 text-green-900'
					: colour === 'teal'
					? 'bg-teal-100 text-teal-900'
					: 'bg-uom-navy-900 text-white', // default navy

				'flex h-12 w-12 flex-shrink-0 content-center items-center justify-center rounded',
			)}
		>
			<Icon
				icon={icon}
				className="h-6 w-6"
			/>
		</div>
	)
}
