import React, {useEffect, useState} from 'react'
import {MultipleSelect} from '~/components/MultipleSelect'
import {SearchBox} from '~/components/SearchBox'
import {Select} from '~/components/Select'
import {GiftRecordCard} from '~/pages/giftGovernanceRecords/GiftRecordCard'
import {GiftRecordModal} from '~/pages/giftGovernanceRecords/GiftRecordModal'
import {ListGridViewButtons} from '~/pages/giftGovernanceRecords/ListGridViewButtons'
import {GiftRecord, useGiftGovernanceRecordsStore} from '~/pages/giftGovernanceRecords/usesGiftGovernanceRecordsStore'
import {render} from '~/utils/render'
import {cx, getUrlParam, removeUrlParam, setUrlParam} from '~/utils/utils'
import {LoadingAnimation} from '~/blocks/LoadingAnimation'
import {Icon} from '~/blocks/Icon'

interface GiftGovernanceRecordsConfig {
	apiUrl: string
	divisions: Division[]
	giftTypes: GiftType[]
	giftCategories: GiftCategory[]
	pageLabels: {
		searchPlaceholder?: string
		foundRecordCountLabel?: string
		searchTermLabel?: string
		divisionFilterLabel?: string
		allDivisionsName?: string
		typeFilterLabel?: string
		allTypesName?: string
		categoryFilterLabel?: string
		allCategoriesName?: string
	}
	cardLabels: {
		workdayIdLabel: string
		projectCodeLabel: string
		idLabel: string
		pdfLabel: string
		detailsLabel: string
	}

	modalLabels: {
		descriptionLabel: string
		detailsLabel: string
	}
}

interface GiftCategory {
	id: string
	name: string
}

interface GiftType {
	id: string
	name: string
}

interface Division {
	id: string
	name: string
}

export const config: GiftGovernanceRecordsConfig = (window as any).giftGovernanceRecordsConfig ?? {}

export const initGiftGovernanceRecords = () => {
	document.querySelectorAll('#gift-governance-records').forEach((element) => {
		render(<GiftGovernanceRecordsPage />, element)
	})
}

const GiftGovernanceRecordsPage = () => {
	const [searchTerm, setSearchTerm] = useState<string | null>(getUrlParam('search'))
	const [divisionFilter, setDivisionFilter] = useState<string | null>(getUrlParam('division'))
	const [giftTypeFilter, setGiftTypeFilter] = useState<string | null>(getUrlParam('type'))
	const [categoryFilters, setCategoryFilters] = useState<string[]>(getUrlParam('categories')?.split('|') ?? [])
	const [isGridView, setIsGridView] = useState(getUrlParam('view') === 'grid')
	const {isLoading, apiResponse} = useGiftGovernanceRecordsStore(searchTerm, divisionFilter, giftTypeFilter, categoryFilters)
	const [openModalGiftRecord, setOpenModalGiftRecord] = useState<GiftRecord | null>(null)

	useEffect(() => {
		const modalGiftId = getUrlParam('modalGiftId')
		if (modalGiftId != null && modalGiftId.length > 0) {
			waitToOpenModal()
		}

		function waitToOpenModal() {
			if (apiResponse == null) {
				setTimeout(waitToOpenModal, 500)
			} else {
				setOpenModalGiftRecord(apiResponse.results.find((giftRecord) => giftRecord.gift_id === modalGiftId) ?? null)
			}
		}
	})

	const hasResults = (apiResponse?.total_results != null && apiResponse.total_results > 0) ?? false

	function handleSearch(newTerm: string) {
		if (newTerm != null && newTerm.trim().length > 0) {
			setUrlParam('search', newTerm)
		} else {
			removeUrlParam('search')
		}
		setSearchTerm(newTerm)
	}

	function handleGridViewChange(newIsGridView: boolean) {
		setIsGridView(newIsGridView)
		if (newIsGridView) {
			setUrlParam('view', 'grid')
		} else {
			removeUrlParam('view')
		}
	}

	function handleDivisionChange(newDivision: string | null) {
		setDivisionFilter(newDivision)
		if (newDivision != null && newDivision.trim().length > 0) {
			setUrlParam('division', newDivision)
		} else {
			removeUrlParam('division')
		}
	}

	function handleTypeChange(newType: string | null) {
		setGiftTypeFilter(newType)
		if (newType != null && newType.trim().length > 0) {
			setUrlParam('type', newType)
		} else {
			removeUrlParam('type')
		}
	}

	function handleSetCategories(newCategories: string[]) {
		setCategoryFilters(newCategories)
		if (newCategories.length > 0) {
			setUrlParam('categories', newCategories.join('|'))
		} else {
			removeUrlParam('categories')
		}
	}

	function handleOpenModal(giftRecord: GiftRecord) {
		setOpenModalGiftRecord(giftRecord)
		setUrlParam('modalGiftId', giftRecord.gift_id)
	}

	function handleCloseModal() {
		setOpenModalGiftRecord(null)
		removeUrlParam('modalGiftId')
	}

	return (
		<div>
			<div className="mt-[64px] flex w-full flex-col justify-between gap-y-[12px] sm:flex-row">
				<SearchBox
					handleSearch={handleSearch}
					initialValue={searchTerm ?? undefined}
					placeholder={config.pageLabels.searchPlaceholder}
				/>
				<ListGridViewButtons
					gridView={isGridView}
					setGridView={handleGridViewChange}
				/>
			</div>
			<div className={cx(hasResults ? '' : '', 'mt-[24px] min-h-[16px] text-xs font-normal leading-4 text-gray-600')}>
				{apiResponse != null && config.pageLabels.foundRecordCountLabel?.replace('$record-count', apiResponse?.total_results.toString() ?? '')}
				{searchTerm != null && searchTerm.length > 0 && config.pageLabels.searchTermLabel?.replace('$search-term', searchTerm ?? '')}
			</div>
			<div className="mt-[64px] flex flex-col gap-[24px] md:grid md:grid-cols-3">
				<div className="w-full">
					<label className="mb-[16px] block text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">{config.pageLabels.divisionFilterLabel}</label>
					<Select
						options={config.divisions.map((division) => division.id + ' - ' + division.name)}
						blankOption={config.pageLabels.allDivisionsName}
						placeholder={config.pageLabels.allDivisionsName}
						onChangeIndex={(index) => handleDivisionChange(index == null ? null : config.divisions[index].id)}
						currentIndex={divisionFilter == null ? undefined : config.divisions.findIndex((findDivision) => findDivision.id === divisionFilter)}
					/>
				</div>
				<div className="w-full">
					<label className="mb-[16px] block text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">{config.pageLabels.typeFilterLabel}</label>
					<Select
						options={config.giftTypes.map((giftType) => giftType.name)}
						blankOption={config.pageLabels.allTypesName}
						placeholder={config.pageLabels.allTypesName}
						onChangeIndex={(index) => handleTypeChange(index == null ? null : config.giftTypes[index].id)}
						currentIndex={giftTypeFilter == null ? undefined : config.giftTypes.findIndex((giftType) => giftType.id === giftTypeFilter)}
					/>
				</div>
				{/* <div className="flex-1">
					<label className="mb-[16px] block text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">{config.pageLabels.categoryFilterLabel}</label>
					<MultipleSelect
						options={config.giftCategories.map((category) => category.id + ' - ' + category.name)}
						selectedIndexes={categoryFilters.map((categoryFilter) => config.giftCategories.findIndex((category) => category.id === categoryFilter))}
						blankOption={config.pageLabels.allCategoriesName}
						placeholder={config.pageLabels.allCategoriesName}
						onChangeSelection={(newSelectedIndexes) => handleSetCategories(newSelectedIndexes.map((index) => config.giftCategories[index].id))}
						selectionLabel={categoryFilters.join(', ')}
					/>
				</div> */}
			</div>
			<div className="min-h-[400px]">
				{isLoading ? (
					<div className="mx-auto mt-[100px]">
						<LoadingAnimation />
					</div>
				) : apiResponse == null ? (
					<div className="mt-[100px] flex flex-col items-center text-center">
						<div className="mb-[18px] flex h-[50px] w-[50px] items-center justify-center rounded-full bg-gray-100">
							<Icon
								icon="search"
								className="mt-[4px] h-[22px] w-[22px] text-uom-navy-800"
							/>
						</div>
						<div className="text-gray-500">Find gift records using the search and filters above</div>
					</div>
				) : apiResponse?.total_results != null && apiResponse?.total_results <= 0 ? (
					<div className="mt-[100px] text-center text-gray-500">No results found</div>
				) : (
					<div className="mt-[24px] grid gap-[24px] sm:grid-cols-2 md:grid-cols-3">
						{apiResponse?.results.map((giftRecord, index) => (
							<GiftRecordCard
								key={index}
								giftRecord={giftRecord}
								isGridView={isGridView}
								className={cx(isGridView ? '' : 'col-span-2')}
								openModal={handleOpenModal}
							/>
						))}
					</div>
				)}
			</div>
			<GiftRecordModal
				openModalGiftRecord={openModalGiftRecord}
				close={handleCloseModal}
			/>
		</div>
	)
}
export default GiftGovernanceRecordsPage
