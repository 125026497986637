import React, {useState} from 'react'
import {Icon} from '~/blocks/Icon'
import {getSectionProps, SectionProps, SectionWrapper} from '~/components/SectionWrapper'
import {queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx} from '~/utils/utils'
import './Accordion.css'

interface AccordionProps extends SectionProps {
	singleBlockOpen: boolean
	fullWidth: boolean
	sections: {
		title?: string
		content?: string
	}[]
	card: boolean
}

export function initAccordion() {
	document.querySelectorAll<HTMLElement>('ul.accordion').forEach((element) => {
		const props: AccordionProps = {
			singleBlockOpen: element.dataset.singleFocus === '',
			fullWidth: element.classList.contains('full-width'),
			sections: Array.from(element.querySelectorAll<HTMLLIElement>(':scope > li')).map((section) => ({
				title: queryTextContent('.accordion__title', section),
				content: section.querySelector('.accordion__hidden')?.innerHTML ?? '',
			})),
			card: element.classList.contains('card'),
		}

		const div = document.createElement('div')
		div.classList.add('section-spacing', 'accordion')
		element.parentNode?.replaceChild(div, element)

		render(<Accordion {...props} />, div)
	})

	document.querySelectorAll<HTMLElement>('.staff-ui-accordion').forEach((element) => {
		const sectionProps = getSectionProps(element)
		const props: AccordionProps = {
			...sectionProps,
			singleBlockOpen: element.dataset.singleSectionOpen === 'true',
			fullWidth: element.dataset.fullWidth === 'true',
			sections: Array.from(element.querySelectorAll<HTMLLIElement>('.accordion-section')).map((section) => ({
				title: queryTextContent('.title', section),
				content: section.querySelector('.body')?.innerHTML ?? '',
			})),
			card: element.dataset.sectionsAsCards === 'true',
		}
		const div = document.createElement('div')
		element.parentNode?.replaceChild(div, element)

		render(<Accordion {...props} />, div)
	})
}

function Accordion(props: AccordionProps) {
	const [openSections, setOpenSections] = useState<number[]>([])

	function toggleSection(toggleIndex: number) {
		if (openSections.some((index) => index === toggleIndex)) {
			setOpenSections(props.singleBlockOpen ? [] : openSections.filter((index) => index !== toggleIndex))
		} else {
			setOpenSections(props.singleBlockOpen ? [toggleIndex] : [...openSections, toggleIndex])
		}
	}

	return (
		<SectionWrapper {...props}>
			<div className={cx(props.fullWidth ? 'w-full' : 'max-w-content')}>
				<ul className={cx(props.card ? 'space-y-[24px]' : 'divide-y divide-gray-300 border-b border-t border-gray-300', 'no-list block-uplift')}>
					{props.sections.map((section, index) => {
						const open = openSections.includes(index)
						return (
							<li
								key={section.title}
								className={cx(props.card ? 'rounded-lg bg-white px-[24px] shadow' : '', '!py-6 text-lg')}
							>
								<button
									type="button"
									onClick={() => toggleSection(index)}
									className="group flex w-full items-start justify-between text-left text-gray-400 focus:outline-none"
								>
									<span className="font-medium text-gray-900 group-hover:underline">{section.title}</span>
									<span className="ml-6 flex h-7 items-center">
										<div
											className={cx(
												'--group-hover:brightness-90 box-border flex h-[36px] w-[36px] items-center justify-center rounded-full focus:outline-none group-focus:border-2 group-focus:border-blue-700 group-focus:bg-gray-50',
												props.backgroundColour === 'white' ? 'group-hover:bg-gray-50' : 'group-hover:bg-gray-200',
											)}
										>
											<Icon
												icon="chevron-down"
												className={cx(open ? '-rotate-180' : 'rotate-0', 'h-[28px] transform')}
												aria-hidden="true"
											/>
										</div>
									</span>
								</button>
								{section.content != null && (
									<div
										className={cx(open ? '' : 'hidden', props.fullWidth ? '' : 'pr-12', props.card ? 'accordion-card-content' : '', 'accordion-content mt-2 text-base font-normal leading-6')}
										dangerouslySetInnerHTML={{__html: section.content}}
									/>
								)}
							</li>
						)
					})}
				</ul>
			</div>
		</SectionWrapper>
	)
}
