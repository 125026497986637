import React from 'react'
import {cx} from '~/utils/utils'

export function LevelPips(props: {level: number; forceEmptyWhiteBackground?: boolean}) {
	return (
		<div className="space-y-[2px]">
			<div
				className={cx(
					props.level >= 3 ? 'bg-blue-700' : props.forceEmptyWhiteBackground ? 'bg-white' : 'bg-gray-100 group-focus-within:bg-white group-hover:bg-white',
					'h-[4px] w-[16px] rounded-[1px]',
				)}
			/>
			<div
				className={cx(
					props.level >= 2 ? 'bg-blue-700' : props.forceEmptyWhiteBackground ? 'bg-white' : 'bg-gray-100 group-focus-within:bg-white group-hover:bg-white',
					'h-[4px] w-[16px] rounded-[1px]',
				)}
			/>
			<div className={cx('h-[4px] w-[16px] rounded-[1px] bg-blue-700')} />
		</div>
	)
}
