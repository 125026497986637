import React from 'react'
import {queryHref, queryImgAltText, querySrc, queryTextContent} from '~/utils/query'
import {renderToMain} from '~/utils/render'
import {autoLink, pageType} from '~/utils/utils'

export interface StaffNewsArticlePageProps {
	title?: string
	summary?: string
	articleBody?: string
	datePublished?: string
	adminEditUrl?: string
	issueTitle?: string
	issueUrl?: string
	imageUrl?: string
	imageAltText?: string
	videoUrl?: string
	contact1Label?: string
	contact1Text?: string
	contact1Ulr?: string
	contact2Label?: string
	contact2Text?: string
	contact2Ulr?: string
	previousTitle?: string
	previousUlr?: string
	nextTitle?: string
	nextUlr?: string
}

export const initStaffNewsArticlePage = () => {
	if (pageType === 'staff-news-article') {
		const props = {
			title: queryTextContent('h1'),
			summary: autoLink(queryTextContent('.sn-summary')),
			articleBody: autoLink(queryTextContent('[data-sh-2021="body"]')),
			datePublished: queryTextContent('time'),
			adminEditUrl: queryHref('[data-sh-2021="edit"]'),
			issueTitle: queryTextContent('[data-sh-2021="issue"]'),
			issueUrl: queryHref('[data-sh-2021="issue"]'),
			imageUrl: querySrc('[data-sh-2021="image"]'),
			imageAltText: queryImgAltText('[data-sh-2021="image"]'),
			videoUrl: querySrc('[data-sh-2021="video"]'),
			contact1Label: queryTextContent('[data-sh-2021="contact1-label"]'),
			contact1Text: queryTextContent('[data-sh-2021="contact1"]'),
			contact1Ulr: queryHref('[data-sh-2021="contact1"]'),
			contact2Label: queryTextContent('[data-sh-2021="contact2-label"]'),
			contact2Text: queryTextContent('[data-sh-2021="contact2"]'),
			contact2Ulr: queryHref('[data-sh-2021="contact2"]'),
			previousTitle: queryTextContent('[data-sh-2021="previous"]'),
			previousUlr: queryHref('[data-sh-2021="previous"]'),
			nextTitle: queryTextContent('[data-sh-2021="next"]'),
			nextUlr: queryHref('[data-sh-2021="next"]'),
		}

		renderToMain(<StaffNewsArticlePage {...props} />)
	}
}
export default function StaffNewsArticlePage(props: StaffNewsArticlePageProps) {
	return (
		<article className="mx-auto max-w-[792px] px-4 pb-20 pt-[64px] sm:px-6 lg:px-8">
			<div className="flex justify-between">
				<div>
					<span className="pr-[24px] text-sm font-normal leading-5">{props.datePublished ? props.datePublished : 'Unpublished'}</span>
					{props.issueUrl && props.issueTitle && (
						<a
							href={props.issueUrl}
							className="mr-2 rounded-full border border-gray-300 bg-gray-50 px-[13px] py-1 text-xs font-medium leading-4 text-blue-700 ring-inset hover:bg-gray-100 hover:underline focus:border-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-700"
						>
							Issue {props.issueTitle}
						</a>
					)}
				</div>
				{props.adminEditUrl && (
					<a
						className="text-sm text-blue-700 underline"
						href={props.adminEditUrl}
					>
						Edit
					</a>
				)}
			</div>
			<h1 className="block-uplift pt-[16px] text-3xl font-extrabold leading-8 tracking-tight text-gray-900">{props.title}</h1>
			<div
				className="border-b-2 border-gray-200 pb-[24px] pb-[24px] pt-[10px] text-lg font-normal leading-6 md:pt-[30px]"
				dangerouslySetInnerHTML={{__html: props.summary ?? ''}}
			/>
			{props.imageUrl && (
				<img
					className=""
					src={props.imageUrl}
					alt={props.imageAltText}
				/>
			)}
			{props.videoUrl && (
				<div className="relative h-0 w-full pb-[56.25%]">
					<iframe
						className="absolute left-0 top-0 h-full w-full"
						height="auto"
						src={props.videoUrl}
					/>
				</div>
			)}
			<div
				className="pb-[48px] pt-[24px] text-base font-normal leading-7"
				dangerouslySetInnerHTML={{__html: props.articleBody ?? ''}}
			/>
			{props.contact1Text && (
				<div className="text-base leading-7">
					<strong className="font-semibold text-gray-900">{props.contact1Label} </strong>
					<a
						className="text-blue-700 hover:underline"
						href={props.contact1Ulr}
						target="_blank"
					>
						{props.contact1Text}
					</a>
				</div>
			)}
			{props.contact2Text && (
				<div className="pt-[24px] text-base leading-7">
					<strong className="font-semibold text-gray-900">{props.contact2Label} </strong>
					<a
						className="text-blue-700 hover:underline"
						href={props.contact2Ulr}
						target="_blank"
					>
						{props.contact2Text}
					</a>
				</div>
			)}
			<div className="w-full justify-between gap-x-[24px] pt-[64px] md:flex">
				{props.previousUlr ? (
					<a
						href={props.previousUlr}
						className="group mb-[24px] block w-full rounded-md border-2 border-transparent p-[24px] text-sm leading-5 shadow hover:shadow-lg focus:border-blue-700 md:mb-0 md:max-w-[384px]"
					>
						<h6 className="block-uplift font-semibold text-gray-900">Previous story:</h6>
						<div className="pt-[8px] font-normal text-blue-700 group-hover:underline">{props.previousTitle}</div>
					</a>
				) : (
					<div className="hidden md:block" />
				)}
				{props.nextUlr && (
					<a
						href={props.nextUlr}
						className="group order-2 block w-full rounded-md border-2 border-transparent p-[24px] text-right text-sm leading-5 shadow hover:shadow-lg focus:border-blue-700 md:max-w-[384px]"
					>
						<h6 className="block-uplift font-semibold text-gray-900">Next story:</h6>
						<div className="pt-[8px] font-normal text-blue-700 group-hover:underline">{props.nextTitle}</div>
					</a>
				)}
			</div>
		</article>
	)
}
