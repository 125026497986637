import React, {useState} from 'react'
import {cx} from '~/utils/utils'
import {Icon} from '~/blocks/Icon'

interface Props {
	label?: string
	value: string
	className?: string
}

export const CopyToClipboardButton = (props: Props) => {
	const [copied, setCopied] = useState(false)

	const copyCode = () => {
		navigator.clipboard.writeText(props.value).then(() => {
			setCopied(true)
			setTimeout(() => {
				setCopied(false)
			}, 2000)
		})
	}

	return (
		<button
			className={cx('button-xs soft !focus:outline-none !mt-0 !max-w-full justify-center', props.className)}
			onClick={() => copyCode()}
		>
			{copied ? (
				<>
					Copied
					<Icon
						icon="check"
						className="ml-[8px] inline h-[16px] w-[16px]"
					/>
				</>
			) : (
				<div>{props.label ?? 'Copy'}</div>
			)}
		</button>
	)
}
