import React, {useRef} from 'react'
import {Icon} from '~/blocks/Icon'
import {FormIconInBox} from '~/images/FormIconInBox'
import {SupportArticleIconInBox} from '~/images/SupportArticleIconInBox'
import {useLineCount} from '~/pages/search/useLineCount'
import {autoLink, cx, isUrlSameHost} from '~/utils/utils'
import type {CardProps} from './Card'

const defaultLinkLabels = {
	form: 'View form',
	'support-article': 'Read support article',
}


export function ServiceNowCard({url, body, type, title, linkLabel, className = '', lineClamp = true, autoLinkBody = false, ...props}: CardProps) {
	const titleRef = useRef<HTMLDivElement>(null)
	const headerLineCount = useLineCount(titleRef, 24)

	return (
		<a
			href={url}
			className={cx(
				lineClamp ? 'h-full max-h-[316px]' : 'mb-6',
				'group flex flex-col rounded-lg border-2 border-transparent bg-white p-6 shadow hover:no-underline hover:shadow-lg focus:border-blue-700  focus:outline-none',
				className,
			)}
			target= {isUrlSameHost(url)? '' : '_blank'}
		>
			{type === 'form' && (
				<div className="flex justify-between pb-6">
					<FormIconInBox />
					<Icon
						icon="external-link"
						className="h-[24px] text-yellow-800"
					/>
				</div>
			)}
			{type === 'support-article' && (
				<div className="flex justify-between pb-6">
					<SupportArticleIconInBox />
					<Icon
						icon="external-link"
						className="h-[24px] text-uom-navy-900"
					/>
				</div>
			)}
			<h3
				ref={titleRef}
				className="block-uplift text-lg font-semibold leading-6 text-gray-900"
			>
				{title}
			</h3>
			{body && (
				<div
					className={cx(
						lineClamp ? `shrink-0 flex-grow line-clamp-${6 - headerLineCount} overflow-hidden overflow-ellipsis` : 'flex-shrink',
						'break-words pt-2 text-base font-normal leading-6 text-gray-600',
						props.horizontalLayout && 'max-w-[300px]',
					)}
					dangerouslySetInnerHTML={{
						__html: autoLinkBody ? autoLink(body) : body,
					}}
				/>
			)}
			<div className="mt-auto pt-6 text-sm font-medium leading-5 text-blue-700 hover:underline">
				{linkLabel ? linkLabel : type === 'form' ? defaultLinkLabels.form : defaultLinkLabels['support-article']}{' '}
				<Icon
					icon="arrow-forward"
					className="mb-[4px] inline h-4 w-4"
				/>
			</div>
		</a>
	)
}
