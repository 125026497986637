import React from 'react'
import {Icon} from '~/blocks/Icon'
import {SocialIcon} from '~/images/SocialIcon'
import {queryAllArray, queryBackgroundImage, queryHref, querySrc, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx, getBackgroundImage, notEmpty, replaceWithNewDiv} from '~/utils/utils'
import './BlockListing.css'

interface BlockListingProps {
	blocks: Block[]
}

type Block = NewsHero | News | Event | Exhibition | Twitter | Instagram | More

interface NewsHero {
	kind: 'newsHero'
	double: boolean
	url?: string
	imageUrl?: string
	category?: string
	title?: string
}

interface News {
	kind: 'news'
	double: boolean
	url?: string
	imageUrl?: string
	category?: string
	title?: string
	body?: string
	date?: string
}

interface Event {
	kind: 'event'
	double: boolean
	url?: string
	imageUrl?: string
	title?: string
	subtitle?: string
	body?: string
	date?: BlockDate
	buttonLabel?: string
}

interface Exhibition {
	kind: 'exhibition'
	double: boolean
	url?: string
	imageUrl?: string
	title?: string
	date?: BlockDate
}

interface Twitter {
	kind: 'twitter'
	double: boolean
	username?: string
	accountUrl?: string
	body?: string
}

interface Instagram {
	kind: 'instagram'
	double: boolean
	url?: string
	imageUrl?: string
	username?: string
}

interface More {
	kind: 'more'
	double: boolean
	links: {
		url?: string
		label?: string
		icon?: string
	}[]
}

interface BlockDate {
	date?: string
	time?: string
	dayOfWeek?: string
}

export function initBlockListing() {
	document.querySelectorAll<HTMLElement>('ul.block-listing').forEach((element) => {
		queryAllArray('[data-icon]', element).forEach((iconTab) => iconTab.remove())

		const blocks: Block[] = Array.from(element.querySelectorAll<HTMLElement>(':scope > li'))
			.map((block) => {
				const double = block.classList.contains('double')

				if (block.classList.contains('newshero')) {
					const category = queryTextContent('em', block) ?? ''
					const newsHero: NewsHero = {
						kind: 'newsHero',
						double: double,
						url: queryHref(':scope > a', block),
						imageUrl: getBackgroundImage(block),
						category: category,
						title: queryTextContent('strong', block)?.replace(category, ''),
					}
					return newsHero
				}

				if (block.classList.contains('news')) {
					const news: News = {
						kind: 'news',
						double: double,
						url: queryHref(':scope > a', block),
						imageUrl: queryBackgroundImage('.block-listing__img', block) ?? querySrc('.crop-height img', block),
						category: queryTextContent('.meta em', block),
						title: queryTextContent(':scope > a > strong, .block-container > strong', block),
						body: queryAllArray('a > p, .block-container > p', block)
							.map((paragraph) => paragraph.outerHTML)
							.join(' '),
						date: queryTextContent('.meta time', block),
					}
					return news
				}

				if (block.classList.contains('event')) {
					const event: Event = {
						kind: 'event',
						double: double,
						url: queryHref(':scope > a', block),
						imageUrl: queryBackgroundImage('.block-listing__img', block) ?? getBackgroundImage(block),
						title: queryTextContent(':scope > a > strong', block),
						subtitle: queryTextContent(':scope > a > p', block),
						body: queryTextContent('.mid-unit', block),
						date: parseBlockDate(block.querySelector('.when')),
						buttonLabel: queryTextContent('.button, .button-hero, .button-hero-reverse, .button-hero-inverse', block),
					}
					return event
				}

				if (block.classList.contains('exhibition')) {
					const exhibition: Exhibition = {
						kind: 'exhibition',
						double: double,
						url: queryHref(':scope > a', block),
						imageUrl: querySrc('img', block),
						title: queryTextContent('.mid-unit', block),
						date: parseBlockDate(block.querySelector('.when')),
					}
					return exhibition
				}

				if (block.classList.contains('twitter')) {
					const twitter: Twitter = {
						kind: 'twitter',
						double: double,
						username: queryTextContent(':scope .meta a', block),
						accountUrl: queryHref(':scope .meta a', block),
						body: block.querySelector('.block-container strong ')?.innerHTML,
					}
					return twitter
				}

				if (block.classList.contains('instagram')) {
					const instagram: Instagram = {
						kind: 'instagram',
						double: double,
						url: queryHref(':scope > a', block),
						imageUrl: getBackgroundImage(block),
						username: queryTextContent('.meta em', block),
					}
					return instagram
				}

				if (block.classList.contains('more')) {
					const more: More = {
						kind: 'more',
						double: double,
						links: queryAllArray('.block-container > a').map((link) => ({
							url: link.getAttribute('href') ?? undefined,
							label: link.textContent?.trim(),
							icon: link.classList.contains('twitter') ? 'twitter' : link.classList.contains('instagram') ? 'instagram' : undefined,
						})),
					}
					return more
				}
			})
			.filter(notEmpty)

		const div = replaceWithNewDiv(element)
		div.classList.add('section-spacing')

		render(<BlockListing blocks={blocks} />, div)
	})
}

function parseBlockDate(element: HTMLElement | null): BlockDate {
	if (element == null) {
		return {}
	}
	if (element.classList.contains('range')) {
		return {
			date: queryAllArray('time', element)
				.map((element) => element.textContent?.trim())
				.join(' - '),
		}
	} else {
		const date = queryTextContent('time', element)?.trim() ?? ''
		const whenText = element.textContent?.trim()
		return {
			date,
			time: whenText?.substring(whenText?.indexOf(date) + date.length).trim(),
			dayOfWeek: whenText?.substring(0, whenText?.indexOf(date)).trim(),
		}
	}
}

function BlockListing({blocks}: BlockListingProps) {
	return (
		<div className="block-listing grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3 xl:grid-cols-4">
			{blocks.map((block, index) => {
				const wholeCardIsLink = 'url' in block && block.url != null && block.url.length > 0
				const LinkOrDiv = wholeCardIsLink ? 'a' : 'div'
				const hasImage = 'imageUrl' in block && block.imageUrl != null && block.imageUrl.length > 0

				return (
					<LinkOrDiv
						key={index}
						href={wholeCardIsLink ? block.url : undefined}
						className={cx(
							wholeCardIsLink ? 'focus:outline-blue hover:shadow-lg' : '',
							block.double ? 'sm:col-span-2' : '',
							block.kind,
							'group block h-[510px] w-full rounded shadow hover:no-underline',
						)}
						data-block-type={block.kind}
					>
						{block.kind === 'newsHero' && (
							<>
								<div className="overflow-hidden rounded-t">
									<img
										src={block.imageUrl}
										className="h-[356px] w-full object-cover"
										alt=""
									/>
								</div>
								<div className="h-full min-h-0 p-6">
									{block.category != null && <div className="pb-[12px] text-base font-semibold leading-6 text-blue-700">{block.category}</div>}
									{block.title != null && <div className="h-full text-3xl font-extrabold leading-8 tracking-tight text-blue-700 line-clamp-2 group-hover:underline">{block.title}</div>}
								</div>
							</>
						)}

						{block.kind === 'news' && (
							<>
								{hasImage && (
									<div className="overflow-hidden rounded-t">
										<img
											src={block.imageUrl}
											className="h-[256px] w-full object-cover"
											alt=""
										/>
									</div>
								)}
								<div className="flex h-full min-h-0 flex-col justify-start overflow-y-hidden p-6">
									{block.category != null && <div className="mb-[8px] max-w-max flex-none rounded-full bg-gray-100 px-[13px] py-[4px] text-xs font-medium text-gray-600">{block.category}</div>}
									{block.title != null && <div className="flex-none text-lg font-semibold leading-6 text-gray-900 line-clamp-2">{block.title}</div>}
									{block.body != null && (
										<div
											className={cx(hasImage ? 'line-clamp-5' : '', 'body flex-shrink overflow-y-hidden overflow-ellipsis pt-[.7rem] text-base font-normal leading-6 text-gray-600')}
											dangerouslySetInnerHTML={{__html: block.body ?? ''}}
										/>
									)}
									{(block.imageUrl == null || block.imageUrl.length <= 0) && <div className="mt-auto flex-none pt-[.7rem] text-base font-normal leading-6 text-gray-600">{block.date}</div>}
								</div>
							</>
						)}

						{block.kind === 'event' && (
							<>
								<div className="overflow-hidden rounded-t">
									<img
										src={block.imageUrl}
										className={cx(block.double ? 'h-[336px]' : 'h-[256px]', 'w-full object-cover')}
										alt=""
									/>
								</div>
								<div className="h-full min-h-0 p-6">
									{!block.double && <div className="mb-[8px] max-w-max rounded-full bg-gray-100 px-[13px] py-[4px] text-xs font-medium text-gray-600">Event</div>}
									{block.date != null && <BlockDateDisplay blockDate={block.date} />}
									{block.title != null && block.title.length > 0 && <div className="text-3xl font-extrabold leading-8 tracking-tight text-blue-700 group-hover:underline">{block.title}</div>}
									{block.subtitle != null && block.subtitle.length > 0 && (
										<div
											className="pt-[12px] text-base font-normal leading-6 text-gray-600"
											dangerouslySetInnerHTML={{__html: block.subtitle ?? ''}}
										/>
									)}
									{block.body != null && (
										<div
											className="body pt-[1rem] text-base font-normal leading-6 text-gray-600 line-clamp-5"
											dangerouslySetInnerHTML={{__html: block.body ?? ''}}
										/>
									)}
									{block.buttonLabel != null && block.buttonLabel.length > 0 && <div className="button-hero-inverse !mt-[24px] justify-self-end">{block.buttonLabel}</div>}
								</div>
							</>
						)}

						{block.kind === 'exhibition' && (
							<>
								<div className="flex-none overflow-hidden rounded-t">
									<img
										src={block.imageUrl}
										className="h-[256px] w-full object-cover"
										alt=""
									/>
								</div>
								<div className="flex max-h-[230px] flex-grow flex-col overflow-y-hidden p-6">
									<div className="mb-[8px] max-w-max rounded-full bg-gray-100 px-[13px] py-[4px] text-xs font-medium text-gray-600">Exhibition</div>
									{block.date != null && <BlockDateDisplay blockDate={block.date} />}
									{block.title != null && <div className="pt-[12px] text-lg font-semibold leading-6 text-gray-900">{block.title}</div>}
								</div>
							</>
						)}

						{block.kind === 'twitter' && (
							<>
								<div className="flex h-full flex-grow flex-col p-6">
									{block.body != null && (
										<div
											className="body flex-grow text-base font-normal leading-6 text-gray-600"
											dangerouslySetInnerHTML={{__html: block.body ?? ''}}
										/>
									)}
									<a
										href={block.accountUrl}
										className="mt-auto flex h-[44px] flex-none items-center justify-end text-blue-700"
									>
										<SocialIcon
											name="twitter"
											className="h-[24px]"
										/>
										<div className="text- ml-2 font-normal leading-5">{block.username}</div>
									</a>
								</div>
							</>
						)}

						{block.kind === 'instagram' && (
							<div className="flex h-full flex-col justify-between">
								<div className="overflow-hidden rounded-t">
									<img
										src={block.imageUrl}
										className="h-[418px] w-full object-cover"
										alt=""
									/>
								</div>
								<div className="min-h-0 flex-grow p-6">
									<div className="my-auto mt-auto block flex h-full items-center justify-end  border-2 border-transparent text-blue-700 focus:border-blue-700">
										<SocialIcon
											name="instagram"
											className="h-[24px]"
										/>
										<div className="text- ml-2 font-normal leading-5 hover:underline">{block.username}</div>
									</div>
								</div>
							</div>
						)}

						{block.kind === 'more' && (
							<div className="grid h-full text-blue-700 ">
								{block.links.slice(0, 6).map((link, index) => (
									<div
										key={index}
										className="border-b-2 border-gray-200"
									>
										<a
											href={link.url}
											className="flex h-full items-center border-2 border-transparent p-6 hover:bg-gray-50 focus:border-blue-700 focus:outline-none"
										>
											{link.icon === 'twitter' && (
												<SocialIcon
													name="twitter"
													className="mr-2 h-[24px]"
												/>
											)}
											{link.icon === 'instagram' && (
												<SocialIcon
													name="instagram"
													className="mr-2 h-[24px]"
												/>
											)}
											{link.label}
											<Icon
												icon="arrow-right"
												className="ml-[4px] inline h-4"
											/>
										</a>
									</div>
								))}
							</div>
						)}
					</LinkOrDiv>
				)
			})}
		</div>
	)
}

interface BlockDateDisplayProps {
	blockDate?: BlockDate
}

function BlockDateDisplay({blockDate}: BlockDateDisplayProps) {
	const {date, dayOfWeek, time} = blockDate ?? {}

	if (date == null && dayOfWeek == null && time == null) {
		return null
	}

	return (
		<div>
			{dayOfWeek != null && dayOfWeek.length > 0 && <div className="text-sm font-normal leading-5 text-gray-600">{dayOfWeek}</div>}
			<div className="text-lg font-semibold leading-6 text-gray-900">{date}</div>
			{time != null && time.length > 0 && <div className="text-sm font-normal leading-5 text-gray-600">{time}</div>}
		</div>
	)
}
