import {create} from 'zustand'
import {persist} from 'zustand/middleware'
import {immer} from 'zustand/middleware/immer'
import {cleanFetchData, updateMetadata} from './saveToUserProfileApi'

interface SelfGuidanceToolConfig {
	sections: Section[]
	labels: {
		levelLabels: string[]
		levelNoneLabel: string

		footerSectionLabel: string
		footerBackButtonLabel: string
		footerNextButtonLabel: string
		footerViewResultsButtonLabel: string

		resultsBackToQuestionsLinkLabel: string
		resultsHeader: string
		resultsLead: string

		resultsLevelsHeader: string

		resultsSuggestionsHeader: string
		resultsSuggestionsLead: string

		resultsSuggestionsLevelLabel: string
		resultsSuggestionsCountLabel: string
		resultsSuggestionsCountLabelAll: string

		setUpLinkedInLearningButtonLabel: string
		setUpLinkedInLearningButtonUrl: string
		setUpLinkedInLearningModalTitle: string
		setUpLinkedInLearningModalBody: string
		setUpLinkedInLearningModalAlreadyDoneButtonLabel: string
		setUpLinkedInLearningModalDoSetupButtonLabel: string
		setUpLinkedInLearningModalDoSetupButtonUrl: string

		privacySectionHeading: string
	}
	survey: {
		url: string
		icon: string
		title: string
		linkLabel: string
		height: string
	}
}

export interface Section {
	title: string
	description?: string
	questionGroups: {
		title: string
		questions: Question[]
	}[]
	courses: Course[]
}

export interface Question {
	id: string
	levels: string[]
}

export interface Course {
	level: string
	title: string
	description: string
	url: string
	thumbnailFileName: string
	thumbnailFileIdUrl: string
}

interface Answers {
	[questionId: string]: {
		level?: number
		none: boolean
	}
}

interface SelfGuidanceToolStore {
	currentSectionIndex: number
	answers: Answers
	answersInitialized: boolean
	showResults?: boolean
	completed: boolean

	initializeAnswers: () => void
	isSectionComplete: (sectionIndex: number) => boolean
	levelForSection: (sectionIndex: number) => number
	currentSection: () => Section
	setCurrentSectionIndex: (index: number) => void
	getSuggestedCoursesForSection: (sectionIndex: number) => Course[]
	getAllSuggestedCourses: () => Course[]
	previousSection: () => void
	nextSection: () => void
	setLevel: (questionId: string, level: number) => void
	setNone: (questionId: string, isNone: boolean) => void
	setShowResults: (shouldShowResults: boolean) => void
}

export const selfGuidanceToolConfig: SelfGuidanceToolConfig = (window as any).selfGuidanceToolConfig ?? {}
export const sections = selfGuidanceToolConfig.sections
export const labels = selfGuidanceToolConfig.labels
export const levelLabels = selfGuidanceToolConfig.labels?.levelLabels
export const survey = selfGuidanceToolConfig.survey

export const useSelfGuidanceToolStore = create<SelfGuidanceToolStore>()(
	immer(
		persist(
			(set, get) => ({
				currentSectionIndex: 0,
				answers: {},
				answersInitialized: false,
				showResults: undefined,
				completed: false,

				initializeAnswers: () => {
					set((state) => {
						sections
							.flatMap((section) => section.questionGroups)
							.flatMap((questionGroup) => questionGroup.questions)
							.forEach((question) => {
								if (state.answers[question.id] === undefined) {
									state.answers[question.id] = {
										level: undefined,
										none: false,
									}
								}
							})
						state.answersInitialized = true
					})
				},

				currentSection: () => {
					return sections[get().currentSectionIndex]
				},

				isSectionComplete: (sectionIndex: number) => {
					const section = sections[sectionIndex]

					if (!section) return false

					return section.questionGroups
						.flatMap((group) => group.questions)
						.every((question) => {
							const answer = get().answers[question.id]
							return answer != null && (answer.level != null || answer.none)
						})
				},

				getSuggestedCoursesForSection: (sectionIndex: number) => {
					return sections[sectionIndex].courses?.filter((course) => course.level.toLowerCase() === levelLabels[get().levelForSection(sectionIndex) - 1]?.toLowerCase()) ?? []
				},

				getAllSuggestedCourses: () => {
					return sections.flatMap((section, sectionIndex) => section.courses?.filter((course) => course.level === levelLabels[get().levelForSection(sectionIndex) - 1]) ?? [])
				},

				levelForSection: (sectionIndex) => {
					let sum = 0
					let count = 0

					sections[sectionIndex].questionGroups
						.flatMap((section) => section.questions)
						.forEach((question) => {
							const answer = get().answers[question.id]
							if (answer.level != null && !answer.none) {
								sum += answer.level
								count++
							}
						})

					if (count === 0) {
						return 0
					}

					const average = sum / count

					if (average <= 33) {
						return 1
					}
					if (average > 33 && average <= 66) {
						return 2
					}
					return 3
				},

				setCurrentSectionIndex: (index) =>
					set((state) => {
						state.currentSectionIndex = index
					}),
				previousSection: () => {
					set((state) => {
						state.currentSectionIndex = Math.max(0, state.currentSectionIndex - 1)
					})
				},
				nextSection: () => {
					set((state) => {
						state.currentSectionIndex = Math.min(sections.length - 1, state.currentSectionIndex + 1)
					})
				},
				setLevel: (questionId, level) =>
					set((state) => {
						if (state.answers[questionId] == null) {
							state.answers[questionId] = {
								none: false,
								level: level,
							}
						} else {
							state.answers[questionId].level = level
						}
					}),
				setNone: (questionId, isNone) =>
					set((state) => {
						if (state.answers[questionId] == null) {
							state.answers[questionId] = {
								level: undefined,
								none: isNone,
							}
						} else {
							state.answers[questionId].none = isNone
						}
					}),
				setShowResults: (shouldShowResults) =>
					set((state) => {
						state.showResults = shouldShowResults
						if (!shouldShowResults) {
							state.currentSectionIndex = 0
						} else {
							state.completed = true
						}
					}),
			}),
			{
				name: 'self-guidance-tool-store',
				version: 1,
				partialize: (state) => ({
					currentSectionIndex: state.currentSectionIndex,
					answers: state.answers,
					showResults: state.showResults,
					completed: state.completed,
				}),
				getStorage: () => {
					return {
						getItem: (name: string): string | null => {
							const localStorageString = localStorage.getItem(name)
							if (localStorageString != null && localStorageString.length > 0) {
								return localStorageString
							}

							const stateFromServerString = cleanFetchData((window as any).digitalSkillsHubProfileData ?? '')
							if (stateFromServerString.length > 0) {
								return stateFromServerString
							}

							return null
						},
						setItem: (name: string, value: string) => {
							const currentStoreState: any = JSON.parse(localStorage.getItem(name) ?? '{}')
							const newStoreState: any = JSON.parse(value).state

							localStorage.setItem(name, value)

							// If the user has changed the section or gone to view results, we want to push the state to the server
							if (currentStoreState.currentSectionIndex !== newStoreState.currentSectionIndex || newStoreState.showResults == true) {
								updateMetadata(value)
							}
						},
						removeItem: () => {
							console.warn('removeItem not implemented')
						},
					}
				},
				migrate: (persistedState, versionOfStoredState) => {
					const newState = persistedState as SelfGuidanceToolStore

					// Version 1 added the completed property, so we are calculating it here
					if (versionOfStoredState === 0) {
						newState.completed = sections[sections.length - 1].questionGroups
							.flatMap((group) => group.questions)
							.every((question) => {
								const answer = newState.answers[question.id]
								return answer != null && (answer.level != null || answer.none)
							})
					}
					return newState
				},
			},
		),
	),
)
