import React from 'react'
import {Icon} from '~/blocks/Icon'
import {useSideScrollButtons} from '~/utils/useSideScrollButtons'
import {cx} from '~/utils/utils'
import {QuestionLevel} from './QuestionLevel'
import {labels, Question, useSelfGuidanceToolStore} from './useSelfGuidanceToolStore'

export const QuestionComponent = ({question}: {question: Question}) => {
	const {answers, setNone} = useSelfGuidanceToolStore()
	const {sideScrollRef, handleSideScrollLeftPointerDown, handleSideScrollLeftPointerUp, handleSideScrollRightPointerDown, handleSideScrollRightPointerUp} = useSideScrollButtons<HTMLDivElement>()

	return (
		<div>
			<div
				ref={sideScrollRef}
				className="tag-question hide-horizontal-scrollbar -mx-4 overflow-y-hidden px-4 pb-[24px] sm:-mx-6 sm:px-6 md:overflow-y-visible md:pb-0"
			>
				<div className="w-[808px] sm:w-[824px] md:w-full">
					<div className="w-[792px] rounded-lg p-[24px] shadow  md:w-full">
						<fieldset className={cx(answers[question.id]?.none ? 'opacity-30' : '', 'grid grid-cols-3 space-x-[24px]')}>
							{question.levels.map((levelDescription, index) => {
								return (
									<QuestionLevel
										key={index}
										index={index}
										question={question}
										levelDescription={levelDescription}
									/>
								)
							})}
						</fieldset>
						<div
							className="mt-[24px] flex items-center md:justify-end"
							onClick={() => {
								setNone(question.id, !answers[question.id]?.none)
							}}
						>
							<input
								type="checkbox"
								className="tag-question-na h-[16px] w-[16px] border-[1px] text-gray-500 !ring-offset-0 checked:!bg-blue-700"
								checked={answers[question.id]?.none}
								onChange={(event) => {
									setNone(question.id, event.target.checked)
								}}
							/>
							<label className="ml-4 text-sm font-medium leading-5 text-gray-600">{labels.levelNoneLabel}</label>
						</div>
					</div>
				</div>
			</div>
			<div className="flex space-x-2 md:hidden">
				<button
					className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
					onPointerDown={handleSideScrollLeftPointerDown}
					onPointerUp={handleSideScrollLeftPointerUp}
					aria-label="scroll left"
				>
					<Icon
						icon="chevron-left"
						className="mr-[2px] h-[22px] w-[22px]"
					/>
				</button>
				<button
					className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
					onPointerDown={handleSideScrollRightPointerDown}
					onPointerUp={handleSideScrollRightPointerUp}
					aria-label="scroll right"
				>
					<Icon
						icon="chevron-right"
						className="ml-[2px] h-[22px] w-[22px]"
					/>
				</button>
			</div>
		</div>
	)
}
