import {Transition} from '@headlessui/react'
import React, {ChangeEvent, Fragment, KeyboardEvent, useEffect, useRef, useState} from 'react'
import {useOnClickOutside} from 'usehooks-ts'
import {Icon} from '~/blocks/Icon'
import {cx, detectMob} from '~/utils/utils'
import {useSearchSuggest} from './useSearchSuggest'

interface SearchBoxProps {
	query?: string
	onNewQuery?: (newQuery: string) => void
	onNewInputValue?: (newInputValue: string) => void
	placeholder?: string
	className?: string
	mainSearchPage?: boolean
}

interface GlobalSearchConfig {
	placeholder?: string
	url?: string
}

const globalSearchConfig: GlobalSearchConfig = (window as any).globalSearchConfig ?? {}

const defaultOnNewQuery = (newQuery: string) => {
	window.location.href = (globalSearchConfig.url ?? '/search') + '?query=' + newQuery
}

export function GlobalSearchBox({query = '', onNewQuery = defaultOnNewQuery, placeholder = globalSearchConfig.placeholder ?? 'Search Staff Hub', className, ...props}: SearchBoxProps) {
	const [inputValue, setInputValue] = useState(query)
	const [showSuggestions, setShowSuggestions] = useState(false)
	const [currentFocus, setCurrentFocus] = useState(-1)

	const inputRef = useRef<HTMLInputElement>(null)

	const clickOutsideRef = useRef<HTMLDivElement>(null)
	useOnClickOutside(clickOutsideRef, () => setShowSuggestions(false))

	const suggestions = useSearchSuggest(inputValue, showSuggestions)

	useEffect(() => {
		setInputValue(query)
		setShowSuggestions(false)
	}, [query])

	function onSelectQuery(newQuery: string) {
		onNewQuery(newQuery)
		setInputValue(newQuery)
		setShowSuggestions(false)

		if (detectMob()) inputRef.current?.blur()
	}

	function onInputKeyDown(event: KeyboardEvent<HTMLInputElement>) {
		if (event.code === 'Escape') {
			setShowSuggestions(false)
		} else if (event.code === 'ArrowDown') {
			setCurrentFocus(currentFocus + 1)
		} else if (event.code === 'ArrowUp') {
			setCurrentFocus(currentFocus - 1)
		} else if (event.code === 'Enter' || event.key === 'Enter') {
			// event.key is for mobile
			event.preventDefault()
			if (currentFocus > 0) {
				onSelectQuery(suggestions[currentFocus])
			} else {
				onSelectQuery(inputValue)
				setShowSuggestions(false)
			}
		}
	}

	function onInputChanged(event: ChangeEvent<HTMLInputElement>) {
		setInputValue(event.target.value)
		props.onNewInputValue && props.onNewInputValue(event.target.value)
		setShowSuggestions(true)
		setCurrentFocus(-1)
	}

	return (
		<div className={cx('relative', className)}>
			<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
				<Icon
					icon="search"
					className={cx(props.mainSearchPage ? 'h-[20px]' : ' h-[18px]', ' mt-[2px] text-gray-500')}
					aria-hidden="true"
				/>
			</div>
			<div
				ref={clickOutsideRef}
				role="search"
			>
				<input
					ref={inputRef}
					id="search"
					name="search"
					value={inputValue}
					onChange={onInputChanged}
					onKeyUp={onInputKeyDown}
					className={cx(
						props.mainSearchPage ? 'rounded-md border-0 py-[14px] pl-[48px] font-normal leading-5 md:rounded-r-none md:py-[20px]' : 'rounded-md border py-2 pl-9 focus:border-blue-700',
						'peer block w-full  border-gray-500 bg-white  pr-3 text-sm  placeholder-gray-600 focus:text-gray-900 focus:outline-none sm:text-sm',
					)}
					placeholder={placeholder}
					type="search"
				/>
				<button
					type="submit"
					value="Submit"
					onClick={() => onSelectQuery(inputValue)}
					className="sr-only"
					aria-label="Submit search query"
				/>
				{/* Search suggestions */}
				<Transition
					show={showSuggestions && suggestions.length > 0}
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<div className="absolute right-0 z-50 mt-2 w-full origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						{suggestions.map((suggestion, index) => (
							<button
								key={suggestion}
								onClick={() => onSelectQuery(suggestion)}
								className={cx(index === currentFocus ? 'bg-gray-100 hover:text-gray-900' : '', 'block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100')}
							>
								{suggestion}
							</button>
						))}
					</div>
				</Transition>
			</div>
		</div>
	)
}
