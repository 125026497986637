import throttle from 'lodash/throttle'
import React, {useEffect, useState} from 'react'
import {Icon} from '~/blocks/Icon'
import {render} from '~/utils/render'
import {useSideScrollButtons} from '~/utils/useSideScrollButtons'
import {cx} from '~/utils/utils'
import './JumpNav.css'

interface JumpNavProps {
	top: boolean // Show the inline "mobile" version of the menu at all screen sizes
	headings: {
		id: string
		label: string
		shortLabel?: string
		wrapTitle?: boolean
	}[]
	columnList: boolean
	direction: 'horizontal' | 'vertical'
	numberOfColumns: string
	numberOfRows: string
	targetHeadingLevel: number
	noBorders?: boolean
	sectionSpacing?: boolean
}

export function initJumpNav() {
	document.querySelectorAll<HTMLElement>('.jumpnav').forEach((element) => {
		if (document.querySelector('.block-jumpnav') != null) {
			return
		}

		const targetHeadingLevelNumber = Number(element.dataset.targetHeadingLevel)
		const targetHeadingLevel = isNaN(targetHeadingLevelNumber) ? 2 : targetHeadingLevelNumber

		const dynamicContainerId = element.closest('.horizontal-tab-contents, .slide-over-modal-body')?.id // If this is found it means the jumpnav is inside a horizontal tab, and we should only search for headers withing it

		const headerQuery = `${dynamicContainerId != null && dynamicContainerId.length > 0 ? '#' + dynamicContainerId + ' ' : ''}h${targetHeadingLevel}[id]:not([id=""])`

		const props: JumpNavProps = {
			top: element.classList.contains('top'),
			columnList: element.classList.contains('column-list'),
			direction: element.dataset.direction === 'vertical' ? 'vertical' : 'horizontal',
			numberOfColumns: element.dataset.numberOfColumns ?? '4',
			numberOfRows: element.dataset.numberOfRows ?? '3',
			headings: Array.from(document.querySelectorAll<HTMLElement>(headerQuery))
				.filter((heading) => heading.closest('.accordion') == null)
				.map((heading) => ({
					id: heading.id ?? '',
					label: heading.textContent ?? '',
					shortLabel: heading.getAttribute('data-short-label') ?? undefined,
					wrapTitle: heading.getAttribute('data-wrap-title') === 'true',
				}))
				.filter((header) => header.id.length > 0 && header.label.length > 0)
				.map((header) => {
					if (header.id.match(/^\d/)) {
						header.id = 'header_' + header.id
					}
					return header
				}),
			targetHeadingLevel,
			noBorders: element.classList.contains('no-borders'),
			sectionSpacing: element.classList.contains('section-spacing'),
		}

		if (props.headings.length <= 0) {
			return
		}

		if (!props.top && !props.columnList) {
			document.body.classList.add('jumpnav-active')
		}

		const nav = document.createElement('nav')
		nav.ariaLabel = 'Sidebar'
		nav.classList.add('rendered-jumpnav')
		element.parentNode?.replaceChild(nav, element)

		render(<JumpNav {...props} />, nav)
	})
}

function JumpNav({headings, top, columnList, noBorders, sectionSpacing, direction, numberOfColumns, numberOfRows}: JumpNavProps) {
	if (columnList) {
		return (
			<div className={cx(noBorders ? '!mt-[24px] border-b-2 border-gray-200 pb-[24px] pt-0' : 'mt-[8px] border-y-2 border-gray-200 py-[24px]', sectionSpacing && 'section-spacing')}>
				<h2 className="!m-0 max-w-content !text-base !font-semibold !leading-6">On this page</h2>
				<ul
					className={cx(direction === 'vertical' && 'grid-flow-col', 'no-list grid-col m-0 grid max-w-max  gap-x-[24px] gap-y-[16px] p-0 sm:max-h-[7rem]')}
					style={{
						gridTemplateColumns: direction === 'horizontal' ? `repeat(${numberOfColumns}, minmax(0, max-content))` : undefined,
						gridTemplateRows: direction === 'vertical' ? `repeat(${numberOfRows}, minmax(0, max-content))` : undefined,
					}}
				>
					{headings.map((heading) => (
						<li
							key={heading.label}
							className="!m-0 !p-0"
						>
							<a
								href={'#' + heading.id}
								className={`${heading.wrapTitle ? 'wrapped-title' : ''} text-sm font-medium leading-5 tracking-wide underline`}
							>
								{heading.shortLabel || heading.label}
							</a>
						</li>
					))}
				</ul>
			</div>
		)
	}

	const [showScrollButtons, setShowScrollButtons] = useState(false)
	const {sideScrollRef, handleSideScrollLeftPointerDown, handleSideScrollLeftPointerUp, handleSideScrollRightPointerDown, handleSideScrollRightPointerUp} = useSideScrollButtons<HTMLDivElement>()
	useEffect(() => {
		const handleWindowResize = () => {
			setShowScrollButtons((sideScrollRef.current?.clientWidth ?? 0) < (sideScrollRef.current?.scrollWidth ?? 0))
		}

		// This is need to set the initial value
		setTimeout(() => {
			handleWindowResize()
		}, 100)

		window.addEventListener('resize', handleWindowResize)
		return () => window.removeEventListener('resize', handleWindowResize)
	}, [])

	const [inViewHeadingIndex, setInViewHeadingIndex] = useState<number>(-1)
	useEffect(() => {
		const headingElements = headings.map((heading) => document.querySelector('#' + heading.id))
		const handleScroll = () => {
			const firstInViewIndex = headingElements.findIndex((heading) => {
				const headingY = heading?.getBoundingClientRect().y
				return headingY != null && headingY >= -30 //
			})
			setInViewHeadingIndex(firstInViewIndex)
		}

		// This is need to set the initial value
		setTimeout(() => {
			handleScroll()
		}, 100)

		window.addEventListener('scroll', throttle(handleScroll, 200), {
			passive: true,
		})
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	return (
		<>
			{/*  Desktop  */}
			{!top && (
				<div className="fixed left-0 top-0 z-[2] hidden h-screen w-[234px] xl:block">
					<div className="static mt-[192px] rounded-r-2xl bg-white py-[8px]">
						<div className="my-[24px] border-r border-gray-300 bg-white px-[24px]">
							<h2 className="pb-[24px] !text-xs font-medium uppercase leading-4 tracking-wider text-gray-900">on this page</h2>
							<div className="space-y-1">
								{headings.map((heading, index) => {
									const current = index === inViewHeadingIndex
									return (
										<a
											key={heading.label}
											id={heading.id + '_button'}
											href={'#' + heading.id}
											className={cx(
												current ? 'bg-gray-100' : 'hover:bg-gray-50',
												'flex w-full items-center rounded-md border-2 border-transparent px-3 py-2 text-sm font-medium text-gray-900 focus:border-blue-700',
											)}
											aria-current={current ? 'page' : undefined}
										>
											<span className={heading.wrapTitle ? 'wrapped-title' : 'truncate'}>{heading.shortLabel || heading.label}</span>
										</a>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			)}

			{/*  Mobile  */}
			<div className={cx(top ? '' : 'xl:hidden', 'mb-6', sectionSpacing && 'section-spacing')}>
				<div
					ref={sideScrollRef}
					className="mx-[-1rem] flex items-center overflow-x-hidden px-[1rem] pb-2 sm:-mx-6  sm:px-6 lg:mx-0 lg:px-0"
				>
					{headings.map((heading, index) => (
						<a
							key={index}
							href={'#' + heading.id}
							className={`${
								heading.wrapTitle ? 'wrapped-title' : ''
							} mr-2 inline-flex items-center whitespace-nowrap rounded-md border-2 border-transparent px-3 py-2 text-sm font-medium leading-5 text-gray-900 hover:bg-gray-100 focus:border-blue-700 focus:outline-none`}
						>
							{heading.shortLabel || heading.label}
						</a>
					))}
				</div>
				<div className={cx(showScrollButtons ? '' : 'hidden', 'mb-4 flex justify-between pt-[4px] md:pt-[6px]')}>
					<div className="flex space-x-2">
						<button
							className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
							onPointerDown={handleSideScrollLeftPointerDown}
							onPointerUp={handleSideScrollLeftPointerUp}
							aria-label="scroll left"
						>
							<Icon
								icon="chevron-left"
								className="mr-[2px] h-[22px] w-[22px]"
							/>
						</button>
						<button
							className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
							onPointerDown={handleSideScrollRightPointerDown}
							onPointerUp={handleSideScrollRightPointerUp}
							aria-label="scroll right"
						>
							<Icon
								icon="chevron-right"
								className="ml-[2px] h-[22px] w-[22px]"
							/>
						</button>
					</div>
				</div>
			</div>
		</>
	)
}
