import {addIcon, Icon as Iconify} from '@iconify/react'
import type React from 'react'
import {render} from '~/utils/render'
import './Icon.css'

interface Props {
	icon?: string
	label?: string
	className?: string
}

export function initIcon() {
	document.querySelectorAll<HTMLElement>('.sh-available-icons').forEach((element) => {
		render(<AvailableIcons />, element)
	})

	document.querySelectorAll<HTMLElement>('[data-icon]').forEach((element) => {
		const props: Props = {
			icon: element.dataset.icon,
			label: element.textContent ?? undefined,
			className: element.className,
		}

		const replacementElement = document.createElement(element.tagName)
		element.parentElement?.insertBefore(replacementElement, element)
		element.remove()
		render(<Icon {...props} />, replacementElement)
	})
}

addIcon('uom:arrow-left-alt', {
	body: '<path d="M20.7263 34.6454H50.0863C51.5529 34.6454 52.7529 33.4454 52.7529 31.9787C52.7529 30.512 51.5529 29.312 50.0863 29.312L20.7263 29.312V24.5387C20.7263 23.3387 19.2863 22.752 18.4596 23.6054L11.0463 31.0454C10.5396 31.5787 10.5396 32.4054 11.0463 32.9387L18.4596 40.3787C19.2863 41.232 20.7263 40.6187 20.7263 39.4454V34.6454Z" fill="currentColor"/>',
	width: 64,
	height: 64,
})

addIcon('uom:arrow-right-alt', {
	body: '<g transform="translate(64 0) scale(-1 1)"><path d="M20.7263 34.6454H50.0863C51.5529 34.6454 52.7529 33.4454 52.7529 31.9787C52.7529 30.512 51.5529 29.312 50.0863 29.312L20.7263 29.312V24.5387C20.7263 23.3387 19.2863 22.752 18.4596 23.6054L11.0463 31.0454C10.5396 31.5787 10.5396 32.4054 11.0463 32.9387L18.4596 40.3787C19.2863 41.232 20.7263 40.6187 20.7263 39.4454V34.6454Z" fill="currentColor"></path></g>',
	width: 64,
	height: 64,
})

const iconMap: {[iconName: string]: string} = {
	add: 'ic:round-plus',
	delete: 'ic:round-remove',
	check: 'ic:round-check',
	'check-circle': 'ic:round-check-circle',
	'check-circle-outline': 'ic:round-check-circle-outline',
	form: 'ic:round-fact-check',
	close: 'ic:round-close',
	favorite: 'ic:round-favorite',
	'favorite-outline': 'ic:round-favorite-border',
	star: 'ic:round-star',
	'star-outline': 'ic:round-star-border',
	'star-circle': 'ic:round-stars',
	info: 'ic:round-info',
	'info-outline': 'material-symbols:info-outline-rounded',
	warning: 'ic:round-warning',
	'warning-outline': 'ic:round-warning-amber',
	'danger-outline': 'material-symbols:cancel-outline-rounded',
	question: 'ic:round-help',
	support: 'mdi:lifebuoy',
	'question-outline': 'ic:round-help-outline',
	'thumb-up': 'ic:round-thumb-up',
	'thumb-down': 'ic:round-thumb-down',
	clock: 'ic:round-schedule',
	'clock-filled': 'ic:round-watch-later',
	'light-bulb': 'ic:baseline-lightbulb',
	lock: 'ic:round-lock',
	student: 'ic:round-school',
	home: 'ic:round-home',
	search: 'ic:round-search',
	menu: 'ic:round-menu',
	'more-vertical': 'ic:round-more-vert',
	ellipsis: 'ic:round-more-horiz',
	apps: 'ic:round-apps',
	grid: 'mdi:grid-on',
	hr: 'ic:round-person',
	group: 'ic:round-group',
	groups: 'ic:round-groups',
	face: 'ic:round-face',
	identification: 'ic:outline-badge',
	'account-circle': 'ic:round-account-circle',
	'users-circle': 'ic:round-supervised-user-circle',
	facebook: 'ic:round-facebook',
	'arrow-left-alt': 'uom:arrow-left-alt',
	'arrow-right-alt': 'uom:arrow-right-alt',
	'chevron-up': 'ic:round-expand-less',
	'chevron-down': 'ic:round-expand-more',
	'chevron-right': 'material-symbols:chevron-right-rounded',
	'chevron-left': 'material-symbols:chevron-left-rounded',
	'arrow-drop-down': 'material-symbols:arrow-drop-down-rounded',
	'arrow-drop-up': 'material-symbols:arrow-drop-up-rounded',
	'arrow-drop-left': 'material-symbols:arrow-left-rounded',
	'arrow-drop-right': 'material-symbols:arrow-right-rounded',
	'arrow-up': 'ic:round-north',
	'arrow-down': 'ic:round-south',
	'arrow-left': 'ic:round-west',
	'arrow-right': 'ic:round-east',
	'arrow-down-left': 'ic:round-south-west',
	'arrow-down-right': 'ic:round-south-east',
	'arrow-up-left': 'ic:round-north-west',
	'arrow-up-right': 'ic:round-north-east',
	'arrow-forward': 'ic:round-arrow-forward',
	'arrow-back': 'ic:round-arrow-back',
	trash: 'ic:round-delete',
	filter: 'ic:round-filter-alt',
	send: 'ic:round-send',
	download: 'ic:round-download',
	'download-cloud': 'ic:round-cloud-download',
	settings: 'ic:round-settings',
	notifications: 'ic:round-notifications',
	'notifications-outline': 'ic:round-notifications-none',
	mail: 'ic:round-mail',
	chat: 'ic:round-chat',
	'chat-alt': 'ic:round-chat',
	print: 'ic:round-print',
	phone: 'ic:round-phone',
	rss: 'ic:round-rss-feed',
	share: 'ic:round-share',
	date: 'ic:round-date-range',
	bar: 'ic:round-local-bar',
	tea: 'ic:round-emoji-food-beverage',
	cafe: 'ic:round-local-cafe',
	bus: 'ic:round-directions-bus',
	bike: 'ic:round-directions-bike',
	campaign: 'ic:round-account-balance',
	car: 'ic:round-directions-car',
	flight: 'ic:round-flight',
	city: 'ic:round-location-city',
	pharmacy: 'ic:round-local-pharmacy',
	train: 'ic:round-train',
	location: 'ic:round-location-on',
	faculty: 'ic:round-corporate-fare',
	walk: 'ic:round-directions-walk',
	map: 'ic:round-map',
	cutlery: 'ic:round-local-dining',
	world: 'ic:round-public',
	accessible: 'ic:round-accessible',
	jobs: 'ic:round-work',
	cart: 'ic:round-shopping-cart',
	tag: 'ic:round-local-offer',
	money: 'ic:round-attach-money',
	computer: 'ic:round-computer',
	'desktop-computer': 'ic:round-desktop-windows',
	devices: 'ic:round-devices',
	smartphone: 'ic:round-smartphone',
	'zoom-in': 'ic:round-zoom-in',
	'zoom-out': 'ic:round-zoom-out',
	book: 'ic:round-book',
	bookmark: 'ic:round-bookmark',
	'support-article': 'ic:round-menu-book',
	library: 'ic:round-local-library',
	'external-link': 'ic:round-launch',
	outbound: 'ic:round-outbound',
	verified: 'ic:round-verified',
	edit: 'ic:round-edit',
	visibility: 'ic:round-visibility',
	link: 'ic:round-link',
	photo: 'ic:round-photo',
	camera: 'ic:round-camera-alt',
	/*	'check-circle': 'material-symbols:check-circle-outline-rounded',*/
}

export function Icon(props: Props) {
	const {icon, className} = props

	if (icon == null || icon.length <= 0) return null

	let iconName = iconMap[icon]
	if (iconName == null) {
		iconName = icon
	}

	return (
		<Iconify
			className={className}
			icon={iconName}
			data-icon-name={icon}
		/>
	)
}

function AvailableIcons() {
	return (
		<div className="flex flex-wrap gap-x-[12px] gap-y-[48px] pt-6 text-gray-900">
			{Object.keys(iconMap).map((key) => (
				<div
					key={key}
					className="flex w-[99px] flex-col items-center"
				>
					<div
						className="small"
						data-icon={key}
					/>
					<div className="pt-3 text-center text-gray-600">{key}</div>
				</div>
			))}
		</div>
	)
}
