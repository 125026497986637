import React from 'react'
import {Card, CardProps} from '~/cards/Card'
import {queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'

interface HighlightedTopicsProps {
	title?: string
	highlightedTopics: CardProps[]
}

export function initHighlightedTopics() {
	document.querySelectorAll<HTMLElement>('.sh-highlighted-topics, .block-highlighted-topics').forEach((element) => {
		element.classList.add('doesnt-need-gap')

		const props: HighlightedTopicsProps = {
			title: queryTextContent('h2', element),
			highlightedTopics: Array.from(element.querySelectorAll<HTMLElement>('article')).map((article) => ({
				title: article.querySelector('h3')?.textContent ?? '',
				body: article.querySelector('p')?.textContent ?? '',
				linkLabel: article.querySelector('a')?.textContent ?? '',
				url: article.querySelector('a')?.href ?? '',
				type: 'general',
			})),
		}

		render(<HighlightedTopics {...props} />, element)
	})
}

export function HighlightedTopics({title, highlightedTopics}: HighlightedTopicsProps) {
	return (
		<div className="full-width-background before:bg-gray-50">
			<div className="relative py-[48px]">
				<h2 className="block-uplift pb-[24px] text-2xl font-semibold leading-8 text-gray-900">{title}</h2>
				<article className="grid gap-[24px] md:grid-cols-2">
					{highlightedTopics.map((highlightedTopic) => (
						<Card
							key={highlightedTopic.url}
							{...highlightedTopic}
						/>
					))}
				</article>
			</div>
		</div>
	)
}
