import React from 'react'
import {useSideScrollButtons} from '~/utils/useSideScrollButtons'
import {LevelPips} from './LevelPips'
import {labels, levelLabels, sections, useSelfGuidanceToolStore} from './useSelfGuidanceToolStore'
import {Icon} from '~/blocks/Icon'

export const ResultsLevels = () => {
	const {levelForSection} = useSelfGuidanceToolStore()
	const {sideScrollRef, handleSideScrollLeftPointerDown, handleSideScrollLeftPointerUp, handleSideScrollRightPointerDown, handleSideScrollRightPointerUp} = useSideScrollButtons<HTMLDivElement>()

	return (
		<>
			<h2 className="!mt-[48px] !pb-[8px] !text-xl md:!pb-[24px] md:!text-2xl">{labels.resultsLevelsHeader}</h2>
			<div
				ref={sideScrollRef}
				className="hide-horizontal-scrollbar -mx-4 flex gap-[24px]  overflow-y-hidden px-4 pb-[24px] sm:-mx-6 sm:px-6 md:grid md:grid-cols-2 md:overflow-y-visible lg:grid-cols-3"
			>
				{sections.map((section, index) => {
					const level = levelForSection(index)
					if (level === 0) return null
					return (
						<div
							key={index}
							className="rounded-lg p-[24px] shadow"
						>
							<h3 className="!mt-0 min-w-max pb-[8px] !text-xs !font-medium uppercase !leading-4 !tracking-wider !text-gray-600">{section.title}</h3>
							<div className="rounded-lg bg-gray-50 p-[12px]">
								<LevelPips
									level={level}
									forceEmptyWhiteBackground={true}
								/>
								<div className="pt-[12px] text-sm font-semibold leading-5 text-gray-900">{levelLabels[level - 1]}</div>
							</div>
						</div>
					)
				})}
			</div>
			<div className="flex space-x-2 md:hidden">
				<button
					className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
					onPointerDown={handleSideScrollLeftPointerDown}
					onPointerUp={handleSideScrollLeftPointerUp}
					aria-label="scroll left"
				>
					<Icon
						icon="chevron-left"
						className="mr-[2px] h-[22px] w-[22px]"
					/>
				</button>
				<button
					className="flex h-[34px] w-[34px] items-center justify-center rounded-full shadow focus:bg-gray-200 focus:outline-none"
					onPointerDown={handleSideScrollRightPointerDown}
					onPointerUp={handleSideScrollRightPointerUp}
					aria-label="scroll right"
				>
					<Icon
						icon="chevron-right"
						className="ml-[2px] h-[22px] w-[22px]"
					/>
				</button>
			</div>
		</>
	)
}
