import './Button.css'

export function initButton() {
	document.querySelectorAll<HTMLElement>('.with-arrow, .button-hero, .button-hero-inverse').forEach((element) => {
		element.innerHTML =
			element.innerHTML +
			'<span class="arrow"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" /></svg></span>'
	})

	document.querySelectorAll<HTMLElement>('.with-arrow-left, .button-hero-reverse').forEach((element) => {
		element.innerHTML =
			'<span class="arrow arrow-left"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" /></svg></span>' +
			element.innerHTML
	})

	// Uplift old Matrix buttons
	document.querySelectorAll('.enquiry-form__submit-button').forEach((element) => element.classList.add('button-sm'))
}
