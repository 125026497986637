import {useEffect, useState} from 'react'
import {config} from '~/pages/giftGovernanceRecords/GiftGovernanceRecordsPage'

export const useGiftGovernanceRecordsStore = (searchTerm: string | null, divisionFilter: string | null, giftTypeFilter: string | null, categoryFilters: string[]) => {
	const [isLoading, setIsLoading] = useState(false)
	const [apiResponse, setApiResponse] = useState<GiftApiResponse | null>(null)

	const isNotEmptySearch =
		(searchTerm != null && searchTerm.length > 0) || (divisionFilter != null && divisionFilter.length > 0) || (giftTypeFilter != null && giftTypeFilter.length > 0) || categoryFilters.length > 0

	useEffect(() => {
		if (!isNotEmptySearch) {
			setApiResponse(null)
			return
		}

		const fetchData = async () => {
			setIsLoading(true)
			const response = await fetch(
				config.apiUrl +
					new URLSearchParams({
						queries_key_word_query: searchTerm ?? '',
						//queries_name__query: '',
						queries_type_query: giftTypeFilter ?? '',
						queries_division_query: divisionFilter ?? '',
						queries_category_query: categoryFilters.join(' '),
						//queries_code_id_query: '',
					}),
			)
			const data = (await response.json()) as GiftApiResponse
			//data.results = data.results.sort((a, b) => a.projectName.toLowerCase().replace('the', '').localeCompare(b.projectName.toLowerCase().replace('the', '')))
			setApiResponse(data)
			setIsLoading(false)
		}

		fetchData().then()
	}, [searchTerm, divisionFilter, giftTypeFilter, categoryFilters])
	return {isLoading, apiResponse}
}

export interface GiftApiResponse {
	total_results: number
	query: Query
	results: GiftRecord[]
}

export interface Query {
	keyWord: string
	projectName: string
	giftType: string
	giftDivision: string
	giftCategory: string
	giftCodeID: string
}

export interface GiftRecord {
	workday_id: string
	gift_id: string
	projectCode: string
	projectName: string
	division: string
	type: string
	categories: string[]
	pdf_url?: string
	description: string
	year: string
	value: string
	recitalText: string
	recordAssetID: number
}
