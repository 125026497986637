import React from 'react'
import {IconInBox} from '~/images/IconInBox'
import {Icon} from '~/blocks/Icon'

export interface ContactMethod {
	icon?: string
	title?: string
	body?: string
	linkText?: string
	linkUrl?: string
	linkTarget?: string
}

export function ContactMethodCard({contactMethod, classes}: {contactMethod: ContactMethod; classes: string}) {
	return (
		<a
			href={contactMethod.linkUrl}
			className={'flex w-[240px] flex-shrink-0 flex-col rounded-md border-transparent pt-[24px] pr-4 text-base hover:no-underline  ' + classes}
		>
			<div className="flex-1">
				<IconInBox
					colour="navy"
					icon={contactMethod.icon === 'mobile' ? 'smartphone' : contactMethod.icon ?? ''}
				/>
				<div className="pt-[16px] font-medium text-gray-900">{contactMethod.title}</div>
			</div>
			<div>
				<div className="pt-[4px] text-sm font-normal text-gray-600">{contactMethod.body}</div>
				<div className="pt-[24px] text-sm font-medium leading-5 text-blue-700 hover:underline">
					{contactMethod.linkText}
					<Icon
						icon="arrow-forward"
						className="ml-2 mb-[4px] inline h-4 w-4"
					/>
				</div>
			</div>
		</a>
	)
}
