import React from 'react'
import {Icon} from '~/blocks/Icon'
import {DownloadIconInBox} from '~/images/DownloadIconInBox'
import {ExternalLinkIconInBox} from '~/images/ExternalLinkIconInBox'
import {FormIconInBox} from '~/images/FormIconInBox'
import {IconInBox} from '~/images/IconInBox'
import {LinkIconInBox} from '~/images/LinkIconInBox'
import {SupportArticleIconInBox} from '~/images/SupportArticleIconInBox'
import {cx} from '~/utils/utils'
import type {CardProps} from './Card'
import {CommonCardProps} from './Card'
import {CardWrapper} from './CardWrapper'

export interface LinkCardProps extends CommonCardProps {
	isDownload?: boolean
	hasDownloadClass?: boolean
	colour?: string
}

export function LinkCard(props: CardProps) {
	const url = new URL(props.url ?? '', 'https://' + window.location.host)
	const isInternal = url.hostname === window.location.host

	function linkIcon() {
		if (props.isDownload) {
			return <DownloadIconInBox />
		}
		if (url.protocol.toLowerCase() === 'mailto:') {
			return (
				<IconInBox
					icon="mail"
					colour="gray"
				/>
			)
		}
		if (props.modalContent != null && props.modalContent.length > 0) {
			if (props.type == 'link') {
				return (
					<IconInBox
						icon="ellipsis"
						colour="green"
					/>
				)
			}
			else {
				return <LinkIconInBox />
			}
		}

		if ((url.hostname === 'unimelb.service-now.com' || url.hostname === 'uomservicehub.service-now.com') && url.searchParams.get('id') === 'kb_article') {
			return <SupportArticleIconInBox />
		}
		if (url.hostname === 'unimelb.service-now.com' || url.hostname === 'uomservicehub.service-now.com') {
			return <FormIconInBox />
		}
		if (isInternal) {
			return <LinkIconInBox />
		}
		return <ExternalLinkIconInBox />
	}

	return (
		<CardWrapper
			{...props}
			className={cx(
				'group flex h-[100px] flex-1 items-center rounded-lg border-2 border-transparent p-6 text-sm font-medium leading-5 text-blue-700 shadow hover:underline hover:shadow-lg focus:border-blue-700 focus:outline-none',
				props.horizontalLayout && 'min-w-max pr-[42px]',
			)}
		>
			{props.icon == null || props.icon.length <= 0 ? (
				linkIcon()
			) : (
				<IconInBox
					colour={props.colour ?? ''}
					icon={props.icon}
				/>
			)}
			<span className="flex-shrink pl-[24px] pr-[16px]">{props.title}</span>
			{props.modalContent == null && (
				<Icon
					icon={isInternal ? 'arrow-right' : 'external-link'}
					className="inline h-4 w-4 flex-shrink-0"
				/>
			)}
		</CardWrapper>
	)
}
