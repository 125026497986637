import React from 'react'
import {Icon} from '~/blocks/Icon'

export const IconAttachedToLastWord = ({text, icon, className}: {text: string; icon: string; className: string}) => {
	const lastSpaceIndex = text?.lastIndexOf(' ') ?? 0

	return (
		<>
			{text.substring(0, lastSpaceIndex + 1)}
			<span className="whitespace-nowrap">
				{text.substring(lastSpaceIndex)}
				<Icon
					icon={icon}
					className={className}
				/>
			</span>
		</>
	)
}
