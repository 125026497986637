import {MutableRefObject, useEffect, useState} from 'react'

export function useLineCount(ref: MutableRefObject<any> | null, lineHeight: number) {
	const [lineCount, setLineCount] = useState(1)

	useEffect(() => {
		if (ref?.current != null) {
			setLineCount(Math.floor(ref.current.clientHeight / lineHeight))
		}
	}, [])
	return lineCount
}
