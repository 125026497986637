import React, {useEffect, useRef, useState} from 'react'
import {LinkDropdown, LinkDropdownOption} from '~/components/LinkDropdown'
import {queryHref, querySrc, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx, getBackgroundImage, replaceWithNewDiv} from '~/utils/utils'
import './Banner.css'

export type Colour = 'teal' | 'navy' | 'gray'
export type Size = 'extra-small' | 'small' | 'large'

export interface BannerProps {
	imageUrl?: string
	colour?: Colour
	size?: Size
	preHeading?: string
	h1?: string
	h2?: string
	p?: string
	buttonLabel?: string
	buttonUrl?: string
	linkDropdownLabel?: string
	linkDropdownPlaceholder?: string
	linkDropdownOptions?: LinkDropdownOption[]
	linkDropdownButtonLabel?: string
}

export function initBanner() {
	document.querySelectorAll<HTMLElement>('.sh-banner, .block-banner').forEach((element) => {
		element.classList.add('doesnt-need-gap')

		let colour: Colour = 'navy'
		if (element.classList.contains('green') || element.dataset.colour === 'green' || element.dataset.color === 'green') {
			colour = 'teal'
		} else if (element.classList.contains('gray') || element.dataset.colour === 'gray' || element.dataset.color === 'gray') {
			colour = 'gray'
		}

		let size: Size = 'small'
		if (element.classList.contains('extra-small') || element.dataset.size === 'extra-small') {
			size = 'extra-small'
		}
		if (element.classList.contains('large') || element.dataset.size === 'large') {
			size = 'large'
		}

		const props: BannerProps = {
			imageUrl: querySrc('img', element),
			colour: colour,
			size: size,
			h1: queryTextContent('h1', element),
			preHeading: queryTextContent('.pre-heading', element),
			h2: queryTextContent('h2', element),
			p: queryTextContent('p', element),
			buttonLabel: queryTextContent('a', element),
			buttonUrl: queryHref('a', element),
			linkDropdownLabel: queryTextContent('label.link-dropdown, label[for="link-dropdown"]', element),
			linkDropdownPlaceholder: element.querySelector<HTMLElement>('select.link-dropdown')?.dataset.placeholder,
			linkDropdownOptions: Array.from(element.querySelectorAll<HTMLOptionElement>('.link-dropdown option')).map((option) => ({
				url: option.value,
				label: option.textContent || '',
				group: option.classList.contains('group'),
			})),
			linkDropdownButtonLabel: queryTextContent('button.link-dropdown', element),
		}

		if (props.h1) {
			document.querySelector('.aligned-title')?.remove()
			document.querySelector('.lead.left')?.remove()
		}

		element.classList.add('doesnt-need-gap')
		render(<Banner {...props} />, element)
	})

	document.querySelectorAll<HTMLElement>('ul.hero-listing').forEach((element) => {
		let colour: Colour = 'navy'
		if (element.classList.contains('green')) {
			colour = 'teal'
		} else if (element.classList.contains('gray')) {
			colour = 'gray'
		}

		let size: Size = 'small'
		if (element.classList.contains('large')) {
			size = 'large'
		}

		const props: BannerProps = {
			imageUrl: getBackgroundImage(element.querySelector<HTMLElement>(':scope > li > a')),
			buttonUrl: queryHref(':scope > li > a', element),
			buttonLabel: 'View',
			colour: colour,
			size: size,
			h2: queryTextContent('.inner > strong', element),
			p: queryTextContent('.inner > p', element),
		}

		const div = replaceWithNewDiv(element)
		div.classList.add('doesnt-need-gap')

		render(<Banner {...props} />, div)
	})

	document.querySelectorAll<HTMLElement>('.staff-ui-banner').forEach((element) => {
		const props: BannerProps = {
			h1: queryTextContent('h1', element),
			p: queryTextContent('.subheading', element),
			imageUrl: querySrc('img', element),
			colour: (element.dataset.backgroundColour as Colour) ?? 'navy',
			size: (element.dataset.size as Size) ?? 'small',
		}
		const newElement = replaceWithNewDiv(element)
		newElement.classList.add('doesnt-need-gap')
		render(<Banner {...props} />, newElement)
	})
}

export function Banner({imageUrl, colour, size, preHeading, h1, h2, p, buttonLabel, buttonUrl, linkDropdownLabel, linkDropdownPlaceholder, linkDropdownOptions, linkDropdownButtonLabel}: BannerProps) {
	const [height, setHeight] = useState(0)
	const imageRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const handleWindowResize = () => {
			if (imageRef?.current != null) {
				setHeight(imageRef.current.clientHeight)
			}
		}

		// This is need to set the initial value
		setTimeout(() => {
			handleWindowResize()
		}, 100)

		window.addEventListener('resize', handleWindowResize)
		return () => window.removeEventListener('resize', handleWindowResize)
	}, [])

	const isSmall = size === 'small'
	const hasButton = buttonLabel != null && buttonLabel.length > 0 && buttonUrl != null && buttonUrl.length > 0
	const hasLinkDropdown = linkDropdownOptions != null && linkDropdownOptions.length > 0

	function getPadding() {
		if (isSmall) {
			return hasButton ? 'lg:py-[83px]' : 'lg:py-[128px]'
		}
		if (size === 'extra-small') {
			return 'lg:py-[100px]'
		} else {
			if (hasButton) {
				return 'lg:py-[128px]'
			}
			if (hasLinkDropdown) {
				return 'lg:py-[147px]'
			}
			return 'lg:py-[172px]'
		}
	}

	return (
		<div className={cx('full-width-background banner-background banner', colour)}>
			<div
				ref={imageRef}
				className="relative flex h-full min-h-[200px] justify-between gap-x-[24px]"
			>
				<div className={cx(getPadding(), 'my-auto flex-shrink py-[48px]')}>
					{preHeading && <div className="pb-[8px] text-xs font-medium uppercase leading-4 tracking-wider">{preHeading}</div>}
					{h1 && <h1 className="block-uplift text-3xl font-extrabold leading-8 tracking-tight">{h1}</h1>}
					{h2 && <h2 className={cx(h1 ? 'pt-[24px] text-lg font-normal leading-6' : 'text-3xl font-extrabold leading-8 tracking-tight', 'block-uplift')}>{h2}</h2>}
					{p && (
						<div
							className="block-paragraph-padding pt-[24px] text-lg font-normal leading-6"
							dangerouslySetInnerHTML={{__html: p}}
						/>
					)}
					{hasButton && (
						<a
							className="link-button focus:outline-blue mt-[48px] block max-w-max cursor-pointer rounded-lg px-[17px] py-[9px] shadow-sm hover:no-underline"
							href={buttonUrl}
						>
							{buttonLabel}
						</a>
					)}
					{hasLinkDropdown && (
						<LinkDropdown
							label={linkDropdownLabel}
							placeholder={linkDropdownPlaceholder}
							options={linkDropdownOptions}
							buttonLabel={linkDropdownButtonLabel}
						/>
					)}
				</div>
				<div className={cx(colour, 'banner-aperture relative -mr-8 hidden max-w-[50%] flex-shrink-0 lg:block xl:mr-0')}>
					<img
						src={imageUrl}
						alt=""
						className={cx(isSmall ? 'ml-[24px] w-[calc(var(--height)*1.15)]' : 'ml-[32px] w-[calc(var(--height)*1.10)]', 'banner-image-clip-path block h-full object-cover object-right-top')}
						style={{['--height' as any]: height + 'px'}}
					/>
				</div>
			</div>
		</div>
	)
}
