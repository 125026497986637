import React, {Fragment} from 'react'
import {cx, range, unique} from '~/utils/utils'
import {Icon} from '~/blocks/Icon'

interface PaginationProps {
	numberOfPages: number
	currentPage: number
	goToPage: (newPageNumber: number) => void
}

export function Pagination({numberOfPages, currentPage, goToPage}: PaginationProps) {
	function numbers() {
		if (numberOfPages <= 10) {
			return <>{range(1, numberOfPages).map((i) => pageNumber(i))}</>
		} else {
			const pagesToShow = unique([1, 2, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, numberOfPages - 1, numberOfPages]).filter(
				(value) => value > 0 && value <= numberOfPages,
			)
			return pagesToShow.map((i) => (
				<Fragment key={i}>
					{i === numberOfPages - 1 && currentPage < numberOfPages - 4 && threeDots()}
					{pageNumber(i)}
					{i === 2 && currentPage > 5 && threeDots()}
				</Fragment>
			))
		}
	}

	function threeDots() {
		return <div className="inline-flex items-center border-transparent px-4 pt-4 text-sm font-medium">...</div>
	}

	function pageNumber(i: number) {
		return (
			<button
				key={i}
				onClick={() => goToPage(i)}
				className={cx(
					i === currentPage ? 'border-blue-700 text-blue-700' : 'cursor-pointer border-transparent hover:border-gray-500 hover:text-gray-500',
					'inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium',
				)}
			>
				{i}
			</button>
		)
	}

	return (
		<nav className="mt-12 flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
			{/* Previous */}
			<div className="-mt-px flex w-0 flex-1">
				<button
					onClick={() => goToPage(currentPage - 1)}
					className={cx(
						currentPage > 1 ? '' : 'hidden',
						'inline-flex cursor-pointer items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium hover:border-gray-500 hover:text-gray-500',
					)}
				>
					<Icon
						icon="arrow-back"
						className="mr-2 h-[18px]"
						aria-hidden="true"
					/>
					Previous
				</button>
			</div>
			{/* Numbers */}
			<div className="hidden md:-mt-px md:flex">{numbers()}</div>
			{/* Next */}
			<div className="-mt-px flex w-0 flex-1 justify-end">
				<button
					onClick={() => goToPage(currentPage + 1)}
					className={cx(
						currentPage < numberOfPages ? 'cursor-pointer' : 'hidden',
						'inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium hover:border-gray-500 hover:text-gray-500',
					)}
				>
					Next
					<Icon
						icon="arrow-forward"
						className="ml-2 h-[18px]"
						aria-hidden="true"
					/>
				</button>
			</div>
		</nav>
	)
}
