import React, {useEffect, useState} from 'react'
import {SearchBox} from '~/components/SearchBox'
import {useNewsData} from './NewsApi'
import {render} from '~/utils/render'
import {cx, getUrlParam, removeUrlParam, setUrlParam} from '~/utils/utils'
import {LoadingAnimation} from '~/blocks/LoadingAnimation'
import {Icon} from '~/blocks/Icon'
import {NewsRecordCard} from './NewsRecordCard'
import {NewsListGridViewButtons} from './NewsListGridViewButtons'
import './NewsRecordsPage.css'
import Masonry from 'react-masonry-css'
import { NewsMultipleSelect } from './NewsMultipleSelect'
import { NewsSearchBox } from './NewsSearchBox'

interface NewsRecordsConfig {
	allNewsUrl: string
	newsDetailsUrl: string
	limit: number
	faculty: Faculty[]
	article_category: ArticleCategory[]
	// article_type: ArticleType[]
	pageLabels: {
		searchLabel?: string
		searchPlaceholder?: string
		// foundRecordCountLabel?: string
		facultyFilterLabel?: string
		allFacultyName?: string
		categoryFilterLabel?: string
		allCategoryName?: string
		// typeFilterLabel?: string
		// allTypeName?: string
	}
	modalLabels: {
		descriptionLabel: string
		detailsLabel: string
	}
}

export interface Faculty {
	id: string
	name: string
	tag_name: string
}
export interface ArticleCategory {
	id: string
	name: string
	tag_name: string
}
// interface ArticleType {
// 	id: string
// 	name: string
// }

export const config: NewsRecordsConfig = (window as any).newsRecordsConfig ?? {}

export const initNewsRecords = () => {
	document.querySelectorAll('#news-records').forEach((element) => {
		render(<NewsRecordsPage />, element)
	})
}

const breakpointColumnsObj = {
	default: 3,
	1100: 2,
	700: 1,
}

const NewsRecordsPage = () => {
	const [searchTerm, setSearchTerm] = useState<string | null>(getUrlParam('search'))
	const [newsFacultyFilter, setFacultyFilter] = useState<string[]>(getUrlParam('faculty')?.split(',') || [])
	const [newsCategoryFilter, setArticleCategoryFilter] = useState<string[]>(getUrlParam('category')?.split(',') || [])
	// const [newsTypeFilter, setArticleTypeFilter] = useState<string[]>(getUrlParam('type')?.split(',') || [])
	const [isGridView, setIsGridView] = useState(getUrlParam('view') === 'grid')
	const [offset, setOffset] = useState(0)
	const [issue, setIssue] = useState<string | null>(getUrlParam('issue'))
	const {isLoading, news, totalCount} = useNewsData(searchTerm, newsFacultyFilter, newsCategoryFilter, offset, issue)
	const [openModalNewsRecord, setOpenModalNewsRecord] = useState<any | null>(null)

	const handleShowMore = () => {
		setOffset((prevOffset) => prevOffset + config.limit)
	}

	const hasResults = news?.length > 0

	const getSelectedIndexes = (filter: string[], options: {id: string; name: string}[]) =>
		options.map((option, index) => (filter.includes(option.id) ? index : null)).filter((index) => index !== null) as number[]

	const handleSelectionChange = (
		selectedIndexes: number[],
		setFilter: React.Dispatch<React.SetStateAction<string[]>>,
		options: { id: string; name: string }[],
		paramName: string
	) => {
		setOffset(0);
		const selectedIds = selectedIndexes.map((index) => options[index].id);
		setFilter(selectedIds);
		if (selectedIds.length > 0) {
			setUrlParam(paramName, selectedIds.join(','));
		} else {
			removeUrlParam(paramName);
		}
	};	

	function handleSearch(newTerm: string) {
		setOffset(0)
		if (newTerm != null && newTerm.trim().length > 0) {
			setUrlParam('search', newTerm)
		} else {
			removeUrlParam('search')
		}
		setSearchTerm(newTerm)
	}

	function handleGridViewChange(newIsGridView: boolean) {
		setIsGridView(newIsGridView)
		if (newIsGridView) {
			setUrlParam('view', 'grid')
		} else {
			removeUrlParam('view')
		}
	}

	// function handleOpenModal(newsRecord: any) {
	// 	setOpenModalNewsRecord(newsRecord)
	// 	setUrlParam('modalNewsId', newsRecord.id)
	// }

	function handleCloseModal() {
		setOpenModalNewsRecord(null)
		removeUrlParam('modalNewsId')
	}

	return (
		<div>
			<div className="mt-[16px] grid grid-cols-1 gap-[24px] md:grid-cols-3">
				{/* Search Box */}
				<div className="w-full md:col-span-1">
					<label className="mb-[16px] block text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">{config.pageLabels.searchLabel}</label>
					<NewsSearchBox
						handleSearch={handleSearch}
						initialValue={searchTerm ?? undefined}
						placeholder={config.pageLabels.searchPlaceholder}
						customWidthClass="w-full"
					/>
				</div>

				{/* Faculty Filter */}
				<div className="w-full">
					<label className="mb-[16px] block text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">{config.pageLabels.facultyFilterLabel}</label>
					<NewsMultipleSelect
						options={config.faculty.map((faculty) => faculty)}
						selectedIndexes={getSelectedIndexes(newsFacultyFilter, config.faculty)}
						onChangeSelection={(newIndexes) => handleSelectionChange(newIndexes, setFacultyFilter, config.faculty, 'faculty')}
						placeholder={config.pageLabels.allFacultyName ?? 'Select Faculty'}
						selectionLabel={newsFacultyFilter.map((id) => config.faculty.find((faculty) => faculty.id === id)?.name).join(', ')}
						colour="pink"
						dropdownAlign="right"
					/>
				</div>

				{/* Category Filter */}
				<div className="w-full">
					<label className="mb-[16px] block text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">{config.pageLabels.categoryFilterLabel}</label>
					<NewsMultipleSelect
						options={config.article_category.map((category) => category)}
						selectedIndexes={getSelectedIndexes(newsCategoryFilter, config.article_category)}
						onChangeSelection={(newIndexes) => handleSelectionChange(newIndexes, setArticleCategoryFilter, config.article_category, 'category')}
						placeholder={config.pageLabels.allCategoryName ?? 'Select Category'}
						selectionLabel={newsCategoryFilter.map((id) => config.article_category.find((category) => category.id === id)?.name).join(', ')}
						colour="blue"
						dropdownAlign="right"
					/>
				</div>

				{/* Type Filter */}
				{/* <div className="w-full">
					<label className="mb-[16px] block text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">{config.pageLabels.typeFilterLabel}</label>
					<NewsMultipleSelect
						options={config.article_type.map((type) => type.name)}
						selectedIndexes={getSelectedIndexes(newsTypeFilter, config.article_type)}
						onChangeSelection={(newIndexes) => handleSelectionChange(newIndexes, setArticleTypeFilter, config.article_type)}
						placeholder={config.pageLabels.allTypeName ?? 'Select Type'}
						selectionLabel={newsTypeFilter.map((id) => config.article_type.find((type) => type.id === id)?.name).join(', ')}
						colour="orange"
					/>
				</div> */}
			</div>

			<div className="mb-[12px] flex w-full flex-col justify-between gap-y-[12px] sm:flex-row">
				<div className={cx(news?.length > 0 ? '' : '', 'mt-[24px] min-h-[16px] text-xs font-normal leading-4 text-gray-600')}>
					{/* {news != null && config.pageLabels.foundRecordCountLabel?.replace('$record-count', news.length.toString() ?? '')} */}
					{/* {searchTerm != null && searchTerm.length > 0 && config.pageLabels.searchTermLabel?.replace('$search-term', searchTerm ?? '')} */}
				</div>
				<div className="mt-[24px]">
					<NewsListGridViewButtons
						gridView={isGridView}
						setGridView={handleGridViewChange}
					/>
				</div>
			</div>  
			<div className="min-h-[400px]">
				<div>
					{news == null ? (
						<div className="mt-[100px] flex flex-col items-center text-center">
							<div className="mb-[18px] flex h-[50px] w-[50px] items-center justify-center rounded-full bg-gray-100">
								<Icon
									icon="search"
									className="mt-[4px] h-[22px] w-[22px] text-uom-navy-800"
								/>
							</div>
							<div className="text-gray-500">Find news records using the search and filters above</div>
						</div>
					) : !isLoading && news?.length <= 0 ? (
						<div className="mt-[100px] text-center text-gray-500">No results found</div>
					) : isGridView ? (
						<div className="mb-8">
							<Masonry
								breakpointCols={breakpointColumnsObj}
								className="masonry-grid"
								columnClassName="masonry-grid_column"
							>
								{news.map((newsItem) => (
									<NewsRecordCard
										key={newsItem.id}
										newsItem={newsItem}
										isGridView={isGridView}
									/>
								))}
							</Masonry>
						</div>
					) : (
						<div className="grid gap-2 lg:w-4/5">
							{news.map((newsItem, index) => (
								<NewsRecordCard
									key={newsItem.id}
									newsItem={newsItem}
									isGridView={isGridView}
									className={cx(index === news.length - 1 ? 'border-b-0' : 'border-b-2 border-gray-200')}
								/>
							))}
						</div>
					)}
				</div>

				{/* <NewsRecordModal
				openModalNewsRecord={openModalNewsRecord}
				close={handleCloseModal}
			/> */}

				{/* Show More Button */}
				{isLoading ? (
					<div className={cx(news?.length > 0 ? '-mt-[100px]' : '', "mb-4 flex justify-center")}>
						<LoadingAnimation />
					</div>
				) : (
					news.length < (totalCount ?? 0) && (
						<div className="mb-4 flex justify-center">
							<button
								onClick={handleShowMore}
								className="rounded-md border border-gray-400 px-4 py-2 text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300"
							>
								View more
							</button>
						</div>
					)
				)}
			</div>
		</div>
	)
}

export default NewsRecordsPage
