import React from 'react'
import {ContactCard, ContactCardProps} from '~/cards/ContactCard'
import {ListingCard} from '~/cards/ListingCard'
import {GeneralCard} from './GeneralCard'
import {IconCard, IconCardProps} from './IconCard'
import {ImageCard, ImageCardProps} from './ImageCard'
import {LeftBorderCard} from './LeftBorderCard'
import {LinkCard, LinkCardProps} from './LinkCard'
import {LogoCard, LogoCardProps} from './LogoCard'
import {ServiceNowCard} from './ServiceNowCard'
import {StaffProfileCard, StaffProfileCardProps} from './StaffProfileCard'

export type CardTypes =
	| 'general'
	| 'feature'
	| 'form'
	| 'support-article'
	| 'supportArticle'
	| 'left-border'
	| 'logo'
	| 'icon'
	| 'link'
	| 'image'
	| 'staff-profile'
	| 'profile'
	| 'contact'
	| 'header-image'
	/* Types below are old and should be removed once they are no longer used on the site */
	| 'left-boarder'
	| 'link-with-left-boarder'
	| 'link-with-logo'
	| 'modal-button'

export type CardProps = IconCardProps & ImageCardProps & LinkCardProps & LogoCardProps & StaffProfileCardProps & ContactCardProps

export interface CommonCardProps {
	type?: CardTypes
	url?: string
	displayUrl?: string
	title?: string
	body?: string
	linkLabel?: string
	linkTarget?: string
	autoLinkBody?: boolean
	modalContent?: string

	className?: string
	lineClamp?: boolean
	horizontalLayout?: boolean

	linkType?: string // link, email, phone
	listLayout?: boolean
}

export function Card(cardProps: CardProps) {
	if (cardProps.type === 'general' || cardProps.type === 'feature') {
		return <GeneralCard {...cardProps} />
	}
	if (cardProps.type === 'form' || cardProps.type === 'support-article') {
		return <ServiceNowCard {...cardProps} />
	}
	if (cardProps.type === 'left-border' || cardProps.type === 'left-boarder' || cardProps.type === 'link-with-left-boarder') {
		return <LeftBorderCard {...cardProps} />
	}
	if (cardProps.type === 'logo' || cardProps.type === 'link-with-logo') {
		return <LogoCard {...cardProps} />
	}
	if (cardProps.type === 'icon' || cardProps.type === 'modal-button') {
		return <IconCard {...cardProps} />
	}
	if (cardProps.type === 'link') {
		return <LinkCard {...cardProps} />
	}
	if (cardProps.type === 'image') {
		return <ImageCard {...cardProps} />
	}
	if (cardProps.type === 'staff-profile' || cardProps.type === 'profile') {
		return <StaffProfileCard {...cardProps} />
	}
	if (cardProps.type === 'contact') {
		return <ContactCard {...cardProps} />
	}
	if (cardProps.type === 'header-image') {
		return <ListingCard {...cardProps} />
	}
	return null
}
