import React, {useEffect, useRef, useState} from 'react'
import {queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx, replaceWithNewDiv} from '~/utils/utils'
import './Header.css'

interface HeaderProps {
	heading?: string
	colour: 'blue' | 'teal'
	paragraphs: string[]
	midAlign: boolean
	author?: string
	authorExtraInfo?: string
}

export function initHeader() {
	document.querySelectorAll<HTMLElement>('header.with-filters').forEach((element) => {
		const p = element.querySelector(':scope > p')
		const filteredListingWrapper = element.querySelector('.filtered-listing-wrapper')

		if (element.classList.contains('teal')) {
			filteredListingWrapper?.classList.add('teal')
		}

		if (p != null) {
			p.classList.add('filtered-listing-description')
			filteredListingWrapper?.appendChild(p)
		}

		if (filteredListingWrapper != null) {
			element.parentElement?.insertBefore(filteredListingWrapper, element)
		}
		element.remove()
	})

	document.querySelectorAll<HTMLElement>(':not(article) > header:not(.darker)').forEach((element) => {
		const props: HeaderProps = {
			heading: queryTextContent('h1', element),
			colour: element.classList.contains('teal') ? 'teal' : 'blue',
			paragraphs: Array.from(element.querySelectorAll(':scope > p:not(.author), :scope > * > p:not(.author)')).map((p) => p.innerHTML),
			midAlign: element.querySelector('.mid-align') != null && !element.classList.contains('banner'),
			author: queryTextContent('p.author em', element),
			authorExtraInfo: element.querySelector('p.author')?.innerHTML.split('<br>')[1],
		}

		const div = replaceWithNewDiv(element)
		div.classList.add('doesnt-need-gap')

		render(<Header {...props} />, div)
	})
}

function Header(props: HeaderProps) {
	const [height, setHeight] = useState(0)
	const headerRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const handleWindowResize = () => {
			if (headerRef?.current != null) {
				setHeight(headerRef.current.clientHeight)
			}
		}

		// This is need to set the initial value
		setTimeout(() => {
			handleWindowResize()
		}, 200)

		window.addEventListener('resize', handleWindowResize)
		return () => window.removeEventListener('resize', handleWindowResize)
	}, [])

	const hasHeading = props.heading != null && props.heading?.length > 0

	return (
		<header className={'full-width-background banner-background banner text-white ' + props.colour}>
			<div
				ref={headerRef}
				className={cx(props.midAlign ? 'justify-center' : 'justify-between', 'relative flex gap-x-[24px]')}
			>
				<div className={cx(props.midAlign ? 'w-full text-center' : '', 'flex max-w-[792px] flex-grow flex-col gap-y-[24px] py-[48px]')}>
					{hasHeading && <h1 className="!m-0 !p-0 text-3xl font-extrabold leading-8 tracking-tight !text-white">{props.heading}</h1>}
					{props.paragraphs.map((paragraph, index) => (
						<p
							key={index}
							className="!m-0 !p-0 text-lg font-normal leading-6"
							dangerouslySetInnerHTML={{__html: paragraph ?? ''}}
						/>
					))}
				</div>
				{!props.midAlign && (
					<div
						className={cx(
							height <= 180 ? 'w-[calc(var(--height)*1.46)]' : '',
							height > 180 && height <= 250 ? 'w-[calc(var(--height)*1.26)]' : '',
							height > 250 ? 'w-[calc(var(--height)*0.96)]' : '',
							'-mr-4 hidden object-cover sm:-mr-6 md:block lg:-mr-8 xl:mr-0',
						)}
						style={{['--height' as any]: height + 'px'}}
					>
						<Aperture
							colour={props.colour}
							height={height}
						/>
					</div>
				)}
			</div>
		</header>
	)
}

function Aperture({height, colour}: {height: number; colour: 'blue' | 'teal'}) {
	const isBlue = colour === 'blue'

	const aperturePrimaryColour = isBlue ? '#005FA3' : '#32869C'
	const apertureSecondaryColour = isBlue ? '#0077CC' : '#3FA7C2'

	if (height < 180) {
		return (
			<svg
				className="h-full"
				viewBox="0 0 234 160"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					y="107.879"
					width="223.275"
					height="223.275"
					transform="rotate(-30 0 107.879)"
					fill={aperturePrimaryColour}
				/>
				<rect
					x="20.4825"
					y="43.7878"
					width="223.275"
					height="223.275"
					transform="rotate(-15 20.4825 43.7878)"
					fill={apertureSecondaryColour}
				/>
			</svg>
		)
	}

	if (height < 250) {
		return (
			<svg
				className="h-full"
				viewBox="0 0 252 200"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					y="144.412"
					width="348.825"
					height="348.825"
					transform="rotate(-30 0 144.412)"
					fill={aperturePrimaryColour}
				/>
				<rect
					x="32"
					y="44.2826"
					width="348.825"
					height="348.825"
					transform="rotate(-15 32 44.2826)"
					fill={apertureSecondaryColour}
				/>
			</svg>
		)
	}

	return (
		<svg
			className="h-full"
			viewBox="0 0 286 298"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="49.5749"
				y="194.272"
				width="348.825"
				height="348.825"
				transform="rotate(-39.8974 49.5749 194.272)"
				fill={aperturePrimaryColour}
			/>
			<rect
				x="66.3132"
				y="60.0112"
				width="348.825"
				height="348.825"
				transform="rotate(-25.4614 66.3132 60.0112)"
				fill={apertureSecondaryColour}
			/>
		</svg>
	)
}
