import React from 'react'
import {StaffNewsArticle, StaffNewsArticleCard} from '~/cards/StaffNewsArticleCard'
import {Pagination} from '~/components/Pagination'
import {getNewsArticles} from '~/pages/news/getNewsArticles'
import {queryDataSet, queryHref, queryTextContent} from '~/utils/query'
import {renderToMain} from '~/utils/render'
import {pageType} from '~/utils/utils'
import {Issue, RecentIssues} from './RecentIssues'

export interface StaffNewsPageProps {
	title?: string
	articles?: StaffNewsArticle[]
	submitStoryUrl?: string
	currentPage?: number
	numberOfPages?: number
	adminCreateNewIssueUrl?: string
	adminManageUrl?: string
	adminDocumentationUrl?: string

	recentIssues: Issue[]
	unpublishedIssues: Issue[]
}

export const initStaffNewsPage = () => {
	if (pageType === 'staff-news') {
		/*document.querySelector('[role="main"]')?.classList.add('hidden')*/
		document.body.classList.add('block-right-sidebar')

		const props: StaffNewsPageProps = {
			title: queryTextContent('[data-sh-2021="page-title"]'),
			articles: getNewsArticles('[data-sh-2021="articles"] > li '),
			submitStoryUrl: queryHref('[data-sh-2021="submit-story"]'),
			currentPage: parseInt(queryDataSet('[data-sh-2021="articles"]')?.currentPage ?? ''),
			numberOfPages: parseInt(queryDataSet('[data-sh-2021="articles"]')?.numberOfPages ?? ''),
			adminCreateNewIssueUrl: queryHref('[data-sh-2021="admin-create-new-issue"]'),
			adminManageUrl: queryHref('[data-sh-2021="admin-manage"]'),
			adminDocumentationUrl: queryHref('[data-sh-2021="admin-documentation"]'),

			recentIssues: Array.from(document.querySelectorAll<HTMLAnchorElement>('[data-sh-2021="recent-issues"] > li > a ')).map((issue) => ({
				url: issue.href,
				title: issue?.textContent ?? '',
			})),
			unpublishedIssues: Array.from(document.querySelectorAll<HTMLAnchorElement>('[data-sh-2021="unpublished-issues"]')).map((issue) => ({
				url: issue.href,
				title: issue?.textContent ?? '',
				status: issue.dataset.status,
			})),
		}
		renderToMain(<StaffNewsPage {...props} />)
	}
}
export default function StaffNewsPage(props: StaffNewsPageProps) {
	return (
		<div className="pb-12">
			<div className="flex justify-between border-b-2 border-gray-200 pb-[23px] pt-[10px] md:pt-[30px]">
				<h1 className="block-uplift text-3xl font-extrabold leading-8 tracking-tight text-gray-900">{props.title}</h1>
				<a
					href={props.submitStoryUrl}
					className="hidden items-center rounded-md border border-transparent bg-uom-navy-900 px-[17px] py-2 text-sm font-medium leading-5 text-white shadow-sm hover:bg-uom-navy-dark hover:no-underline focus:outline-none md:inline-flex"
				>
					Submit Story
				</a>
			</div>
			<a
				href={props.submitStoryUrl}
				className="mt-[24px] flex w-full items-center justify-center rounded-md border border-transparent bg-uom-navy-900 px-[17px] py-2 text-sm font-medium leading-5 text-white shadow-sm hover:bg-uom-navy-dark hover:no-underline focus:outline-none md:hidden"
			>
				Submit Story
			</a>
			{props.adminCreateNewIssueUrl && (
				<div className="pt-6 text-sm text-gray-600">
					<h3 className="block-uplift text-xs font-medium uppercase leading-4 tracking-wider">Admin</h3>
					<div className="block flex gap-x-2 text-sm">
						<a
							href={props.adminCreateNewIssueUrl}
							className="text-blue-700 underline"
						>
							Create new issue
						</a>
						<a
							href={props.adminManageUrl}
							className="text-blue-700 underline"
						>
							Manage
						</a>
						<a
							href={props.adminDocumentationUrl}
							className="text-blue-700 underline"
						>
							Documentation
						</a>
					</div>
					{props.unpublishedIssues && (
						<div className="pt-5">
							<h3 className="block-uplift text-xs font-medium uppercase leading-4 tracking-wider">Unpublished Issues</h3>
							<ul className="no-list block-uplift test-sm pt-2">
								{props.unpublishedIssues.map((issue) => (
									<li key={issue.url}>
										<a
											href={issue.url}
											className="pb-1 text-blue-700 underline"
										>
											{issue.title}
										</a>{' '}
										<span className="ml-1.5 text-[.8rem]">({issue.status})</span>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
			<RecentIssues issues={props.recentIssues} />
			<div className="grid gap-5 pb-[84px] pt-[24px] md:grid-cols-2 lg:grid-cols-3">
				{props.articles?.map((article) => (
					<StaffNewsArticleCard
						key={article.url}
						article={article}
					/>
				))}
			</div>
			{props.numberOfPages != null && props.numberOfPages > 0 && (
				<Pagination
					currentPage={props.currentPage ?? 1}
					goToPage={(newPageNumber) => (window.location.href = '/staff-news?result_3495978_result_page=' + newPageNumber)}
					numberOfPages={props.numberOfPages}
				/>
			)}
		</div>
	)
}
