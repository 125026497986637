import React from 'react'
import {EmbedSurvey} from '~/blocks/EmbedSurvey'
import {ResultsLevels} from './ResultsLevels'
import {SuggestedCourses} from './SuggestedCourses'
import {labels, survey, useSelfGuidanceToolStore} from './useSelfGuidanceToolStore'
import {Icon} from '~/blocks/Icon'

export function Results() {
	const {setShowResults} = useSelfGuidanceToolStore()

	return (
		<div className="tag-results">
			<button
				className="mt-[-20px] flex items-center text-blue-700 md:mt-0"
				onClick={() => setShowResults(false)}
			>
				<Icon
					icon="arrow-back"
					className="mr-2 inline w-[19px]"
					aria-hidden="true"
				/>{' '}
				{labels.resultsBackToQuestionsLinkLabel}
			</button>
			<h1 className="!mt-[16px] !py-0 !text-2xl md:!mt-[32px] md:!text-3xl">{labels.resultsHeader}</h1>
			<p className="!pt-[16px] !pb-0">{labels.resultsLead}</p>
			{survey.url != null && survey.url.length > 0 && (
				<div className="mt-[48px]">
					<EmbedSurvey {...survey} />
				</div>
			)}
			<ResultsLevels />
			<SuggestedCourses />
		</div>
	)
}
