import { useEffect, useState } from 'react'

export interface NewsItem {
	id: number
	issue: {no: number}[]
	news_type: string
	amplify_news: boolean
	faculty_1: string | null
	faculty_2: string | null
	faculty_3: string | null
	editorial_contact: string
	news_headline: string
	brief_text: string
	published_contact_email: string
	link_info: string
	existing_page_url: string | null
	detailed_news_story: string
	author_name: string | null
	file_upload: string | null
	file_description: string | null
	created_date: string
	published_date: string
	start_date: string
	end_date: string
	category_1: string
	category_2: string | null
	category_3: string | null
}

export const useNewsData = (searchTerm: string | null, facultyFilter: string[], categoryFilter: string[], offset: number, issue: string | null) => {
	const [news, setNews] = useState<NewsItem[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const [totalCount, setTotalCount] = useState<number | null>(null)
	const [triggerFetch, setTriggerFetch] = useState(false)

	const config = (window as any).newsRecordsConfig

	const fetchData = async () => {
		try {
			setIsLoading(true)

			let url = new URL(config.allNewsUrl)
			url.searchParams.append('limit', config.limit.toString())
			url.searchParams.append('offset', offset.toString())

			if (searchTerm) {
				url.searchParams.append('search', searchTerm)
			}

			if (facultyFilter.length > 0) {
				url.searchParams.append('faculty', facultyFilter.join(','))
			}
			if (categoryFilter.length > 0) {
				url.searchParams.append('category', categoryFilter.join(','))
			}

			if (issue) {
				url.searchParams.append('issue', issue)
			  }

			const response = await fetch(url.toString(), { 
				headers: {Accept: 'application/json'},
			})

			if (!response.ok) throw new Error('Failed to fetch news')

			const newsData = await response.json()
			setTotalCount(newsData.count)
			const newItems = newsData.results.filter((item: NewsItem) => !news.some((existingItem) => existingItem.id === item.id))
			setNews((prevNews) => (offset === 0 ? newItems : [...prevNews, ...newItems]))
		} catch (error) {
			setError((error as Error).message || 'An unknown error occurred')
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		setNews([])
		setTriggerFetch((prev) => !prev)
	}, [searchTerm, facultyFilter, categoryFilter, issue])

	useEffect(() => {
		fetchData()
	}, [offset, triggerFetch])

	return {news, isLoading, error, totalCount}
}



export const useNewsRecord = (id: string | null) => {
	const [newsItem, setNewsItem] = useState<NewsItem | null>(null)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)

	const config = (window as any).newsItemConfig

	const fetchNewsItem = async () => {
		if (!id) return
		try {
			setIsLoading(true)
			const url = `${config.singleNewsUrl}?id=${id}`
			const response = await fetch(url, {headers: {Accept: 'application/json'}})

			if (!response.ok) throw new Error('Failed to fetch news item')

			const newsData = await response.json()
			setNewsItem(newsData)
		} catch (error) {
			setError((error as Error).message || 'An unknown error occurred')
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (id) {
			fetchNewsItem()
		}
	}, [id])

	return {newsItem, isLoading, error}
}

export const useNewsListingData = (offset: number, category: string | null, issue: string | null) => {
	const [news, setNews] = useState<NewsItem[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const [totalCount, setTotalCount] = useState<number | null>(null)
	const [fullname, setFullname] = useState<string | null>(null)
	const [triggerFetch, setTriggerFetch] = useState(false)

	const config = (window as any).newsRecordsConfig

	const fetchData = async () => {
		try {
			setIsLoading(true)

			let url = new URL(config.allNewsUrl)
			url.searchParams.append('limit', config.limit.toString())
			url.searchParams.append('offset', offset.toString())

			if (category) {
				url.searchParams.append('category', category)
			  }

			if (issue) {
				url.searchParams.append('issue', issue)
			  }

			const response = await fetch(url.toString(), { 
				headers: {Accept: 'application/json'},
			})

			if (!response.ok) throw new Error('Failed to fetch news')

			const newsData = await response.json()
			setTotalCount(newsData.news.length)
			setFullname(
				newsData.consolidated_article_meta.button_label
				  ? newsData.consolidated_article_meta.button_label.charAt(0).toUpperCase() +
					newsData.consolidated_article_meta.button_label.slice(1)
				  : newsData.consolidated_article_meta.name
			  );	
			const newItems = newsData.news.filter((item: NewsItem) => !news.some((existingItem) => existingItem.id === item.id))
			setNews((prevNews) => (offset === 0 ? newItems : [...prevNews, ...newItems]))
		} catch (error) {
			setError((error as Error).message || 'An unknown error occurred')
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		setNews([])
		setTriggerFetch((prev) => !prev)
	}, [category, issue])

	useEffect(() => {
		fetchData()
	}, [offset, triggerFetch])

	return {news, isLoading, error, totalCount, fullname}
}
