import {Disclosure, Transition} from '@headlessui/react'
import React, {Fragment} from 'react'
import {Icon} from '~/blocks/Icon'
import {SocialIcon} from '~/images/SocialIcon'
import {UomLogo} from '~/images/UomLogo'
import {render} from '~/utils/render'
import {cx, isEven} from '~/utils/utils'
import {useHideFooter} from './useHideFooter'

interface FooterProps {
	traditionalOwners: TraditionalOwners
	aboutLinks: Link[]
	socialLinks: Link[]
	contactLinks: Link[]
	legalLinks: Link[]
	idNumbers: string[]
}

interface TraditionalOwners {
	acknowledgement: string
	reconciliation: Link
}

interface Link {
	label?: string
	url: string
	icon?: string
}

export const initGlobalFooter = () => {
	const props: FooterProps = (window as any).footerContent

	let footer = document.querySelector('body > footer')
	if (footer == null) {
		footer = document.createElement('footer')
		document.body.append(footer)
	}

	document.querySelectorAll('[id^=content_container_]').forEach((element) => {
		if (element.textContent == null || element.textContent.trim().length <= 0) {
			element.remove()
		}
	})
	const withLoginLastElementChild = (document.querySelector('[data-sh-page-type]') ?? document.querySelector('[role="main"]'))?.lastElementChild
	if (
		withLoginLastElementChild != null &&
		!(
			withLoginLastElementChild.classList.contains('doesnt-need-gap') ||
			withLoginLastElementChild.classList.contains('doesnt-need-gap-without-padding') ||
			withLoginLastElementChild.querySelector('.doesnt-need-gap, .doesnt-need-gap-without-padding') != null
		)
	) {
		footer.classList.add('mt-12')
	}

	footer.classList.add('relative', 'bg-white')
	render(<Footer {...props} />, footer)
}

function Footer({traditionalOwners, aboutLinks, socialLinks, contactLinks, legalLinks, idNumbers}: FooterProps) {
	const {isFooterHidden} = useHideFooter()

	return (
		<div className={cx(isFooterHidden ? 'hidden' : '')}>
			<div
				id="footer"
				className="relative z-[3] bg-uom-navy-900 !text-white"
			>
				<div className="container flex flex-col gap-y-[50px] py-[48px] md:flex-row md:justify-between md:py-[64px]">
					<div className="flex min-h-full max-w-[500px] flex-col justify-between lg:max-w-[588px]">
						<section>
							<h4 className="block-uplift text-lg font-semibold leading-6">{traditionalOwners.acknowledgement}</h4>
							<a
								className="block pt-[20px] text-sm font-medium text-white hover:underline"
								href={traditionalOwners.reconciliation?.url}
							>
								{traditionalOwners.reconciliation?.label}{' '}
								<Icon
									icon="arrow-forward"
									className="mb-[4px] ml-1 inline h-[16px] w-auto"
								/>
							</a>
						</section>
						<section className="pt-[24px] md:hidden">
							<Disclosure>
								<Disclosure.Button className="text-sm font-medium hover:underline md:hidden">
									<span>Additional links</span>
									<Icon
										icon="chevron-down"
										className="mb-[6px] ml-[8px] inline h-[20px] w-auto ui-open:rotate-180"
									/>
								</Disclosure.Button>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="opacity-0 scale-95"
									enterTo="opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="opacity-100 scale-100"
									leaveTo="opacity-0 scale-95"
								>
									<Disclosure.Panel>
										<ul className="no-list block-uplift grid grid-cols-1 gap-x-[15px] gap-y-[20px] !pt-[20px]">
											{aboutLinks.map((link) => (
												<li key={link.label}>
													<a
														className="block text-sm font-medium text-white hover:underline"
														href={link.url}
													>
														{link.label}{' '}
														<Icon
															icon="arrow-forward"
															className="mb-[4px] ml-1 inline h-[16px] w-auto"
														/>
													</a>
												</li>
											))}
										</ul>
									</Disclosure.Panel>
								</Transition>
							</Disclosure>
						</section>
						<section className="hidden pt-[52px] md:block">
							<ul className="no-list block-uplift grid grid-cols-2  gap-x-[80px] gap-y-[20px] pt-0">
								{aboutLinks.map((link) => (
									<li key={link.label}>
										<a
											className="block text-sm font-medium text-white hover:underline"
											href={link.url}
										>
											{link.label}{' '}
											<Icon
												icon="arrow-forward"
												className="mb-[4px] ml-1 inline h-[16px] w-auto"
											/>
										</a>
									</li>
								))}
							</ul>
						</section>
					</div>
					<div className="flex flex-col-reverse items-center justify-between gap-y-[50px] md:flex-col md:items-end md:gap-y-[65px]">
						<section className="flex flex-col items-center">
							<a
								className="block"
								href="https://unimelb.edu.au"
							>
								<UomLogo className="block h-[116px] w-auto" />
							</a>
							<div className="flex flex-nowrap gap-x-[15px] pt-[24px]">
								{socialLinks.map((social) => (
									<a
										key={social.url}
										className="block text-white hover:no-underline"
										href={social.url}
									>
										<SocialIcon
											name={social.icon}
											className="w-[24px]"
										/>
									</a>
								))}
							</div>
						</section>
						<section>
							<ul className="no-list block-uplift flex flex-col items-center gap-y-[24px] text-right text-sm font-medium md:items-end">
								{contactLinks.map((contactMethod) => (
									<li key={contactMethod.url}>
										<a
											className="text-white hover:underline"
											href={contactMethod.url}
										>
											<Icon
												icon={contactMethod.icon}
												className="mb-[4px] mr-[15px] inline h-[18px] w-[18px]"
											/>
											{contactMethod.label}
											<Icon
												icon="arrow-forward"
												className="mb-[4px] ml-1 inline h-[16px] w-auto"
											/>
										</a>
									</li>
								))}
							</ul>
						</section>
					</div>
				</div>
			</div>
			<div className="container z-[3] flex flex-col items-center gap-y-[24px] whitespace-nowrap py-[24px] text-sm font-medium md:py-[24px] lg:flex-row lg:justify-between">
				<ul className="no-list block-uplift grid grid-cols-2 gap-[24px] text-blue-700 md:flex">
					{legalLinks.map((legalLink, index) => (
						<li
							key={legalLink.label}
							className={cx(isEven(index) ? 'text-right' : '')}
						>
							<a
								className="underline"
								href={legalLink.url}
							>
								{legalLink.label}
							</a>
						</li>
					))}
				</ul>
				<ul className="no-list block-uplift grid grid-cols-2 gap-x-[24px] md:flex">
					{idNumbers.map((id, index) => (
						<li
							key={id}
							className={cx(isEven(index) ? 'text-right' : '')}
						>
							{id}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
