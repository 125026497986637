import React, {useRef} from 'react'
import {cx} from '~/utils/utils'
import {LevelPips} from './LevelPips'
import {levelLabels, useSelfGuidanceToolStore} from './useSelfGuidanceToolStore'

export function QuestionLevel({index, question, levelDescription}: {index: number; question: {id: string; levels: string[]}; levelDescription: string}) {
	const {answers, setLevel} = useSelfGuidanceToolStore()
	const radioButtonRef = useRef<HTMLInputElement>(null)

	const isChecked = answers[question.id] != null && answers[question.id]?.level === index * 50 && !answers[question.id]?.none
	return (
		<div
			className={cx(isChecked ? '!bg-blue-50' : '', 'group rounded-lg border-2 border-transparent p-[24px] shadow focus-within:border-blue-700 focus-within:!bg-blue-50 hover:bg-gray-100')}
			onClick={() => {
				setLevel(question.id, index * 50)
				radioButtonRef.current?.focus()
			}}
		>
			<div className="mb-[24px] flex justify-between">
				<LevelPips
					level={index + 1}
					forceEmptyWhiteBackground={isChecked}
				/>
				<input
					ref={radioButtonRef}
					className={cx('tag-question-level !outline-none !ring-0', `tag-question-level-${index}`, isChecked && 'tag-checked')}
					onChange={() => {
						setLevel(question.id, index * 50)
					}}
					type="radio"
					checked={isChecked}
					disabled={answers[question.id] != null && answers[question.id].none}
				/>
			</div>
			<div className="pb-[4px] text-base font-semibold leading-6 text-gray-900">{levelLabels[index]}</div>
			<label className="text-sm font-normal leading-5 text-gray-600">{levelDescription}</label>
		</div>
	)
}
