import React from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'
import {CardWrapper} from './CardWrapper'

interface ListingCardProps {
	url?: string
	imageUrl?: string
	imageHeightClass?: string
	title?: string
	titleTag?: string
	titleId?: string
	body?: string
	linkLabel?: string
	mobileFixedWidth?: boolean
	textOnly?: boolean
	noCardBoarder?: boolean
	modalContent?: string
	fixedImageHeight?: boolean
}

export function ListingCard({url, imageUrl, imageHeightClass, title, titleTag, titleId, body, linkLabel, mobileFixedWidth, textOnly, noCardBoarder, fixedImageHeight}: ListingCardProps) {
	const wholeCardIsLink = url != null && url.length > 0

	return (
		<CardWrapper
			className={cx(
				mobileFixedWidth ? 'w-[300px] md:w-auto' : 'w-auto',
				noCardBoarder ? '' : 'overflow-hidden rounded-lg shadow',
				wholeCardIsLink ? 'hover:no-underline hover:shadow-lg' : '',
				'listing-card focus:outline-blue inline-flex flex-shrink-0 flex-col text-gray-600 md:flex-1',
			)}
			url={url}
		>
			{!textOnly && (
				<div className={cx(imageHeightClass, 'flex-shrink-0 rounded-t bg-gray-50')}>
					{imageUrl != null && imageUrl.length > 0 && (
						<img
							className={cx(fixedImageHeight ? 'h-[164px] w-full object-cover' : 'h-full w-full object-fill')}
							src={imageUrl}
							alt=""
						/>
					)}
				</div>
			)}
			<div className={cx(noCardBoarder ? '' : 'p-6', 'flex flex-1  flex-col justify-between bg-white')}>
				<div className="flex-1">
					<div className="mt-2 block">
						{titleTag === 'H2' ? (
							<h2
								id={titleId}
								className="block-uplift text-lg font-semibold leading-6 text-gray-900"
							>
								{title}
							</h2>
						) : (
							<h3 className="block-uplift text-lg font-semibold leading-6 text-gray-900">{title}</h3>
						)}
						<div
							className="mt-3 max-w-content text-base"
							dangerouslySetInnerHTML={{__html: body ?? ''}}
						/>
					</div>
				</div>
				{wholeCardIsLink && (
					<div className="mt-auto pt-6 text-sm font-medium leading-5 text-blue-700 hover:underline">
						{linkLabel && linkLabel.length > 0 ? linkLabel : 'View'}{' '}
						<Icon
							icon="arrow-forward"
							className="mb-[4px] inline h-4 w-4"
						/>
					</div>
				)}
			</div>
		</CardWrapper>
	)
}
