import {Dialog} from '@headlessui/react'
import React, {useState} from 'react'
import Modal from '~/components/Modal'
import {Select} from '~/components/Select'
import {cx} from '~/utils/utils'
import {CourseCard} from './CourseCard'
import {LevelPips} from './LevelPips'
import {labels, levelLabels, sections, useSelfGuidanceToolStore} from './useSelfGuidanceToolStore'

export const SuggestedCourses = () => {
	const {levelForSection, getSuggestedCoursesForSection} = useSelfGuidanceToolStore()
	const [showResultsForSectionIndex, setShowResultsForSectionIndex] = useState<number>(0)
	const [modalOpen, setModalOpen] = useState<string | null>()

	// Create a list of sections without the ones that don't have a "maturity level" because all responses for that section were N/A
	const sectionsWithAMaturityLevel = sections.filter((section, index) => levelForSection(index) !== 0)

	const currentSectionUnfilteredIndex = sections.findIndex((section) => section.title === sectionsWithAMaturityLevel[showResultsForSectionIndex].title)
	const levelForCurrentSection = levelForSection(currentSectionUnfilteredIndex)
	const courses = getSuggestedCoursesForSection(currentSectionUnfilteredIndex)

	const handleCourseClick = (courseUrl: string) => {
		if (localStorage.getItem('set-up-linked-in-learning-modal-shown') == null) {
			localStorage.setItem('set-up-linked-in-learning-modal-shown', Date.now().toString())
			setModalOpen(courseUrl)
		} else {
			window.open(courseUrl ?? '', '_blank')
		}
	}

	return (
		<>
			<h3 className="!mt-[48px] !pb-0">{labels.resultsSuggestionsHeader}</h3>
			<p className="!pt-[16px] pb-[24px]">{labels.resultsSuggestionsLead}</p>
			<div className="border-b border-gray-200">
				{/* Mobile - Select */}
				<div className="md:hidden">
					<label
						htmlFor="tabs"
						className="sr-only"
					>
						Select a tab
					</label>
					<Select
						options={sectionsWithAMaturityLevel.map((section) => section.title ?? '').filter((title) => title.length > 0)}
						onChangeIndex={(index) => {
							if (index == null) return
							setShowResultsForSectionIndex(index)
						}}
						currentIndex={showResultsForSectionIndex}
					/>
				</div>
				{/* Desktop - Tabs */}
				<nav
					className="-mb-px hidden space-x-8 md:flex"
					aria-label="Tabs"
				>
					{sectionsWithAMaturityLevel.map((section, index) => {
						const selected = index === showResultsForSectionIndex
						return (
							<button
								key={section.title}
								className={cx(
									selected ? 'border-blue-700 text-blue-700' : 'border-transparent text-gray-600 hover:border-gray-500',
									'group cursor-pointer whitespace-nowrap border-b-2 pb-2 text-sm font-medium hover:no-underline focus:outline-none',
								)}
								aria-current={selected ? 'page' : undefined}
								onClick={() => setShowResultsForSectionIndex(index)}
							>
								<div className="tab-label rounded-lg px-2 py-2 group-hover:bg-gray-100 group-focus:bg-gray-100">{section.title}</div>
							</button>
						)
					})}
				</nav>
			</div>
			<div className="mb-[24px]">
				<div className="mb-[24px] mt-[24px] flex items-center">
					<LevelPips level={levelForCurrentSection} />
					<span className="ml-[24px] pt-[2px] text-xs font-normal leading-4 text-gray-600">
						{labels.resultsSuggestionsLevelLabel} {levelLabels[levelForCurrentSection - 1]}
					</span>
				</div>
				<div className="text-xs font-normal leading-4 text-gray-600">
					{courses.length} {labels.resultsSuggestionsCountLabel} `{sectionsWithAMaturityLevel[showResultsForSectionIndex].title}`
				</div>
			</div>
			<div className="grid gap-x-[24px] gap-y-[48px] sm:grid-cols-2 lg:grid-cols-3">
				{courses.map((course, courseIndex) => (
					<CourseCard
						course={course}
						handleOnClick={handleCourseClick}
						key={courseIndex}
					/>
				))}
			</div>
			<Modal
				open={modalOpen != null && modalOpen.length > 0}
				close={() => setModalOpen(null)}
				title="hello"
			>
				<div className="sm:flex sm:items-start">
					<div className="">
						<Dialog.Title
							as="h3"
							className="block-uplift text-2xl font-semibold leading-8 text-gray-900"
						>
							{labels.setUpLinkedInLearningModalTitle}
						</Dialog.Title>
						<div className="mt-[12px]">
							<p className="block-uplift p-0 text-sm text-gray-600">{labels.setUpLinkedInLearningModalBody}</p>
						</div>
					</div>
				</div>
				<div className="mt-[12px] flex justify-end gap-x-[12px]">
					<button
						className="button-sm soft"
						onClick={(event) => {
							event.preventDefault()
							window.open(modalOpen ?? '', '_blank')
							setModalOpen(null)
						}}
					>
						{labels.setUpLinkedInLearningModalAlreadyDoneButtonLabel}
					</button>
					<button
						type="button"
						className="button-sm brand"
						onClick={(event) => {
							event.preventDefault()
							window.open(labels?.setUpLinkedInLearningModalDoSetupButtonUrl ?? '', '_blank')
							setModalOpen(null)
						}}
					>
						{labels.setUpLinkedInLearningModalDoSetupButtonLabel}
					</button>
				</div>
			</Modal>
		</>
	)
}
