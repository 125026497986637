import React from 'react'
import {IconInBox} from '~/images/IconInBox'

export function ExternalLinkIconInBox() {
	return (
		<IconInBox
			colour="teal"
			icon="external-link"
		/>
	)
}
