import React from 'react'
import {Icon} from '~/blocks/Icon'
import type {GrantRecord} from '~/pages/grantRecords/useGrantRecordsStore'
import {cx} from '~/utils/utils'
import {config} from '~/pages/grantRecords/GrantRecordsPage'
import * as path from 'path'

interface GrantRecordCardProps {
	grantRecord: GrantRecord
	isGridView: boolean
	className?: string
	searchTerm:string
}

export function GrantRecordCard({grantRecord, isGridView, className, searchTerm}: GrantRecordCardProps) {
	const tagClassesSearch = 'rounded-full bg-green-100 text-gray-600 px-[13px] py-[4px]'
	const tagClassesFaculty = 'rounded-full bg-blue-200 text-blue-800 px-[13px] py-[4px]'
	const tagClassesYear = 'rounded-full bg-pink-100 text-red-900 px-[13px] py-[4px]'
	const tagClassesInternational = 'rounded-full bg-yellow-100 text-yellow-800 px-[13px] py-[4px]'
	return (
		<>
			<a
				href={grantRecord.pdf_url}
				target={'_blank'}
				className={cx(
					isGridView ? '' : 'h-full max-h-[316px]',
					'group flex flex-col rounded-lg border-2 border-transparent bg-white p-6 text-left shadow hover:no-underline hover:shadow-lg  focus:border-blue-700 focus:outline-none',
					className,
				)}
			>
				<div className="flex h-full flex-col text-base font-normal leading-6 text-gray-900">
					<div className="pb-3.5">
						<span className="mb-[4px] pr-1 font-bold">{grantRecord.title} </span>
						<span className="mb-[4px] pr-1 font-medium">({grantRecord.author})</span>
					</div>
					<div>{grantRecord.summary}</div>
					<div className="mb-[24px]"></div>
					<div className={cx(isGridView ? 'flex-grow' : 'sm:flex-row', 'flex flex-col-reverse justify-between gap-y-[24px]')}>
						<div className={cx(isGridView ? '' : 'items-top sm:justify-start', 'flex  min-w-[104px] justify-end text-blue-700')}>
							{grantRecord.pdf_url != null && grantRecord.pdf_url.length > 0 ? (
								<div className="flex items-center">
									{'View '}{(grantRecord.pdf_url.split('.').pop())?.toUpperCase()}{' '}
									<Icon
										icon="external-link"
										className="ml-[16px] h-[16px]"
									/>
								</div>
							) : (
								<div>{/*config.cardLabels.detailsLabel*/}</div>
							)}
						</div>
						<div className={cx(isGridView ? '' : 'sm:justify-end', 'flex flex-wrap gap-[8px] text-xs font-medium  leading-4')}>
							{(searchTerm != null && searchTerm.length > 0) &&  <div className={tagClassesSearch}>{searchTerm}</div>}
							<div className={tagClassesFaculty}>{grantRecord.facultyAbbr1}</div>
							{grantRecord.facultyAbbr2 != "" && <div className={tagClassesFaculty}>{grantRecord.facultyAbbr2}</div>}
							{grantRecord.facultyAbbr3 != "" && <div className={tagClassesFaculty}>{grantRecord.facultyAbbr3}</div>}
							{grantRecord.facultyAbbr4 != "" && <div className={tagClassesFaculty}>{grantRecord.facultyAbbr4}</div>}
							<div className={tagClassesYear}>{grantRecord.year}</div>
							{(grantRecord.international == "1")?  <div className={tagClassesInternational}>Internationalisation</div>:<div className={tagClassesInternational}>General</div>}
						</div>
					</div>
				</div>
			</a>
		</>
	)
}
