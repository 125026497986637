import {UomLogo} from '~/images/UomLogo'
import {GlobalSearchBox} from '~/pages/search/GlobalSearchBox'
import {render} from '~/utils/render'
import BreadCrumbs from './BreadCrumbs'
import {HeaderNavDesktop} from './HeaderNavDesktop'
import {HeaderNavMobile} from './HeaderNavMobile'
import {HeaderQuickAccess} from './HeaderQuickAccess'
import {HeaderSearchMobile} from './HeaderSearchMobile'

export const initGlobalHeader = () => {
	const header = document.createElement('header')
	header.classList.add('z-[900]')
	document.body.prepend(header)

	render(<Header />, header)
}

function Header() {
	return (
		<>
			<div className="fixed top-0 z-[900] w-full bg-white">
				<div
					className="pointer-events-none absolute inset-0 z-20 shadow "
					aria-hidden="true"
				/>
				<div className="md:relative">
					<div className="container md:divide-y md:divide-gray-200">
						<div className="flex h-[80px] justify-between md:relative md:h-16">
							<div className="relative hidden flex-shrink-0 items-center md:flex">
								<a
									className="flex items-center"
									href="https://unimelb.edu.au/"
									aria-label="University of Melbourne homepage"
								>
									<UomLogo className="block h-[65px] w-auto" />
								</a>
								<a
									className="text-md ml-[24px] font-semibold text-gray-900 underline hover:decoration-2"
									href={(window as any).siteRoot ?? '/'}
								>
									{(window as any).siteName}
								</a>
							</div>
							<div className="relative flex hidden flex-1 items-center justify-center px-2 md:flex">
								<div className="w-full sm:max-w-[389px]">
									<GlobalSearchBox />
								</div>
							</div>
							<div className="flex w-full items-center justify-between md:relative md:ml-4 md:w-auto">
								<a
									className="flex items-center md:hidden"
									href="/"
								>
									<UomLogo className="block h-[40px] w-auto rounded-lg" />
								</a>
								<HeaderSearchMobile />
								<HeaderQuickAccess />
								<HeaderNavMobile />
							</div>
						</div>
						<HeaderNavDesktop />
					</div>
				</div>
			</div>
			{/* Used to keep content from been hidden under the fixed header */}
			<div className="mt-[80px] md:mt-[116px]" />
			<BreadCrumbs />
		</>
	)
}
