import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useEffect, useState} from 'react'
import {Icon} from '~/blocks/Icon'
import './HeaderQuickAccessUpdateNotice.css'

interface QuickAccessUpdateNoticeConfig {
	enabled?: boolean
	title?: string
	bodyHTML?: string
	reshowIfLastSeenBefore?: string
}

const config: QuickAccessUpdateNoticeConfig = (window as any).quickAccessUpdateNoticeConfig ?? {}

export default function HeaderQuickAccessUpdateNotice(props: {openQuickAccess: () => void}) {
	if (!config.enabled) return null

	const [open, setOpen] = useState(false)
	const [lastShown] = useState(window.localStorage.getItem('quickAccessUpdateNoticeLastShown'))

	useEffect(() => {
		if (window.innerWidth < 767) return
		const show = lastShown == null || (config.reshowIfLastSeenBefore != null && Number(lastShown) < Date.parse(config.reshowIfLastSeenBefore ?? '0'))

		if (show) {
			window.localStorage.setItem('quickAccessUpdateNoticeLastShown', Date.now().toString())
		}
		setOpen(show)
	}, [])

	useEffect(() => {
		if (open) {
			document.body.classList.add('modal-open')
		} else {
			document.body.classList.remove('modal-open')
		}
	}, [open])

	/* eslint-disable @typescript-eslint/no-empty-function */
	return (
		<Transition.Root
			show={open}
			as={Fragment}
		>
			<Dialog
				as="div"
				className="relative z-[900]"
				onClose={() => {
					/* We don't want the "modal" to close when the outside is clicked */
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10">
					<Dialog.Panel className="container">
						<button
							onClick={() => {
								props.openQuickAccess()
								setOpen(false)
							}}
							aria-controls="quick-access-popover-menu"
							className="hover z-[920] ml-auto mt-[14px] flex h-auto w-auto items-center justify-center rounded-lg border border-gray-600 bg-white px-[11px] py-[7px] text-sm font-medium leading-5 text-gray-600 hover:bg-gray-100 hover:text-gray-900 focus:outline-none hover:focus:bg-gray-100"
						>
							<span className="hidden md:block">Quick Access</span>
							<Icon
								icon="apps"
								className="h-[24px] md:ml-2 md:inline md:h-[18px] md:text-blue-700"
								aria-hidden="true"
							/>
						</button>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="mt-[8px] mr-[38px] flex justify-end">
								<div
									onClick={() => {
										props.openQuickAccess()
										setOpen(false)
									}}
									className="relative mt-[68px] max-w-sm  transform overflow-hidden rounded-lg bg-white p-[24px] text-left shadow-xl transition-all"
								>
									<div className="flex gap-x-[16px]">
										<div className="flex h-[40px] w-[40px] flex-shrink-0 items-center justify-center rounded-lg bg-blue-700">
											<Icon
												icon="apps"
												className="h-[20px] text-white"
												aria-hidden="true"
											/>
										</div>
										<div className="">
											<h3 className="block-uplift text-lg font-semibold leading-6 text-gray-900">{config.title}</h3>
											<div
												className="quick-access-update-notice-body mt-2 text-sm text-gray-600"
												dangerouslySetInnerHTML={{__html: config.bodyHTML ?? ''}}
											/>
										</div>
									</div>
								</div>
								<svg
									className="ml-[8px]"
									width="130"
									height="130"
									viewBox="0 0 130 130"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_6728_214476)">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M5.84594 125.918C66.9712 116.987 96.6616 62.1418 103.412 4.8859C103.53 3.90272 104.423 3.19581 105.406 3.31361C106.389 3.43141 107.096 4.32394 106.978 5.30713C100.042 64.167 69.2003 120.29 6.36241 129.47C5.38375 129.615 4.47306 128.935 4.32808 127.952C4.18309 126.973 4.86276 126.063 5.84594 125.918Z"
											fill="white"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M105.069 6.17112C103.207 8.71744 100.905 11.9389 100.633 12.2968C93.2664 21.9973 84.7892 30.0349 74.8441 37.1121C74.0376 37.6875 72.914 37.4971 72.3385 36.6906C71.7631 35.8842 71.9535 34.7606 72.76 34.1852C82.4061 27.3255 90.6295 19.5326 97.7746 10.1221C98.1914 9.57386 103.361 2.32897 104.516 0.942539C104.988 0.371656 105.432 0.195078 105.54 0.163362C106.111 -0.0269318 106.56 0.0863032 106.886 0.240351C107.253 0.417053 107.788 0.856427 108.06 1.70822C108.34 2.60079 108.467 4.58538 108.54 5.08377C109.654 12.6004 110.243 21.0867 112.382 28.8117C114.421 36.1788 117.873 42.8616 124.792 47.1478C125.635 47.6688 125.893 48.7789 125.372 49.6216C124.851 50.4643 123.741 50.7227 122.898 50.2017C115.164 45.4081 111.199 38.0046 108.92 29.7721C106.795 22.0923 106.152 13.6832 105.069 6.17112Z"
											fill="white"
										/>
									</g>
									<defs>
										<clipPath id="clip0_6728_214476">
											<rect
												width="130"
												height="130"
												fill="white"
												transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
											/>
										</clipPath>
									</defs>
								</svg>
							</div>
						</Transition.Child>
					</Dialog.Panel>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
