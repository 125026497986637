import React from 'react'
import {Select} from '~/components/Select'
import {render} from '~/utils/render'

export const initFormSelect = () => {
	Array.from(document.querySelectorAll<HTMLSelectElement>('select.sq-form-field')).forEach((element) => {
		if (element.disabled) return

		element.classList.add('sr-only')

		const props: FormSelectProps = {
			id: element.id.length > 0 ? element.id : Math.random().toString(36).substring(2, 15),
			hasError: element.closest('.sq-form-question-error') != null,
			defaultValue: element.querySelector<HTMLOptionElement>('option[selected]')?.value,
			options: Array.from(element.querySelectorAll<HTMLOptionElement>('option:not([disabled][selected])')).map((option) => {
				return {
					label: option.innerHTML,
					value: option.value,
				}
			}),
		}

		const div = document.createElement('div')
		div.ariaHidden = 'true'
		element.parentElement?.insertBefore(div, element.nextSibling)

		render(<FormSelect {...props} />, div)
	})
}

interface FormSelectProps {
	id: string
	hasError: boolean
	placeholder?: string
	defaultValue?: string
	options: {
		label: string
		value: string
	}[]
}

const FormSelect = (props: FormSelectProps) => {
	const [currentIndex, setCurrentIndex] = React.useState<number | undefined>(props.options.findIndex((option) => option.value == props.defaultValue))

	const handleSelection = (index: number | undefined) => {
		setCurrentIndex(index)
		const newValue = index != null ? props.options[index].value : ''
		const option = document.querySelector<HTMLOptionElement>(`select#${props.id} > option[value="${newValue}"]`)
		if (option != null) {
			option.selected = true
		}
	}

	return (
		<div className="">
			{props.options != null && props.options.length > 0 && (
				<Select
					className="w-[384px]"
					options={props.options.map((option) => option.label)}
					currentIndex={currentIndex}
					onChangeIndex={handleSelection}
					placeholder={props.placeholder ?? undefined}
					error={props.hasError}
					disabled={false}
				/>
			)}
		</div>
	)
}
