import React from 'react'
import {Icon} from '~/blocks/Icon'
import type {GiftRecord} from '~/pages/giftGovernanceRecords/usesGiftGovernanceRecordsStore'
import {cx} from '~/utils/utils'
import {config} from '~/pages/giftGovernanceRecords/GiftGovernanceRecordsPage'

interface GiftRecordCardProps {
	giftRecord: GiftRecord
	isGridView: boolean
	className?: string
	openModal: (giftRecord: GiftRecord) => void
}

export function GiftRecordCard({giftRecord, isGridView, className, openModal}: GiftRecordCardProps) {
	const tagClasses = 'rounded-full bg-gray-100 px-[13px] py-[4px]'
	return (
		<>
			<a
				href={giftRecord.pdf_url}
				target={'_blank'}
				className={cx(
					isGridView ? '' : 'h-full max-h-[316px]',
					'group flex flex-col rounded-lg border-2 border-transparent bg-white p-6 text-left shadow hover:no-underline hover:shadow-lg  focus:border-blue-700 focus:outline-none',
					className,
				)}
				onClick={() => {
					if (giftRecord.pdf_url == null || giftRecord.pdf_url.length === 0) {
						openModal(giftRecord)
					}
				}}
			>
				<div className="flex h-full flex-col text-base font-normal leading-6 text-gray-900">
					{isGridView ? (
						<div>
							{giftRecord.workday_id != 'null' && giftRecord.workday_id != null && giftRecord.workday_id != '' ? (
								<div>
									<span className="mb-[4px] pr-1 font-medium">{config.cardLabels.workdayIdLabel}:</span>
									{giftRecord.workday_id}
								</div>
							) : null}
							<div>
								<span className="mb-[4px] pr-1 font-medium">{config.cardLabels.idLabel}:</span>
								{giftRecord.gift_id}
							</div>
						</div>
					) : (
						<div className="flex justify-between">
							<div>
								<span className="mb-[4px] pr-1 font-medium">{config.cardLabels.idLabel}:</span>
								{giftRecord.gift_id}
							</div>
							{giftRecord.workday_id != 'null' && giftRecord.workday_id != null && giftRecord.workday_id != '' ? (
								<div>
									<span className="mb-[4px] pr-1 font-medium">{config.cardLabels.workdayIdLabel}:</span>
									{giftRecord.workday_id}
								</div>
							) : null}
						</div>
					)}
					<div>
						<span className="mb-[4px] pr-1 font-medium">{config.cardLabels.projectCodeLabel}:</span>
						{giftRecord.projectCode}
					</div>
					<div className="mb-[24px]">{giftRecord.projectName}</div>
					<div className={cx(isGridView ? 'flex-grow' : 'sm:flex-row', 'flex flex-col-reverse justify-between gap-y-[24px]')}>
						<div className={cx(isGridView ? '' : 'items-top sm:justify-start', 'flex  min-w-[104px] justify-end text-blue-700')}>
							{giftRecord.pdf_url != null && giftRecord.pdf_url.length > 0 ? (
								<div className="flex items-center">
									{config.cardLabels.pdfLabel}{' '}
									<Icon
										icon="external-link"
										className="ml-[16px] h-[16px]"
									/>
								</div>
							) : (
								<div>{config.cardLabels.detailsLabel}</div>
							)}
						</div>
						<div className={cx(isGridView ? '' : 'sm:justify-end', 'flex flex-wrap gap-[8px] text-xs font-medium  leading-4 text-gray-600')}>
							<div className={tagClasses}>{giftRecord.type}</div>
							<div className={tagClasses}>{giftRecord.division}</div>
							{/* {giftRecord.categories.map((category) => (
								<div
									key={category}
									className={tagClasses}
								>
									{category}
								</div>
							))} */}
						</div>
					</div>
				</div>
			</a>
		</>
	)
}