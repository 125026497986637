import React from 'react'
import {Card, CardProps} from '~/cards/Card'

interface Props {
	cards?: CardProps[]
}

export const CardList = ({cards}: Props) => {
	return (
		<div className="flex max-w-[792px] flex-col gap-y-[24px]">
			{cards?.map((cardData, index) => (
				<Card
					key={index}
					{...cardData}
					horizontalLayout={true}
				/>
			))}
		</div>
	)
}
