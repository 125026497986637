import React from 'react'
import {Icon} from '~/blocks/Icon'
import {FormIconInBox} from '~/images/FormIconInBox'
import {SupportArticleIconInBox} from '~/images/SupportArticleIconInBox'
import type {MenuItem} from './HeaderNavDesktop'

export function HeaderMenuItemCard(props: {menuItem: MenuItem}) {
	return (
		<a
			href={props.menuItem.url}
			target={props.menuItem.openInNewTab ? '_blank' : '_self'}
			className="flex flex-col justify-between rounded-lg border-2 border-transparent p-[12px] hover:bg-gray-50 hover:no-underline focus:border-blue-700 focus:bg-gray-50 focus:outline-none"
		>
			<div className="flex md:h-full md:flex-col">
				{props.menuItem.icon != null && (
					<div className="flex-shrink-0 pb-[16px]">
						{props.menuItem.icon === 'kb' && <SupportArticleIconInBox />}
						{props.menuItem.icon === 'form' && <FormIconInBox />}
					</div>
				)}
				<div className="ml-4 text-gray-600 md:ml-0 md:flex md:flex-1 md:flex-col md:justify-between">
					<div>
						<div className="text-base font-medium text-gray-900">{props.menuItem.title}</div>
						<div className="mt-1 text-sm">{props.menuItem.description}</div>
					</div>
					{props.menuItem.linkText && (
						<div className="mt-4 flex items-center text-sm font-medium text-blue-700 hover:underline">
							{props.menuItem.linkText}{' '}
							<Icon
								icon="arrow-forward"
								className="ml-2 h-4 w-4"
							/>
						</div>
					)}
				</div>
			</div>
		</a>
	)
}
