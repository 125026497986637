import React from 'react'
import Masonry from 'react-masonry-css'
import {Card, CardProps} from '~/cards/Card'
import './CardMasonry.css'

interface Props {
	cards?: CardProps[]
}

export const CardMasonry = ({cards}: Props) => {
	return (
		<Masonry
			breakpointCols={{
				default: 3,
				768: 2,
				640: 1,
			}}
			className="card-masonry"
			columnClassName="card-masonry-column"
		>
			{cards?.map((cardData, index) => (
				<Card
					key={index}
					{...cardData}
					lineClamp={false}
				/>
			))}
		</Masonry>
	)
}
